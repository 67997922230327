import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import associations from 'theme/data/associations';
import events from 'theme/data/events/events';
import Associations from 'theme/components/pages/associations/Associations';
import people from 'theme/data/people';
import activities from 'theme/data/activities';
import ProfileIntro from './ProfileIntro';
import ProfileBanner from './Banner';
import Followers from './Followers';
import ActivityLog from './ActivityLog';
import Experiences from './Experiences';
import Education from './Education';
import Events from './Events';
import Photos from './Photos';

const Profile: FC = function Profile() {
	return (
		<>
			<ProfileBanner />
			<Row className="g-3 mb-3">
				<Col lg={8}>
					<ProfileIntro />
					<Associations associations={associations.slice(0, 4)} colBreakpoints={{ sm: 6 }} />
					<div className='mt-3'>
						<ActivityLog activities={activities.slice(5, 9)} />
					</div>
					<Photos />
				</Col>
				<Col lg={4}>
					<div className="sticky-sidebar">
						<Experiences />
						<Education />
						<Events cardTitle="Events" events={events.slice(2, 5)} />
					</div>
				</Col>
			</Row>
			<Followers totalFollowers={people.length} followers={people.slice(0, 12)} colBreakpoints={undefined} />
		</>
	);
};

export default Profile;
