import React, { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { labels } from "features/locale/it-it";

export const KeyButton: React.FC<{ shiftId: string, icon? : ReactElement<any, any> }> = ({ shiftId, icon }) => {
	const { keysButton } = labels;
	const navigate = useNavigate();

	return (
		<Col
			className="patrol-item-column px-0 px-md-2 d-flex justify-content-center"
			onClick={() => navigate(`/shift/${shiftId}/keys`)}
		>
			<Row className="patrol-item-container align-items-center cursor-pointer m-0 my-1 my-md-3 py-1 px-2 pos-rel">
				<div className='container-icon'>
					{icon}
				</div>
				<Col className="patrol-item">
					<Row className="d-flex mb-2">
						<Col className="patrol-title blue-text d-flex align-items-center text-center p-0 col-12">
							{keysButton}
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};