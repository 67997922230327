import BoxDetails from "components/content/BoxDetails";
import "./style.scss";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
	CustomerAddress,
	CustomerResponse,
} from "features/storeManagement/services/models/CustomerResponse";
import { useParams } from "react-router-dom";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetAllAuditsMutation } from "features/storeManagement/services/checklistService";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary";
import ChecklistItem from "components/modules/Checklist/ChecklistItem";
import ChecklistIcon from "assets/icons/Checklist";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";

const ChecklistModulePage = () => {
	const { customer, serviceAddress } = useParams();
	useChangeDocumentTitle(labels.checklistText);
	const [selectedCustomer, setSelectedCustomer] = React.useState<
		CustomerResponse | undefined | null
	>(undefined);
	const [selectedAddress, setSelectedAddress] = React.useState<
		CustomerAddress | undefined | null
	>(undefined);

	const [
		getCustomers,
		{
			data: customersData,
			isLoading: customersIsLoading,
			isError: isCustomersError,
			error: customersError,
		},
	] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData });

	useEffect(() => {
		if (!customersData && !isCustomersError && !customersIsLoading) {
			getCustomers();
		}
	}, [customersData, customersIsLoading]);

	useEffect(() => {
		if (!customersIsLoading && !!customersData) {
			const currentCustomer = customersData?.find(
				(item: CustomerResponse) => item.serviceAddress === serviceAddress
			);
			setSelectedCustomer(currentCustomer || null);
		}
	}, [customersIsLoading, customersData]);

	useEffect(() => {
		if (
			!customersIsLoading &&
			customersData &&
			(selectedCustomer || selectedCustomer === null)
		) {
			const currentAddress = selectedCustomer?.addresses.find(
				(item: CustomerAddress) =>
					customer &&
					item.idCustomer === customer
			);
			setSelectedAddress(currentAddress || null);
		}
	}, [customersIsLoading, selectedCustomer]);

	const [
		getAllAudits,
		{ data: allAudits, isLoading: allAuditsIsLoading, error: auditsError },
	] = useGetAllAuditsMutation({ fixedCacheKey: fixedCacheKeys.allAuditsData });

	useEffect(() => {
		if (!allAuditsIsLoading && !allAudits) {
			getAllAudits({
				idCustomer: customer,
				serviceAddress: serviceAddress,
			});
		}
	}, [allAudits, allAuditsIsLoading]);

	const getChecklist = () => {
		if (allAudits && allAudits.items.length) {
			return allAudits?.items;
		}
		return [];
	};

	const checklist = getChecklist();

	return (
		<ErrorBoundary errorMessage={customersError || auditsError}>
			<Container
				fluid
				className="checklist-module-page-container p-0 px-2 mb-5 mt-4"
			>
				<Suspense isLoading={customersIsLoading || allAuditsIsLoading} fullPage>
					{selectedAddress && (
						<Container fluid className="box-details-container p-1">
							<BoxDetails customerAddress={selectedAddress} />
						</Container>
					)}

					{!!checklist.length && (
						<Container
							fluid
							className="audits-container p-0 mb-5 mt-4 mt-md-5 "
						>
							<Row className="d-flex justify-content-start m-0 px-0">
								{checklist.map((item) => (
									<ChecklistItem
										key={item.id}
										checklist={item}
										internal={false}
										icon={<ChecklistIcon size="lg" />}
									/>
								))}
							</Row>
						</Container>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ChecklistModulePage;
