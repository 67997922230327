import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { labels } from "features/locale/it-it";

import "./style.scss";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import ModulePageTitle from "components/modules/ModulePageTitle";
import PageTitle from "components/modules/PageTitle";
import { useGetParcelsMutation } from "features/storeManagement/services/parcelService";
import {
	Parcel,
	ParcelQuestion,
} from "features/storeManagement/services/models/ParcelsResponse";
import Select from "../../content/FormComponents/Select";
import { renderTheFormComponent } from "../../content/FormComponents/renderTheFormComponent";
import useParcelForm from "./useParcelNewForm";
import { QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import SelectAutoComplete from "components/content/FormComponents/SelectAutoComplete";
import { useGetRecipientsMutation } from "features/storeManagement/services/recipientService";
import {
	parseRecipientsAll,
	parseSingleRecipientCarrier,
	parseSingleRecipientPerson,
} from "./ParcelUtils";
import ServiceParcelPage from "../ParcelItemsPage/ServiceParcelPage";
import Suspense from "features/Suspense";
import { useDispatch } from "react-redux";
import AlertModal from "components/content/modals/AlertModal/AlertModal";
import { AutocompleteOptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

const ParcelItemNewPage: React.FC = () => {
	const { shiftId, parcelId, customer, contract, position, serviceAddress } =
		useParams();
	useChangeDocumentTitle(labels.pageTitle_ParcelPage);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [popupData, setPopupData] = useState<
		GetRecipientsResponse | undefined
	>();
	const [parcelRecipientDefaultValue, setParcelRecipientDefaultValue] =
		useState<AutocompleteOptionsType | undefined>();
	const [parcelDeliveryDefaultValue, setParcelDeliveryDefaultValue] = useState<
		AutocompleteOptionsType | undefined
	>();
	const [autoCompleteType, setAutoCompleteType] = useState<string>();

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const isOperatoreResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;

	const redirectFunction = () => {
		const url = isOperatoreResponsabile
			? `/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}`
			: `/shift/${shiftId}/parcel`;
		navigate(url);
	};

	const [responseErrorModal, setResponseErrorModal] = useState<
		FetchBaseQueryError | SerializedError | undefined
	>(undefined);

	const handleResponseErrorModal = (
		err: FetchBaseQueryError | SerializedError | undefined
	) => {
		setResponseErrorModal(err);
	};

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{ data: currentShiftData, isLoading: currentShiftIsLoading },
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		getRecipients,
		{ data: recipientsData, isLoading: recipientsIsLoading },
	] = useGetRecipientsMutation({
		fixedCacheKey: fixedCacheKeys.recipientsParcelData,
	});

	const [
		getParcelsMutations,
		{ data: parcelsGetData, isLoading: parcelIsLoading, error: parcelError },
	] = useGetParcelsMutation({
		fixedCacheKey: fixedCacheKeys.parcelData,
	});
	useEffect(() => {
		if (shiftId && shiftData && shiftData.parcel && !showConfirmModal) {
			getParcelsMutations({
				shiftId,
				parcelId: shiftData.parcel.id.toString(),
			});

			getRecipients({
				shiftId: shiftId,
				parcelId: shiftData.parcel.id.toString(),
			});
		}
	}, [shiftData, showConfirmModal]);

	useEffect(() => {
		if (
			shiftId &&
			currentShiftData &&
			currentShiftData.parcel &&
			!showConfirmModal
		) {
			getParcelsMutations({
				shiftId,
				parcelId: currentShiftData.parcel.id.toString(),
			});

			getRecipients({
				shiftId,
				parcelId: currentShiftData.parcel.id.toString(),
			});
		}
	}, [currentShiftData, showConfirmModal]);

	const parcelData: Parcel | undefined =
		shiftData?.parcel || currentShiftData?.parcel;

	const {
		handleSubmit,
		isLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
	} = useParcelForm(String(parcelData?.id), parcelsGetData?.warehousings);

	const selectProps = {
		id: 1,
		title: labels.parcelSelectWarehouse,
		subtitle: "",
		enabled: true,
		type: "select" as QuestionType,
		answers:
			parcelsGetData?.warehousings.map((warehousing) => {

				const parcel = warehousing;

				const parts = [
					parcel?.building ? `Edificio: ${parcel.building}` : "",
					parcel?.buildingStory ? `Piano: ${parcel.buildingStory}` : "",
					parcel?.room ? `Stanza: ${parcel.room}` : "",
					parcel?.shelf ? `Scaffale: ${parcel.shelf}` : "",
					parcel?.shelfTier ? `Ripiano: ${parcel.shelfTier}` : "",
				].filter(Boolean);

				const optionToReturn = parts.join(" - ").replace(/ - $/, "");

				return optionToReturn;
			}) || null,
		isMandatory: true,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.parcelSelectKey,
		isExecutable: true,
		validated: validated,
		fullSize: true,
		customClass: null,
	};

	const renderedQuestionComponents = parcelsGetData?.questions.map(
		(question: ParcelQuestion) => {
			const theCompenentToBeRendered = renderTheFormComponent({
				...question,
				setFormData: setFormData,
				formData: formData,
				sectionAndQuestionId: `${question.id}`,
				isExecutable: true,
				validated,
				fullSize: true,
			});
			return (
				theCompenentToBeRendered && (
					<Row key={question.id}>
						<Col className="items-container px-0 my-3 new-item-container">
							{theCompenentToBeRendered}
						</Col>
					</Row>
				)
			);
		}
	);

	useEffect(() => {
		if (!showConfirmModal && popupData) {
			if (autoCompleteType === "parcelRecipientPersonId") {
				setParcelDeliveryDefaultValue(parcelDeliveryDefaultValue);
				setParcelRecipientDefaultValue(parseSingleRecipientPerson(popupData));
			} else {
				setParcelDeliveryDefaultValue(parseSingleRecipientPerson(popupData));
				setParcelRecipientDefaultValue(parcelRecipientDefaultValue);
			}
		}
	}, [showConfirmModal, popupData]);

	useEffect(() => {
		if (showConfirmModal) setPopupData(undefined);
	}, [showConfirmModal]);

	return (
		<ErrorBoundary errorMessage={parcelError || responseErrorModal || error}>
			<Container className="parcel-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={parcelIsLoading} fullPage>
					<ServiceParcelPage
						showConfirmModal={showConfirmModal}
						setShowConfirmModal={setShowConfirmModal}
						setPopupData={setPopupData}
						handleResponseErrorModal={handleResponseErrorModal}
						isFromKey={false}
						parcelId={(
							shiftData?.parcel?.id ||
							currentShiftData?.parcel?.id ||
							""
						)?.toString()}
					/>
					<Form
						ref={form}
						noValidate
						onSubmit={handleSubmit}
						validated={false}
						id="parcelForm"
					>
						<Row className="m-0">
							<Col className="items-container px-0 my-0">
								<PageTitle title={labels.parcelTitle}></PageTitle>
							</Col>
						</Row>

						<Container className="parcel_item_container">
							<Col className="items-container px-0 my-2 mt-3">
								<ModulePageTitle title={labels.recipientLabel} />
							</Col>

							<SelectAutoComplete
								formData={formData}
								setFormData={setFormData}
								defaultValue={parcelRecipientDefaultValue || null}
								enabled={true}
								isClearable={true}
								name={`${labels.parcelRecipientPersonKey}`}
								options={parseRecipientsAll(recipientsData || [])}
								title={labels.parcelDestinatarioLabel}
								subtitle={labels.textAggiungiNominativo}
								isMandatory={true}
								validated={false}
								newRecipientButton={true}
								clickedButton={() => setShowConfirmModal(true)}
								setType={setAutoCompleteType}
								setClickedValue={setParcelRecipientDefaultValue}
								className="select-width"
							/>
						</Container>

						<Container className="parcel_item_container mt-3">
							<Col className="items-container px-0 my-2 mt-3">
								<ModulePageTitle title={labels.recipientPersonLabel} />
							</Col>

							<SelectAutoComplete
								formData={formData}
								setFormData={setFormData}
								defaultValue={parcelDeliveryDefaultValue || null}
								enabled={true}
								isClearable={true}
								name={`${labels.parcelDeliveryPersonKey}`}
								options={parseRecipientsAll(recipientsData || [])}
								title={labels.parcelDepositarioLabel}
								subtitle={labels.textAggiungiNominativo}
								isMandatory={true}
								validated={false}
								newRecipientButton={true}
								clickedButton={() => setShowConfirmModal(true)}
								setType={setAutoCompleteType}
								setClickedValue={setParcelDeliveryDefaultValue}
								className="select-width"
							/>
						</Container>

						<Container className="parcel_item_container mt-3">
							<Col className="items-container px-0 my-2 mt-3">
								<ModulePageTitle title={labels.storageLabel} />
							</Col>
							<Col className="items-container px-0 my-4">
								<Select {...selectProps} customClass="standard_select_width" />
							</Col>
						</Container>

						{parcelsGetData?.questions &&
							parcelsGetData?.questions.length > 0 && (
							<Container className="parcel_item_container mt-3">
								<div className="parcel_dynamic_items_container">
									{renderedQuestionComponents}
								</div>
							</Container>
						)}
						<Row>
							<Col className="items-container px-0 my-4">
								<Button
									type="button"
									variant="green"
									className="submit-and-draft-buttons"
									onClick={() => {
										const url = `/shift/${shiftId}/parcel`;
										navigate(url);
									}}
									disabled={isLoading}
								>
									{labels.cancel}
								</Button>
								<Button
									type="submit"
									id="submit"
									form="parcelForm"
									variant="green"
									className="submit-and-draft-buttons"
									disabled={isLoading || !isFormValid}
								>
									{isLoading ? <Spinner /> : labels.save}
								</Button>
							</Col>
						</Row>
						<AlertModal
							headerMessage={title}
							bodyMessage={bodyTextAlert}
							okButtonText={labels.ok}
							showAlertModal={showAlertModal}
							setShowAlertModal={setShowAlertModal}
							redirectFunction={redirectFunction}
						/>
					</Form>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ParcelItemNewPage;
