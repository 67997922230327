import { createApi } from "@reduxjs/toolkit/query/react";
import { headersCommon } from "./constants/headers";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import { PBIReportResponse } from "./models/ReportResponse";

export const reportApi = createApi({
	reducerPath: "reportApi",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getReportList: builder.mutation<PBIReportResponse[], void>({
			query: () => ({
				url: `/fe/Reports`,
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
			}),
		}),

		getReport: builder.mutation<PBIReportResponse, string>({
			query: (reportId) => ({
				url: `/fe/Reports/${reportId}`,
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
			}),
		}),
	}),
});

export const { useGetReportListMutation, useGetReportMutation } = reportApi;
