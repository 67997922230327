import NavbarLabel from "components/navbar/vertical/NavbarLabel";
import NavbarVerticalMenu from "components/navbar/vertical/NavbarVerticalMenu";
import { labels } from "features/locale/it-it";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ActivityItem from "../Activity/ActivityItem";
import ChecklistIcon from "assets/icons/Checklist";
import CalendarIcon from "assets/icons/Calendar";
import AreaDocumentiIcon from "assets/icons/AreaDocumentiIcon";

interface ModuleListActivityProps {}

interface ActivityList {
	id: string;
	description: string;
	redirectUrl: string;
	icon: ReactElement<any, any>;
}

const ModuleListActivity: React.FC<ModuleListActivityProps> = () => {
	const { customer, serviceAddress } = useParams();
	const activityList: ActivityList[] = [
		{
			id: "activity1",
			description: labels.auditChecklistItemDescription,
			redirectUrl: `/audit/list/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <ChecklistIcon size="lg" />,
		},
		{
			id: "activity2",
			description: labels.gestioneTurniDescription,
			redirectUrl: `/turni/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <CalendarIcon size="lg" />,
		},
		{
			id: "activity3",
			description: labels.documentsArea,
			redirectUrl: `/documents/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <AreaDocumentiIcon size="lg" />,
		},
	];

	return (
		<>
			<Container fluid className="modules-container p-0 mb-5 mt-2 mt-md-5 ">
				<Row className="generic-report-title mx-2 my-2 px-0">
					<Col className="mx-0 bold-text-report blue-text px-0 py-2">
						{labels.myActivitiesText}
					</Col>
				</Row>
				<Row className="d-flex justify-content-start m-0">
					{activityList.map((activity) => {
						return (
							<ActivityItem
								key={activity.id}
								description={activity.description}
								redirectUrl={activity.redirectUrl}
								icon={activity.icon}
							/>
						);
					})}
				</Row>
			</Container>
		</>
	);
};

export default ModuleListActivity;
