import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
	ChecklistFormStateType,
	Question,
} from "features/storeManagement/services/models/ChecklistResponse";
import {
	checklistApi,
	usePostAChecklistMutation,
	usePutAChecklistMutation,
} from "features/storeManagement/services/checklistService";
import {
	apiResponseToFormDataTransformer,
	formDataIncidentToApiResponseTransformer,
	formDataToApiResponseTransformer,
} from "features/utils/formComponentsStateUpdate";
import { Section } from "features/storeManagement/services/models/ChecklistResponse";
import { useDispatch } from "react-redux";
import {
	incidentApi,
	useGetIncidentQuestionsMutation,
	usePostOneIncidentMutation,
	usePutOneIncidentMutation,
} from "features/storeManagement/services/IncidentService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

const useChecklistForm = (
	sections: Section[],
	isIncident?: boolean | undefined
) => {
	const [formData, setFormData] = useState<ChecklistFormStateType>(
		apiResponseToFormDataTransformer(sections)
	);
	const [
		mandatoryIncidentQuestionsForDraft,
		setMandatoryIncidentQuestionsForDraft,
	] = useState<Question[]>([]);
	const [validated, setValidated] = useState<boolean>(false);
	const [isValidForDraft, setIsValidForDraft] = useState<boolean>(true);
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const {
		shiftId,
		checklistId,
		auditId,
		draftId,
		customer,
		incidentId,
		serviceAddress,
		internal,
		customerAddress
	} = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;
	const isCliente = getValuesForUser().getUserRole() === roles.cliente.key;

	const [
		postOneChecklist,
		{
			data: postOneChecklistData,
			isLoading: postOneChecklistIsLoading,
			error: postOneChecklistError,
		},
	] = usePostAChecklistMutation();

	const [
		putOneChecklist,
		{
			data: putOneChecklistData,
			isLoading: putOneChecklistIsLoading,
			error: putOneChecklistError,
		},
	] = usePutAChecklistMutation();

	const [
		postOneIncident,
		{
			data: postOneIncidentData,
			isLoading: postOneIncidentIsLoading,
			error: postOneIncidentError,
		},
	] = usePostOneIncidentMutation();

	const [
		putOneIncident,
		{
			data: putOneIncidentData,
			isLoading: putOneIncidentIsLoading,
			error: putOneIncidentError,
		},
	] = usePutOneIncidentMutation();

	const [, { data: incidentQuestionsData }] = useGetIncidentQuestionsMutation({
		fixedCacheKey: fixedCacheKeys.incidentQuestionsData,
	});

	useEffect(() => {
		if (incidentQuestionsData && incidentQuestionsData.questions.length > 0) {
			setMandatoryIncidentQuestionsForDraft(
				incidentQuestionsData.questions.filter(
					(question) => !!question.isMandatoryDraft
				)
			);
		}
	}, [incidentQuestionsData]);

	useEffect(() => {
		if (
			postOneChecklistData ||
			putOneChecklistData ||
			postOneIncidentData ||
			putOneIncidentData
		) {
			const customerContext = customer !== undefined;
			const responsabileContext = customer;
			const url = auditId ? `/audit/${auditId}` : `/shift/${shiftId}`;
			const customerSuffix =
				!shiftId && customerContext
					? `/customer/${customer}/serviceAddress/${serviceAddress}/internal/${internal}`
					: "";
			const responsabileSuffix =
				!shiftId && responsabileContext
					? `/customer/${customer}/serviceAddress/${serviceAddress}`
					: "";
			const checklistSuffix = `/checklist/${checklistId}`;
			const incidentSuffix = `/incident/${incidentId}`;

			// If Coordiantore page
			if (auditId) {
				if (isResponsabile) {
					navigate(`/checklist/${auditId}` + responsabileSuffix);
				}else if(isCliente){
					navigate(`/checklist/${auditId}`);
				}else {
					navigate(url + customerSuffix);
				}
			}

			// If Operatore page
			if (checklistId) {
				navigate(url + checklistSuffix);
			}

			// If Incident page
			if (incidentId) {
				if (shiftId) {
					navigate(url + incidentSuffix);
				}
			}

			// If Responsabile page
			if (isResponsabile && incidentId) {
				navigate(incidentSuffix + responsabileSuffix);
			}

			dispatch(checklistApi.util.resetApiState());
			dispatch(incidentApi.util.resetApiState());
		}
	}, [
		postOneChecklistData,
		putOneChecklistData,
		postOneIncidentData,
		putOneIncidentData,
	]);

	const form = useRef(null);

	useEffect(() => {
		if (form && form.current) {
			if (!!mandatoryIncidentQuestionsForDraft.length) {
				let allMandatoryElementsForDraftAreCompiled: boolean = true;
				mandatoryIncidentQuestionsForDraft.forEach((item) => {
					if (allMandatoryElementsForDraftAreCompiled) {
						const element: HTMLFormElement | null =
							form.current &&
							(form.current as HTMLFormElement).querySelector(
								`#question-${item.id}`
							);
						if (!element) {
							/* elemento non renderizzato */
						} else if (!(element as HTMLFormElement).value.length) {
							allMandatoryElementsForDraftAreCompiled = false;
						}
					}
				});
				setIsValidForDraft(allMandatoryElementsForDraftAreCompiled);
			}

			setIsFormValid(!!(form.current as HTMLFormElement).checkValidity());
		}
	}, [formData]);

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		event.preventDefault();
		event.stopPropagation();

		const buttonClicked =
			event.nativeEvent.submitter && event.nativeEvent.submitter.id;
		const isDraft = buttonClicked === "draft";
		const isSubmit = buttonClicked === "submit";
		const status: 0 | 1 = isSubmit && !isDraft ? 1 : 0;

		const minValuesCheck =
			(isSubmit &&
				event.currentTarget &&
				event.currentTarget.checkValidity()) ||
			(isDraft && Object.keys(formData).length > 0 && isValidForDraft);

		const paramsPostWhenIsIncident = {
			incidentId: incidentId,
			payload: formDataIncidentToApiResponseTransformer(
				formData,
				shiftId || "",
				status
			).toPost(),
		};

		const paramsPostWhenIsChecklist = {
			checklistId: checklistId || auditId || "",
			checklistInsertedAnswers: {
				...formDataToApiResponseTransformer(formData, shiftId || "", serviceAddress || "").toPost(),
				status: status,
			},
		};

		const paramsPutWhenIsIncident = {
			incidentId: incidentId,
			payload: formDataIncidentToApiResponseTransformer(
				formData,
				shiftId || "",
				status
			).toPut(),
		};

		const paramsPutWhenIsChecklist = {
			checklistId: checklistId || auditId || "",
			checklistInsertedAnswers: {
				...formDataToApiResponseTransformer(formData, shiftId || "").toPut(),
				status: status,
			},
		};

		if (draftId && minValuesCheck) {
			if (isIncident) {
				putOneIncident({ ...paramsPutWhenIsIncident, answerId: draftId });
			} else {
				putOneChecklist({ ...paramsPutWhenIsChecklist, answerId: draftId });
			}
			setFormData({});
		} else if (minValuesCheck) {
			if (isIncident) {
				postOneIncident({ ...paramsPostWhenIsIncident });
			} else {
				postOneChecklist({ ...paramsPostWhenIsChecklist });
			}
			setFormData({});
		}
		setValidated(true);
	};

	return {
		handleSubmit,
		isLoading:
			postOneChecklistIsLoading ||
			putOneChecklistIsLoading ||
			postOneIncidentIsLoading ||
			putOneIncidentIsLoading,
		error:
			postOneChecklistError ||
			putOneChecklistError ||
			postOneIncidentError ||
			putOneIncidentError,
		validated,
		isValidForDraft,
		formData,
		setFormData,
		isFormValid,
		form,
		showConfirmModal,
		setShowConfirmModal,
	};
};

export default useChecklistForm;
