import './style.scss';
import { FC } from 'react';
import ShiftList from 'components/modules/Shift/ShiftList';
import { Container } from 'react-bootstrap';
import { useGetPlanningsQuery } from 'features/storeManagement/services/shiftsService';
import Suspense from 'features/Suspense';
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { labels } from 'features/locale/it-it';
import ErrorBoundary from 'features/ErrorBoundary/ErrorBoundary';

const OperatorePage: FC = function OperatorePage() {
	useChangeDocumentTitle(labels.pageTile_OperatorePage);

	const { 
		data: planningsData,
		isLoading: planningsIsLoading,
		error: planningsError
	} = useGetPlanningsQuery(undefined, { refetchOnMountOrArgChange: true })
	
	return (
		<ErrorBoundary errorMessage={planningsError}>
			<Container fluid className='d-flex operatore-container justify-content-around align-items-start p-0 px-4 mb-0 mt-4'>
				<Suspense isLoading={planningsIsLoading} fullPage>
					<ShiftList data={planningsData} />
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default OperatorePage;