import './style.scss'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { labels } from 'features/locale/it-it'
import { useGetCurrentShiftMutation, useGetOneShiftMutation } from 'features/storeManagement/services/shiftsService'
import { Reply } from 'features/storeManagement/services/models/PatrolsResponse'
import ModuleState from 'components/modules/ModuleState'
import { moduleStatus } from 'features/types/modules/ModuleStateProps'
import ModulePageTitle from 'components/modules/ModulePageTitle'
import EmptyPageNote from 'components/content/EmptyPageNote'
import Suspense from 'features/Suspense/Suspense'
import { fixedCacheKeys } from 'features/storeManagement/services/constants/fixedCacheKeys'
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle'
import { getValuesForUser } from 'features/utils/userRoleUtils'
import { roles } from 'features/storeManagement/services/constants/roles'
import { Shift } from 'features/storeManagement/services/models/ShiftsResponse'
import ErrorBoundary from 'features/ErrorBoundary/ErrorBoundary'

const PatrolItemsPage = () => {
	const { shiftId, patrolId } = useParams();

	useChangeDocumentTitle(labels.pageTile_PatrolItemsPage);
	
	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [getOneShift, {
		data: oneShiftData,
		isLoading: shiftIsLoading,
	}] = useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData })

	const [getCurrentShift, {
		data: currentShiftData,
		isLoading: currentShiftIsLoading,
		error: currentShiftError
	}] = useGetCurrentShiftMutation({ fixedCacheKey: fixedCacheKeys.currentShiftData })

	const shiftData : Shift | undefined = oneShiftData || currentShiftData || undefined;

	const patrolData = shiftData?.patrols.find((patrol) => {
		return Number(patrol.id) === Number(patrolId);
	});

	useEffect(()=>{
		if(isOperatoreEsterno){
			getCurrentShift()
		}
	},[])

	return (
		<ErrorBoundary errorMessage={currentShiftError}>
			<Container className='patrol-items-page-container p-0 px-4 mb-5 mt-4'>
				<Suspense isLoading={shiftIsLoading || currentShiftIsLoading} fullPage>
					<Row>
						<Col className='items-container px-0 my-0'>
							<ModulePageTitle title={patrolData && patrolData.description} />
						</Col>
					</Row>
					{/* {patrolData && !patrolData.executable && (
						<div className="container-parcel-label">
							<div className="container__elements">
								<div className="container__elements__label">
									<Form.Label
										htmlFor="parcel-label"
										className="parcel-label mb-0 w-100"
									>
										{labels.noExecutablePatrol}
									</Form.Label>
								</div>
							</div>
						</div>
					)} */}
					{patrolData &&
					<>
						{(patrolData && patrolData.replies && patrolData.replies.length > 0) ? <Row>
							<Col className='items-container'>
								{patrolData?.replies?.map((reply: Reply) => {
									return (
										<ModuleState
											key={reply.id}
											id={reply.id}
											date={reply.lastUpdateDate}
											state={reply.status}
											baseRedirectUrl={`/shift/${shiftId}/patrol/${patrolId}`}
											type={'patrol'}
											warning={!!reply.forceClosedNote && reply.status === moduleStatus.saved}
										/>
									)
								})}
							</Col>
						</Row> : <Row>
							<Col className='items-container'>
								<EmptyPageNote note={labels.noChecklistYet} isLoading={shiftIsLoading} className='empty-page-note' />
							</Col>
						</Row>}
					</>}
				</Suspense>
			</Container>
		</ErrorBoundary>
	)
}

export default PatrolItemsPage