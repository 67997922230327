import { labels } from "features/locale/it-it";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ActivityItem from "../Activity/ActivityItem";
import ChecklistIcon from "assets/icons/Checklist";
import CalendarIcon from "assets/icons/Calendar";
import KeysIcon from "assets/icons/KeysIcon";
import { Incident } from "features/storeManagement/services/models/IncidentResponse";
import { Journal } from "features/storeManagement/services/models/JournalResponse";
import { KeyManagementT } from "features/storeManagement/services/models/KeysResponse";
import { Parcel } from "features/storeManagement/services/models/ParcelsResponse";
import { responsabileChecklist } from "features/storeManagement/services/models/ResponsabileResponse";
import ParcelManagement from "assets/icons/ParcelManagement";
import NewspaperIcon from "assets/icons/Newspaper";
import IncidentManagementIcon from "assets/icons/IncidentManagement";
import { Checklist, DocumentToDownload } from "features/storeManagement/services/models/ChecklistResponse";
import { GuestConfiguration } from "features/storeManagement/services/models/ShiftsResponse";
import ControlloAccessiIcon from "assets/icons/ControlloAccessiIcon";
import AreaDocumentiIcon from "assets/icons/AreaDocumentiIcon";

interface ModuleListActivityProps {}

export interface ActivityList {
	id: string;
	description: string;
	redirectUrl: string;
	icon: ReactElement<any, any>;
}

interface ResponsabileProps {
	checklists?: Checklist[]
	documents?: DocumentToDownload[],
	incidents?: Incident[],
	journal?: Journal,
	parcel?: Parcel,
	keys?: KeyManagementT,
	keyManagement?: number,
	guestConfiguration?: GuestConfiguration
}

const ModulesResponsabile: React.FC<ResponsabileProps> = (props: ResponsabileProps) => {
	const { customer, contract, position, serviceAddress } = useParams();
	let activityList:ActivityList[]= [];
	
	if(props.checklists && props.checklists.length > 0){
		activityList.push({
			id: "checklistId",
			description: labels.checklistText,
			redirectUrl: `/checklist/list/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <ChecklistIcon size="lg" />,
		})
	}
	if(props.incidents && props.incidents.length > 0){
		activityList.push({
			id: "incidentId",
			description: labels.eventiSegnalati,
			redirectUrl: `/incident/list/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <IncidentManagementIcon size="lg" />,
		},)
	}
	if(props.journal){
		activityList.push ({
			id: "journalId",
			description: labels.menuJournal,
			redirectUrl: `/journal/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <NewspaperIcon size="lg" />,
		})
	}
	if(props.keys){
		activityList.push (	{
			id: "keysId",
			description: labels.menuKeysManagement,
			redirectUrl: `/keys/customer/${customer}/keyManagementId/${props.keyManagement}/serviceAddress/${serviceAddress}`,
			icon: <KeysIcon size="lg" />,
		})
	}
	if(props.parcel){
		activityList.push ({
			id: "parcelId",
			description: labels.menuParcelManagement,
			redirectUrl: `/parcel/${props?.parcel?.id}/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <ParcelManagement size="lg" />,
		})
	}
	if(props.guestConfiguration){
		activityList.push ({
			id: "controlloAccessiId",
			description: labels.controlloAccessi,
			redirectUrl: `/controlloAccessi/${props?.guestConfiguration?.id}/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <ControlloAccessiIcon size="lg" />,
		})
	}
	if(props.documents && props.documents.length > 0){
		activityList.push ({
			id: "documentsId",
			description: labels.documentsArea,
			redirectUrl: `/documents/customer/${customer}/serviceAddress/${serviceAddress}`,
			icon: <AreaDocumentiIcon size="lg" />,
		})
	}

	const isMyActivitiesLabel = () => {
		return (
			(props?.checklists && props.checklists.length > 0) ||
			(props?.incidents && props.incidents.length > 0) ||
			props?.parcel ||
			props?.journal ||
			props?.keyManagement
		);
	};

	return (
		<>
			<Container fluid className="modules-container p-0 mb-5 mt-2 mt-md-5 ">
				{
					(isMyActivitiesLabel() &&
					<Row className="generic-report-title mx-2 my-2 px-0">
						<Col className="mx-0 bold-text-report blue-text px-0 py-2">
							{labels.myActivitiesText}
						</Col>
					</Row>)
				} 	
				<Row className="d-flex justify-content-start m-0">
					{activityList.map((activity) => {
						return (
							<ActivityItem
								key={activity.id}
								description={activity.description}
								redirectUrl={activity.redirectUrl}
								icon={activity.icon}
							/>
						);
					})}
				</Row>
			</Container>
		</>
	);
};

export default ModulesResponsabile;
