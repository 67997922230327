import { createApi } from "@reduxjs/toolkit/query/react"
import { modulesDictionary } from "features/modulesDictionary"
import { ModulesData } from "features/types/data/modules"
import { headersCommon } from "./constants/headers"
import { ModulesResponse } from "./models/ModulesReponse"
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth"

export const modulesServiceApi = createApi({
	reducerPath: 'modulesService',
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({
		getModules: builder.mutation<ModulesResponse, string>({
			query: token => ({
				url: 'modules',
				method: 'GET',
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				},
			}),
			extraOptions: {
				maxRetries: 8 // It's defaulted to 5.
			},
			transformResponse: (data: ModulesData) => {
				if(data.modules) {
					data.modules = data.modules.map(e => e.toLowerCase())
					data.modulesList = data.modules.map((module) =>
						modulesDictionary[module.toLowerCase()] || {}
					)
				}
				return data
			}
		})
	})
})

export const { useGetModulesMutation } = modulesServiceApi