import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IEventOtherInfo } from 'features/types/events/createEvent';
import EventDetails from './EventDetails';
import EventTicket from './EventTicket';
import EventSchedule from './EventSchedule';
import EventHeader from './EventHeader';
import EventUpload from './EventUpload';
import EventFooter from './EventFooter';
import EventOtherInfo from './EventOtherInfo';
import EventBanner from './EventBanner';
import EventCustomField from './EventCustomField';

const CreateEvent: FC = function CreateEvent() {
	const defaultValues = {
		timeZone: 'GMT-12:00/Etc/GMT-12',
		selectType: '1',
		selectTopic: '1'
	};
	const submittedValues = {} as Record<string, string>;
	const { register, handleSubmit, setValue, control, reset } = useForm({
		defaultValues
	});

	const onSubmit = (data: any): void => {
		// ------- Get all object keys form data and set empty values to reset ------------
		const keys = Object.keys(data);
		keys.forEach(key => {
			submittedValues[key] = '';
		})
		const allValues = { ...submittedValues, ...defaultValues };
		reset({ ...allValues });
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Row className="g-3">
				<Col xs={12}>
					<EventHeader />
				</Col>
				<Col xs={12}>
					<EventBanner />
				</Col>
				<Col lg={8}>
					<EventDetails register={register} setValue={setValue} />
					<EventTicket register={register} control={control} setValue={setValue}
					/>
					<EventSchedule register={register} setValue={setValue} />
					<EventUpload register={register} setValue={setValue} />
					<EventCustomField register={register} control={control} setValue={setValue}
					/>
				</Col>
				<Col lg={4}>
					<div className="sticky-sidebar">
						<EventOtherInfo register={register} control={control as IEventOtherInfo["control"]} />
					</div>
				</Col>
				<Col>
					<EventFooter />
				</Col>
			</Row>
		</Form>
	);
};

export default CreateEvent;
