import React from 'react'
import { Form } from 'react-bootstrap'
import createMarkupSicurForce from 'features/utils/createMarkupSicurForce'

const Subtitle = ({ text }: { text: string }) => {
	return (text ?
		<div className='mb-1'>
			<Form.Text dangerouslySetInnerHTML={createMarkupSicurForce(text)} />
		</div> : null
	)
}

export default Subtitle