import React from 'react'
import LoginPage from 'components/authentication/Login/LoginPage'
import { Routes, Route } from 'react-router-dom'

const UnAuthorized = () => {

	return (
		<Routes>
			<Route path="*" element={<LoginPage />} />
		</Routes>
	)
}

export default UnAuthorized