import "./style.scss";
import { DocumentToDownload } from "features/storeManagement/services/models/ChecklistResponse";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconFunction from "../ModuleListReport/Util";
import { dateParser } from "features/utils/dateUtils";
import IconItem from "theme/components/common/icon/IconItem";
import { Color } from "components/global/Utils";
import { labels } from "features/locale/it-it";
import ImageModal from "components/content/modals/AlertModal/ImageModal";
import { useState } from "react";
import VideoModal from "components/content/modals/AlertModal/VideoModal";
import { useMediaQuery } from "features/hooks/useMediaQuery";

interface DocumentDetailProps {
	documentData: DocumentToDownload;
	handleDownloadById: Function;
	documentByIdData: DocumentToDownload | undefined;
}

const DocumentDetail: React.FC<DocumentDetailProps> = ({ documentData, handleDownloadById, documentByIdData }) => {
	const [showImgPreview, setShowImgPreview] = useState(false);
	const [showVideoPreview, setShowVideoPreview] = useState(false);

	const isSmallerThan768 = useMediaQuery('(max-width:768px)');
	
	const icon = () => {
		return IconFunction(documentData.type, "xl");
	};

	const handleShowPreview = () => {
		if (documentData.type === labels.image) {
			setShowImgPreview(true);
		} else {
			setShowVideoPreview(true);
		}
	}

	return (
		<div className="documentDetail_container">
			{showImgPreview && (
				<ImageModal
					path={documentData.path}
					setShowPreview={setShowImgPreview}
					showFlag={showImgPreview}
					base64String={documentData.mediaValue}
					mediaName={documentData.mediaName}
				/>
			)}
			{showVideoPreview && (
				<VideoModal
					path={documentData.path}
					setShowPreview={setShowVideoPreview}
					showFlag={showVideoPreview}
				/>
			)}

			<div className="documentDetail_icon">{icon()}</div>
			<div className="documentDetail_text">
				<OverlayTrigger delay={{hide: 450, show: 300}}overlay={(props) => (
					<Tooltip {...props}>{documentData.mediaName}</Tooltip>
				)}
				placement={isSmallerThan768 ? "top" : "left"}>
					<div className="documentDetail_text__title">
						{documentData.mediaName}
					</div>
				</OverlayTrigger>
				<div className="documentDetail_text__subtitle">
					{documentData.mediaDescription}
				</div>
			</div>
			<div className="documentDetail_date">
				{dateParser(documentData.createdDate).getDay() +
					" - " +
					dateParser(documentData.createdDate).getHour().substring(0, 5)}
			</div>
			<div className="documentDetail_download">
				{(documentData.type === labels.image ||
					documentData.type === labels.video) && (
					<OverlayTrigger delay={{hide: 450, show: 300}}overlay={(props) => (
						<Tooltip {...props}>{labels.preview}</Tooltip>
					)}
					placement="top">
						<div>
							<IconItem
								icon="eye"
								colorIcon={Color.green}
								width={isSmallerThan768 ? '18' : '23'}
								height={isSmallerThan768 ? '18' : '23'}
								onClick={handleShowPreview}
							/>
						</div>
					</OverlayTrigger>
				)}
				{documentData.type === labels.image ||
				documentData.type === labels.video ? (
						<OverlayTrigger delay={{hide: 450, show: 300}}overlay={(props) => (
							<Tooltip {...props}>{labels.download}</Tooltip>
						)}
						placement="top">
							<div>
								<IconItem
									icon="download"
									colorIcon={Color.green}
									width={isSmallerThan768 ? '18' : '23'}
									height={isSmallerThan768 ? '18' : '23'}
									onClick={() => handleDownloadById(documentData.id.toString())}
								/>
							</div>
						</OverlayTrigger>
				    ) : (
						<OverlayTrigger delay={{hide: 450, show: 300}} overlay={(props) => (
							<Tooltip {...props}>{labels.download}</Tooltip>
						)} placement="top">
							<div onClick={() => { documentData.type === labels.pdf ? window.open(documentData.path, "_blank") : window.location.href = documentData.path; }}>
								<IconItem
									icon="download"
									colorIcon={Color.green}
									width={isSmallerThan768 ? '18' : '23'}
									height={isSmallerThan768 ? '18' : '23'}
								/>
							</div>
						</OverlayTrigger>
						
				    )}
			</div>
		</div>
	);
};

export default DocumentDetail;
