import { createApi } from '@reduxjs/toolkit/query/react'
import { privacyPolicyQuersFnWithReauth } from './queryFuncs/privacyPolicyQuersFnWithReauth'

import { headersCommon } from './constants/headers'
import { PrivacyAcceptResponse, PrivacyTextType } from './models/PrivacyPolicyTypes'

export const privacyPolicyApi = createApi({
	reducerPath: 'policyApi',
	baseQuery: privacyPolicyQuersFnWithReauth,
	endpoints: builder => ({
		getPrivacyPolicyText: builder.query<PrivacyTextType, void>({
			query: () => ({
				url: '/Policy',
				method: 'GET',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		}),
		acceptPrivacyPolicy: builder.mutation<PrivacyAcceptResponse, void>({
			query: () => ({
				url: '/Policy',
				method: 'POST',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				},
				body: JSON.stringify({
					"policyAccepted": true
				})
			})
		})
	})
})

export const { useAcceptPrivacyPolicyMutation, useGetPrivacyPolicyTextQuery } = privacyPolicyApi