import { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import FalconCloseButton from "theme/components/common/FalconCloseButton";
import "./style.scss";
import { labels } from "features/locale/it-it";
import DownloadIcon from "assets/icons/DownloadIcon";

export const ImageModal: React.FC<{
	path: string | null | undefined;
	setShowPreview: Function;
	showFlag: boolean;
	base64String?: string | null | undefined;
	mediaName?: string | null;
	labelHeader?: string | null;
}> = ({ path, setShowPreview, showFlag, base64String, mediaName, labelHeader }) => {
	const [show, setShow] = useState(showFlag);

	const handleClose = () => {
		setShow(false);
		setShowPreview(false);
	};

	const handleDownload = () => {
		if (base64String && mediaName) {
			const link = document.createElement("a");
			link.href = base64String;
			link.download = mediaName;
			link.click();
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleClose} keyboard={false}>
				<Modal.Header className="bg-light border-0">
					<Col md={10}>
						<Modal.Title>{labelHeader ? labelHeader : labels.journalImagePreview}</Modal.Title>
					</Col>
					<Col md={base64String && mediaName ? 2 : 1} className="d-flex align-items-center">
						{base64String && mediaName && (
							<div className="d-flex justify-content-center download_img_btn" onClick={handleDownload}>
								<DownloadIcon size={"lg"} style={{ margin: "auto" }} />
							</div>
						)}
						<FalconCloseButton
							className="btn btn-circle btn-sm transition-base p-0"
							onClick={handleClose}
						/>
					</Col>
				</Modal.Header>
				<Modal.Body className="p-4">
					<div className="previewJornal">
						<div>
							{path && (
								<img
									className="JournalePreviewImage"
									src={path}
									alt="Preview"
								/>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ImageModal;
