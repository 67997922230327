import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { headersCommon } from "./constants/headers"
import { baseQueryWithReauth } from './queryFuncs/baseQueryWithReauth'
import { DettaglioSegnalazioneResponse, guestStoricoApi } from './models/GuestResponse'
import { GuestResponseApi, GuestResponseDettaglioApi, SearchPostResponseApi } from './models/GuestResponse'
import { CreaSegnalazioneBody, GuestRequestApi, GuestRequestPutApi, GuestRequestPutStatoApi } from './models/GuestRequest'

export const guestApi = createApi({
	reducerPath: "guestService",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getGuest: builder.mutation<GuestResponseApi,{ shiftId: string,  guestConfigurationId: number}>(
			{
				query: ({ shiftId, guestConfigurationId }) => ({
					url: shiftId ==='' ? `/fe/Guests/${guestConfigurationId}` : `/fe/Guests/${guestConfigurationId}/Filters?shiftId=${shiftId}`,
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
				}),
			}),

		getGuestResponsabile: builder.mutation<GuestResponseApi,{ customer: string,  serviceAddress: string, guestConfigurationId: number}>(
			{
				query: ({ customer, serviceAddress, guestConfigurationId }) => ({
					url: `/fe/Guests/${guestConfigurationId}/Filters?idCustomer=${customer}&serviceAddress=${serviceAddress}`,
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
				}),
			}),

		postGuestFilter: builder.mutation<SearchPostResponseApi[],{ guestConfigurationId: number, filters : GuestRequestApi}>(
			{
				query: ({ guestConfigurationId, filters }) => ({
					url: `/fe/Guests/${guestConfigurationId}`,
					method: "POST",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body: JSON.stringify(filters),
				}),
			}),

		postGuestRegistro: builder.mutation<SearchPostResponseApi,{ guestConfigurationId: number, filters : GuestRequestApi}>(
			{
				query: ({ guestConfigurationId, filters }) => ({
					url: `/fe/Guests/${guestConfigurationId}/Registry`,
					method: "POST",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body: JSON.stringify(filters),
				}),
			}),

		postGuestAggiungi: builder.mutation<SearchPostResponseApi,{ guestConfigurationId: number, filters : GuestRequestApi}>(
			{
				query: ({ guestConfigurationId, filters }) => ({
					url: `/fe/Guests/${guestConfigurationId}/Guest`,
					method: "POST",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body: JSON.stringify(filters),
				}),
			}),

		getGuestDettaglio: builder.mutation<GuestResponseDettaglioApi,{ shiftId?: string, customerId?: string, serviceAddress?: number,  guestConfigurationId: number, guestAdmissionId: number}>(
			{
				query: ({ shiftId, customerId, serviceAddress, guestConfigurationId, guestAdmissionId }) => ({
					url: shiftId ? `/fe/Guests/${guestConfigurationId}/Admission/${guestAdmissionId}?shiftId=${shiftId}` : `/fe/Guests/${guestConfigurationId}/Admission/${guestAdmissionId}?serviceAddress=${serviceAddress}&idCustomer=${customerId}`,
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
				}),
			}),

		putGuest: builder.mutation<GuestResponseDettaglioApi,{ guestConfigurationId: number, guestAdmissionId:number,  objSend : GuestRequestPutApi}>(
			{
				query: ({ guestConfigurationId, guestAdmissionId, objSend }) => ({
					url: `/fe/Guests/${guestConfigurationId}/Admission/${guestAdmissionId}`,
					method: "PUT",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body: JSON.stringify(objSend),
				}),
			}),

		getStoricoSegnalazioni: builder.mutation<guestStoricoApi[],{ shiftId? : string, guestConfigurationId: number, guestAdmissionId:number,  customerId? : string, serviceAddress?: string}>(
			{
				query: ({ shiftId, guestConfigurationId, guestAdmissionId, customerId, serviceAddress }) => ({
					url: shiftId ? `/fe/Guests/${guestConfigurationId}/Guest/${guestAdmissionId}/Index?shiftId=${shiftId}` : `/fe/Guests/${guestConfigurationId}/Guest/${guestAdmissionId}/Index?idCustomer=${customerId}&serviceAddress=${serviceAddress}`,
					method: "GET",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
				}),
			}),
			
		getDettaglioSegnalazione: builder.mutation<DettaglioSegnalazioneResponse,{ shiftId?: string, customerId?: string, serviceAddress?: number,  guestConfigurationId: number, guestId: number}>(
			{
				query: ({ shiftId, customerId, serviceAddress, guestConfigurationId, guestId }) => ({
					url: shiftId ? `/fe/Guests/${guestConfigurationId}/Guest/${guestId}/Warnings?shiftId=${shiftId}` : `/fe/Guests/${guestConfigurationId}/Guest/${guestId}/Warnings?serviceAddress=${serviceAddress}&idCustomer=${customerId}`,
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
				}),
			}),

		getDettaglioLetturaSegnalazione: builder.mutation<DettaglioSegnalazioneResponse,{ shiftId?: string, customerId?: string, serviceAddress?: number,  guestConfigurationId: number, guestId: number, guestCardCheckId:number}>(
			{
				query: ({ shiftId, customerId, serviceAddress, guestConfigurationId, guestId, guestCardCheckId }) => ({
					url: shiftId ? `/fe/Guests/${guestConfigurationId}/Guest/${guestId}/Warnings?guestCardCheckId=${guestCardCheckId}&shiftId=${shiftId}` : `/fe/Guests/${guestConfigurationId}/Guest/${guestId}/Warnings?serviceAddress=${serviceAddress}&idCustomer=${customerId}&guestCardCheckId=${guestCardCheckId}`,
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
				}),
			}),
	
		postCreaSegnalazione: builder.mutation<DettaglioSegnalazioneResponse,{ guestConfigurationId: number, guestId: number, filters : CreaSegnalazioneBody}>(
			{
				query: ({ guestConfigurationId,guestId, filters }) => ({
					url: `/fe/Guests/${guestConfigurationId}/Guest/${guestId}/Warnings`,
					method: "POST",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body: JSON.stringify(filters),
				}),
			}),

		putCambioStatoGuest: builder.mutation<GuestResponseDettaglioApi,{ guestConfigurationId: number, guestId: number, guestAdmissionId: number, objSend: GuestRequestPutStatoApi }>(
			{
				query: ({ guestConfigurationId, guestId, guestAdmissionId, objSend }) => ({
					url: `/fe/Guests/${guestConfigurationId}/Admission/${guestAdmissionId}/Guest/${guestId}/Status`,
					method: "PUT",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body: JSON.stringify(objSend),
				}),
			}),
	})
})

export const {
	useGetGuestMutation,
	useGetGuestResponsabileMutation,
	usePostGuestFilterMutation,
	usePostGuestRegistroMutation,
	usePostGuestAggiungiMutation,
	useGetGuestDettaglioMutation,
	usePutGuestMutation,
	useGetStoricoSegnalazioniMutation,
	useGetDettaglioSegnalazioneMutation,
	usePostCreaSegnalazioneMutation,
	useGetDettaglioLetturaSegnalazioneMutation,
	usePutCambioStatoGuestMutation
} = guestApi;