import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './queryFuncs/baseQueryWithReauth'
import { headersCommon } from './constants/headers'
import {AllIncidentsResponse, Incident, IncidentQuestionsApi, IncidentResponse} from '../services/models/IncidentResponse'
import { DeleteMediaIncidentRequest, FormDataAnswersToPostIncident, FormDataAnswersToPostIncidentResponse, IncidentsRequest } from './models/IncidentRequest'

export const incidentApi = createApi({
	reducerPath: 'incidentService',
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({
		getIncidentDrafts: builder.mutation<IncidentResponse, { shiftId: string | number, incidentId: number | string, answerId?:string }>({
			query: ({shiftId, incidentId, answerId}) => ({
				url: answerId ? `/fe/Incidents/${incidentId}?answerId=${answerId}&shiftId=${shiftId}` : `/fe/Incidents/${incidentId}/Answers?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		getIncidentDraftsResponsabile: builder.mutation<IncidentResponse, {incidentId: number | string, answerId?:string }>({
			query: ({incidentId, answerId}) => ({
				url: answerId ? `/fe/Incidents/${incidentId}?answerId=${answerId}` : `/fe/Incidents/${incidentId}/Answers`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		getIncidentQuestions: builder.mutation<IncidentQuestionsApi, { shiftId: string | number, incidentId: number | string, answerId: number | string | undefined }>({
			query: ({shiftId, incidentId, answerId}) => ({
				url: answerId ? `/fe/Incidents/${incidentId}/?answerId=${answerId}&shiftId=${shiftId}` : `/fe/Incidents/${incidentId}/?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		getIncidentQuestionsResponsabile: builder.mutation<IncidentQuestionsApi, { incidentId: number | string, answerId: number | string | undefined }>({
			query: ({ incidentId, answerId}) => ({
				url: answerId ?`/fe/Incidents/${incidentId}/?answerId=${answerId}` : `/fe/Incidents/${incidentId}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		postOneIncident: builder.mutation<FormDataAnswersToPostIncidentResponse, {incidentId: string | undefined, payload: FormDataAnswersToPostIncident}>({
			query: ({incidentId, payload}) => ({
				url: `/fe/Incidents/${incidentId}`,
				method: 'POST',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(
					payload
				)
			})
			
		}),
		putOneIncident: builder.mutation<FormDataAnswersToPostIncidentResponse, {incidentId: string | undefined, answerId: string | undefined, payload: FormDataAnswersToPostIncident}>({
			query: ({incidentId, answerId, payload}) => ({
				url: `/fe/Incidents/${incidentId}/${answerId}`,
				method: 'PUT',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(
					payload
				)
			})
			
		}),
		deleteIncidentMedia: builder.mutation<FormDataAnswersToPostIncidentResponse, DeleteMediaIncidentRequest>({
			query: ({incidentId, answerId, answerDetailId, deleteBody}) => ({
				url: `/fe/Incidents/${incidentId}/${answerId}/${answerDetailId}`,
				method: 'DELETE',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(
					deleteBody
				)
			})
			
		}),
		getAllIncidents: builder.mutation<AllIncidentsResponse, IncidentsRequest>({
			query: ({idCustomer, serviceAddress}) => ({
				url: `/fe/Incidents?idCustomer=${idCustomer}&ServiceAddress=${serviceAddress}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
	})
})


export const { useGetIncidentDraftsMutation, useGetIncidentDraftsResponsabileMutation, useGetIncidentQuestionsMutation, useGetIncidentQuestionsResponsabileMutation, usePostOneIncidentMutation, usePutOneIncidentMutation, useDeleteIncidentMediaMutation, useGetAllIncidentsMutation } = incidentApi
