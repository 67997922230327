import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import Background from 'theme/components/common/Background';
import Avatar from 'theme/components/common/Avatar';
import classNames from 'classnames';
import { IProfileBanner, IProfileBannerBody, IProfileBannerHeader } from 'features/types/user/profile';

const ProfileBannerHeader: FC<IProfileBannerHeader> = function ProfileBannerHeader({ avatar, coverSrc, className }) {
	return (
		<Card.Header className={classNames(className, 'position-relative min-vh-25 mb-7')}>
			<Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
			<Avatar size="5xl" className="avatar-profile" src={avatar} mediaClass="img-thumbnail shadow-sm" />
		</Card.Header>
	);
};

const ProfileBannerBody: FC<IProfileBannerBody> = function ProfileBannerBody({ children }) {
	return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner: FC<IProfileBanner> = function ProfileBanner({ children }) {
	return <Card className="mb-3">{children}</Card>;
};

export default Object.assign(ProfileBanner, {
	Header: ProfileBannerHeader,
	Body: ProfileBannerBody
});
