import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Association from 'theme/components/pages/associations/Association'
import associationsData from 'theme/data/associations';
import { IAssociations } from 'features/types/user/profile';
import { nanoid } from '@reduxjs/toolkit';

const Associations: FC<IAssociations> = function Associations({
	associations = associationsData,
	colBreakpoints = { sm: 6, md: 4 },
	...rest
}) {
	return (
		<Card {...rest}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">Associations</h5>
			</Card.Header>
			<Card.Body className="fs--1">
				<Row className="g-3">
					{associations.map(association => (
						<Col key={nanoid()} {...colBreakpoints}>
							<Association image={association.image} title={association.title} description={association.description} to={association.to}
							/>
						</Col>
					))}
				</Row>
			</Card.Body>
		</Card>
	);
};

export default Associations;
