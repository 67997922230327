import { createApi } from '@reduxjs/toolkit/query/react'
import { headersCommon } from "./constants/headers"
import { OnePatrolResponse } from './models/PatrolsResponse'
import { baseQueryWithReauth } from './queryFuncs/baseQueryWithReauth'

export const partolsApi = createApi({
	reducerPath: 'patrolService',
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({
		getOnePatrol: builder.query<OnePatrolResponse, { shiftId: string | number, patrolId: string | number, draftId: string | number }>({
			query: ({ shiftId, patrolId, draftId }) => ({
				url: `/fe/Patrols/${patrolId}?shiftId=${shiftId}&replyId=${draftId}`,
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		})
	})
})

export const { useGetOnePatrolQuery } = partolsApi