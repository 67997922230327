import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";
import { labels } from "features/locale/it-it";
import NavbarVerticalMenu from "components/navbar/vertical/NavbarVerticalMenu";
import { ModulesDictionaryType } from "features/types/data/modules";
import NavbarLabel from "components/navbar/vertical/NavbarLabel";
import { PBIReportResponse } from "features/storeManagement/services/models/ReportResponse";
import ReportItem from "../Report/ReportItem";
import IconFunction from "./Util";
import { useParams } from "react-router-dom";
import routes from "features/routes/routes";
interface ModulesListProps {
	asMenu?: boolean;
	separator?: boolean;
	report?: PBIReportResponse[];
}

const ModulesListReport: React.FC<ModulesListProps> = ({
	asMenu,
	separator,
	report,
}) => {
	const { customer, serviceAddress } = useParams();
	
	const reportsModulesRoutes = (report: PBIReportResponse[]) => {
		let routes: ModulesDictionaryType[] = [];
		report.forEach((report) => {
			if (report) {
				let navigationPath = '';
				if (customer && serviceAddress) {
					navigationPath = `/report/${report.id}/customer/${customer}/serviceAddress/${serviceAddress}`;
				} else {
					navigationPath = `/report/${report.id}`;
				}
				const route: ModulesDictionaryType = {
					name: report.title || "",
					icon: IconFunction(report.reportType, "sm"),
					to: navigationPath,
					component: "report",
				};
				routes.push(route);
			}
		});
		return routes;
	};

	return (
		<>
			{!asMenu && report && !!report.length && (
				<Container fluid className="report-container p-0 mb-5 mt-2 mt-md-5 ">
					<Row className="generic-report-title mx-2 my-2 px-0">
						<Col className="mx-0 bold-text-report blue-text px-0 py-2">
							{labels.reportAreaText}
						</Col>
					</Row>
					<Row className="d-flex justify-content-start m-0">
						{report &&
							report.map(
								(report) =>
									report && (
										<ReportItem
											key={report.id}
											report={report}
											icon={IconFunction(report.reportType, "lg")}
										/>
									)
							)}
					</Row>
				</Container>
			)}
			{!!asMenu && (
				<>
					{report && !!report.length && (
						<>
							<div className="mt-3">
								<NavbarLabel label={labels.reportAreaText} />
							</div>
							<NavbarVerticalMenu routes={reportsModulesRoutes(report)} />
						</>
					)}
				</>
			)}
		</>
	);
};

export default ModulesListReport;
