import "./style.scss";

import React from "react";
import { Col, Row } from "react-bootstrap";
import { CustomerDetailsProps } from "features/types/modules/AuditModulesPageProps";
import { labels } from "features/locale/it-it";

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ customerInfo }) => {
	return (
		<Row
			className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
			onClick={() => {}}
		>
			<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
				<Col
					className="datetime-box col-7 d-flex flex-column align-items-start px-1"
				>
					<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
						<div className="bold-text col-5">
							{labels.accordionVenueName + ":"}
						</div>
						<div className="day bold-text col-6">
							{customerInfo?.venueName?.toLowerCase()}
						</div>
					</div>

				</Col>
				<Col
					key="checkout-time"
					className="datetime-box col-5 d-flex flex-column align-items-start px-1"
				>

					<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
						<span className="bold-text col-5">
							{labels.accordionDetailCustomer}
						</span>
						<span className="day bold-text col-6">
							{customerInfo?.fullAddress?.toLowerCase()}
						</span>
					</div>
				</Col>
			</Row>
		</Row>
	);
};

export default CustomerDetails;
