import { labels } from "features/locale/it-it";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import { QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import { parcelKeys } from "features/storeManagement/services/models/ParcelResponse";

type props = {
	formData: ChecklistFormStateType;
	setFormData: Function;
	validated: boolean;
	title?: string;
};

export const selectProps = ({ formData, setFormData, validated }: props) => {
	return {
		id: 1,
		title: "",
		subtitle: "",
		enabled: true,
		type: "select" as QuestionType,
		answers: [labels.parcelLost, labels.parcelStolen, labels.parcelDeleted],
		isMandatory: false,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.parcelSelectKey,
		isExecutable: true,
		validated: validated,
		fullSize: true,
	};
};

export const radioProps = ({ formData, setFormData, validated, title }: props) => {
	return {
		id: 1,
		title: title || "",
		subtitle: "",
		enabled: true,
		type: "radio" as QuestionType,
		answers: [labels.yesLabel, labels.noLabel],
		isMandatory: false,
		answerDetail: null,
		formData,
		setFormData,
		sectionAndQuestionId: parcelKeys.isSamePerson,
		isExecutable: true,
		validated,
	};
};


export const textAreaProps = ({ formData, setFormData, validated }: props) => {
	return {
		id: 1,
		title: "",
		subtitle: "",
		enabled: true,
		type: "textarea" as QuestionType,
		answers: null,
		isMandatory: false,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.reportNotesKey,
		isExecutable: true,
		validated,
	};
};

export const textAreaPropsSegnalazione = ({ formData, setFormData, validated }: props) => {
	return {
		id: 1,
		title: "",
		subtitle: "",
		enabled: true,
		type: "textarea" as QuestionType,
		answers: null,
		isMandatory: false,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.reportNotesKeySegnalazione,
		isExecutable: true,
		validated,
	};
};
