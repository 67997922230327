import Main from 'theme/Main'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import reportWebVitals from './theme/reportWebVitals'
import 'theme/helpers/initFA'
import { Provider } from 'react-redux'
import { store } from 'features/storeManagement/store'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('main') as HTMLElement)
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Main>
					<App />
				</Main>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
)
reportWebVitals()
