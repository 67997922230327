import { createApi } from '@reduxjs/toolkit/query/react'
import { headersCommon } from './constants/headers'
import { baseQueryWithReauth } from './queryFuncs/baseQueryWithReauth'
import { Planning } from './models/PlanningsResponse'
import { Shift, ActionBodyType, CurrentShiftType } from './models/ShiftsResponse'

export const shiftsApi = createApi({
	reducerPath: 'shiftsApi',
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({
		getOneShift: builder.mutation<Shift, string>({
			query: shiftId => ({
				url: `/Shifts?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		}),
		getPlannings: builder.query<Planning[], void>({
			query: () => ({
				url: `/Plannings?cid=${localStorage.cid || sessionStorage.cid}`,
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			}),
		}),
		getCurrentShift: builder.mutation<Shift, void>({
			query: () => ({
				url: `/Shifts/Current`,
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		}),
		beginFinishShiftAction: builder.mutation<any, Partial<ActionBodyType>>({
			query: body => ({
				url: `/Actions`,
				method: 'POST',
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				},
				body: JSON.stringify(body)
			})
		}),
		postCurrentShift: builder.mutation<Shift, CurrentShiftType>({
			query: CurrentShift => ({
				url: `/Shifts`,
				method: "POST",
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(CurrentShift)
			})
		}),
		deleteCurrentShift: builder.mutation<{succeeded: boolean}, void>({
			query: () => ({
				url: `/Shifts/Current`,
				method: "DELETE",
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		})

	})
})

export const {
	useGetOneShiftMutation,
	useGetPlanningsQuery,
	useGetCurrentShiftMutation,
	usePostCurrentShiftMutation,
	useBeginFinishShiftActionMutation,
	useDeleteCurrentShiftMutation
} = shiftsApi
