import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
	JournalBodyRequestType,
	JournalFormInputT,
	JournalFormStateType,
	MediaTypeRequest,
} from "features/storeManagement/services/models/JournalResponse";
import { useDispatch } from "react-redux";
import {
	journalApi,
	usePostAJournalMutation,
	usePutAJournalMutation,
} from "features/storeManagement/services/journalService";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

const useJournalForm = ({
	text: defaultText,
	journalId,
	journalLogId,
	journalCausalId,
	medias,
}: JournalFormInputT) => {
	const [formData, setFormData] = useState<JournalFormStateType>({
		["JournalNote "]: [defaultText],
	});

	const [validated, setValidated] = useState<boolean>(false);
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const { shiftId, contract, position, customer, serviceAddress } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [
		postAJournal,
		{
			data: postOneJournalData,
			isLoading: postOneJournalDataIsLoading,
			error: postOneJournalDataError
		},
	] = usePostAJournalMutation();

	const [
		putAJournal,
		{
			data: putOneJournalData,
			isLoading: putOneJournalIsLoading,
			error: putOneJournalError
		},
	] = usePutAJournalMutation();

	useEffect(() => {
		if (postOneJournalData || putOneJournalData) {
			const url = isResponsabile ? `/journal/customer/${customer}/serviceAddress/${serviceAddress}` : `/shift/${shiftId}/journal`;
			navigate(url);

			dispatch(journalApi.util.resetApiState());
		}
	}, [postOneJournalData, putOneJournalData]);

	const form = useRef(null);

	useEffect(
		() =>
			setIsFormValid(
				!!(
					form &&
					form.current &&
					(form.current as HTMLFormElement).checkValidity() &&
					formData["JournalNote "][0].trim() !== ""
				)
			),
		[formData]
	);

	
	const isResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		event.preventDefault();
		event.stopPropagation();

		const isNew = !defaultText;
		const isValidForm =
			event.currentTarget && event.currentTarget.checkValidity();
		if (isValidForm && (shiftId||isResponsabile)) {
			let bodyJournal: JournalBodyRequestType = {
				status: 0,
				shiftId: shiftId || "",
				journalText: formData["JournalNote "][0],
			};
			if (journalCausalId) bodyJournal.journalCausalId = journalCausalId;
			const mediasInput: MediaTypeRequest[] = [];
			if (medias) {
				medias.forEach((media) => {
					media.mediaB64 &&
						mediasInput.push({
							mediaValue: media.mediaB64,
						});
				});
				bodyJournal.medias = mediasInput;
			}
			if (isNew) {
				postAJournal({
					journalId: journalId || 1,
					journalBodyRequest: bodyJournal,
				});
			} else {
				putAJournal({
					journalId: journalId || 1,
					journalLogId: journalLogId || 0,
					journalBodyRequest: bodyJournal,
				});
			}
		}

		setValidated(true);
	};

	return {
		handleSubmit,
		isLoading: postOneJournalDataIsLoading || putOneJournalIsLoading,
		error: postOneJournalDataError || putOneJournalError,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		showConfirmModal,
		setShowConfirmModal,
		postOneJournalDataError,
		putOneJournalError
	};
};

export default useJournalForm;
