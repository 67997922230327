import { labels } from 'features/locale/it-it'
import React, { FC } from 'react'
import { Card } from 'react-bootstrap'

const Error500: FC = function Error500() {
	return (
		<Card className='text-center h-100'>
			<Card.Body className='p-5'>
				<div className='display-1 text-300 fs-error'>{labels.upsThereWasAProblem}</div>
				<p className='lead mt-4 text-800 font-sans-serif fw-semi-bold'>
					{labels.upsThereWasAProblemMessage}
				</p>
				<hr />
				<p>
					{labels.pleaseRetry}
					{' '}
					{/* {labels.ifProblemPersists}
					<a href='mailto:info@exmaple.com' className='ms-1'>
						{labels.contactUs}
					</a>
					. */}
				</p>
			</Card.Body>
		</Card>
	)
}

export default Error500
