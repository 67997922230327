import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import SignaturePad from "react-signature-canvas";
import "./Signature.scss";
import { Button, Col, Row } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Signature = ({ setSignature, flagEmptySign, setFlagEmptySign }: { setSignature?: Function, flagEmptySign?: boolean, setFlagEmptySign?: Function }) => {
	const signatureRef = useRef<SignatureCanvas>(null);
	const [signatureUrl, setSignatureUrl] = useState<string | null>(null);
	const [designDisabled, setDesignDisabled] = useState<boolean>(true);

	const handleClear = () => {
		signatureRef.current?.clear();
		setSignatureUrl(null);
		if (setSignature) setSignature(null)
		if(setFlagEmptySign) setFlagEmptySign(false)
	};

	useEffect(() => {
		if (flagEmptySign === true) {
			handleClear();
		}
	}, [flagEmptySign])

	const activateDraw = () => {
		setDesignDisabled(false);
		signatureRef.current?.on();
	};

	const handleSave = () => {
		if (!signatureRef.current?.isEmpty()) {
			let sign = signatureRef.current;

			if (sign) {
				setSignatureUrl(sign.toDataURL("image/png"));
				if (setSignature) setSignature(sign.toDataURL("image/png"));
			}
		}
	};

	useEffect(() => {
		signatureRef.current?.off();
	}, []);

	return (
		<section className="signature-container">
			<div>
				<Row className="text-end">
					<Col className="pb-2">
						<Button
							onClick={activateDraw}
							className="mx-1 signatureButton p-0"
							variant="link"
							size="sm"
						>
							<FontAwesomeIcon icon="pen" className="m-0" />
						</Button>
						<Button
							onClick={handleClear}
							className="mx-1 signatureButton p-0"
							variant="link"
							size="sm"
						>
							<FontAwesomeIcon icon="trash" className="m-0" />
						</Button>
					</Col>
				</Row>
				<div
					className={`sigPadContainer ${designDisabled ? "disabled-background" : ""}`}
				>
					<SignaturePad
						canvasProps={{
							className: "sigCanvas",
						}}
						clearOnResize={false}
						ref={signatureRef}
						onEnd={handleSave}
					/>
				</div>
			</div>
		</section>
	);
};

export default Signature;
