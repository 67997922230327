import React, { FC } from 'react'
import { Nav, Navbar, Row, Col } from 'react-bootstrap'

const NavbarLabel: FC<{ label: string }> = ({ label }: { label: string }) => {
	return (
		<Nav.Item as='li'>
			<Row className='mb-2 navbar-vertical-label-wrapper'>
				<Col xs='auto' className='navbar-vertical-label navbar-vertical-label'>
					{label}
				</Col>
				<Col className='ps-0'>
					<hr className='mb-0 navbar-vertical-divider' />
				</Col>
			</Row>
		</Nav.Item>
	)
}

export default NavbarLabel