import { FC } from 'react';
import classNames from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { IIconGroup } from 'features/types/common/icon';
import IconItem from './IconItem';

const IconGroup: FC<IIconGroup> = function IconGroup({ icons, className, ...rest }) {
	return (
		<div className={classNames('icon-group', className)} {...rest}>
			{icons.map((icon, index) => (
				<IconItem icon="function" onClick={undefined} href="" bg="" size='sm' color="" className="" iconClass="" transform="" {...icon} key={nanoid()} />
			))}
		</div>
	)
};

export default IconGroup;
