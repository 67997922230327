import "./style.scss";
import { labels } from "features/locale/it-it";
import { MouseEventHandler } from "react";
import { Button } from "react-bootstrap";

const PrintButton = () => {

	const onPrintHandler = () => {
		const dom = document.getElementById("main");
		
		dom?.classList.add("printable_page");
		window.print();
		dom?.classList.remove("printable_page");
	};

	return (
		<Button
			type="button"
			id="downloadPdf"
			form="checklistForm"
			variant="green"
			className="submit-and-draft-buttons-"
			onClick={onPrintHandler}
		>
			{labels.downloadPDF}
		</Button>
	);
};

export default PrintButton;
