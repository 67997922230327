import React, { ReactNode, useEffect, useState } from "react";

import Radio from "./Radio";
import Picture from "./Picture";
import Text from "./Text";
import TextArea from "./TextArea";
import Datepicker from "./Datepicker";
import Checkbox from "./Checkbox";
import {
	ChecklistFormItemsPropsType,
	PictureChecklistFormItemsPropsType,
} from "features/storeManagement/services/models/ChecklistResponse";
import Select from "./Select";
import { JournalMedia } from "features/storeManagement/services/models/JournalResponse";
import { useDeleteIncidentMediaMutation } from "features/storeManagement/services/IncidentService";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { labels } from "features/locale/it-it";
import AlertModal from "../modals/AlertModal/AlertModal";
import { useDeleteChecklistMediaMutation } from "features/storeManagement/services/checklistService";
import { useParams } from "react-router-dom";

export const renderTheFormComponent = (
	props: ChecklistFormItemsPropsType
): ReactNode => {
	switch (props.type) {
	case "checkbox":
		return <Checkbox key={props.id} {...props} />;
	case "datepicker":
		return <Datepicker key={props.id} {...props} />;
	case "text":
		return <Text key={props.id} {...props} />;
	case "textarea":
		return <TextArea key={props.id} {...props} />;
	case "radio":
		return <Radio key={props.id} {...props} />;
	case "select":
		return <div className="standard_select_width"><Select key={props.id} {...props} /></div>;
	case "picture":
		return <RenderPictureFormComponent key={props.id} {...props} />
	}
};

export const RenderPictureFormComponent = (
	props: PictureChecklistFormItemsPropsType
) => {
	const {checklistId, auditId} = useParams();
	const [mediaArray, setMediaArray] = useState<
		JournalMedia[] | null | undefined
	>(props.medias ? props.medias : []);
	const [mediaIdToDel, setMediaIdToDel] = useState<number>();
	const [showErrorMediaModal, setShowErrorMediaModal] = useState<boolean>(false);

	const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
		useState<boolean>(false);
	const [
		deleteMediaIncident,
		{
			data: deleteMediaIncidentData,
			isLoading: deleteMediaIncidentIsLoading,
			error: deleteMediaIncidentError,
			isError: isDeleteMediaIncidentError
		},
	] = useDeleteIncidentMediaMutation();

	useEffect(() => {
		const arrayToDelete = mediaArray?.filter((el) => el.id !== mediaIdToDel)
		setMediaArray(arrayToDelete);
		setShowDeleteConfirmModal(false);
	}, [deleteMediaIncidentData]);

	useEffect(()=>{
		if(isDeleteMediaIncidentError){
			setShowDeleteConfirmModal(false);
			setShowErrorMediaModal(true)
		}
	},[isDeleteMediaIncidentError])

	const [
		deleteMediaChecklist,
		{
			data: deleteMediaChecklistData,
			isLoading: deleteMediaChecklistIsLoading,
			error: deleteMediaChecklistError,
			isError: isDeleteMediaChecklistError
		},
	] = useDeleteChecklistMediaMutation();

	useEffect(() => {
		const arrayToDelete = mediaArray?.filter((el) => el.id !== mediaIdToDel)
		setMediaArray(arrayToDelete);
		setShowDeleteConfirmModal(false);
	}, [deleteMediaChecklistData]);

	useEffect(()=>{
		if(isDeleteMediaChecklistError){
			setShowDeleteConfirmModal(false);
			setShowErrorMediaModal(true)
		}
	},[isDeleteMediaChecklistError])

	return (
		<>
			<Picture
				key={props.id}
				{...props}
				showDeleteConfirmModal={showDeleteConfirmModal}
				setMediaIdToDel={setMediaIdToDel}
				mediaIdToDel={mediaIdToDel}
				setMediaArray={setMediaArray}
				mediaArray={mediaArray}
				setShowDeleteConfirmModal={setShowDeleteConfirmModal}
				deleteAJournalMedia={checklistId || auditId ? deleteMediaChecklist : deleteMediaIncident}
				deleteIsLoading={checklistId || auditId ? deleteMediaChecklistIsLoading : deleteMediaIncidentIsLoading}
			/>
			<AlertModal
				headerMessage={labels.warning}
				bodyMessage={labels.modalErrorIncidentBody}
				okButtonText={labels.ok}
				showAlertModal={showErrorMediaModal}
				setShowAlertModal={setShowErrorMediaModal}
			/>
		</>
	);
};
