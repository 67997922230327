import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { labels } from 'features/locale/it-it'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import styles from './privacyPage.module.scss'
import { useAcceptPrivacyPolicyMutation, useGetPrivacyPolicyTextQuery } from 'features/storeManagement/services/privacyPolicyService'
import { fixedCacheKeys } from 'features/storeManagement/services/constants/fixedCacheKeys'
import { PrivacyPageProps } from 'features/types/authentication/privacyPage'
import SicurforceSpinner from 'components/content/SicurforceSpinner'
import createMarkupSicurForce from 'features/utils/createMarkupSicurForce'

library.add(fas)

const PrivacyPage = ({ canAccept }: PrivacyPageProps) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const [isChecked, setIsChecked] = useState<boolean>(false)

	const { data: textData, isLoading: textDataIsLoading } = useGetPrivacyPolicyTextQuery()
	const [submitAccept] = useAcceptPrivacyPolicyMutation({ fixedCacheKey: fixedCacheKeys.privacyPolicyAccepted })

	if (textDataIsLoading) {
		return <SicurforceSpinner.FullPage />
	}
	
	return (
		textData && <div className={styles.privacyPageContainer}>
			<div key='short' dangerouslySetInnerHTML={createMarkupSicurForce(textData.shortText)} />
			<div key='expand'
				onClick={() => setIsExpanded(!isExpanded)}
				className={styles.clickForMoreDetalis}
			>
				<FontAwesomeIcon
					icon={isExpanded ? 'angle-up' : 'angle-down'}
					cursor="pointer"
					color='lightblue'
				/>
				<span>{labels.clickForDetails}</span>
			</div>
			{isExpanded && <div className='mx-4' key='long' dangerouslySetInnerHTML={{ __html: textData.text }} />}
			{(canAccept) &&
				<>
					<div key='acceptTerms' className={styles.acceptTerms}>
						<input type='checkbox' onChange={() => setIsChecked(!isChecked)} />
						<ol>
							{textData.policyDeclarations[0] && textData.policyDeclarations.map((e: string) => <ul key={e}>{e}</ul>)}
						</ol>
					</div>
					<div key="buttonContainer" className={styles.buttonContainer}>
						<Button
							variant='green'
							disabled={!isChecked}
							onClick={() => submitAccept()}
						>
							{labels.iAccept}
						</Button>
					</div>
				</>}
		</div>
		|| null
	)
}

export default PrivacyPage