import React, { FC } from 'react'
import { useAuthenticationApp } from './useAuthenticationApp'

import Layout from 'layout/Layout'
import UnAuthorized from 'layout/UnAuthorized'
import SicurforceFullPageSpinner from 'components/content/SicurforceSpinner/SicurforceFullPageSpinner'
import PrivacyPage from 'components/authentication/PrivacyPage'
import 'assets/scss/global.scss'

const App: FC = function App() {
	const {
		isLoggedIn,
		isLoading,
		authenticatedNotPolicyAccepted
	} = useAuthenticationApp()

	if (isLoading) {
		return <SicurforceFullPageSpinner />
	}

	if (isLoggedIn && authenticatedNotPolicyAccepted) {
		return <PrivacyPage canAccept />
	}


	return (
		isLoggedIn ?
			<Layout /> :
			<UnAuthorized />
	);
};

export default App;
