import { Col, Container, Row } from "react-bootstrap"
import "./style.scss";
import { GuestResponseDettaglioApi } from "features/storeManagement/services/models/GuestResponse";
import UserIcon from 'assets/icons/UserIcon';
import { labels } from "features/locale/it-it";

interface dettaglioInterface {
	dataDettaglio: GuestResponseDettaglioApi,
	setShowPreview: Function
}

const ControlloAccessiDatiUtente = (data: dettaglioInterface) => {

	const styleButtonFunction = (status:string) => {
		if(status === labels.statusEnabled){
			return "text_dettaglio_status__circle text_dettaglio_status__circle_green"
		}else if(status === labels.statusDisabled){
			return "text_dettaglio_status__circle text_dettaglio_status__circle_orange"
		}else{
			return "text_dettaglio_status__circle text_dettaglio_status__circle_red"
		}
	}

	const textFunction = (status:string) => {
		if(status === labels.statusEnabled){
			return labels.abilitatoLabel
		}else if(status === labels.statusDisabled){
			return labels.disabilitatoLabel
		}else{
			return labels.bloccatoLabel
		}
	}

	return(
		<Container className="controllo-accessi-dettaglio w-100 d-flex flex-wrap align-items-center justify-content-center mt-4 p-0">
			<Row className="col-12 m-0">
				<Col lg={5} md={5} sm={12} className="d-flex flex-column flex-row align-items-center p-1">
					<div>
						<label><b className="text_dettaglio_titolo">{labels.dettaglioUtenteLabel}:</b></label>
					</div>
					{
						data?.dataDettaglio?.guest?.picture ? (
							<div>
								<div className="controllo-accessi-dettaglio__profilePic_container" onClick={() => {data.setShowPreview(true)}}>
									<img className="controllo-accessi-dettaglio__profilePic_img" src={data?.dataDettaglio?.guest?.picture?.path} alt="" />
								</div>
							</div>
						) : (
							<UserIcon size="lg"/>
						)
					}
					<div className="d-flex flex-row-reverse controllo-accessi-dettaglio__status_container">
						<p className="text_dettaglio_status">{textFunction(data?.dataDettaglio?.guest?.status)}</p>
						<div className={styleButtonFunction(data?.dataDettaglio?.guest?.status)}></div>
					</div>
				</Col>
				{data?.dataDettaglio?.guest && (
					<Col lg={7} md={7} sm={12} className="d-flex flex-column p-0">
						<div className="w-100">
							<label><b className="text_dettaglio_titolo">{labels.datiUtenteLabel}:</b></label>
						</div> 
						<div className="d-flex p-3 w-100 controllo-accessi-box controllo-accessi-box__text">
							<Col className="d-flex flex-wrap dettaglio-utente">
								<div>
									<label className="text_dettaglio_label">{labels.textCognome}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.lastName ? data.dataDettaglio.guest.lastName : "-" }</span>
								</div>
								<div>
									<label className="text_dettaglio_label">{labels.textName}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.firstName ? data.dataDettaglio.guest.firstName : "-"}</span>
								</div>
								<div>
									<label className="text_dettaglio_label">{labels.azienda}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.companyName ? data.dataDettaglio.guest.companyName : "-"}</span>
								</div>
								<div>
									<label className="text_dettaglio_label">{labels.codiceFiscaleText}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.fiscalCode ? data.dataDettaglio.guest.fiscalCode : "-"}</span>
								</div>
								<div>
									<label className="text_dettaglio_label">{labels.pivaText}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.vatCode ? data.dataDettaglio.guest.vatCode : "-"}</span>
								</div>
								<div>
									<label className="text_dettaglio_label">{labels.tipologiaLabel}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.type ? data.dataDettaglio.guest.type : "-"}</span>
								</div>
								<div>
									<label className="text_dettaglio_label">{labels.customerAddress}:</label>
									<span className="text_dettaglio_value print-blue-text">{data.dataDettaglio.guest.address ? data.dataDettaglio.guest.address : "-"}</span>
								</div>
							</Col>
						</div>
					</Col>
				)}
			</Row>
		</Container>
	)
}

export default ControlloAccessiDatiUtente