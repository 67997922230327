import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { version } from "../../config";
import "./style.scss";
import { labels } from "features/locale/it-it";

const Footer: FC = function Footer() {
	return (
		<div id="footer">
			<footer className="footer d-flex align-items-center">
				<Row className="justify-content-between w-100 fs--1">
					<Col className="col-10 text-start">
						<p className="mb-0">
							{labels.sicurforceBrand}
							<span className="d-none d-inline-block">| </span>
							<br className="d-none" /> {new Date().getFullYear()}
						</p>
					</Col>
					<Col className="col-2 text-end">
						<p
							className={`app-version ${process.env.REACT_APP_NODE_ENV} mb-0`}
						>{`v${version}`}</p>
					</Col>
				</Row>
			</footer>
		</div>
	);
};

export default Footer;
