import ControlloAccessiFilters from "components/modules/ControlloAccessi/ControlloAccessiFilters";
import "./style.scss";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { Container, Form } from "react-bootstrap";
import { useParams } from "react-router";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import {
	guestApi,
	useGetGuestMutation,
	useGetGuestResponsabileMutation,
	usePostGuestAggiungiMutation,
	usePostGuestFilterMutation,
	usePostGuestRegistroMutation,
} from "features/storeManagement/services/guestService";
import { useEffect, useState } from "react";
import { ObjectType } from "features/types";
import { GuestRequestApi } from "features/storeManagement/services/models/GuestRequest";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import Suspense from "features/Suspense";
import { SearchPostResponseApi } from "features/storeManagement/services/models/GuestResponse";
import AlertModal from "components/content/modals/AlertModal/AlertModal";
import ControlloAccessiTable from "components/modules/ControlloAccessi/ControlloAccessiTable/ControlloAccessiTable";
import { Regex } from "components/global/Utils";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
	ResponseError,
	filterGuest,
} from "features/storeManagement/services/models/GuestResponse";

const ControlloAccessiPage = () => {
	const [dataFilter, setDataFilter] = useState<
		SearchPostResponseApi[] | SearchPostResponseApi | undefined
	>();

	const { shiftId, guestConfigurationId, customer, serviceAddress } =
		useParams();
	const [formData, setFormData] = useState<ObjectType>(Object.create({}));
	const [emptyMandatoryFields, setEmptyMandatoryFields] = useState<String[]>(
		[]
	);
	const [wrongFields, setWrongFields] = useState<String[]>([]);
	const [isMandatoryFieldsFilled, setIsMandatoryFieldsFilled] =
		useState<boolean>(false);
	const [isFieldsCorrect, setIsFieldsCorrect] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [isExistingEmail, setIsExistingEmail] = useState<boolean>(false);
	const [isAggiungiGuestError, setIsAggiungiGuestError] = useState<
		FetchBaseQueryError | SerializedError | undefined
	>(undefined);

	const dispatch = useDispatch();

	useChangeDocumentTitle(labels.pageTitle_controlloAccessi);

	const [
		getGuest,
		{
			data: guestData,
			isLoading: guestDataIsLoading,
			isError: isGuestDataError,
			error: guestDataError,
		},
	] = useGetGuestMutation({ fixedCacheKey: fixedCacheKeys.guestData });

	let [
		getGuestResponsabile,
		{
			data: guestDataResponsabile,
			isLoading: guestDataResponsabileIsLoading,
			isError: isGuestDataResponsabileError,
			error: guestDataResponsabileError,
		},
	] = useGetGuestResponsabileMutation({
		fixedCacheKey: fixedCacheKeys.guestResponsabileData,
	});

	let [
		searchGuest,
		{
			data: searchGuestDataResponsabile,
			isLoading: searchGuestDataResponsabileIsLoading,
			isError: isSearchGuestDataResponsabileError,
			error: searchGuestDataResponsabileError,
		},
	] = usePostGuestFilterMutation({
		fixedCacheKey: fixedCacheKeys.guestCercaData,
	});

	let [
		registroGuest,
		{
			data: registroGuestDataResponsabile,
			isLoading: registroGuestDataResponsabileIsLoading,
			isError: isRegistroGuestDataResponsabileError,
			error: registroGuestDataResponsabileError,
		},
	] = usePostGuestRegistroMutation({
		fixedCacheKey: fixedCacheKeys.guestRegistroData,
	});

	let [
		aggiungiGuest,
		{
			data: aggiungiGuestDataResponsabile,
			isLoading: aggiungiGuestDataResponsabileIsLoading,
			isError: isAggiungiGuestDataResponsabileError,
			error: aggiungiGuestDataResponsabileError,
		},
	] = usePostGuestAggiungiMutation({
		fixedCacheKey: fixedCacheKeys.guestAggiungiData,
	});

	useEffect(() => {
		if (shiftId && guestConfigurationId)
			getGuest({
				shiftId: shiftId,
				guestConfigurationId: Number(guestConfigurationId),
			});
	}, [shiftId, guestConfigurationId]);

	useEffect(() => {
		if (customer && serviceAddress && guestConfigurationId) {
			getGuestResponsabile({
				customer: customer,
				serviceAddress: serviceAddress,
				guestConfigurationId: Number(guestConfigurationId),
			});
		}
	}, [customer, serviceAddress, guestConfigurationId]);

	const handleSubmitCerca = (e: React.SyntheticEvent) => {
		e.preventDefault();
		clearErrorFields();
		let arrFilter = Object.entries(formData || {});
		let objFilter = Object.fromEntries(arrFilter);

		const objResult = Object.entries(objFilter).map(([chiave, valore]) => {
			return { name: chiave, value: valore };
		});

		let searchBody: GuestRequestApi = { filters: [] };
		if (shiftId) {
			searchBody = {
				shiftId: shiftId,
				filters: objResult,
			};
		} else if (customer && serviceAddress) {
			searchBody = {
				idCustomer: customer,
				serviceAddress: serviceAddress,
				filters: objResult,
			};
		}
		searchGuest({
			guestConfigurationId: Number(guestConfigurationId),
			filters: searchBody,
		});
	};

	const handleRegistro = (e: React.SyntheticEvent) => {
		e.preventDefault();
		clearErrorFields();
		let arrFilter = Object.entries(formData || {});
		let objFilter = Object.fromEntries(arrFilter);
		const objResult = Object.entries(objFilter).map(([chiave, valore]) => {
			return { name: chiave, value: valore };
		});

		let searchBody: GuestRequestApi = { filters: [] };
		if (shiftId) {
			searchBody = {
				shiftId: shiftId,
				filters: objResult,
			};
		} else if (customer && serviceAddress) {
			searchBody = {
				idCustomer: customer,
				serviceAddress: serviceAddress,
				filters: objResult,
			};
		}

		registroGuest({
			guestConfigurationId: Number(guestConfigurationId),
			filters: searchBody,
		});
	};

	const showRegistryFunction = () => {
		if (guestData?.showRegistry || guestDataResponsabile?.showRegistry) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		searchGuestDataResponsabile = undefined;
		registroGuestDataResponsabile = undefined;
		setDataFilter(undefined);
	}, []);

	useEffect(() => {
		if (searchGuestDataResponsabile) {
			registroGuestDataResponsabile = undefined;
			setDataFilter(searchGuestDataResponsabile);
		}
	}, [searchGuestDataResponsabile]);

	useEffect(() => {
		if (registroGuestDataResponsabile) {
			searchGuestDataResponsabile = undefined;
			setDataFilter(registroGuestDataResponsabile);
		}
	}, [registroGuestDataResponsabile]);
	const clearErrorFields = () => {
		setEmptyMandatoryFields([]);
		setWrongFields([]);
	};

	const checkFields = (obj: ObjectType, fields: filterGuest[]) => {
		const arr: string[] = [];

		for (const field of fields) {
			if (
				obj === null ||
				obj === undefined ||
				!(field.name in obj) ||
				obj[field.name] === undefined ||
				obj[field.name] === null
			) {
				arr.push(field.name);
			}
		}

		setEmptyMandatoryFields((prevFields) => [...prevFields, ...arr]);
		if (arr.length === 0) {
			setIsMandatoryFieldsFilled(true);
		} else {
			setIsMandatoryFieldsFilled(false);
		}
	};

	const checkFieldsValidation = (obj: ObjectType) => {
		const arr: string[] = [];

		for (const key in obj) {
			if (Object.keys(Regex).includes(key)) {
				const regex = new RegExp(Regex[key as keyof typeof Regex]);
				if (!regex.test(obj[key])) {
					arr.push(key);
				}
			}
		}

		setWrongFields((prevFields) => [...prevFields, ...arr]);
		if (arr.length === 0) {
			setIsFieldsCorrect(true);
		} else {
			setIsFieldsCorrect(false);
		}
	};

	const handleAggiungiGuest = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		setDataFilter(undefined);
		// vedo che tutti i campi obbligatori siano compilati
		let requiredFileds: filterGuest[] = [];
		if (guestData) {
			requiredFileds = guestData.filters?.filter((data) => {
				return data.required === true;
			});
		} else if (guestDataResponsabile) {
			requiredFileds = guestDataResponsabile?.filters?.filter((data) => {
				return data.required === true;
			});
		}

		if (requiredFileds.length > 0) {
			await checkFields(formData, requiredFileds);
		}

		// vedo che i campi compilati rispettino le eventuali regex
		if (formData) {
			await checkFieldsValidation(formData);
		}
	};

	useEffect(() => {
		if (isMandatoryFieldsFilled === true && isFieldsCorrect === true) {
			let arrFilter = Object.entries(formData || {});
			let objFilter = Object.fromEntries(arrFilter);
			const objResult = Object.entries(objFilter).map(([chiave, valore]) => {
				return { name: chiave, value: valore };
			});

			let searchBody: GuestRequestApi = { filters: [] };
			if (shiftId) {
				searchBody = {
					shiftId: shiftId,
					filters: objResult,
				};
			} else if (customer && serviceAddress) {
				searchBody = {
					idCustomer: customer,
					serviceAddress: serviceAddress,
					filters: objResult,
				};
			}
			
			aggiungiGuest({
				guestConfigurationId: Number(guestConfigurationId),
				filters: searchBody,
			});
			// resetto eventuali dati di una chiamata precedente
			aggiungiGuestDataResponsabile = undefined;
			aggiungiGuestDataResponsabileError = undefined;
		}
	}, [isMandatoryFieldsFilled, isFieldsCorrect]);

	useEffect(() => {
		if (
			aggiungiGuestDataResponsabile &&
			isMandatoryFieldsFilled &&
			isFieldsCorrect
		) {
			setShowConfirmModal(true);
			setIsExistingEmail(false);
			setFormData({});
			setIsMandatoryFieldsFilled(false);
			setIsFieldsCorrect(false);
		}
	}, [aggiungiGuestDataResponsabile, isMandatoryFieldsFilled, isFieldsCorrect]);

	useEffect(() => {
		if (
			aggiungiGuestDataResponsabileError &&
			isMandatoryFieldsFilled &&
			isFieldsCorrect
		) {
			const error = aggiungiGuestDataResponsabileError as ResponseError;
			if (
				error.data.StatusCode === 400 &&
				error.data.ErrorCode === labels.guest
			) {
				setIsExistingEmail(true);
			} else {
				setIsAggiungiGuestError(error);
			}
			setShowConfirmModal(false);
			setFormData({});
			setIsMandatoryFieldsFilled(false);
			setIsFieldsCorrect(false);
		}
	}, [
		aggiungiGuestDataResponsabileError,
		isMandatoryFieldsFilled,
		isFieldsCorrect,
	]);

	useEffect(() => {
		return () => {
			// pulisco le api relative a controllo accessi nel momento in cui il componente viene distrutto
			dispatch(guestApi.util.resetApiState());
		};
	}, []);

	return (
		<ErrorBoundary
			errorMessage={
				guestDataError ||
				guestDataResponsabileError ||
				searchGuestDataResponsabileError ||
				registroGuestDataResponsabileError ||
				isAggiungiGuestError
			}
		>
			<Suspense
				isLoading={guestDataIsLoading || guestDataResponsabileIsLoading}
				fullPage
			>
				<Container>
					<ControlloAccessiFilters
						guestData={guestData}
						guestDataResponsabile={guestDataResponsabile}
						formData={formData}
						setFormData={setFormData}
						emptyMandatoryFields={emptyMandatoryFields}
						setEmptyMandatoryFields={setEmptyMandatoryFields}
						wrongFields={wrongFields}
						setWrongFields={setWrongFields}
						handleSubmitCerca={handleSubmitCerca}
						handleRegistro={handleRegistro}
						handleAggiungiGuest={handleAggiungiGuest}
						searchGuestDataResponsabileIsLoading={
							searchGuestDataResponsabileIsLoading
						}
						registroGuestDataResponsabileIsLoading={
							registroGuestDataResponsabileIsLoading
						}
						aggiungiGuestDataResponsabileIsLoading={
							aggiungiGuestDataResponsabileIsLoading
						}
					/>
					{dataFilter && (
						<ControlloAccessiTable
							data={dataFilter}
							showRegistry={showRegistryFunction()}
						/>
					)}
				</Container>
				<AlertModal
					headerMessage={labels.addGuestTitle}
					bodyMessage={labels.addGuestConfirmBody}
					okButtonText={labels.ok}
					showAlertModal={showConfirmModal}
					setShowAlertModal={setShowConfirmModal}
					redirectFunction={() => {
						setShowConfirmModal(false);
					}}
				/>
				<AlertModal
					headerMessage={labels.warning}
					bodyMessage={labels.duplicateGuestModalBody}
					okButtonText={labels.ok}
					showAlertModal={isExistingEmail}
					setShowAlertModal={setIsExistingEmail}
					redirectFunction={() => {
						setIsExistingEmail(false);
					}}
				/>
			</Suspense>
		</ErrorBoundary>
	);
};

export default ControlloAccessiPage;
