import {
	useGetRiconsegnaDetailsMutation,
	useGetRiconsegnaResponsabileDetailsMutation,
} from "features/storeManagement/services/keyManagementService";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import isMobile from "ismobilejs";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { nanoid } from "@reduxjs/toolkit";
import PrintButton from "components/content/PrintButton/PrintButton";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";

const KeyDeliveredResponsabilePage = () => {
	const { keyManagementId, keyRegistryManagementId, answerId } = useParams();
	let objInfoDataOperatorKey = undefined;

	useChangeDocumentTitle(labels.pageTitle_KeysPage);

	const [
		getRiconsegnaResponsabileDetails,
		{
			data: riconsegnaDetailsResponsabileData,
			isLoading: riconsegnaResponsabileDataIsLoading,
			error: riconsegnaResponsabileDataError,
		},
	] = useGetRiconsegnaResponsabileDetailsMutation({
		fixedCacheKey: fixedCacheKeys.getRecipientKey,
	});

	useEffect(() => {
		if (
			keyManagementId !== undefined &&
			keyRegistryManagementId !== undefined &&
			answerId
		) {
			getRiconsegnaResponsabileDetails({
				keyManagementId: keyManagementId.toString(),
				keyRegistryManagementId: Number(keyRegistryManagementId),
				answerId: Number(answerId)
			});
		}
	}, []);

	if (
		riconsegnaDetailsResponsabileData &&
		riconsegnaDetailsResponsabileData.keyRecipient
	) {
		objInfoDataOperatorKey = {
			firstname:
				riconsegnaDetailsResponsabileData.keyRecipient.firstName?.toString(),
			lastname:
				riconsegnaDetailsResponsabileData.keyRecipient.lastName?.toString(),
			OperatorName:
				riconsegnaDetailsResponsabileData.operatorNameOutbound?.toString(),
			time: riconsegnaDetailsResponsabileData.outBoundTimeStamp?.toString(),
			companyName:
				riconsegnaDetailsResponsabileData.keyRecipient.companyName?.toString(),
			additionalInfo:
				riconsegnaDetailsResponsabileData.keyRecipient.additionalInfo?.toString(),
			deliveredNote:
				riconsegnaDetailsResponsabileData.deliveredNote?.toString(),
			storedNote:
				riconsegnaDetailsResponsabileData.storedNote?.toString(),
		};
	}
	const deliveredFromName = `${
		riconsegnaDetailsResponsabileData?.keyDelivery?.firstName ?? ""
	} ${riconsegnaDetailsResponsabileData?.keyDelivery?.lastName ?? ""} `;


	const checkDelivered = () => {
		if(riconsegnaDetailsResponsabileData?.managementStatus === labels.keyStatusStored){
			return true
		}else{
			return false
		}
	}

	const isShowMediaQuestions = riconsegnaDetailsResponsabileData && 
	riconsegnaDetailsResponsabileData.keyRegistryModelOut && 
	riconsegnaDetailsResponsabileData.keyRegistryModelOut.questions &&
	riconsegnaDetailsResponsabileData.keyRegistryModelOut.questions.length > 0;

	return (
		<ErrorBoundary errorMessage={riconsegnaResponsabileDataError}>
			<Container className="box-details-container key-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={riconsegnaResponsabileDataIsLoading} fullPage>
					<Container fluid className='box-details-accordion p-0 mt-2 open standard d-flex justify-content-end'>
						<PrintButton />
					</Container>
					<BoxDetails
						recipientKeyData={
							riconsegnaDetailsResponsabileData?.keyRegistryModelOut
						}
						objInfoDataOperatorKey={objInfoDataOperatorKey}
					/>

					{isShowMediaQuestions &&
					riconsegnaDetailsResponsabileData.keyRegistryModelOut.questions.filter((el) => el.type === labels.picture).map((question: Question) => (
						question.answerDetail?.medias && question.answerDetail?.medias.length > 0 && (
							<div key={question.id} className="box-details-container px-sm-5 px-0">
								<MediaPicker
									read={true}
									mediaArray={question.answerDetail?.medias}
									mediaTitle={question.title}
									deleteIsLoading={false}
									showDeleteConfirmModal={false}
									deleteAJournalMedia={() => {}}
									setShowConfirmModal={() => {}}
									setMediaArray={() => {}}
									setShowDeleteConfirmModal={() => {}}
									setMediaIdToDel={() => {}}
									flagGiornale={false}
								/>
							</div>
						)
					)
					)}

					{checkDelivered() && (
						<div className="print-full-section">
							<div className="box-details-container px-1 mt-3 container-fluid key-consultation-box">
								<div className="element_container__label my-2 mt-5">
									<ModulePageTitle title={labels.infoKeyStatusConsegnata} />
								</div>
							</div>
							<div
								className={`box-details-accordion p-1 mx-auto mt-2 open standard mb-3`}
							>
								<Container
									fluid
									className="box-modules-container base-container fade-content mt-2 p-0"
								>
									<Row
										className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
										onClick={() => {}}
									>
										<div />
										<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
											<Col className="datetime-box col-7 d-flex flex-column align-items-start px-1">
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.withdrawnfromKeyStatusConsegnate}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{deliveredFromName}
																</div>
																<div className="col-12">
																	{
																		riconsegnaDetailsResponsabileData
																			?.keyDelivery?.companyName
																	}{" "}
																	{riconsegnaDetailsResponsabileData
																		?.keyDelivery?.additionalInfo
																		? `- ${riconsegnaDetailsResponsabileData?.keyDelivery?.additionalInfo}`
																		: ""}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.operatorKeyStatusConsegnate}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{
																		riconsegnaDetailsResponsabileData?.operatorNameInbound ? riconsegnaDetailsResponsabileData?.operatorNameInbound : "-"
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
											<Col
												key="checkout-time"
												className="datetime-box col-5 d-flex flex-column align-items-start px-1"
											>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.dateKeyStatusRiconsegnate}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{riconsegnaDetailsResponsabileData?.inBoundTimeStamp
																		? format(
																			new Date(
																				riconsegnaDetailsResponsabileData.inBoundTimeStamp
																			),
																			"dd/MM/yyyy HH:mm"
																		  )
																		: "-"}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.noteKeyStatusRiconsegna}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{riconsegnaDetailsResponsabileData?.storedNote
																		? riconsegnaDetailsResponsabileData?.storedNote
																		: "-"}
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</Row>
								</Container>
							</div>
						</div>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default KeyDeliveredResponsabilePage;
