import { fetchBaseQuery, FetchArgs, FetchBaseQueryError, BaseQueryFn } from "@reduxjs/toolkit/query/react"
import { setIsLoggedIn } from "../../slices/loggedInSlice"

const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_DEV_BASE_URL })


export const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError> = async (args, api, extraOptions) => {
		let result = await baseQuery(args, api, extraOptions)

		// try to get a new token
		if (result.error && result.error.status === 401) {
			let refreshTokenCallArgs = JSON.parse(JSON.stringify(args))
			refreshTokenCallArgs.url = '/Auth/RefreshToken'
			refreshTokenCallArgs.body = JSON.stringify({ RefreshToken: localStorage.refreshToken || sessionStorage.refreshToken })
			refreshTokenCallArgs.method = 'POST'

			let refreshResult: any = await baseQuery(refreshTokenCallArgs, api, extraOptions)
			if (refreshResult.data) {
				// store the new token
				if (localStorage.refreshToken) localStorage.setItem('refreshToken', refreshResult.data.refreshToken)
				if (localStorage.token) localStorage.setItem('token', refreshResult.data.token)
				if (sessionStorage.refreshToken) sessionStorage.setItem('refreshToken', refreshResult.data.refreshToken)
				if (sessionStorage.token) sessionStorage.setItem('token', refreshResult.data.token)

				// retry the initial query, with new token
				result = await baseQuery(args, api, extraOptions)
			} else {
				// sign out
				api.dispatch(setIsLoggedIn(false))
			}
		}

		return result
	}