import Title from "./Title";
import Subtitle from "./Subtitle";
import Select, { SingleValue } from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
	AutocompleteOptionsType,
	AutocompleteSelectFormItemsPropsType,
} from "features/storeManagement/services/models/AutocompleteSelect";
import { labels } from "features/locale/it-it";
import "./selectAutoComplete.scss";
import SearchIcon from "assets/icons/SearchIcon";
import classNames from "classnames";
import { useEffect, useState } from "react";

const SelectAutoComplete = ({
	formData,
	setFormData,
	title,
	subtitle,
	enabled,
	defaultValue,
	validated,
	isMandatory,
	isLoading,
	isClearable,
	isRtl,
	isSearchable,
	name,
	options,
	newRecipientButton = true,
	clickedButton,
	setType,
	setClickedValue,
	className,
	titleClassName,
	selectClassName
}: AutocompleteSelectFormItemsPropsType) => {
	const lookUpObjValue = (val: SingleValue<AutocompleteOptionsType>) => {
		if (val) {
			let recipientValues = val.value.split("_");
			let recipientId = recipientValues[recipientValues.length - 1];
			setFormData({ ...formData, [`${name} `]: [recipientId] });
		} else {
			setFormData({ ...formData, [`${name} `]: undefined });
		}
	};
	const [selectedValue, setSelectedValue] =
		useState<SingleValue<AutocompleteOptionsType> | null>(null);
	const formatOptionLabel = ({
		value,
		label,
		company,
		additionalInfo,
	}: AutocompleteOptionsType) => (
		<div className="options-container">
			<div className="label m-0">{label}</div>
			{company && additionalInfo && (
				<div className="company-info m-0">
					{company} - {additionalInfo}
				</div>
			)}
			{company && !additionalInfo && (
				<div className="company-info m-0">{company}</div>
			)}
			{!company && additionalInfo && (
				<div className="company-info m-0">{additionalInfo}</div>
			)}
		</div>
	);

	useEffect(() => {
		if (defaultValue) {
			setSelectedValue(defaultValue);
			lookUpObjValue(defaultValue);
		}
	}, [defaultValue]);

	const styleFunction = () => {
		if(title == labels.keyDeliveredAuthorizationRequiredTo){
			return "red-color"
		}
		if(titleClassName && newRecipientButton){
			return titleClassName
		}else{
			return ''
		}
	}

	return (
		<Form.Group as={Row} className="row-container align-mid mb-2">
			<Col className="items-container px-3 my-2 allinea_container_end align-mid">
				{title && title !== '' && <Title text={title} isMandatory={isMandatory} className={styleFunction()}/>}
				<div className={className? className : ''}>
					<Select
						formatOptionLabel={formatOptionLabel}
						className={"basic-single m-0" + selectClassName ? selectClassName : ''}
						classNamePrefix="select"
						value={selectedValue}
						isDisabled={!enabled}
						isLoading={isLoading ? isLoading : false}
						isClearable={isClearable ? isClearable : false}
						isRtl={isRtl ? isRtl : false}
						isSearchable={isSearchable ? isSearchable : true}
						name={name}
						options={options}
						onChange={(val) => {
							setSelectedValue(val);
							lookUpObjValue(val);
							setClickedValue && setClickedValue(val);
						}}
						placeholder={labels.select}
					/>
				</div>
				<div className="w-40">
					{subtitle && newRecipientButton && <Subtitle text={subtitle} />}
					{newRecipientButton && (
						<Button
							type="button"
							form="parcelForm"
							variant="green"
							className="newRecipient"
							onClick={(e) => {
								clickedButton(e);
								setType && setType(name);
							}}
						>
							{labels.newRecipient}
						</Button>
					)}
				</div>
			</Col>
			{validated && (
				<Form.Control.Feedback type="invalid">
					{labels.mustSelectOne}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	);
};

export default SelectAutoComplete;
