import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Title from './Title'
import Subtitle from './Subtitle'

import { updateState } from 'features/utils/formComponentsStateUpdate'
import { labels } from 'features/locale/it-it'
import { ChecklistFormItemsPropsType } from 'features/storeManagement/services/models/ChecklistResponse'
import { preventSubmitOnEnter } from 'features/utils/preventSubmitOnEnter'

import './checkbox.scss'

const Checkbox = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	answerDetail,
	formData,
	setFormData,
	sectionAndQuestionId,
	isExecutable,
	validated
}: ChecklistFormItemsPropsType) => {
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`
	const [checkedBoxes, setCheckedBoxes] = useState<string[]>(formData[formDataKeyForComponent] ? (formData[formDataKeyForComponent] as string[]) : [])

	const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, answer: string) => {

		if (e.target.checked) {
			setCheckedBoxes(checkedBoxes => {
				if (!checkedBoxes.includes(answer)) {
					const newCheckedBoxes = [...checkedBoxes]
					newCheckedBoxes.push(answer)
					return newCheckedBoxes
				}
				else {
					return checkedBoxes
				}
			})
		} else {
			setCheckedBoxes(checkedBoxes => {
				const newCheckedBoxes = [...checkedBoxes]
				return newCheckedBoxes.filter(checkbox => checkbox !== answer)
			})
		}
	}

	useEffect(() => {
		updateState.updateMultipleCheckboxState(checkedBoxes, setFormData, formDataKeyForComponent)
	}, [checkedBoxes])

	return (
		<Form.Group 
			key={`question-${id}`}
			id={`question-${id}`} 
			className='checkbox-question-container'
		>
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<div className='checkboxes-container'>
				{answers && answers.map(answer => {
					const answer_id = `${id}_answer-${answer.toLowerCase().trim().replaceAll(' ', '-')}`;
					return (
						<div className='question-container' key={`content-${answer_id}`}>
							<Form.Check
								className='checkbox-square'
								id={answer_id}
								key={`question-${answer_id}`}
								required={isMandatory && checkedBoxes.length === 0}
								disabled={!enabled || !isExecutable}
								defaultChecked={checkedBoxes.includes(answer)}
								onKeyDown={e => preventSubmitOnEnter(e)}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckChange(e, answer)}
								title={answer}
							/>
							<Form.Check.Label
								key={`label-${answer_id}`}
								htmlFor={answer_id}
							>
								{answer}
							</Form.Check.Label>
						</div>
					)
				})}
			</div>
			{(validated && checkedBoxes.length < 1) && <div className='feedback-message'>{labels.mustCheck}</div>}

		</Form.Group>
	)
}

export default Checkbox