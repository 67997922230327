import React, { FC, useContext, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Collapse, Nav } from 'react-bootstrap'
import { nanoid } from '@reduxjs/toolkit'
import classNames from 'classnames'
import AppContext from 'theme/context/Context'
import { ChildrenType, ICollapseItems, INavbarVerticalMenu } from 'features/types'
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem'
import { ModulesDictionaryType } from 'features/types/data/modules'

const CollapseItems: FC<ICollapseItems> = function CollapseItems({ route }) {
	const { pathname } = useLocation()

	const openCollapse = (childrens: ChildrenType[]): boolean => {
		const checkLink = (children: ChildrenType): boolean => {
			if (children.to === pathname) {
				return true
			}
			return (
				Object.prototype.hasOwnProperty.call(children, 'children') &&
				(children?.children?.some(checkLink) as boolean)
			)
		}
		return childrens.some(checkLink)
	}

	const [open, setOpen] = useState(
		openCollapse(route.children as ChildrenType[])
	)

	return (
		<Nav.Item as='li'>
			<Nav.Link
				onClick={(): void => {
					setOpen(!open)
				}}
				className={classNames(
					'dropdown-indicator cursor-pointer', {
						'text-500': !route.active,
						'active': open
					}
				)}
				aria-expanded={open}
			// {...route}
			>
				<NavbarVerticalMenuItem route={route} />
			</Nav.Link>
			<Collapse in={open}>
				<Nav className='flex-column nav' as='ul'>
					<NavbarVerticalMenu routes={route.children as ModulesDictionaryType[]} />
				</Nav>
			</Collapse>
		</Nav.Item>
	)
}

const NavbarVerticalMenu: FC<INavbarVerticalMenu> =
	function NavbarVerticalMenu({ routes }) {
		const {
			config: { showBurgerMenu },
			setConfig
		} = useContext(AppContext)

		const handleNavItemClick = (): void => {
			if (showBurgerMenu) {
				setConfig('showBurgerMenu', !showBurgerMenu)
			}
		}
		return (
			<>
				{routes.map((route: ModulesDictionaryType) => {
					if (!route.children) {
						return (
							<Nav.Item as='li' key={nanoid()} onClick={handleNavItemClick}>
								<NavLink
									end={route.exact}
									to={route.to as string}
									state={{ open: route.to === '/authentication-modal' }}
									className={({ isActive }): string =>
										isActive && !route.forceNotActive ? 'active nav-link' : 'nav-link'
									}
								>
									<NavbarVerticalMenuItem route={route} />
								</NavLink>
							</Nav.Item>
						)
					}
					return <CollapseItems route={route} key={nanoid()} />
				})}
			</>
		)
	}

export default NavbarVerticalMenu
