import {
	GuestResponseDettaglioApi,
} from "features/storeManagement/services/models/GuestResponse";
import { Col, Container, Row } from "react-bootstrap";
import { dateParser } from "features/utils/dateUtils";
import { labels } from "features/locale/it-it";
import './style.scss'
interface dettaglioInterface {
	dataDettaglio: GuestResponseDettaglioApi;
}

const ControlloAccessiDatiAccesso = (data: dettaglioInterface) => {
	return (
		<Container className="controllo-accessi-dettaglio w-100 d-flex flex-wrap align-items-center justify-content-center mt-1 p-0">
			<div className="w-100">
				<label>
					<b className="text_dettaglio_titolo">{labels.datiAccessoLabel}:</b>
				</label>
			</div>
			<Row className="d-flex flex-row controllo-accessi-box controllo-accessi-box__text w-100 p-2">
				<Col className="d-flex flex-wrap controllo-accessi">
					<div>
						<label className="text_dettaglio_label">{labels.ingressoProgrammatoLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{data?.dataDettaglio?.checkinScheduled
								? dateParser(
									data.dataDettaglio.checkinScheduled.toString() || ""
								).getDay() +
									" " +
								dateParser(
									data.dataDettaglio.checkinScheduled.toString() || ""
								)
									.getHour()
									.substring(0, 5)
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.uscitaProgrammata}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{data?.dataDettaglio?.checkoutScheduled
								? dateParser(
									data.dataDettaglio.checkoutScheduled.toString() || ""
								).getDay() +
								  " " +
								dateParser(
									data.dataDettaglio.checkoutScheduled.toString() || ""
								  )
									.getHour()
									.substring(0, 5)
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.ingressoRegistratoLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{data?.dataDettaglio?.checkin ? 
								dateParser(
									data.dataDettaglio.checkin.toString() || ""
								).getDay() +
									" " +
								dateParser(data.dataDettaglio.checkin.toString() || "")
									.getHour()
									.substring(0, 5)
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.uscitaRegistrata}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{data?.dataDettaglio?.checkout ? 	
								dateParser(
									data.dataDettaglio.checkout.toString() || ""
								).getDay() +
									" " +
								dateParser(data.dataDettaglio.checkout.toString() || "")
									.getHour()
									.substring(0, 5)
								: "-"}
						</span>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default ControlloAccessiDatiAccesso;
