import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'theme/assets/img/icons/spot-illustrations/16.png';
import { IConfirmMailContent } from 'features/types/authentication/email';

const ConfirmMailContent: FC<IConfirmMailContent> = function ConfirmMailContent({ email, layout, titleTag: TitleTag }) {
	return (
		<>
			<img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={100} />
			<TitleTag>Please check your email!</TitleTag>
			<p>
				An email has been sent to <strong>{email}</strong>. Please click on the
				included link to reset your password.
			</p>
			<Link to={`/authentication/${layout}/login`}>
				<Button color="green" size="sm" className="mt-3">
					<FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="me-1" />
					Return to login
				</Button>
			</Link>
		</>
	)
};

export default ConfirmMailContent;
