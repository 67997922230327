import "./style.scss";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Suspense from "features/Suspense";
import { labels } from "features/locale/it-it";
import JournalForm from "components/modules/Journal/JournalForm";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { useGetOneJournalLogDetailMutation } from "features/storeManagement/services/journalService";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";

const JournalPage: React.FC<{ read?: boolean }> = ({ read }) => {
	useChangeDocumentTitle(labels.pageTitle_JournalPage);

	const { shiftId, journalId, draftId, journalLogId } = useParams();

	const journalLogIdParam = journalLogId
		? Number(journalLogId)
		: Number(draftId) || 0;

	const [
		getOneJournalLogDetail,
		{ data: journalGetData, isLoading: journalIsLoading, error: journalError },
	] = useGetOneJournalLogDetailMutation();

	useEffect(() => {
		if (draftId || journalLogId) {
			getOneJournalLogDetail({
				shiftId: shiftId || "",
				journalId: Number(journalId) || 1,
				journalLogId: journalLogIdParam,
			});
		}
	}, []);

	const logText: string = journalGetData?.journalText || "";
	const operatorName: string =
		journalGetData?.firstName + " " + journalGetData?.lastName;
	const lastUpdate: Date | undefined | null = journalGetData?.lastUpdateDate;
		
	return (
		<ErrorBoundary errorMessage={journalError}>
			<Container className="checklist-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={journalIsLoading} fullPage>
					<JournalForm
						read={read}
						text={logText || ""}
						journalId={journalGetData?.journalId || Number(journalId)}
						journalLogId={Number(journalLogId || draftId)}
						journalCausalId={journalGetData?.journalCausalId}
						operator={operatorName}
						medias={journalGetData?.medias}
						lastUpdate={lastUpdate}
					></JournalForm>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default JournalPage;
