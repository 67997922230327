import React from 'react'
import { Form } from 'react-bootstrap'
import createMarkupSicurForce from 'features/utils/createMarkupSicurForce'

import './title.scss'

const Title = ({ text, isMandatory, className }: { text: string, isMandatory?: boolean, className?: string }) => {
	return (
		<Form.Label
			dangerouslySetInnerHTML={createMarkupSicurForce(`${text} ${isMandatory ? "*" : ""}`)}
			className={`checklist-question-title ${className || ""}`}
		/>
	)
}

export default Title