import { FC } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomDateInput from 'theme/components/common/CustomDateInput';
import { IEventScheduleItem } from 'features/types/events/createEvent';

const EventScheduleItem: FC<IEventScheduleItem> = function EventScheduleItem({
	index,
	title,
	handleRemove,
	handleChange,
	startDate,
	startTime,
	endDate,
	endTime,
	register,
	setValue
}) {
	return (
		<div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
			<div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
				<Button size="sm" variant="link" className="p-0" onClick={(): void => handleRemove(index)}
				>
					<FontAwesomeIcon className="text-danger" icon="times-circle" />
				</Button>
			</div>

			<Row className="gx-2 gy-3">
				<Col md="12">
					<Form.Group controlId="scheduleStartTitle">
						<Form.Label>Title</Form.Label>
						<Form.Control type="text" placeholder="Title" defaultValue={title} {...register(`scheduleTitle${index}`)}
						/>
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group controlId={`ScheduleStartDate${index}`}>
						<Form.Label>Start Date</Form.Label>

						<DatePicker selected={startDate} onChange={(newDate: any): void => {
							handleChange(index, 'startDate', newDate);
							setValue(`ScheduleStartDate${index}`, newDate);
						}} customInput={
							<CustomDateInput formControlProps={{
								placeholder: 'd/m/y',
								...register(`ScheduleStartDate${index}`)
							}} value='' onClick={undefined} isInvalid={false} isValid={false} errorMessage='' />
						}
						/>
					</Form.Group>
				</Col>

				<Col md="6">
					<Form.Group controlId="scheduleStartTime">
						<Form.Label>Start Time</Form.Label>
						<DatePicker selected={startTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm" onChange={(newDate: any): void => {
							handleChange(index, 'startTime', newDate);
							setValue(`ScheduleStartTime${index}`, newDate);
						}} customInput={
							<CustomDateInput formControlProps={{
								placeholder: 'H:i',
								...register(`ScheduleStartTime${index}`)
							}} value='' onClick={undefined} isInvalid={false} isValid={false} errorMessage='' />
						}
						/>
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group controlId="scheduleEndDate">
						<Form.Label>End Date</Form.Label>

						<DatePicker selected={endDate} onChange={(newDate: any): void => {
							handleChange(index, 'endDate', newDate);
							setValue(`ScheduleEndDate${index}`, newDate);
						}} customInput={
							<CustomDateInput formControlProps={{
								placeholder: 'd/m/y',
								...register(`ScheduleEndDate${index}`)
							}} value='' onClick={undefined} isInvalid={false} isValid={false} errorMessage='' />
						}
						/>
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group controlId="scheduleEndTime">
						<Form.Label>End Time</Form.Label>
						<DatePicker selected={endTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm" onChange={(newDate: any): void => {
							handleChange(index, 'endTime', newDate);
							setValue(`ScheduleEndTime${index}`, newDate);
						}} customInput={
							<CustomDateInput formControlProps={{
								placeholder: 'H:i',
								...register(`ScheduleEndTime${index}`)
							}} value='' onClick={undefined} isInvalid={false} isValid={false} errorMessage='' />
						}
						/>
					</Form.Group>
				</Col>
			</Row>
		</div>
	);
};
export default EventScheduleItem;


