import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { ICustomDateInput } from 'features/types/events/createEvent';

const CustomDateInput: FC<ICustomDateInput> = function CustomDateInput({ value, onClick, isInvalid, isValid, formControlProps, errorMessage }) {
	return (
		<>
			<Form.Control isInvalid={isInvalid} isValid={isValid} value={value} onClick={onClick} {...formControlProps} />
			<Form.Control.Feedback type="invalid">
				{errorMessage}
			</Form.Control.Feedback>
		</>

	)
};
export default CustomDateInput;
