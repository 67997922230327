import { FC } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, Page } from 'powerbi-client';

const basicFilter = {
	$schema: "http://powerbi.com/product/schema#basic",
	target: {
		table: "Shift",
		column: "ShiftCity"
	},
	operator: "In",
	values: ['SUISIO'],
	filterType: models.FilterType.Basic
};

const ReportTestPage: FC = function ReportTestPage() {
	return (
		<>
			<PowerBIEmbed
				embedConfig={{
					type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
					id: '5015dc3c-e796-4eb9-aab0-a9b3699e6e66',
					embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=5015dc3c-e796-4eb9-aab0-a9b3699e6e66&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
					//embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=41632bf1-14ad-44ef-916a-62e375e727d3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
					accessToken: 'H4sIAAAAAAAEACWTx66rVgBF_-VOiWTTTaQ3oIPph2JgRu8dA4co_54rZb4me2ntf37sBPZTkv_8_SPa-H2Um2_DsYsZ7lg1zZevqoJXb4DXIzS1i5cdSGUNGnHNKZR51jRKY66hCsU31o65kbR1vQ4DSftJhFhakXLfVcVpRY-XvhhKwBwLcxZQqMLSPHZGFLEw2UZdUtL97XQW01Am7k6UL7sjtyxyx8ve7uRvk2Dx2b4eXNQFGbO9DJXimEOPrN5MLMgwzDZUFWW8WPaFY_YYSE0uYDOLv8N79nMCiAyhL6XCQNmvHL-iBw9k551o1g3U0VRVeLW2JSeAcoSdWHHi4e7SF4kUtq6xhGwRybc93kZQxD5Qm-13qJuDC-jD-ezkMH4AJp8VQfILMbKJCPnW4kMePMRhcMBimII7FydZlryH4Sa7saZFWgSskPDTuSZAQWrrSp00ziPymoHCJg-0z1zCvUuYZvmr7Kk1VJDMKg1rtogBnI-j2MVcxhtXn1G5mh6haHXt6nN7zDzKRZ-nP5P2d4_tA-p313YhXUqpLlcqNdMXAxycSg1GHqk8n2iKuS8pogG1YWtwkqmIX2-dj6wgn91cl98sztE0L8yxTm638hQvRuuGUChLYaQDGde_aZS8DtaauDtmjD0-FC5GLhQXz3ce4jmn6wg_YIyfPN41RAuNkiKCnY9isf0Xo8o1Ei5Z4_QepRoqVNM7sedUOIoM0YTNJd6Top-ssNy8HYub2LP5S8KklEd3Vi7R2Rh32Yld_22-Av96UYOxXsOe6lpN1IdWdh_m4fz58_PXD7_CeZ-0Av5mPiHNILEKW2pBVjlNZcaThpnL-eAUPJ4yM-641Bd5tcSt7xHNqk8oQydz12ypbCERkPT7eLS7I9JYGKz4WNfxrVl0kfYSGcp92dJmqnLhJTvN-XHGAwlGL71GGpLW5bif2K_4dmC6oeJKkN0FCyUYH97vfa5bxREkpLxkv92v8A2IOwGZvGuNcLnvhOslUDyBaSl50U2X6yZ5KlBDbj8TXBgkzdUcu8QM4DaH8blvh_MUdadRY0OfvrNduuQPEVR1IJviF1FGdS9Qm8rWE_Ap7DT-YovKzEqcX6bFHOaTkay9BJfX8SLmw5Z66IZmGR0HMToO5DEJGreF6hrJ7gY6jf1fM5zrYlWDX8vBkG1nqPRoazML0QJ7VIrgf8ptqjHZv2vxi610sCEFn2BSrk1p0qZ-cpdod_pxX47eCutrBoFnYnr6O3IrukgMlV2LopUNHfWjTca1948HG2mcDmNfY68lcSAADSwzsNmNj6HROJ8Xf1sC5Zu3aYLX-2mqk-fjb-jcnU3PaVC2YX_CjiZqSOv9kBiEgqhx-rl3eqyztzUe2uJK8_AIyOBEhE8k7JlwlQnzvR0RaReHpOcMKqWbdWioNJL6umqrt7d4Q9vq4eBeINS0SXr7ME78M15Ru5zZbMLusUZ3lf3oaFBMnoJjon0BhKUGHHCxLRft7Avf5FjHVOJr0jm-odQ1pmCIEirfONl-tLtca7NGH66f5uT5NZEyfRDM-av53_8AunFZmNoFAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNjk0NzAwNTA4LCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=',
					tokenType: models.TokenType.Embed, 
					filters: [basicFilter],
					settings: {
						panes: {
							filters: {
								expanded: false,
								visible: false
							}
						},
						background: models.BackgroundType.Transparent,

					}
				}}
			/>
		</>
	);
};

export default ReportTestPage;