import { FC } from 'react';
import LogoutContent from './LogoutContent';
import bgImg from 'theme/assets/img/generic/19.jpg';
import AuthSplitLayout from 'layout/AuthLayout';

const LogoutPage: FC = function LogoutPage() {
	return (
		<AuthSplitLayout bgProps={{ image: bgImg }}>
			<div className="text-center">
				<LogoutContent layout="split" titleTag="h3" />
			</div>
		</AuthSplitLayout>
	);
};

export default LogoutPage;
