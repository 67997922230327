import { FC } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { } from 'theme/data/dashboard/default'
import ShiftItem from 'components/modules/Shift/ShiftItem';
import { dateParser } from 'features/utils/dateUtils';
import { Shift } from 'features/storeManagement/services/models/ShiftsResponse';
import { labels } from 'features/locale/it-it';

import './style.scss';
import { Planning } from 'features/storeManagement/services/models/PlanningsResponse';

const ShiftList: FC<{data? : Planning[]}> = function ShiftList({data}) {

	const showShiftItem = (shiftData : Shift) => {
		if (shiftData?.shiftStartTime && shiftData?.shiftEndTime) {
			return dateParser(new Date().toUTCString()).isVisibleShift(
				shiftData?.shiftStartTime,
				shiftData?.shiftEndTime
			);
		}
		return false;
	};

	let validShiftsCounter: number = 0;

	return (
		<Row className="w-100 d-flex">
			{data?.map((planning) => {
				return planning.shiftList.map((item) => {
					const isValidShift = showShiftItem(item);
					isValidShift && validShiftsCounter++;
					return (isValidShift &&
						<Col
							className='shift-item-container px-4 px-lg-5 py-3 py-md-4'
							md={6}
							lg={6}
							xl={4}
							key={item.shiftId + 'container'}>
							<ShiftItem
								key={item.shiftId}
								id={item.shiftId}
								customer={item.shiftCustomerName}
								type={item.shiftJobDescription}
								address={item.shiftFullAddress}
								venueName={item.shiftVenueName}
								startDate={item.shiftStartTime}
								endDate={item.shiftEndTime}
							/>
						</Col>)
				})
			})}
			{!validShiftsCounter && (
				<Container fluid className='modules-container base-container p-0 mb-5 mt-4 w-50'>
					<Row className='d-flex align-items-center m-0'>
						<Col className="p-0 text-center">
							<Alert variant='theme-blue'>
								{labels.noActiveShift}
							</Alert>
						</Col>
					</Row>
				</Container>
			)}
		</Row>
	);
};

export default ShiftList;