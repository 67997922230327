import { parcelStatus } from "features/storeManagement/services/models/ParcelResponse";
import "./style.scss";
import { format } from "date-fns";
import { useGetParcelDetailMutation } from "features/storeManagement/services/parcelService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useEffect } from "react";
import { useParams } from "react-router";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import { labels } from "features/locale/it-it";
import { roles } from "features/storeManagement/services/constants/roles";
import BoxDetails from "components/content/BoxDetails";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { translateStatus } from "./ParcelUtils";
import { Container, Row, Col } from "react-bootstrap";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { nanoid } from "@reduxjs/toolkit";
import PrintButton from "components/content/PrintButton/PrintButton";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";

const ParcelConsultation = () => {
	const { shiftId, parcelId, parcelManagementId, answerId } = useParams();
	useChangeDocumentTitle(labels.pageTitle_ParcelPage);

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{ data: currentShiftData, isLoading: currentShiftIsLoading },
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		getParcelDetail,
		{
			data: parcelDetailData,
			isLoading: parcelDetailDataIsLoading,
			error: parcelDetailDataError,
		},
	] = useGetParcelDetailMutation({
		fixedCacheKey: fixedCacheKeys.parcelDetailData,
	});

	useEffect(() => {
		if (parcelId && parcelManagementId && shiftId && answerId) {
			getParcelDetail({ parcelId, parcelManagementId, shiftId, answerId });
		}
	}, []);
	const isAnomaly =
		parcelDetailData?.status.toLocaleLowerCase() === parcelStatus.lost ||
		parcelDetailData?.status.toLocaleLowerCase() === parcelStatus.cancelled ||
		parcelDetailData?.status.toLocaleLowerCase() === parcelStatus.stolen;

	const parcelFirstName =
		parcelDetailData?.parcelSubstituteRecipient?.firstName || "";
	const parcelLastName =
		parcelDetailData?.parcelSubstituteRecipient?.lastName || "";
	const parcelName = `${parcelFirstName !== "" ? parcelFirstName + " " : ""}${
		parcelLastName !== "" ? parcelLastName : ""
	}`;

	const parcelFirstNameRecipient =
		parcelDetailData?.parcelRecipient?.firstName || "";
	const parcelLastNameRecipient =
		parcelDetailData?.parcelRecipient?.lastName || "";
	const parcelNameRecipient = `${parcelFirstNameRecipient !== "" ? parcelFirstNameRecipient + " " : ""}${
		parcelLastNameRecipient !== "" ? parcelLastNameRecipient : ""
	}`;

	return (
		<ErrorBoundary errorMessage={parcelDetailDataError}>
			<Suspense isLoading={parcelDetailDataIsLoading} fullPage>
				<Container fluid className='box-details-accordion p-1 mt-2 open standard d-flex justify-content-end'>
					<PrintButton />
				</Container>
				<BoxDetails parcel={parcelDetailData} />

				<Container fluid className="box-details-accordion">
					<div>
						{!!parcelDetailData && 
						parcelDetailData.questions && 
						parcelDetailData.questions.filter((el) => el.type === labels.picture).map((question: Question) => (
							question.answerDetail?.medias && question.answerDetail?.medias.length > 0 && (
								<div key={question.id}>
									<MediaPicker
										read={true}
										mediaArray={question.answerDetail?.medias}
										mediaTitle={question.title}
										deleteIsLoading={false}
										showDeleteConfirmModal={false}
										deleteAJournalMedia={() => {}}
										setShowConfirmModal={() => {}}
										setMediaArray={() => {}}
										setShowDeleteConfirmModal={() => {}}
										setMediaIdToDel={() => {}}
										flagGiornale={false}
									/>
								</div>
							)
						)
						)}
					</div>
				</Container>
				
				<div className="print-full-section">

					{isAnomaly && (
						<div className="element_container__label mt-2">
							<ModulePageTitle title={labels.info_segnalazione} />
						</div>
					)}
					{parcelDetailData?.status.toLocaleLowerCase() ===
						parcelStatus.delivered && (
						<div className="element_container__label mt-2">
							<ModulePageTitle title={labels.infoConsegna} />
						</div>
					)}

					<div
						className={`box-details-accordion p-1 mx-auto mt-2 open standard`}
					>
						{
							parcelDetailData?.status.toLocaleLowerCase() !==
								parcelStatus.stored && ( 
								<Container
									fluid
									className="box-modules-container base-container fade-content mt-2 p-0 mb-4"
								>
									<Row
										className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
										onClick={() => {}}
									>
										<div />
										<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 py-3">
											<Col
												key="shift-time"
												className="datetime-box col-7 d-flex flex-column align-items-start px-1"
											>
												{isAnomaly ? (
													<>
														<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
															<div className="bold-text col-5">
																{labels.parcelStatoDelPacco}
															</div>
															<div className="day bold-text col-6">
																{translateStatus(parcelDetailData.status)}
															</div>
														</div>
														<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
															<div className="bold-text col-5">
																{labels.parcelNoteDiSegnalazione}
															</div>
															<div className="day bold-text col-6">
																{parcelDetailData.note ? parcelDetailData.note : "-"}
															</div>
														</div>
													</>
												) : (
													<>
														{parcelDetailData?.parcelRecipient &&
															parcelDetailData.parcelSubstituteRecipient ===
																null && (
															<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
																<div className="bold-text operator-div col-5">
																	{labels.parcelRitiratoDa}:
																</div>
																{parcelDetailData?.parcelRecipient?.recipientType === labels.carrier && ( 
																	<div className="day bold-text col-md-6">
																		<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
																			<div className="bold-text col-12">
																				{parcelDetailData?.parcelRecipient?.companyName}
																			</div>
																		</div>
																	</div>
																)}
							
																{parcelDetailData?.parcelRecipient?.recipientType === labels.person && ( 
																	<div className="day bold-text col-md-6">
																		<div className="d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
							
																			<div className="bold-text col-12">
																				{parcelNameRecipient}
																			</div>
																			{(parcelDetailData?.parcelRecipient.companyName || parcelDetailData?.parcelRecipient.additionalInfo) &&
																			<div className="bold-text col-12">
																				{parcelDetailData?.parcelRecipient.companyName}{" "}
																				{parcelDetailData?.parcelRecipient.additionalInfo
																					? `- ${parcelDetailData?.parcelRecipient.additionalInfo}`
																					: ""}
																			</div>}
																		</div>
																	</div>
																)}
															</div>
														)}
													</>
												)}
												{parcelDetailData?.parcelSubstituteRecipient && (
													<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
														<div className="bold-text col-5">
															{labels.parcelRitiratoDa}:
														</div>
														{parcelDetailData?.parcelSubstituteRecipient
															.recipientType === labels.carrier && (
															<div className="day bold-text col-6">
																{
																	parcelDetailData?.parcelSubstituteRecipient.companyName
																}
															</div>
														)}
														{parcelDetailData?.parcelSubstituteRecipient
															.recipientType === labels.person && (
															<>
																<div className="day bold-text col-md-6">
																	<div className="d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
			
																		<div className="bold-text col-12">
																			{parcelName}
																		</div>
																		{(parcelDetailData?.parcelSubstituteRecipient.companyName || parcelDetailData?.parcelSubstituteRecipient.additionalInfo) &&
			
																		<div className="bold-text col-12">
																			{parcelDetailData?.parcelSubstituteRecipient.companyName}{" "}
																			{parcelDetailData?.parcelSubstituteRecipient.additionalInfo
																				? `- ${parcelDetailData?.parcelSubstituteRecipient.additionalInfo}`
																				: ""}
																		</div>}
																	</div>
																</div>
															</>
														)}
			
														{parcelDetailData?.parcelRecipient &&
															parcelDetailData.parcelSubstituteRecipient ===
																null && (	
															<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
																<div className="day bold-text col-6">
																	{parcelDetailData.parcelRecipient.firstName +
																		" " +
																		parcelDetailData.parcelRecipient.lastName}
																</div>
			
																{parcelDetailData ? (
																	<div className="day bold-text col-6">
																		{parcelDetailData.parcelRecipient.companyName}{" "}
																		{parcelDetailData.parcelRecipient
																			.additionalInfo
																			? `- ${parcelDetailData.parcelRecipient.additionalInfo}`
																			: ""}
																	</div>
																) : null}
															</div>
														)}
													</div>
												)}
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
													<div className="bold-text operator-div col-5">
														{labels.operator} {labels.porter + ":"}
													</div>
													<div className="day bold-text col-6">
														{parcelDetailData?.operatorNameOutbound}
													</div>
												</div>
											</Col>
											<Col
												key="checkout-time"
												className="datetime-box col-5 d-flex flex-column align-items-start px-1"
											>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
													<span className="bold-text col-5">
														{isAnomaly
															? labels.parcelSegnalatoIl
															: labels.parcelConsegnatoIl}
														:
													</span>
													<span className="day bold-text col-6">
														{parcelDetailData?.parcelOutboundTimestamp
															? format(
																new Date(
																	parcelDetailData?.parcelOutboundTimestamp
																),
																"dd/MM/yyyy HH:mm"
															)
															: "-"}
													</span>
												</div>
											</Col>
										</Row>
									</Row>
								</Container>
							)
						}

					</div>

				</div>
			</Suspense>
		</ErrorBoundary>
	);
};

export default ParcelConsultation;
