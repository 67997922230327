import "./style.scss";
import { Row } from "react-bootstrap";
import { PBIReportResponse } from "features/storeManagement/services/models/ReportResponse";
import { CustomerResponse } from "features/storeManagement/services/models/CustomerResponse";

interface CustomerDetailsProps {
	companyName: string;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ companyName }) => {
	return (
		<>
			<Row className="accordion-box flex-nowrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0 flex-no-wrap justify-content-center align-items-center">
				<Row className="client-name w-100 flex-no-wrap justify-content-center align-items-center bold-text-cliente">
					{companyName}
				</Row>
			</Row>
		</>
	);
};

export default CustomerDetails;
