import { FC } from "react";
import "./styles.scss";
import { OperatoreResponse } from "features/storeManagement/services/models/TurniResponse";
import { Container, Form } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import CircleArrowRightIcon from "assets/icons/CircleArrowRight";
import { capitalizeString } from "features/utils/stringUtils";

export interface OperatorsTableProps {
	data: OperatoreResponse[];
	replaceOperator: Function;
	shiftId?: string;
}

const OperatorsTable: React.FC<OperatorsTableProps> = ({
	data,
	shiftId,
	replaceOperator,
}) => {

	return (
		<>
			<div className="operators_table__container">
				<div className="operators__nav_element">
					<div className="operators__nav_element__single operators__headers_font">
						{labels.textCognome}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{labels.textName}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{labels.cf}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{labels.cid}
					</div>
					<div className="operators__nav_element__single_small operators__headers_font">
						{labels.assignShift}
					</div>
				</div>
				{!!data &&
					data.length > 0 &&
					data.map((element: OperatoreResponse, i: number) => (
						<div className="operators__item_element" key={i}>
							<div className="operators__item_element__single">
								<b className="operators__font">{capitalizeString(element.lastName)}</b>
							</div>
							<div className="operators__item_element__single">
								<b className="operators__font">{capitalizeString(element.firstName)}</b>
							</div>
							<div className="operators__item_element__single">
								<b className="operators__font">{element.userId.toUpperCase()}</b>
							</div>
							<div className="operators__item_element__single">
								<b className="operators__font">{element.operatorCid}</b>
							</div>
							<div className="operators__item_element__single_small">
								<CircleArrowRightIcon
									size="xl"
									className="operators__item_element__single__selected_operator cursor-pointer"
									onClick={() => {
										shiftId &&
											replaceOperator({
												shiftId,
												replaceOperatorBody: {
													operatorCid: element.operatorCid,
												},
											});
									}}
								/>
							</div>
						</div>
					))}
				{!!data && data.length === 0 && (
					<div className="empty-search-result-container">
						<Form.Label htmlFor="key-label" className="key-label mb-0">
							{labels.noResultTabellaOperatori}
						</Form.Label>
					</div>
				)}
			</div>
		</>
	);
};

export default OperatorsTable;
