import { FC } from 'react';
import { ICalendar } from 'features/types/common/calendar';

const Calendar: FC<ICalendar> = function Calendar({ month, day }) {
	return (
		<div className="calendar">
			<span className="calendar-month">{month}</span>
			<span className="calendar-day">{day}</span>
		</div>
	)
};

export default Calendar;
