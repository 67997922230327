import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import "./style.scss";
import { MouseEventHandler } from "react";
import { labels } from "features/locale/it-it";
import { isEmptyObject } from "components/global/Utils";
import { GuestResponseApi, filterGuest } from "features/storeManagement/services/models/GuestResponse";
import { ObjectType } from "features/types";

export interface ControlloAccessiFiltersProps {
	guestData: GuestResponseApi | undefined;
	guestDataResponsabile: GuestResponseApi | undefined;
	setFormData: Function;
	formData: ObjectType;
	emptyMandatoryFields: String[];
	setEmptyMandatoryFields: React.Dispatch<React.SetStateAction<String[]>>;
	wrongFields: String[];
	setWrongFields: React.Dispatch<React.SetStateAction<String[]>>;
	handleSubmitCerca: MouseEventHandler<HTMLButtonElement>;
	handleRegistro: MouseEventHandler<HTMLButtonElement>;
	handleAggiungiGuest: MouseEventHandler<HTMLFormElement>;
	searchGuestDataResponsabileIsLoading: boolean;
	registroGuestDataResponsabileIsLoading: boolean;
	aggiungiGuestDataResponsabileIsLoading: boolean;
}

const ControlloAccessiFilters: React.FC<ControlloAccessiFiltersProps> = ({guestData, guestDataResponsabile, formData, setFormData, emptyMandatoryFields,setEmptyMandatoryFields, wrongFields, setWrongFields, handleSubmitCerca, handleRegistro, handleAggiungiGuest, searchGuestDataResponsabileIsLoading, registroGuestDataResponsabileIsLoading, aggiungiGuestDataResponsabileIsLoading}) => {

	const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
				
		/*Elimino i campi che un utente cancella*/
		if(formData && e.target.value === ''){
			delete formData[e.target.name]
			setFormData({
				...formData,
			});
			/*Se formData risulta senza campi lo metto a null per disabilitare il cerca*/
			if(Object.keys(formData).length === 0){
				setFormData(
					{}
				);
			}
		/*Inserisco i campi normalmente*/
		}else{
			setFormData({
				...formData,
				[e.target.name]: e.target.value
			});
		}
	};

	const clearErrorField = (field: filterGuest) => {
		if (emptyMandatoryFields.includes(field.name)) {
			setEmptyMandatoryFields(emptyMandatoryFields.filter(item => item !== field.name)) 
			
		} else if (wrongFields.includes(field.name)) {
			setWrongFields(wrongFields.filter(item => item !== field.name))
		}
	}
	
	const filterResult: filterGuest[] | undefined = guestData?.filters || guestDataResponsabile?.filters;

	const isMandatoryField = (field: filterGuest) => {
		return emptyMandatoryFields.includes(field.name);
	}

	const isWrongField = (field: filterGuest) => {
		return wrongFields.includes(field.name);
	}

	return (
		<Form
			className="p-0"
			onSubmit={handleAggiungiGuest}
			id="controlloAccessi-form-section"
		>
			<Row>
				<Col lg={10} md={12} sm={12} className="pe-sm-1">
					<div className="controlloAccessi__filters_container">
						{filterResult?.map((element:filterGuest, index:number) => (
							<div className="controlloAccessi__filters_item" key={index}>
								<div className="d-flex justify-content-between align-items-center">
									<label className={(isMandatoryField(element) || isWrongField(element)) ? 'error_field' : ''}>{element.label}</label>
									<div className="d-flex flex-column align-items-end">
										{isMandatoryField(element) && <label className="text-end" style={{color: "red"}}>{labels.mandatoryField}</label>}
										{isWrongField(element) && <label className="text-end" style={{color: "red"}}>{labels.wrongField}</label>}
									</div>
								</div>
								<Form.Control
									className={`operators-search-control-form`}
									type="text"
									name={element.name}
									value={formData[element.name] || ''}
									onChange={handleFieldChange}
									onKeyDown={() => clearErrorField(element)}
								/>
							</div>
						))}
					</div>
				</Col>

				<Col lg={2} md={12} sm={12} className="pe-sm-1 d-flex flex-column button_element_center">
					{<Button variant='green' disabled={(formData === null) || isEmptyObject(formData)} className="controlloAccessi__button m-2" onClick={handleSubmitCerca}>{searchGuestDataResponsabileIsLoading ? <Spinner /> : labels.search}</Button>}
					{(guestData?.showRegistry || guestDataResponsabile?.showRegistry) && <Button variant='green' className="controlloAccessi__button m-2" onClick={handleRegistro}>{registroGuestDataResponsabileIsLoading ? <Spinner /> : labels.registro}</Button>}
					{(guestData?.showAddGuest || guestDataResponsabile?.showAddGuest) && <Button type="submit" variant='green' className="controlloAccessi__button m-2">{aggiungiGuestDataResponsabileIsLoading ? <Spinner /> : labels.aggiungi}</Button>}
				</Col>
			</Row>
		</Form>
	);
};

export default ControlloAccessiFilters;
