import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'theme/components/common/Avatar';
import Flex from 'theme/components/common/Flex';
import VerifiedBadge from 'theme/components/common/VerifiedBadge';
import { IEducationItem } from 'features/types/user/profile';

export const EducationItem: FC<IEducationItem> = function EducationItem({ details, isLast }) {
	const { logo, institution, degree, duration, location, verified } = details;
	return (
		<Flex>
			<Link to="#!">
				<Avatar size="3xl" src={logo} />
			</Link>
			<div className="flex-1 position-relative ps-3">
				<h6 className="fs-0 mb-0">
					<Link to="#!">{institution}</Link>
					{verified && <VerifiedBadge placement={undefined} />}
				</h6>
				<p className="mb-1">{degree}</p>
				<p className="text-1000 mb-0">{duration}</p>
				<p className="text-1000 mb-0">{location}</p>
				{!isLast && <div className="border-dashed border-bottom my-3" />}
			</div>
		</Flex>
	);
};

export default EducationItem;
