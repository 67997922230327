import { createApi } from '@reduxjs/toolkit/query/react'
import { bodyCommon } from './constants/body'
import { headersCommon } from './constants/headers'
import { baseQueryWithLogoutIfInvalidToken } from './queryFuncs/loginLogoutQueryFn'
import { AuthenticationResponse, LoginCredentials, LougoutResponse } from './models/AuthenticationResponse'

export const authenticationApi = createApi({
	reducerPath: 'loginApi',
	baseQuery: baseQueryWithLogoutIfInvalidToken,
	endpoints: builder => ({
		attemptLogin: builder.mutation<AuthenticationResponse, LoginCredentials>({
			query: ({credentials, deviceId}) => ({
				url: 'Auth/Token',
				method: 'POST',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				},
				body: JSON.stringify({
					...bodyCommon,
					...credentials
				})
			})
		}),
		attemptLogout: builder.mutation<LougoutResponse, void>({
			query: () => ({
				url: 'Auth/Logout',
				method: 'POST',
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		})
	})
})

export const { useAttemptLoginMutation, useAttemptLogoutMutation } = authenticationApi