import React from 'react'
import usePatrolDetailsPage from './usePatrolDetailsPage'

import { Col, Container, Row } from 'react-bootstrap'
import SicurForceSpinner from 'components/content/SicurforceSpinner'
import ErrorBoundary from 'features/ErrorBoundary'
import ModulePageTitle from 'components/modules/ModulePageTitle'
import PatrolReadItem from 'components/modules/Patrol/PatrolReadItem'

import './style.scss'
import { labels } from 'features/locale/it-it'
import Suspense from 'features/Suspense'

const PatrolDetailsPage: React.FC<{status : 'completed' | 'in-progress'}> = ({status}) => {
	const { patrolData, patrolIsLoading, patrolError } = usePatrolDetailsPage()

	if (patrolIsLoading) {
		return <SicurForceSpinner.FullPage />
	}

	return (
		<ErrorBoundary errorMessage={patrolError}>
			<Container className='patrol-details-page-container p-0 mb-5 mt-4'>
				<Suspense isLoading={patrolIsLoading} fullPage>
					<Row className='mx-0'>
						<Col className='px-0 my-0'>
							<ModulePageTitle
								title={patrolData?.description}
								date={status === 'completed' ? patrolData?.completedAt : patrolData?.lastUpdateDate}
								patrolFinished = {status === 'completed'}
								isPrint={patrolData && patrolData.completedAt ? true : false}
								author={patrolData?.createdBy}
							/>
						</Col>
					</Row>
					<Row className='patrol-read-cards-and-notes-container mx-0'>
						{patrolData && patrolData.configurations &&
							<Col className='patrol-read-cards-container px-0'>
								{patrolData.configurations.map(item =>
									<PatrolReadItem
										type={status}
										key={item.id}
										title={item.serviceAreaTitle}
										subtitle={item.serviceAreaDescription}
										date={item.replyDetail?.completedAt}
										isMandatory={item.isMandatory}
									/>)
								}
							</Col>}
						{(status === 'completed' && patrolData && patrolData.forceClosedNote) && (
							<Col className='patrol-read-notes-container px-0'>
								<div className='patrol-read-notes-title'>
									{labels.forcedCloseNotes}
								</div>
								<div className='patrol-read-notes-body'>
									{patrolData.forceClosedNote}
								</div>
							</Col>
						)}
					</Row>
				</Suspense>
			</Container>
		</ErrorBoundary>
	)
}

export default PatrolDetailsPage