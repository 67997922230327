import React from "react";

import "./style.scss";

import { labels } from "features/locale/it-it";
import { dateParser } from "features/utils/dateUtils";
import PrintButton from "components/content/PrintButton/PrintButton";

const ModulePageTitle = ({
	title,
	patrolFinished,
	date,
	isPrint,
	author
}: {
	title?: string | null;
	patrolFinished?: boolean;
	date?: Date | null;
	isPrint?: boolean | null;
	author?: string;
}) => {

	const functionStyleButton = () => {
		if(title === labels.infoKeyStatusBloccata || title === labels.info_segnalazione){
			return "module-page-title-title-component label-color-red"
		}else {
			return "module-page-title-title-component"
		}
	}

	return (
		<div className="module-page-title-title-component-container">
			{title && (
				<div className={functionStyleButton()}>{title}</div>
			)}
			{
				<div className="module-page-title-date-and-time-container">
					{date && (
						<>
							{" "}
							<div className="module-page-title-last-update">
								{patrolFinished ? labels.patrolFinishedAt : labels.lastUpdate}
							</div>
							<div className="module-page-title-date-and-time-container">
								<div className="module-page-title-date-and-time">
									{dateParser(date).getDay()}
								</div>
								<div className="module-page-title-date-and-time">
									{dateParser(date).getHour().substring(0, 5)}
								</div>
							</div>
						</>
					)}
					{author && (
						<>
							<div className="module-page-title-last-update">{labels.author}</div>
							<div className="module-page-title-date-and-time-container">
								<div className="module-page-title-date-and-time">{author}</div>
							</div>
						</>
					)}
					{patrolFinished === false && (
						<div className="col-4 px-2 module-page-title-status-in-progress bold-text">
							{labels.inProgress}
						</div>
					)}
				</div>
			}
			{isPrint && (
				<div>
					<PrintButton />
				</div>
			)}
		</div>
	);
};

export default ModulePageTitle;
