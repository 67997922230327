import { FC } from 'react';
import LoginForm from './LoginFrom';
import AuthLayout from 'layout/AuthLayout';
import bgImg from 'theme/assets/img/generic/14.jpg';

const LoginPage: FC = function LoginPage() {
	return (
		<AuthLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
			<LoginForm layout="split" hasLabel />
		</AuthLayout>
	);
};

export default LoginPage;
