import "./style.scss";
import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button, Container } from 'react-bootstrap'
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";
import ShiftDetailsTop from "components/modules/Shift/ShiftDetails/ShiftDetailsTop";
import ShiftDetailsBottom from "components/modules/Shift/ShiftDetails/ShiftDetailsBottom";
import CorrispondenteShiftDetailsBottom from "components/modules/Shift/ShiftDetails/CorrispondenteShiftDetailsBottom";
import { CustomerResponse, CustomerAddress } from "features/storeManagement/services/models/CustomerResponse";
import CustomerName from "components/modules/Checklist/AuditDetails/CustomerName";
import CustomerDetails from "components/modules/Checklist/AuditDetails/CustomerDetails";
import { GetParcelDetailResponse } from "features/storeManagement/services/models/ParcelsResponse";
import { labels } from 'features/locale/it-it'
import { KeyRecipientT, getRecipientKeyResponse, keyBoxDetailsButtom } from "features/storeManagement/services/models/KeysResponse";
import { BoxDetailsWrapper } from "./BoxDetailsWrapper";
import KeyButtonDetails from "components/modules/Key/KeyButton/KeyButtonDetails/KeyButtonDetails";
import RecipientTopDetails from "components/pages/Key/RecipientTopDetails/RecipientTopDetails";
import ClienteDetails from "components/modules/Cliente/CustomerDetails";
import { PBIReportResponse } from "features/storeManagement/services/models/ReportResponse";
import { turnoSingoloShift } from "features/storeManagement/services/models/TurniResponse";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import ParcelDetails from "components/modules/Parcel/ParcelDetails/ParcelDetails";
import KeyDetails from "components/pages/Key/KeyDetails/KeyDetails";

library.add(fas);


interface BoxDetailsProps {
	shift?: Shift | null | turnoSingoloShift;
	customerAddress?: CustomerAddress;
	isCorrispondentePage?: boolean;
	parcel?: GetParcelDetailResponse | undefined;
	setIsReport?: Function;
	isReport?: boolean;
	keyData?: getRecipientKeyResponse;
	recipientKeyData?: getRecipientKeyResponse;
	objInfoDataOperatorKey?: keyBoxDetailsButtom;
	keyDataConsegna?: getRecipientKeyResponse;
	flagClientePage?: boolean,
	customerId?: string,
	flagCoordinatore?: boolean,
	companyName?: string,
	flagTurno?: boolean;
	flagKeyResponsabile?:boolean;
	additionalInfo?: string;
}

const BoxDetails: React.FC<BoxDetailsProps> = ({ shift, customerAddress, isCorrispondentePage, parcel, setIsReport, isReport, keyData, recipientKeyData, objInfoDataOperatorKey, keyDataConsegna, flagClientePage = false, customerId, flagCoordinatore = false, flagTurno = false, companyName, flagKeyResponsabile }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;
	let flagConsegnaChiavi = false;
	if (keyDataConsegna) {
		flagConsegnaChiavi = true;
	}

	const ButtonRight = () => {
		return (
			<div>
				{
					keyDataConsegna &&
					<Button variant="green" onClick={() => setIsReport && setIsReport(!isReport)} className={`open-report-button text-nowrap ${!isReport ? 'report' : 'delivery'}`}>
						{!isReport ? labels.parcelApriSegnalazione : keyDataConsegna ? labels.deliverKeys : labels.parcelConsegnaPacco}
					</Button>
				}
				{
					!keyDataConsegna &&
					<Button variant="green" onClick={() => setIsReport && setIsReport(!isReport)} className={`open-report-button text-nowrap ${!isReport ? 'report' : 'delivery'}`}>
						{!isReport ? labels.parcelApriSegnalazione : keyData ? labels.returnKeys : labels.parcelConsegnaPacco}
					</Button>
				}
			</div>
		)
	}
	return (
		<>
			<BoxDetailsWrapper withButton={(shift || isResponsabile) && (parcel || keyData || keyDataConsegna) && setIsReport && (<ButtonRight />)}>

				<Container fluid className='mt-3 px-1 z-index-99'>
					{
						flagClientePage && !flagCoordinatore && companyName &&
						<ClienteDetails companyName={companyName} />
					}
					{
						!flagClientePage && flagCoordinatore &&
						<CustomerName
							customerInfo={customerAddress}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					}
					{
						flagTurno && shift &&
						<ShiftDetailsTop
							shift={shift}
							customerAddress={customerAddress}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					}
					{shift && !parcel && !keyData && !keyDataConsegna && !flagCoordinatore && !flagTurno && (
						<ShiftDetailsTop
							shift={shift}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					)}
					{customerAddress && !parcel && !keyDataConsegna && !flagCoordinatore && (
						<CustomerName
							customerInfo={customerAddress}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					)}

					{parcel && (
						<ParcelDetails  
							parcel={parcel}
						/>
					)
					}

					{(shift || flagKeyResponsabile) && keyData && objInfoDataOperatorKey && (
						<KeyDetails
							keyData={keyData}
							objInfoDataOperatorKey={objInfoDataOperatorKey}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							flagConsegnaChiavi={flagConsegnaChiavi}

						/>
					)}
					{(shift || flagKeyResponsabile) && keyDataConsegna && (
						<KeyDetails
							keyData={keyDataConsegna}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							flagConsegnaChiavi={flagConsegnaChiavi}
							objInfoDataOperatorKey={objInfoDataOperatorKey}
						/>
					)}

					{!shift && !parcel && !keyData && recipientKeyData && (
						<RecipientTopDetails
							keyData={recipientKeyData}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							objInfoDataOperatorKey={objInfoDataOperatorKey}
						/>
					)}
				</Container>
				<div className={`box-details-accordion p-1 mx-auto ${isOpen ? 'open' : ''} ${shift ? 'standard' : 'small'} ${shift && parcel && setIsReport ? '' : 'absolute'}`}>
					<Container fluid className='box-modules-container base-container fade-content mt-2 p-0'>
						{(!keyDataConsegna) && (
							(shift && isCorrispondentePage) ?
								<CorrispondenteShiftDetailsBottom shift={shift} /> :
								shift && !parcel && !keyData && !flagTurno ?
									<ShiftDetailsBottom shift={shift} /> :
									customerAddress && !keyDataConsegna &&
									<CustomerDetails customerInfo={customerAddress} />
						)}

						{shift && flagTurno && (
							<ShiftDetailsBottom shift={shift}/>
						)}
						

						{
							recipientKeyData && objInfoDataOperatorKey &&
							<KeyButtonDetails objInfoDataOperatorKey={objInfoDataOperatorKey} />
						}
					</Container>
				</div>
			</BoxDetailsWrapper>
		</>
	)
}

export default BoxDetails;
