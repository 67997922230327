import { labels } from "features/locale/it-it";
import { parcelStatus } from "features/storeManagement/services/models/ParcelResponse";

export const translateStatus = (status: string) => {
	let translatedStatus: string = "";
	switch (status.toLowerCase()) {
	case parcelStatus.lost:
		translatedStatus = labels.parcelLost;
		break;
	case parcelStatus.stolen:
		translatedStatus = labels.parcelStolen;
		break;
	case parcelStatus.cancelled:
		translatedStatus = labels.parcelDeleted;
		break;
	}
	return translatedStatus;
};
