import { createApi } from "@reduxjs/toolkit/query/react";
import { headersCommon } from "./constants/headers";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import {
	GetRecipientsRequest,
	GetRecipientsResponse,
	PostKeyRecipientRequest,
	PostKeyRecipientResponse,
	PostParcelRecipientRequest,
} from "./models/RecipientsResponse";

export const recipientApi = createApi({
	reducerPath: "recipientService",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getRecipients: builder.mutation<
			GetRecipientsResponse[],
			GetRecipientsRequest
		>({
			query: ({ parcelId, shiftId, recipientType }) => ({
				url: shiftId ? `/fe/Parcels/${parcelId}/Recipients?shiftId=${shiftId}${
					recipientType ? `&recipientType=$${recipientType}` : ""
				}` : `/fe/Parcels/${parcelId}/Recipients${
					recipientType ? `?recipientType=$${recipientType}` : ""
				}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getRecipientsResponsabile: builder.mutation<
			GetRecipientsResponse[],
			GetRecipientsRequest
		>({
			query: ({ parcelId, shiftId, recipientType }) => ({
				url: `/fe/Parcels/${parcelId}/Recipients${
					recipientType ? `&recipientType=$${recipientType}` : ""
				}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		postParcelRecipient: builder.mutation<
			PostKeyRecipientResponse,
			PostParcelRecipientRequest
		>({
			query: ({ parcelObj, parcelId }) => ({
				url: `/fe/Parcels/${parcelId}/Recipients`,
				method: "POST",
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
				body: parcelObj,
			}),
		}),
		postAKeyRecipient: builder.mutation<
			PostKeyRecipientResponse,
			PostKeyRecipientRequest
		>({
			query: ({ parcelObj, keyManagementId, keyRegistryId }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}/Recipients`,
				method: "POST",
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
				body: parcelObj,
			}),
		}),
	}),
});

export const {
	useGetRecipientsMutation,
	useGetRecipientsResponsabileMutation,
	usePostParcelRecipientMutation,
	usePostAKeyRecipientMutation,
} = recipientApi;
