import React, { ReactNode } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { Error403, Error404, Error500 } from 'components/content/errors'
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk'

const ErrorBoundary = ({ errorMessage, children }: { errorMessage: FetchBaseQueryError | SerializedError | undefined, children: ReactNode }) => {
	const error = errorMessage as FetchBaseQueryError

	if (error && error.status === 403) {
		return <Error403 />
	}

	if (error && error.status === 404) {
		return <Error404 />
	}

	if (error) {
		return <Error500 />
	}

	return <>{children}</>
}

export default ErrorBoundary