import team1 from 'theme/assets/img/team/1.jpg'
import team2 from 'theme/assets/img/team/2.jpg'
import team3 from 'theme/assets/img/team/3.jpg'
import team4 from 'theme/assets/img/team/4.jpg'
import team5 from 'theme/assets/img/team/5.jpg'
import team6 from 'theme/assets/img/team/6.jpg'
import team7 from 'theme/assets/img/team/7.jpg'
import team8 from 'theme/assets/img/team/8.jpg'
import team9 from 'theme/assets/img/team/9.jpg'
import team10 from 'theme/assets/img/team/10.jpg'
import team11 from 'theme/assets/img/team/11.jpg'
import team12 from 'theme/assets/img/team/12.jpg'
import team13 from 'theme/assets/img/team/13.jpg'
import team14 from 'theme/assets/img/team/14.jpg'
import team15 from 'theme/assets/img/team/15.jpg'
import team16 from 'theme/assets/img/team/16.jpg'
import team17 from 'theme/assets/img/team/17.jpg'
import team18 from 'theme/assets/img/team/18.jpg'
import team19 from 'theme/assets/img/team/19.jpg'
import team20 from 'theme/assets/img/team/20.jpg'
import team21 from 'theme/assets/img/team/21.jpg'
import team22 from 'theme/assets/img/team/22.jpg'
import team23 from 'theme/assets/img/team/23.jpg'
import team24 from 'theme/assets/img/team/24.jpg'
import team25 from 'theme/assets/img/team/25.jpg'

export default [
	{
		id: 0,
		avatarSrc: team1,
		name: 'Emilia Clarke',
		institution: 'Technext limited',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 1,
		avatarSrc: team2,
		name: 'Kit Harington',
		institution: 'Harvard Korea Society',
		profileLink: '/user/profile',
		institutionLink: '#!',
		mutual: 5,
		status: 'status-away'
	},
	{
		id: 2,
		avatarSrc: team3,
		name: 'Sophie Turner',
		institution: 'Graduate Student Council',
		profileLink: '/user/profile',
		institutionLink: '#!',
		mutual: 10,
		status: 'status-online'
	},
	{
		id: 3,
		avatarSrc: team4,
		name: 'Peter Dinklage',
		institution: 'Art Club, MIT',
		profileLink: '/user/profile',
		institutionLink: '#!',
		mutual: 8,
		status: 'status-online'
	},
	{
		id: 4,
		avatarSrc: team5,
		name: 'Nikolaj Coster',
		institution: 'Archery Club, MIT',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 5,
		avatarSrc: team6,
		name: 'Isaac Hempstead',
		institution: 'Asymptones',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 6,
		avatarSrc: team7,
		name: 'Alfie Allen',
		institution: 'Brain Trust',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 7,
		avatarSrc: team8,
		name: 'Iain Glen',
		institution: 'GSAS Action Coalition',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 8,
		avatarSrc: team9,
		name: 'Liam Cunningham',
		institution: 'Caving Club, MIT',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 9,
		avatarSrc: team10,
		name: 'John Bradley',
		institution: 'Chess Club',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 10,
		avatarSrc: team11,
		name: 'Rory McCann',
		institution: 'Chamber Music Society',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 11,
		avatarSrc: team12,
		name: 'Joe Dempsie',
		institution: 'Clubchem',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 12,
		avatarSrc: team13,
		name: 'Maisie Williams',
		institution: 'Brain Trust',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 13,
		avatarSrc: team14,
		name: 'Lena Headey',
		institution: 'Clubchem',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 14,
		avatarSrc: team15,
		name: 'Gwendoline Christie',
		institution: 'Technext limited',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 15,
		avatarSrc: team16,
		name: 'Nathalie Emmanuel',
		institution: 'Asymptones',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 16,
		avatarSrc: team17,
		name: 'Natalie Dormer',
		institution: 'Musical Club',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 17,
		avatarSrc: team18,
		name: 'Carice Houten',
		institution: 'Concert Choir',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 18,
		avatarSrc: team19,
		name: 'Hannah Murray',
		institution: 'Career Club',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 19,
		avatarSrc: team20,
		name: 'Rose Leslie',
		institution: 'Musical Club',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 20,
		avatarSrc: team21,
		name: 'Rory McCann',
		institution: 'Alpine Ski Club',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 21,
		avatarSrc: team22,
		name: 'Diana Rigg',
		institution: 'Clubchem',
		profileLink: '/user/profile',
		institutionLink: '#!'
	},
	{
		id: 22,
		avatarSrc: team23,
		name: 'Michelle Fairley',
		institution: 'Alpha Chi Omega',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 23,
		avatarSrc: team24,
		name: 'Gemma Whelan',
		institution: 'Chamber Music Society',
		profileLink: '/user/profile',
		institutionLink: '#!',
		status: 'status-online'
	},
	{
		id: 24,
		avatarSrc: team25,
		name: 'Indira Varma',
		institution: 'ASA',
		profileLink: '/user/profile',
		institutionLink: '#!'
	}
]
