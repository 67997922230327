import { Container, Form } from 'react-bootstrap'
import "./style.scss";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ParcelRecipientsWithDate, ParcelResponseApi, parcelStatus } from 'features/storeManagement/services/models/ParcelResponse';
import { labels } from 'features/locale/it-it';

export interface testInterface {
	parcelRecipients: ParcelResponseApi | undefined,
	setUseReturnDataApi: Dispatch<SetStateAction<ParcelRecipientsWithDate[]>>,
	type: string
}

const ParcelSearchItem = (props: testInterface) => {
	/*------CONST-------*/
	const [stringInsert, setStringInsert] = useState<String>("")

	/*--------FUNCTION------------*/
	useEffect(() => {
		handleSubmit()
	}, [stringInsert])

	const handleSubmit = (() => {
		const stored: ParcelRecipientsWithDate[] = []
		const delivered: ParcelRecipientsWithDate[] = []
		const lost: ParcelRecipientsWithDate[] = []
		const stolen: ParcelRecipientsWithDate[] = []
		const cancelled: ParcelRecipientsWithDate[] = []
		let arrDataParcel: ParcelRecipientsWithDate[] = []
		const insertInput = stringInsert.split(" ");


		if (stringInsert == "") {
			arrDataParcel = props.parcelRecipients?.packages || []
		} else if (props.parcelRecipients && props.parcelRecipients.packages) {
			arrDataParcel = [...arrDataParcel, ...props.parcelRecipients.packages.filter((item) => {
				let found = true
				const searchString = (item.parcelRecipient.firstName !== null) ? item.parcelRecipient.firstName + "-".concat(item.parcelRecipient.lastName !== null ? item.parcelRecipient.lastName : "-").concat(item.parcelRecipient.companyName !== null ? "-" + item.parcelRecipient.companyName : "").concat(item.parcelRecipient.additionalInfo !== null ? "-" + item.parcelRecipient.additionalInfo : "") : "-".concat(item.parcelRecipient.lastName !== null ? item.parcelRecipient.lastName : "-").concat(item.parcelRecipient.companyName !== null ? "-" + item.parcelRecipient.companyName : "").concat(item.parcelRecipient.additionalInfo !== null ? "-" + item.parcelRecipient.additionalInfo : "")
				insertInput.map((singleString) => {
					if (!searchString.toLowerCase().includes(singleString.toLowerCase())) {
						found = false
					}
				})
				return found
			})]
		}

		arrDataParcel.map((e) => {
			switch (e.status.toLowerCase()) {
			case parcelStatus.stored:
				stored.push(e)
				break;
			case parcelStatus.delivered:
				delivered.push(e)
				break;
			case parcelStatus.lost:
				lost.push(e)
				break;
			case parcelStatus.stolen:
				stolen.push(e)
				break;
			case parcelStatus.cancelled:
				cancelled.push(e)
				break;
			}
		})
		props.setUseReturnDataApi(stored.concat(delivered).concat(lost).concat(stolen).concat(cancelled))
	})

	return (
		<Container fluid className="p-1">
			<Form.Group className="search-live-form-group">
				<Form.Control
					className={`search-control-form  ${stringInsert.length === 0 ? "background-grey-important" : ""}`}
					placeholder={labels.parcelCercaPlaceholder}
					type="text"
					onChange={({ target }): void => {
						setStringInsert(target.value.toLowerCase());
					}}
				/>
			</Form.Group>
		</Container>
	)
}

export default ParcelSearchItem;