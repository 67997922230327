import { useNavigate, useParams } from "react-router";
import { Container } from "react-bootstrap";
import Suspense from "features/Suspense";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { useGetKeysMutation, useGetKeysResponsabileMutation } from "features/storeManagement/services/keyManagementService";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import { useEffect, useState } from "react";
import "./style.scss";
import KeySearchComponent from "./KeySearchComponent";
import KeyResultsComponent from "./KeyResultsComponent";
import KeyNoResult from "./KeyNoResult";
import { StoredDeliveredKeysT } from "features/storeManagement/services/models/KeysResponse";
import { labels } from "features/locale/it-it";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useGetModuliResponsabileMutation } from "features/storeManagement/services/responsabileService";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";

const KeyResponsabileItemPage = () => {
	const { customer, serviceAddress, position, keyManagementId } = useParams();
	useChangeDocumentTitle(labels.pageTitle_KeysItemsPage);
	const [filteredData, setFilteredData] = useState<StoredDeliveredKeysT[]>([]);
	const [isStorico, setIsStorico] = useState<boolean>(false);
	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [
		getKeysReponsabile,
		{ data: getKeysResponsabileData, isLoading: getKeysResponsabileIsLoading, error: getKeysResponsabileError },
	] = useGetKeysResponsabileMutation({ fixedCacheKey: fixedCacheKeys.keysData });

	const [
		getModuliResponsabile,
		{
			data: moduliResponsabile,
			isLoading: moduliResponsabileIsLoading,
			isError: moduliResponsabileError,
			error: moduliResponsabileErrorBoundary,
		},
	] = useGetModuliResponsabileMutation({
		fixedCacheKey: fixedCacheKeys.moduliResponsabile,
	});

	useEffect(() => {
		if(moduliResponsabile?.keyManagement.id){
			getKeysReponsabile({
				keyManagementId: moduliResponsabile?.keyManagement.id,
			})
		}
	}, [moduliResponsabile]);


	const resultsFound =
	!!getKeysResponsabileData &&
	((!!getKeysResponsabileData.deliveredKeys && getKeysResponsabileData.deliveredKeys.length > 0) ||
		(!!getKeysResponsabileData.storedKeys && getKeysResponsabileData.storedKeys.length > 0) ||
		(!!getKeysResponsabileData.keyManagements && getKeysResponsabileData.keyManagements.length > 0));


	return (
		<ErrorBoundary errorMessage={getKeysResponsabileError}>
			<Suspense
				isLoading={getKeysResponsabileIsLoading || moduliResponsabileIsLoading}
				fullPage
			>	
				<Container className="p-0 px-2 mb-5 mt-4">
					{!resultsFound ? (
						<KeyNoResult label={labels.keyLablesEmptyState}/>
					) : (
						<>
							<KeySearchComponent
								deliveredKeys={getKeysResponsabileData?.deliveredKeys || []}
								storedKeys={getKeysResponsabileData?.storedKeys || []}
								keyManagements={getKeysResponsabileData?.keyManagements || []}
								setFilteredData={setFilteredData}
								setIsStorico={setIsStorico}
								isStorico={isStorico}
							/>
							<KeyResultsComponent
								data={filteredData}
								keyManagementId={moduliResponsabile?.keyManagement.id.toString()}
								deliveredKeys={getKeysResponsabileData?.deliveredKeys || []}
								storedKeys={getKeysResponsabileData?.storedKeys || []}
								keyManagements={getKeysResponsabileData?.keyManagements || []}
								isStorico={isStorico}
							/>
						</>
					)}
				</Container>
			</Suspense>
		</ErrorBoundary>
	);
};

export default KeyResponsabileItemPage;
