import "./styles.scss";
import { Button, Form } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import { capitalizeString } from "features/utils/stringUtils";
import { SearchPostResponseApi } from "features/storeManagement/services/models/GuestResponse";
import { dateParser } from "features/utils/dateUtils";
import CarIcon from "assets/icons/CarIcon";
import isMobile from "ismobilejs";
import { useNavigate, useParams } from "react-router";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

export interface ControlloAccessiTableInterface {
	data: any;
	replaceOperator?: Function;
	shiftId?: string;
	showRegistry: boolean;
}

const ControlloAccessiTable: React.FC<ControlloAccessiTableInterface> = ({
	data,
	showRegistry
}) => {
	const device = isMobile(window.navigator);
	const navigate = useNavigate()
	const {shiftId, guestConfigurationId, customer, serviceAddress} = useParams();
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;

	const styleTextFunction = (status:string) => {
		if(status === labels.statusEnabled){
			return "operators__font greenEnabled"
		}else if(status === labels.statusDisabled){
			return "operators__font orangeDisabled"
		}else{
			return "operators__font redBlocked"
		}
	}

	const styleButtonFunction = (status:string) => {
		if(status === labels.statusEnabled){
			return "button-controllo-accessi greenEnabledButton"
		}else if(status === labels.statusDisabled){
			return "button-controllo-accessi orangeDisabledButton"
		}else{
			return "button-controllo-accessi redBlockedButton"
		}
	}

	const styleIconFunction = (status:string) => {
		if(status === labels.statusEnabled){
			return "#00A898"
		}else if(status === labels.statusDisabled){
			return "orange"
		}else{
			return "#D13438"
		}
	}

	const redirectUrl = (id: number) => {	
		if(isResponsabile){
			navigate(`/controlloAccessi/${guestConfigurationId}/customer/${customer}/serviceAddress/${serviceAddress}/${id}/dettaglio`)
		}else{
			navigate(`/shift/${shiftId}/controlloAccessi/${guestConfigurationId}/${id}/dettaglio`)
		}
	}
	
	return (
		<>
			<div className="operators_table__container mt-3">
				<div className="operators__nav_element">
					<div className="operators__nav_element__single operators__headers_font">
						{labels.textName}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{labels.textCognome}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{labels.azienda}
					</div>
					<div className="operators__item_element__single_small">

					</div>
					{showRegistry && (
						<>
							<div className="operators__nav_element__single operators__headers_font">
								{labels.ingresso}
							</div>
							<div className="operators__nav_element__single operators__headers_font">
								{labels.uscita}
							</div>
						</>
					)}
					<div className="operators__item_element__single operators__headers_font">
						{labels.funzione}
					</div>
				</div>
				{!!data &&
					data.length > 0 &&
					data.map((element: SearchPostResponseApi, i: number) => (
						<div className="operators__item_element" key={i}>
							<div className="operators__item_element__single">
								<b className={styleTextFunction(element.guest.status)}>{capitalizeString(element.guest.firstName ? element.guest.firstName : "-")}</b>
							</div>
							<div className="operators__item_element__single">
								<b className={styleTextFunction(element.guest.status)}>{capitalizeString(element.guest.lastName ? element.guest.lastName : "-")}</b>
							</div>
							<div className="operators__item_element__single">
								<b className={styleTextFunction(element.guest.status)}>{element.guest.companyName ? element.guest.companyName : "-"}</b>
							</div>
							{(element.guest.vehicles.length > 0) ? (
								<div className="operators__item_element__single_small">
									<CarIcon
										size={device.phone ? "sm" : "lg"}
										className="operators__item_element__single__selected_operator cursor-pointer"
										style={{color: styleIconFunction(element.guest.status ? element.guest.status : "-")}}
									/>
								</div>
							):(
								<div className="operators__item_element__single_small"></div>
							)} 
							{showRegistry && (
								<>
									<div className="operators__item_element__single">
										<b className={styleTextFunction(element.guest.status)}>
											{element.checkin
												? dateParser(
													element.checkin || ""
												).getDay() +
													" " +
													dateParser(element.checkin || "")
														.getHour()
														.substring(0, 5)
												: "-"}
										</b>
									</div>
									<div className="operators__item_element__single">
										<b className={styleTextFunction(element.guest.status)}>
											{element.checkout
												? dateParser(
													element.checkout || ""
												).getDay() +
													" " +
													dateParser(element.checkout || "")
														.getHour()
														.substring(0, 5)
												: "-"}
										</b>
									</div>
								</>
							)}
							{
								element.checkin && element.checkout ? (
									<div className="operators__item_element__single">
										{<Button className="button-controllo-accessi greyDisabledButton" variant='secondary' onClick={() => redirectUrl(element.id)}>{labels.read}</Button>}
									</div>
								):(
									<div className="operators__item_element__single">
										{<Button className={styleButtonFunction(element.guest.status)} onClick={() => redirectUrl(element.id)} >{labels.gestione}</Button>}
									</div>
								)
							}
						</div>
					))}
				{(data && data.length === 0) && (
					<div className="empty-search-result-container">
						<Form.Label htmlFor="key-label" className="key-label mb-0">
							{labels.noResultTabellaControlloAccessi}
						</Form.Label>
					</div>
				)}
			</div>
		</>
	);
};

export default ControlloAccessiTable;
