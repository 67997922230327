import { Col, Container, Row } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import { dateParser } from "features/utils/dateUtils";
import { keyBoxDetailsButtom } from "features/storeManagement/services/models/KeysResponse";
import "./style.scss";
import { nanoid } from "@reduxjs/toolkit";

interface keyInterfaceType {
	objInfoDataOperatorKey: keyBoxDetailsButtom | undefined
}

const KeyButtonDetails = (props: keyInterfaceType) => {
	return (
		<>
			<Container className="p-0">
				<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 py-3">
					<Col
						key={nanoid()}
						className="datetime-box col-6 d-flex flex-column align-items-start px-1"
					>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
							<div className="label bold-text">
								{labels.keyStatusConsegnateA}:
							</div>
							<div className="day bold-text">{!props?.objInfoDataOperatorKey?.firstname && !props?.objInfoDataOperatorKey?.lastname ? "-" : `${props?.objInfoDataOperatorKey?.firstname ? props?.objInfoDataOperatorKey?.firstname
								+ "" : ""
							} ${props?.objInfoDataOperatorKey?.lastname ? props?.objInfoDataOperatorKey?.lastname : ""}`}</div>
						</div>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
							<div className="label bold-text">
								{labels.notes}:
							</div>
							<div className="day bold-text">{props?.objInfoDataOperatorKey?.additionalInfo ? props?.objInfoDataOperatorKey?.additionalInfo : "-"}</div>
						</div>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
							<div className="label bold-text">
								{labels.operatorKeyStatusConsegnate}
							</div>
							<div className="day bold-text">
								<span>{props?.objInfoDataOperatorKey?.OperatorName ? props?.objInfoDataOperatorKey?.OperatorName : "-"}</span>
							</div>
						</div>
					</Col>
					<Col
						key="checkout-time"
						className="datetime-box col-6 d-flex flex-column align-items-start px-1"
					>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
							<div className="label-left bold-text">{labels.dateKeyStatusConsegnate}</div>
							<span className="day bold-text margin-inline">
								{props?.objInfoDataOperatorKey?.time ? dateParser(
									props?.objInfoDataOperatorKey?.time || ""
								).getDay() +
									" " +
									dateParser(props?.objInfoDataOperatorKey?.time || "")
										.getHour()
										.substring(0, 5) : "-"}
							</span>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};
export default KeyButtonDetails;
