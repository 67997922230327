import { Question } from "./ChecklistResponse"
import { WareHousings } from "./ParcelsResponse"

export interface Parcel {
	contract: string,
	createdDate: string,
	description: string,
	id: number,
	idCustomer: string,
	lastUpdateDate: string,
	position: string,
	title: string
}

export interface ParcelRecipientsWithDate {
	answerId: number,
	id: string,
	parcelInboundTimestamp: string|null,
	parcelOutboundTimestamp: string|null,
	parcelRecipient: ParcelRecipients,
	status: string
}


export interface ParcelRecipients {
	additionalInfo?: string | null,
	companyName: string,
	email: string,
	firstName: string,
	id: number,
	lastName: string,
	phoneNumber: string,
	recipientType?:string
}

export interface ParcelResponseApi {
		id: number,
		idCustomer: string,
		contract: string,
		position: string,
		title: string,
		description: string,
		questions: Question[],
		warehousings: WareHousings[],
		packages: ParcelRecipientsWithDate[]
}


export interface warehousings {
		id: number,
		name: string,
		title: string,
		venue: null,
		building: string,
		buildingStory: string,
		room: string,
		shelf: string
}

export interface parcelSendObj {
	shiftId: string,
	parcelId: number
}

export enum parcelStatus {
    'stored' = 'stored',
	'delivered' = 'delivered',
	'lost' = 'lost',
	'stolen' = 'stolen',
	'cancelled' = 'cancelled'
}

export interface GetParcelDetailResponse {
    status: string;
    parcelDelivery: ParcelRecipients;
    parcelInboundTimestamp: Date;
    operatorNameInbound: string;
    operatorNameOutbound: string | null;
    parcelOutboundTimestamp: Date | null;
    parcelRecipient: ParcelRecipients;
    parcelSubstituteRecipient: ParcelRecipients | null;
    storage: string;
    questions: Question[];
    warehousing: warehousings;
    parcelSignature: null;
    note: string | null;
}

export interface GetParcelDetailRequest extends parcelSendObj {
    parcelManagementId: string;
}


export enum parcelKeys {
	isSamePerson = "isSamePerson",
	sign = "sign",
	parcelRecipientPersonId="parcelRecipientPersonId"
}