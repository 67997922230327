import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IIConItem } from "features/types/common/icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const IconItem: FC<IIConItem> = function IconItem({
	tag: Tag = "a",
	icon,
	bg,
	size,
	color,
	className,
	transform,
	iconClass,
	onClick,
	style,
	colorIcon,
	width,
	height,
	...rest
}) {
	return (
		<Tag
			className={classNames(className, "icon-item", {
				[`icon-item-${size}`]: size,
				[`bg-${bg}`]: bg,
				[`text-${color}`]: color
			})}
			{...rest}
			onClick={onClick}
		>
			<FontAwesomeIcon
				icon={icon as IconProp}
				transform={transform}
				className={iconClass}
				style={style ? style : {color:colorIcon, width: width, height: height}}
			/>
		</Tag>
	);
};
export default IconItem;
