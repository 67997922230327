import { FilterCondition } from "components/pages/ReportPage/ReportPage";
import { ReactElement } from "react";

export interface report {
	title: string,
	id: string,
	description: string,
	executable: boolean
}

export interface ReportItemsProps {
	shiftId?: string | undefined;
    report: PBIReportResponse;
	icon? : ReactElement<any, any>
}
export interface PBIReportResponse {
    id: number,
    title: string,
    datasetId: string,
    embedUrl: string,
    reportType: string,
    powerBiWorkspaceId: string,
    powerBiReportId: string,
    powerBiToken: string,
    filters: FilterReport[],             
}

export interface FilterReport {
    tableName: string;
    operator: string;
    key: string;
    value: string[];
    operatorType: PBIFilterTypes;
    conditions: FilterCondition[];
    logicalOperator: string;
}

export enum PBIFilterTypes {
	"Basic" = "Basic",
	"Advanced" = "Advanced",
}