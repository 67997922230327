import React from "react";
import isMobile from "ismobilejs";
import Signature from "components/content/Signature/Signature";
import EmptyPageNote from "components/content/EmptyPageNote/EmptyPageNote";
import { labels } from "features/locale/it-it";
import { Row, Col } from "react-bootstrap";
import "./style.scss";

export function is_touch_enabled() {
	return "ontouchstart" in window || navigator.maxTouchPoints > 0;
	// ||( navigator.msMaxTouchPoints > 0 ); for IE
}

type SignaturePropsType = { nameClass?: string; signatureImage?: Function, flagEmptySign?:boolean, setFlagEmptySign?:Function };

const SignaturePage = ({ nameClass, signatureImage, flagEmptySign, setFlagEmptySign }: SignaturePropsType) => {
	const device = isMobile(window.navigator);
	return (
		<div
			className={`${nameClass ? nameClass : ""} signature-page-container p-0`}
		>
			{is_touch_enabled() || device.tablet ? (
				<Row>
					<Col>
						<Signature setSignature={signatureImage} flagEmptySign={flagEmptySign} setFlagEmptySign = {setFlagEmptySign} />
					</Col>
				</Row>
			) : (
				<Row>
					<Col className="items-container justify-content-center align-items-center">
						<EmptyPageNote
							note={labels.notTabletDevice}
							className="empty-page-note"
						/>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default SignaturePage;
