import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarIcon = (props: any) => (
	<FontAwesomeIcon
		icon="fa-solid fa-car"
		width={24}
		height={24}
		size={props.size ? props.size : "sm"}
		{...props}
		style={props.style}
	/>
);

export default CarIcon;
