import { labels } from "features/locale/it-it"
import { Button, Form } from "react-bootstrap"
import './style.scss'
import { useNavigate, useParams } from "react-router"
import { getValuesForUser } from "features/utils/userRoleUtils"
import { roles } from "features/storeManagement/services/constants/roles"
const ParcelNoResult = () => {
	const { shiftId, customer, serviceAddress, parcelId } = useParams()
	const navigate = useNavigate()
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;

	return (
		<div className="container-parcel-label">
			<div className="container__elements">	
				<div className ="container__elements__button">
					<Button type="button" id="submit" form='form-section' className='submit-button submit-button-parcel mt-1' variant="primary" onClick={() => navigate(isResponsabile ? `/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}/new` : `/shift/${shiftId}/parcel/new`)}>
						<p>{labels.parcelButton}</p>
					</Button>
				</div>
				<div className ="container__elements__label">
					<Form.Label
						htmlFor='parcel-label'
						className='parcel-label mb-0'
					>
						{labels.parcelLablesEmptyState}
					</Form.Label>
				</div>
			</div>
		</div>
	)
}

export default ParcelNoResult