import { FC } from 'react';
import { Link } from 'react-router-dom';
import Flex from 'theme/components/common/Flex';
import RegistrationForm from './RegistrationForm';
import bgImg from 'theme/assets/img/generic/15.jpg';
import AuthSplitLayout from 'layout/AuthLayout';

const RegistrationPage: FC = function RegistrationPage() {
	return (
		<AuthSplitLayout bgProps={{ image: bgImg }}>
			<Flex alignItems="center" justifyContent="between">
				<h3>Register</h3>
				<p className="mb-0 fs--1">
					<span className="fw-semi-bold">Already User? </span>
					<Link to="/authentication/login">Login</Link>
				</p>
			</Flex>
			<RegistrationForm hasLabel />
		</AuthSplitLayout>
	);
};

export default RegistrationPage;
