import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import "./styles.scss";
import ConfirmModal from "components/content/ConfirmModal";
import ParcelRecipientForm from "components/modules/Parcel/ParcelForm/ParcelRecipientForm";
import useParcelRecipientForm from "components/modules/Parcel/ParcelForm/useParcelRecipientForm";
import formDataParcelResponse from "components/modules/Parcel/ParcelForm/formDataParcelResponse";
import { objectControlFormParcel } from "components/content/ConfirmModal/ConfirmModalProps";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { PostOneParcelError } from "features/storeManagement/services/models/ParcelsResponse";

export interface ServiceParcelPageProps {
	showConfirmModal: boolean;
	setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
	parcelId?: string;
	isFromKey: boolean;
	keyRegistryId?: string;
	keyManagementId?: string;
	setPopupData: React.Dispatch<React.SetStateAction<GetRecipientsResponse | undefined>>;
	handleResponseErrorModal?: Function;
}
const ServiceParcelPage: FC<ServiceParcelPageProps> = ({
	showConfirmModal,
	setShowConfirmModal,
	parcelId,
	isFromKey = false,
	keyManagementId,
	keyRegistryId,
	setPopupData,
	handleResponseErrorModal
}) => {
	const {
		handleSubmit,
		isLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		setIsFormValid,
		form,
		postRecipientError,
		postAKeyRecipientError
	} = useParcelRecipientForm({
		setShowConfirmModal,
		parcelId,
		isFromKey,
		keyManagementId,
		keyRegistryId,
		setPopupData
	});

	// useChangeDocumentTitle(labels.pageTitle_ParcelPage);

	const [isValidEmail, setIsValidEmail] = useState<boolean>(true)

	const cognomePropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFromKey,
		isFormValid
	).responseParcelCognome(labels.textCognome);
	const societaPropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFromKey,
		isFormValid
	).responseParcelSocieta(labels.textSocieta);

	let checkObj: objectControlFormParcel = {
		cognome: cognomePropsParcel.isMandatory,
		societa: societaPropsParcel.isMandatory,
	};

	const [isExistingEmail, setIsExistingEmail] = useState<boolean>(false)

	useEffect(()=>{
		if((postRecipientError || postAKeyRecipientError) && handleResponseErrorModal){
			const error = postRecipientError as PostOneParcelError || postAKeyRecipientError as PostOneParcelError
			if(error.status === 400 && error.data?.ErrorCode === labels.recipientError){
				setIsExistingEmail(true)
			}else {
				handleResponseErrorModal(postRecipientError || postAKeyRecipientError)
			}
		}
	},[postRecipientError, postAKeyRecipientError])

	return (
		<ConfirmModal
			headerMessage={labels.watchOut}
			bodyMessage={
				<ParcelRecipientForm
					handleSubmit={handleSubmit}
					isLoading={isLoading}
					error={error}
					validated={validated}
					formData={formData}
					setFormData={setFormData}
					isFormValid={isFormValid}
					setIsFormValid={setIsFormValid}
					form={form}
					isFromKey={isFromKey}
					isValidEmail={isValidEmail}
					isExistingEmail={isExistingEmail}
					setIsExistingEmail={setIsExistingEmail}
					showConfirmModal = {showConfirmModal}
				/>
			}
			showConfirmModal={showConfirmModal}
			setShowConfirmModal={setShowConfirmModal}
			isLoading={isLoading}
			onConfirm={handleSubmit}
			headerOn={false}
			classCenterButton={true}
			data={formData}
			setFormData={setFormData}
			checkObj={checkObj}
			confirmButtonText={labels.save}
			annullaVariant={"green"}
			buttonClass={"submit-and-draft-buttons"}
			isValidEmail={isValidEmail}
			setIsValidEmail={setIsValidEmail}
			isFormValid={isFormValid}
			isFromKey={isFromKey}
		/>
	);
};

export default ServiceParcelPage;
