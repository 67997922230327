import React from "react";
import ChecklistReportSection from "./ChecklistReportSection";

import { Section } from "features/storeManagement/services/models/ChecklistResponse";

import "./checklistReport.scss";

const ChecklistReport = ({ sections, isIncident = false  }: { sections?: Section[], isIncident?: boolean }) => {
	
	return (
		<div className={"checklist-report-container"}>
			{sections &&
				sections.map((section, index) => (
					<div key={index}>
						<div className="section-container section-container-margin">
							{!isIncident && section.title && <span className="section-title">{section.title}</span>}
						</div>
						<ChecklistReportSection
							key={section.id}
							section={section}
							sectionIndex={index}
						/>
					</div>
				))}
		</div>
	);
};

export default ChecklistReport;
