import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import {
	ParcelPostRecipients,
} from "features/storeManagement/services/models/ParcelsResponse";
import {
	usePostAKeyRecipientMutation,
	usePostParcelRecipientMutation,
} from "features/storeManagement/services/recipientService";
import { ParcelFormCompletation, ParcelFormStateType } from "features/storeManagement/services/models/ParcelsResponse";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import { useGetOneShiftMutation } from "features/storeManagement/services/shiftsService";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

type RecipientFormTypeProps = {
	setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
	isFromKey?: boolean;
	keyRegistryId?: string;
	keyManagementId?: string;
	setPopupData: React.Dispatch<React.SetStateAction<GetRecipientsResponse | undefined>>;
	parcelId?: string;
};

const useParcelRecipientForm = ({
	setShowConfirmModal,
	setPopupData,
	parcelId,
	isFromKey,
	keyManagementId,
	keyRegistryId
}: RecipientFormTypeProps) => {
	const [validated, setValidated] = useState<boolean>(false);
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [formData, setFormData] = useState<ParcelFormStateType>({});
	const form = useRef(null);
	const { shiftId } = useParams();
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;

	const [
		postRecipient,
		{
			data: postRecipientData,
			isLoading: postRecipientIsLoading,
			error: postRecipientError,
		},
	] = usePostParcelRecipientMutation();
	const [
		postAKeyRecipient,
		{
			data: postAKeyRecipientData,
			isLoading: postAKeyRecipientIsLoading,
			error: postAKeyRecipientError,
		},
	] = usePostAKeyRecipientMutation();

	useEffect(() => {
		setIsFormValid(
			!!(
				form &&
				form.current &&
				(form.current as HTMLFormElement).checkValidity()
			)
		);
	}, [formData]);

	useEffect(() => {
		if (postRecipientData || postAKeyRecipientData) { 
			setShowConfirmModal(false);
			setPopupData(postRecipientData || postAKeyRecipientData);
			setFormData({})
		}
	}, [postRecipientData, postAKeyRecipientData]);

	useEffect(()=>{
		if(postRecipientError === undefined && postAKeyRecipientError === undefined  && postRecipientIsLoading && postAKeyRecipientIsLoading){
			setFormData({})
		}
	},[postRecipientError, postAKeyRecipientError])

	const handleSubmit = () => {
		setValidated(true);
		if (formData) {
			let utilObj: ParcelPostRecipients = {
				shiftId: shiftId || "",
				firstName: formData[`${labels.parcelFirstname} `]
					? formData[`${labels.parcelFirstname} `][0]
					: null,
				lastName: formData[`${labels.parcelLastname} `]
					? formData[`${labels.parcelLastname} `][0]
					: null,
				email: formData[`${labels.parcelEmail} `]
					? formData[`${labels.parcelEmail} `][0]
					: null,
				phoneNumber: formData[`${labels.parcelPhone} `]
					? formData[`${labels.parcelPhone} `][0]
					: null,
				companyName: formData[`${labels.parcelCompany} `]
					? formData[`${labels.parcelCompany} `][0]
					: null,
				additionalInfo: formData[`${labels.parcelNote} `]
					? formData[`${labels.parcelNote} `][0]
					: null,
			};

			if (!isFromKey) {
				utilObj.recipientType =
					formData[`${labels.isCarrier} `][0].toLowerCase() ===
					labels.yesLabel.toLowerCase()
						? 1
						: 0;
			}

			if ((parcelId || keyRegistryId) && (utilObj.shiftId || isResponsabile)) {
				if (isFromKey && keyManagementId && keyRegistryId) {
					postAKeyRecipient({
						parcelObj: utilObj || "",
						keyManagementId,
						keyRegistryId,
					});
				}
				if (!isFromKey && parcelId) {
					postRecipient({ parcelObj: utilObj, parcelId: Number(parcelId) });
				}
			}
		}
	};

	return {
		handleSubmit,
		isLoading: postRecipientIsLoading || postAKeyRecipientIsLoading,
		error: postRecipientError || postAKeyRecipientError,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		postRecipientError,
		postAKeyRecipientError,
		setIsFormValid
	};
};

export default useParcelRecipientForm;
