import { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { } from 'theme/data/dashboard/default'
import { useGetCustomerMutation } from 'features/storeManagement/services/customerService'
import { useNavigate } from "react-router"
import { fixedCacheKeys } from 'features/storeManagement/services/constants/fixedCacheKeys';
import { Form, Button } from 'react-bootstrap'
import { AnyARecord } from 'dns';
import { labels } from 'features/locale/it-it';
import { stringUtils } from 'features/utils/stringUtils';
import Suspense from 'features/Suspense';
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle';
import ErrorBoundary from 'features/ErrorBoundary/ErrorBoundary';
import EmptyPageNote from 'components/content/EmptyPageNote';
import Select from 'react-select'
import { selectOptionObject } from 'features/storeManagement/services/models/ResponsabileResponse';
import { CustomerAddress, CustomerResponse } from 'features/storeManagement/services/models/CustomerResponse';

const ResponsabilePage: FC = function CoordinatorePage() {
	const navigate = useNavigate()

	const [customerSelection, setCustomerSelection] = useState<number>(-1);
	const [customerAddressSelection, setCustomerAddressSelection] = useState<selectOptionObject>({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""});
	const [isCustomerAddressSelectionEnabled, setIsCustomerAddressSelectionEnabled] = useState<boolean>(false);
	const [showPage, setShowPage] = useState(true);
	const [optionsAddress, setOptionsAddress] = useState<selectOptionObject[]>([{id: -1, value: "", label: labels.customerSelectDefault, serviceAddress: ""}]);

	useChangeDocumentTitle(labels.pageTile_ResponsabilePostazione);

	const handleCustomerSelectionChange = (event: any) => {
		setCustomerSelection(event.id);
		setIsCustomerAddressSelectionEnabled(event.id != -1);
		setCustomerAddressSelection({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""})
	};

	const handleCustomerAddressSelectionChange = (event: any) => {
		setCustomerAddressSelection(event)
	};

	const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
		event.preventDefault()
		if (customersData != undefined) {
			const customer = customersData[customerSelection].addresses[customerAddressSelection.id].idCustomer;
			const serviceAddress = customersData[customerSelection].addresses[customerAddressSelection.id].serviceAddress;
			navigate(`/dashboard/responsabile/customer/${customer}/serviceAddress/${serviceAddress}`)
		}
	}

	const [getCustomers, {
		data: customersData,
		isLoading: customersIsLoading,
		isError: customerError,
		error: customerErrorBoundary
	}] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData})

	useEffect(() => {
		if (!customersData && !customerError && !customersIsLoading) {
			getCustomers()
		} else if ((!customersData || !customersData.length) && !customersIsLoading) {
			setShowPage(false);
		}
	}, [customersData, customersIsLoading])

	const options:selectOptionObject[] = [{id: -1, value: labels.customerSelectDefault, label: labels.customerSelectDefault, serviceAddress: ""}]

	customersData?.map((singleData: CustomerResponse, i: number) => {
		options.push({id: i, value: singleData.customerName, label: stringUtils(singleData.customerName).capitalize(), serviceAddress: singleData.serviceAddress})
	})	

	useEffect(()=> {
		setCustomerAddressSelection({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""});
		let addressesOptions: any[] = [];
		customersData && customersData[customerSelection]?.addresses.map((singleData: CustomerAddress, i: number) => {
			addressesOptions.push({id: i, value: singleData.fullAddress, label: stringUtils(singleData.fullAddress).capitalize(), serviceAddress: singleData.serviceAddress || ""});
		})
		setOptionsAddress(addressesOptions);

	}, [customerSelection])


	return (
		<ErrorBoundary errorMessage={customerErrorBoundary}>
			<Container fluid className='d-flex coordinatore-container justify-content-around align-items-start p-0 px-4 mb-0 mt-4'>
				<Suspense isLoading={customersIsLoading} fullPage>
					{!showPage && <EmptyPageNote className="'d-flex w-100 m-5 mx-auto align-items-center justify-content-center text-center" note={labels.noCustomersAvailable} />}
					{(customersData && customersData.length > 0) && (
						<div className="select-element">
							<div className='select-element-content'>
								{showPage && <Form onSubmit={handleSubmit} id="form-section">
									<Form.Label
										htmlFor='customer-selection'
										className='customer-selection-label mb-0'
									>
										{labels.customer}
									</Form.Label>

									<Select 
										placeholder={labels.customerSelectDefault}
										aria-label={labels.customer}
										onChange={handleCustomerSelectionChange}
										options={options}
									/>

									<Form.Label
										htmlFor='customer-address-selection'
										className='customer-address-selection-label mb-0'
									>
										{labels.customerAddress}
									</Form.Label>

									
									<Select 
										placeholder={labels.addressSelectDefault}
										aria-label={labels.addressSelectDefault}
										className={`select-field-question mb-4 w-100`}
										onChange={handleCustomerAddressSelectionChange}
										isDisabled={!isCustomerAddressSelectionEnabled}
										value={customerAddressSelection}
										options={optionsAddress}
									/>

									<div className='button-content'>
										<Button
											type="submit"
											id="submit"
											form='form-section'
											className='submit-button'
											variant="green"
											disabled={customerAddressSelection.id < 0}
										>
											{labels.homeSubmit}
										</Button>
									</div>
								</Form>}
							</div>
						</div>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ResponsabilePage;