/* eslint-disable indent */
import "./style.scss";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { labels } from "features/locale/it-it";
import { Col, Container, Row } from "react-bootstrap";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import Suspense from "features/Suspense";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { useGetAllAuditsMutation } from "features/storeManagement/services/checklistService";
import { CustomerResponse, CustomerAddress } from "features/storeManagement/services/models/CustomerResponse";
import ChecklistItem from "components/modules/Checklist/ChecklistItem";
import BoxDetails from "components/content/BoxDetails";
import EmptyPageNote from "components/content/EmptyPageNote";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import ChecklistIcon from "assets/icons/Checklist";

const AuditModulesPage = () => {
	const { serviceAddress } = useParams();
	const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerResponse | undefined | null>(undefined)
	const [selectedAddress, setSelectedAddress] = React.useState<CustomerAddress | undefined | null>(undefined)

	const [getCustomers, {
		data: customersData,
		isLoading: customersIsLoading,
		isError: customerError
	}] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData })
	
	useEffect(() => {
		if (!customersData && !customerError && !customersIsLoading) {
			getCustomers()
		}
	}, [customersData, customersIsLoading])

	const [, {
		data: allAudits,
		isLoading: allAuditsIsLoading
	}] = useGetAllAuditsMutation({ fixedCacheKey: fixedCacheKeys.allAuditsData })

	const getAudits = (internal? : boolean) => {
		if(allAudits && allAudits.items.length) {
			return allAudits?.items.filter(audit => internal === undefined || internal === audit.internalAudit);
		} 
		return [];
	}

	useEffect(() => {
		if(!customersIsLoading && !!customersData) {
			const currentCustomer = customersData?.find((item : CustomerResponse) => item.serviceAddress === serviceAddress);
			setSelectedCustomer(currentCustomer || null)
		}
	},[customersIsLoading, customersData])
	
	useEffect(() => {
		if(!customersIsLoading && customersData && (selectedCustomer || selectedCustomer === null)) {
			const currentAddress = selectedCustomer?.addresses.find((item : CustomerAddress) => serviceAddress && item.serviceAddress === serviceAddress);
			setSelectedAddress(currentAddress || null);
		}
	},[customersIsLoading, selectedCustomer])

	const internals = getAudits(false);
	const generics = getAudits(true);
	
	useChangeDocumentTitle('Audit');

	return (
		<Container fluid className='audit-page-container p-0 px-2 mb-5 mt-4'>
			{selectedAddress && (
				<div className="box-details-container"><BoxDetails customerAddress={selectedAddress} /></div>
			)}
			<Suspense isLoading={customersIsLoading || allAuditsIsLoading} fullPage>
				<>
					{!!internals.length && (
						<Container fluid className='audits-container p-0 mb-5 mt-4 mt-md-5 '>
							<Row className="internal-audits-title mx-2 my-2 px-0">
								<Col className="mx-0 bold-text blue-text px-0 py-2">
								{labels.internalAudits}
								</Col>
							</Row>
							<Row className='internal-audits d-flex justify-content-start m-0 px-0'>
								{internals.map(audit =>	
									<ChecklistItem
										key={audit.id}
										checklist={audit}
										internal={false}
										icon={<ChecklistIcon size="lg" />}
									/>)
								}
							</Row>
						</Container>
					)}
					{!!generics.length && (
						<Container fluid className='audits-container p-0 mb-5 mt-2 mt-md-5 '>
							<Row className="generic-audits-title mx-2 my-2 px-0">
								<Col className="mx-0 bold-text blue-text px-0 py-2">
									{labels.genericAudits}
								</Col>
							</Row>
							<Row className='generic-audits d-flex justify-content-start m-0 px-0'>
								{generics.map(audit =>
									<ChecklistItem
										key={audit.id}
										checklist={audit}
										internal={true}
										icon={<ChecklistIcon size="lg" />}
									/>)
								}
							</Row>
						</Container>
					)}

					{!generics.length && !internals.length && (
						<EmptyPageNote className="'d-flex w-75 m-5 mx-auto align-items-center justify-content-center text-center" note={labels.noAuditsAvailable} />
					)}
				</>
			</Suspense>
		</Container>

	);
};

export default AuditModulesPage;
