import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Checklist, DocumentToDownload } from 'features/storeManagement/services/models/ChecklistResponse'
import { Journal } from 'features/storeManagement/services/models/JournalResponse'
import ChecklistItem from '../Checklist/ChecklistItem'
import './style.scss'
import { labels } from 'features/locale/it-it'
import NavbarVerticalMenu from 'components/navbar/vertical/NavbarVerticalMenu'
import { ModulesDictionaryType } from 'features/types/data/modules'
import ChecklistIcon from 'assets/icons/Checklist'
import NavbarLabel from 'components/navbar/vertical/NavbarLabel'
import { Patrol } from 'features/storeManagement/services/models/PatrolsResponse'
import { Incident } from 'features/storeManagement/services/models/IncidentResponse'
import PatrolItem from 'components/modules/Patrol/PatrolItem'
import PatrolIcon from 'assets/icons/RondaIcon'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { getValuesForUser } from 'features/utils/userRoleUtils'
import { roles } from 'features/storeManagement/services/constants/roles'
import { useGetCurrentShiftMutation, useGetOneShiftMutation } from 'features/storeManagement/services/shiftsService'
import { fixedCacheKeys } from 'features/storeManagement/services/constants/fixedCacheKeys'
import IncidentManagementIcon from 'assets/icons/IncidentManagement'
import IncidentItem from '../Incident/IncidentItem/IncidentItem'
import { JournalButton } from '../Journal/JournalItem'
import NewspaperIcon from 'assets/icons/Newspaper'
import { Parcel } from 'features/storeManagement/services/models/ParcelsResponse'
import { ParcelButton } from '../Parcel/ParcelButton/ParcelButton'
import BoxIcon from 'assets/icons/Box'
import { KeyManagementT } from 'features/storeManagement/services/models/KeysResponse'
import { KeyButton } from '../Key/KeyButton/KeyButton'
import KeysIcon from 'assets/icons/KeysIcon'
import { useParams } from 'react-router-dom'
import CalendarIcon from 'assets/icons/Calendar'
import { GuestConfiguration } from 'features/storeManagement/services/models/ShiftsResponse'
import ControlloAccessiIcon from 'assets/icons/ControlloAccessiIcon'
import { ControlloAccessiButton } from '../ControlloAccessi/ControlloAccessiButton/ControlloAccessiButton'
import AreaDocumentiIcon from 'assets/icons/AreaDocumentiIcon'
import { AreaDocumentiButton } from '../DocumentDetail/AreaDocumentiButton/AreaDocumentiButton'

interface ModulesListProps {
	asMenu?: boolean,
	separator?: boolean,
	shiftId: string,
	checklists?: Checklist[],
	audits?: Checklist[],
	patrols?: Patrol[],
	incidents?: Incident[],
	journal?: Journal,
	parcel?: Parcel,
	keys?: KeyManagementT,
	guestConfiguration?: GuestConfiguration,
	areaDocumenti?: DocumentToDownload[]
}
const ModulesList: React.FC<ModulesListProps> = ({ asMenu, shiftId, checklists, audits, patrols, incidents, journal, parcel, keys, guestConfiguration, areaDocumenti }) => {
	const isOperatoreEsterno = getValuesForUser().getUserRole() === roles.corrispondente.key
	const isCoordinatore = getValuesForUser().getUserRole() === roles.coordinatore.key
	const isCustomer = getValuesForUser().getUserRole() === roles.cliente.key
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;
	const { customer, reportId, serviceAddress } = useParams()
	const [, { data: currentShiftData }
	] = useGetCurrentShiftMutation({ fixedCacheKey: fixedCacheKeys.currentShiftData })

	const [, {
		data: shiftData,
		isLoading: shiftIsLoading,
	}] = useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData })
	let customerSuffix = ''
	const isContractData = !!customer;

	if(isContractData && !isOperatoreEsterno) {
		customerSuffix = `/customer/${customer}/serviceAddress/${serviceAddress}`
	}
	else if(currentShiftData && !!currentShiftData.shiftCustomerId) {
		customerSuffix = serviceAddress ? `/customer/${currentShiftData.shiftCustomerId}/serviceAddress/${serviceAddress}` :`/customer/${currentShiftData.shiftCustomerId}`;
	 } 

	 let dashboardSuffix = ''
	if(isContractData && !isResponsabile) {
		dashboardSuffix = `/dashboard/customer/${customer}/serviceAddress/${serviceAddress}`
	}else if(isResponsabile) {
		dashboardSuffix = `/dashboard/responsabile/customer/${customer}/serviceAddress/${serviceAddress}`
	} else if(currentShiftData && !!currentShiftData.shiftCustomerId) {
		dashboardSuffix = `/dashboard/customer/${currentShiftData.shiftCustomerId}/serviceAddress/${serviceAddress}`
	 }

	const checklistUrl = (checklist: Checklist) => {
		if(isResponsabile) {
			return `/checklist/${checklist.id}/customer/${customer}/serviceAddress/${serviceAddress}`
		}else if(isCustomer){
			return `/checklist/${checklist.id}`
		}else{
			return `/shift/${shiftId}/checklist/${checklist.id}`
		}
	}
	
	// Second level menu ==================================== >

	const checklistModulesRoutes: ModulesDictionaryType[] = checklists ? checklists.map(checklist => ({
		name: checklist.description || '',
		icon: '•',
		to: checklistUrl(checklist),
		component: 'checklist'
	})) : [];

	const auditModulesRoutes: ModulesDictionaryType[] = audits ? audits.map(audit => ({
		name: audit.description || '',
		icon: '•',
		to: `/audit/${audit.id}${customerSuffix}/internal/${audit.internalAudit}`,
		component: 'audit'
	})) : [];

	const patrolModulesRoutes: ModulesDictionaryType[] = patrols ? patrols.map(patrol => ({
		name: patrol.description || '',
		icon: '•',
		to: `/shift/${shiftId}/patrol/${patrol.id}`,
		component: 'patrol'
	})) : [];

	const incidentModulesRoutes: ModulesDictionaryType[] = incidents ? incidents.map(incident => ({
		name: incident.description || "",
		icon: '•',
		to: isResponsabile ? `/incident/${incident.id}/customer/${customer}/serviceAddress/${serviceAddress}` : `/shift/${shiftId}/incident/${incident.id}`,
		component: 'incident'
	})) : []

	// First level menu ==================================== >

	const checklistModulesRoot: ModulesDictionaryType[] = [{
		name: (isResponsabile || isCustomer) ? labels.menuChecklist : labels.menuAuditChecklist,
		icon: <ChecklistIcon />,
		to: `#`,
		component: 'checklist',
		children: checklistModulesRoutes
	}]

	const auditModulesRoot: ModulesDictionaryType[] = [{
		name: labels.menuAuditChecklist,
		icon: <ChecklistIcon />,
		to: `#`,
		component: 'audit',
		children: auditModulesRoutes
	}]

	const gestioneTurniRoot: ModulesDictionaryType[] = [{
		name: labels.gestioneTurniDescription,
		icon: <CalendarIcon />,
		to: `/turni/customer/${customer}/serviceAddress/${serviceAddress}`,
		component: 'gestioneTurni'	
	}]

	const patrolModulesRoot: ModulesDictionaryType[] = [{
		name: labels.menuPatrol,
		icon: <PatrolIcon />,
		to: `#`,
		component: 'patrol',
		children: patrolModulesRoutes
	}]

	const incidentModulesRoot: ModulesDictionaryType[] = [{
		name: labels.menuIncident,
		icon: <IncidentManagementIcon />,
		to: `#`,
		component: 'incidents',
		children: incidentModulesRoutes
	}]

	const journalModulesRoutes: ModulesDictionaryType[] = journal ? [{
		name: labels.menuJournal || "",
		icon: <NewspaperIcon />,
		to: isResponsabile ? `/journal/customer/${customer}/serviceAddress/${serviceAddress}` : `/shift/${shiftId}/journal`,
		component: 'journal'
	}] : []

	const parcelModulesRoutes: ModulesDictionaryType[] = parcel ? [{
		name: labels.menuParcelManagement || "",
		icon: <BoxIcon />,
		to: isResponsabile ? `parcel/${parcel.id}/customer/${customer}/serviceAddress/${serviceAddress}` : `/shift/${shiftId}/parcel`,
		component: 'parcel'
	}] : []

	const keyModulesRoutes: ModulesDictionaryType[] = keys ? [{
		name: labels.menuKeysManagement || "",
		icon: <KeysIcon />,
		to: isResponsabile ? `/keys/customer/${customer}/keyManagementId/${keys.id}/serviceAddress/${serviceAddress}` : `/shift/${shiftId}/keys`,
		component: 'keys'
	}] : []

	const guestConfigurationMoudelsRoutes: ModulesDictionaryType[] = guestConfiguration ? [{
		name: labels.controlloAccessi || "",
		icon: <ControlloAccessiIcon />,
		to: isResponsabile ? `controlloAccessi/${guestConfiguration.id}/customer/${customer}/serviceAddress/${serviceAddress}` : `shift/${shiftId}/controlloAccessi/${guestConfiguration.id}`,
		component: 'guestConfiguration'
	}] : []

	let areaDocumentiUrl = '';
	if (shiftId) {
		areaDocumentiUrl = `/shift/${shiftId}/documents`;
	} else if (customer && serviceAddress) {
		areaDocumentiUrl = `/documents/customer/${customer}/serviceAddress/${serviceAddress}`;
	} else {
		areaDocumentiUrl = `/documents`
	}

	const areaDocumentiModulesRoot: ModulesDictionaryType[] = [{
		name: labels.documentsArea,
		icon: <AreaDocumentiIcon />,
		to: areaDocumentiUrl,
		component: 'areaDocumenti',
	}]

	const backTo = () => {
		let urlToReturn; 
		if(isOperatoreEsterno && currentShiftData) {
			urlToReturn = `/shift/${currentShiftData.shiftId}${customerSuffix}` 
		} else if(isCoordinatore || isResponsabile) {
			urlToReturn = `${dashboardSuffix}`
		} else {
			urlToReturn = shiftData ? `/shift/${shiftData.shiftId}` : '/'
		} 

		return [
			{
				name: isCoordinatore || isCustomer || isResponsabile ? labels.goToHome : labels.shiftDetails || '',
				icon: <ArrowIcon className='blue-svg rotate-180deg' />,
				to: urlToReturn,
				component: 'ShiftPage',
				forceNotActive: true
			} as ModulesDictionaryType
		];
	}

	const isMyActivitiesLabel = () => {
		return (
			(shiftData?.checklists && shiftData.checklists.length > 0) ||
			(shiftData?.incidents && shiftData.incidents.length > 0) ||
			(shiftData?.patrols && shiftData.patrols.length > 0) ||
			shiftData?.parcel ||
			shiftData?.journal ||
			shiftData?.keyManagement
		);
	};

	return (
		<>
			{!asMenu && (
				<Container fluid className='modules-container p-0 mb-5 mt-2 mt-md-5 '>
					{
						(isMyActivitiesLabel() &&
						<Row className="generic-report-title mx-2 my-2 px-0">
							<Col className="mx-0 bold-text-report blue-text px-0 py-2 font">
								{labels.myActivitiesText}
							</Col>
						</Row>)
					}
							
					<Row className='d-flex justify-content-start m-0'>
						{checklists && checklists.map(checklist =>
							<ChecklistItem
								key={checklist.id}
								checklist={checklist}
								shiftId={shiftId}
								icon={<ChecklistIcon size="lg" />}
							/>)}

						{patrols && patrols.map(patrol =>
							<PatrolItem
								key={patrol.id}
								patrol={patrol}
								shiftId={shiftId}
								icon={<PatrolIcon size="lg" />}
							/>)}

						{incidents && incidents.map(incident =>
							<IncidentItem
								key={incident.id}
								incident={incident}
								shiftId={shiftId}
								icon={<IncidentManagementIcon size="lg" />}
							/>)}

						{journal && (
							<JournalButton shiftId={shiftId} icon={<NewspaperIcon size="lg" />} />
						)}
						{parcel && (
							<ParcelButton shiftId={shiftId} icon={<BoxIcon size="lg" />} />
						)}
						{keys && (
							<KeyButton shiftId={shiftId} icon={<KeysIcon size="lg" />} />
						)}
						{guestConfiguration && (
							<ControlloAccessiButton shiftId={shiftId} guestConfigurationId = {guestConfiguration.id} icon={<ControlloAccessiIcon size="lg" />} />
						)}
						{areaDocumenti && areaDocumenti.length > 0 && (
							<AreaDocumentiButton shiftId={shiftId} icon={<AreaDocumentiIcon size="lg" />} />
						)}
					</Row>
				</Container>
			)}

			{!!asMenu && (
				<>
					{(shiftId || !!audits?.length || !!reportId || isResponsabile) && !isCustomer && (
						<NavbarVerticalMenu routes={backTo()} />
					)}

					{isCustomer && (
						<NavbarVerticalMenu routes={backTo()} />
					)}

					{(checklists || patrols || incidents || journal || parcel || keys) && (checklists && checklists?.length > 0 || incidents && incidents?.length > 0 || patrols && patrols?.length > 0 ) && !isCustomer && !isResponsabile && (
						<div className="mt-3">
							<NavbarLabel label={labels.moduliTurno} />
						</div>
					)}

					{(checklists || patrols || incidents || journal || parcel || keys) && (checklists && checklists?.length > 0 || incidents && incidents?.length > 0 || patrols && patrols?.length > 0 ) && (isCustomer || isResponsabile) && (
						<div className="mt-3">
							<NavbarLabel label={labels.myActivitiesText} />
						</div>
					)}

					{(!(checklists || patrols || incidents || journal || parcel || keys) && !!audits?.length) && (
						<div className="mt-3">
							<NavbarLabel label={labels.myActivitiesText} />
						</div>
					)}

					{checklists && checklists.length > 0 && (
						<NavbarVerticalMenu routes={checklistModulesRoot} />
					)}
					{!!audits?.length && !isCustomer &&(
						<NavbarVerticalMenu routes={auditModulesRoot} />
					)}
					{isCoordinatore && (
						<NavbarVerticalMenu routes={gestioneTurniRoot} />
					)}
					{isCoordinatore && (
						<NavbarVerticalMenu routes={areaDocumentiModulesRoot} />
					)}
					{patrols && patrols.length > 0 && (
						<NavbarVerticalMenu routes={patrolModulesRoot} />
					)}
					{incidents && incidents.length > 0 && (
						<NavbarVerticalMenu routes={incidentModulesRoot} />
					)}
					{journal && (
						<NavbarVerticalMenu routes={journalModulesRoutes} />
					)}
					{parcel && (
						<NavbarVerticalMenu routes={parcelModulesRoutes} />
					)}
					{keys && (
						<NavbarVerticalMenu routes={keyModulesRoutes} />
					)}
					{guestConfiguration && (
						<NavbarVerticalMenu routes={guestConfigurationMoudelsRoutes} />
					)}
					{areaDocumenti && areaDocumenti.length > 0 && (
						<NavbarVerticalMenu routes={areaDocumentiModulesRoot} />
					)}
				</>
			)}
		</>
	)
}

export default ModulesList