import React, { FC } from 'react';
import Select from 'react-select';
import { IMultiSelect } from 'features/types/events/createEvent';

const MultiSelect: FC<IMultiSelect> = function MultiSelect({ options, placeholder, ...rest }) {
	return (
		<Select closeMenuOnSelect={false} options={options} placeholder={placeholder} classNamePrefix="react-select"{...rest} />
	)
};



export default MultiSelect;
