import "./style.scss";
import React, { useEffect, useMemo } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Answer } from "features/storeManagement/services/models/ChecklistResponse";
import { labels } from "features/locale/it-it";
import ModuleState from "components/modules/ModuleState";
import ModulePageTitle from "components/modules/ModulePageTitle";
import EmptyPageNote from "components/content/EmptyPageNote";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";

const ChecklistItemsPage = () => {
	useChangeDocumentTitle(labels.pageTile_ChecklistItemsPage);

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { shiftId, checklistId } = useParams();
	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [
		getOneShift,
		{ data: shiftData, isLoading: shiftIsLoading, error: shiftError },
	] = useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{
			data: currentShiftData,
			isLoading: currentShiftIsLoading,
			error: currentShiftError,
		},
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const checklistData = useMemo(() => {
		if (currentShiftData && isOperatoreEsterno) {
			return currentShiftData.checklists.find(
				(checklist) => Number(checklist.id) === Number(checklistId)
			);
		} else {
			return shiftData?.checklists.find(
				(checklist) => Number(checklist.id) === Number(checklistId)
			);
		}
	}, [shiftData, currentShiftData]);

	useEffect(() => {
		if (isOperatoreEsterno) {
			getCurrentShift();
		} else if (shiftId) {
			getOneShift(shiftId);
		}
	}, [checklistId]);

	const isLoading = shiftIsLoading || currentShiftIsLoading;
	const error = isOperatoreEsterno ? currentShiftError : shiftError;

	return (
		<Container className="checklist-items-page-container p-0 px-4 mb-0 mt-4 ">
			<ErrorBoundary errorMessage={error}>
				<Suspense isLoading={isLoading} fullPage>
					<Row>
						<Col className="items-container px-0 my-0">
							<ModulePageTitle
								title={checklistData && checklistData.description}
							/>
						</Col>
					</Row>
					{/* {checklistData && !checklistData.executable && (
						<div className="container-parcel-label">
							<div className="container__elements">
								<div className="container__elements__label">
									<Form.Label
										htmlFor="parcel-label"
										className="parcel-label mb-0 w-100"
									>
										{labels.noExecutableChecklist}
									</Form.Label>
								</div>
							</div>
						</div>
					)} */}

					{checklistData && (
						<>
							{!isLoading && (
								<Row className="sticky-button-container mt-4">
									<Col className="items-container p-0 my-0">
										{(currentShiftData?.modulesExecutable || shiftData?.modulesExecutable) && <Button
											className="start-new-draft-button--start"
											variant="green"
											disabled={isLoading}
											onClick={() => navigate(`${pathname}/new`)}
										>
											<span>{labels.startNewCompilation}</span>
										</Button>}
									</Col>
								</Row>
							)}

							{checklistData.answers && checklistData.answers.length > 0 ? (
								<Row>
									<Col className="items-container">
										{checklistData.answers.map((answer: Answer) => {
											return (
												<ModuleState
													key={answer.id}
													id={answer.id}
													date={answer.lastUpdateDate}
													state={answer.status}
													baseRedirectUrl={`/shift/${shiftId}/checklist/${checklistId}`}
													type={"checklist"}
												/>
											);
										})}
									</Col>
								</Row>
							) : (
								<Row>
									<Col className="items-container">
										<EmptyPageNote
											note={labels.noChecklistYet}
											isLoading={isLoading}
											className="empty-page-note"
										/>
									</Col>
								</Row>
							)}
						</>
					)}
				</Suspense>
			</ErrorBoundary>
		</Container>
	);
};

export default ChecklistItemsPage;
