import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { IFollower } from 'features/types/user/profile';

const Follower: FC<IFollower> = function Follower({ avatarSrc, name, institution }) {
	return (
		<div className="bg-white dark__bg-1100 p-3 h-100">
			<Link to="/user/profile">
				<Image thumbnail fluid roundedCircle className="mb-3 shadow-sm" src={avatarSrc} width={100}
				/>
			</Link>
			<h6 className="mb-1">
				<Link to="/user/profile">{name}</Link>
			</h6>
			<p className="fs--2 mb-1">
				<Link className="text-700" to="#!">
					{institution}
				</Link>
			</p>
		</div>
	);
};


export default Follower;
