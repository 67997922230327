export const stringUtils = (s: string) => {
	return {
		capitalize() {
			return s.toLowerCase().replace(/(^\w{1})|(\s+\w{1})|(\.+\w{1})/g, letter => letter.toUpperCase());
		}
	}
}

export const capitalizeString = (str: string) => {
	const words = str.toLowerCase().split(" ");
	for (let i in words) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
	}
	return words.join(" ");
};