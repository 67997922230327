import { labels } from "features/locale/it-it";
import { ChecklistFormStateType, QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import { useEffect } from "react";

type props = {
	formData: ChecklistFormStateType;
	setFormData: Function;
	validated: boolean;
	answerArr: string[]
};

export const selectPropsKeys = ({ formData, setFormData, validated, answerArr }: props) => {

	return {
		id: 1,
		title: "",
		subtitle: "",
		enabled: true,
		type: "select" as QuestionType,
		answers: answerArr,
		isMandatory: false,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.keySelectKey,
		isExecutable: true,
		validated: validated,
		fullSize: true,
	};
};