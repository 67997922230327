import React from 'react'
import animationJson from 'assets/animations/spinnerAnimation.json'
import Lottie from 'react-lottie'

const SicurforceSpinner = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationJson
	}

	return	<div className='sicurforce-spinner-container'>
		<Lottie
			options={defaultOptions}
		/>
	</div>
}

export default SicurforceSpinner