import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Dropdown } from 'react-bootstrap'
import SquaresMenuIcon from 'assets/icons/SquaresMenu'
import ProfileItems from 'components/navbar/common/ProfileItems'
import './style.scss'

const ProfileItemsDropdown: FC = function ProfileItemsDropdown() {
	const [show, setShow] = useState<boolean>()

	return (
		<Dropdown
			navbar
			as='li'
			show={show as boolean}
			onToggle={(): void => setShow(!show)}
			className='change-password-dropdown'
		>
			<Dropdown.Toggle
				bsPrefix='toggle'
				as={Link}
				to='#!'
				className='nav-link px-2 nine-dots'
				id="squares_menu"
			>
				<SquaresMenuIcon />
			</Dropdown.Toggle>

			<Dropdown.Menu
				className='dropdown-menu-card dropdown-menu-end drop-down-container'
				show={show}
			>
				<Card className={`dropdown-menu-end shadow-none`} onClick={(): void => setShow(!show)}>
					<Card.Body>
						<ProfileItems isDropdown />
					</Card.Body>
				</Card>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default ProfileItemsDropdown
