import { createApi } from "@reduxjs/toolkit/query/react";
import { headersCommon } from "./constants/headers";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import {
	GetParcelsRequest,
	GetParcelsResponse,
	PostOneParcelRequest,
	PostOneParcelResponse,
	ParcelFormCompletation,
	ParcelPostRecipients,
	GetParcelDetailRequest,
	GetParcelDetailResponse,
	PutParcelStatusRequest,
} from "./models/ParcelsResponse";
import { ParcelResponseApi } from "./models/ParcelResponse";
import { GetRecipientsResponse } from "./models/RecipientsResponse";

export const parcelApi = createApi({
	reducerPath: "parcelService",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getParcel: builder.mutation<
			ParcelResponseApi,
			{ shiftId: string; parcelId: number }
		>({
			query: ({ shiftId, parcelId }) => ({
				url: shiftId ==='' ? `/fe/Parcels/${parcelId}` : `/fe/Parcels/${parcelId}?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
			}),
		}),
		getParcels: builder.mutation<GetParcelsResponse, GetParcelsRequest>({
			query: ({ parcelId, shiftId }) => ({
				url: `/fe/Parcels/${parcelId}?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getParcelDetail: builder.mutation<
			GetParcelDetailResponse,
			GetParcelDetailRequest
		>({
			query: ({ parcelId, parcelManagementId, answerId, shiftId }) => ({
				url: `/fe/Parcels/${parcelId}/${parcelManagementId}?shiftId=${shiftId}${
					answerId != "null" ? `&answerId=${answerId}` : ""
				}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getParcelDetailResponsabile: builder.mutation<
			GetParcelDetailResponse,
			GetParcelDetailRequest
		>({
			query: ({ parcelId, parcelManagementId, answerId, shiftId }) => ({
				url: `/fe/Parcels/${parcelId}/${parcelManagementId}${
					answerId != "null" ? `?answerId=${answerId}` : ""
				}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		postAParcel: builder.mutation<PostOneParcelResponse, PostOneParcelRequest>({
			query: ({ parcelId, parcelBodyRequest }) => ({
				url: `/fe/Parcels/${parcelId}`,
				method: "POST",
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: parcelBodyRequest,
			}),
		}),
		postParcelRecipient: builder.mutation<
			GetRecipientsResponse,
			{ parcelObj: ParcelPostRecipients; parcelId: Number | undefined }
		>({
			query: ({ parcelObj, parcelId }) => ({
				url: `/fe/Parcels/${parcelId}/Recipients`,
				method: "POST",
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
				body: parcelObj,
			}),
		}),
		putAParcel: builder.mutation<PostOneParcelResponse, PutParcelStatusRequest>(
			{
				query: ({ parcelId, parcelManagementId, body }) => ({
					url: `/fe/Parcels/${parcelId}/${parcelManagementId}`,
					method: "PUT",
					headers: {
						...headersCommon,
						Authorization: `Bearer ${
							localStorage.token || sessionStorage.token
						}`,
						deviceId:
							sessionStorage.getItem("deviceId") ||
							localStorage.getItem("deviceId") ||
							"",
					},
					body,
				}),
			}
		),
	}),
});

export const {
	useGetParcelMutation,
	useGetParcelsMutation,
	usePostAParcelMutation,
	usePutAParcelMutation,
	useGetParcelDetailMutation,
	useGetParcelDetailResponsabileMutation,
	usePostParcelRecipientMutation,
} = parcelApi;
