import './style.scss'
import React from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import SicurforceSpinner from 'components/content/SicurforceSpinner'

const EmptyPageNote = ({ note, isLoading, className }: { note: string | undefined, isLoading?: boolean, className?: string }) => {
	return (
		<Row className={`${className || 'd-flex w-md-50 m-5 mx-auto align-items-center justify-content-center text-center'}`}>
			<Col className="p-0">
				{isLoading ? <SicurforceSpinner.Spinner /> : <Alert variant='theme-blue'>
					{note}
				</Alert>}
			</Col>
		</Row>
	)
}

export default EmptyPageNote