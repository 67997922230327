import { Container, Row } from "react-bootstrap";
import "./style.scss";
import BoxDetails from "components/content/BoxDetails";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	CustomerAddress,
	CustomerResponse,
} from "features/storeManagement/services/models/CustomerResponse";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetAllIncidentsMutation } from "features/storeManagement/services/IncidentService";
import IncidentItem from "components/modules/Incident/IncidentItem/IncidentItem";
import IncidentManagementIcon from "assets/icons/IncidentManagement";
import { Incident, IncidentElement } from "features/storeManagement/services/models/IncidentResponse";
import ErrorBoundary from "features/ErrorBoundary";
import Suspense from "features/Suspense";
import { roles } from "features/storeManagement/services/constants/roles";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";

const IncidentModulePage = () => {
	const { customer, contract, position, serviceAddress } = useParams();
	useChangeDocumentTitle(labels.incidentText);
	const [selectedCustomer, setSelectedCustomer] = React.useState<
		CustomerResponse | undefined | null
	>(undefined);
	const [selectedAddress, setSelectedAddress] = React.useState<
		CustomerAddress | undefined | null
	>(undefined);

	const [
		getCustomers,
		{
			data: customersData,
			isLoading: customersIsLoading,
			isError: isCustomersError,
			error: customersError,
		},
	] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData });

	useEffect(() => {
		if (!customersData && !isCustomersError && !customersIsLoading) {
			getCustomers();
		}
	}, [customersData, customersIsLoading]);

	useEffect(() => {
		if (!customersIsLoading && !!customersData) {
			const currentCustomer = customersData?.find(
				(item: CustomerResponse) => item.serviceAddress === serviceAddress
			);
			setSelectedCustomer(currentCustomer || null);
		}
	}, [customersIsLoading, customersData]);

	useEffect(() => {
		if (
			!customersIsLoading &&
			customersData &&
			(selectedCustomer || selectedCustomer === null)
		) {
			const currentAddress = selectedCustomer?.addresses.find(
				(item: CustomerAddress) =>
					customer &&
					serviceAddress &&
					item.idCustomer === customer &&
					item.serviceAddress === serviceAddress
			);
			setSelectedAddress(currentAddress || null);
		}
	}, [customersIsLoading, selectedCustomer]);

	const [
		getAllIncidents,
		{
			data: incidents,
			isLoading: incidentsIsLoading,
			isError: incidentsIsError,
			error: incidentsError,
		},
	] = useGetAllIncidentsMutation({
		fixedCacheKey: fixedCacheKeys.getAllIncidents,
	});

	useEffect(() => {
		if (customer && !incidents && !incidentsIsLoading) {
			getAllIncidents({
				idCustomer: customer,
				serviceAddress: serviceAddress
			});
		}
	}, [customer, contract, position, incidents, incidentsIsLoading]);

	return (
		<ErrorBoundary errorMessage={customersError || incidentsError}>
			<Container
				fluid
				className="incident-module-page-container p-0 px-2 mb-5 mt-4"
			>
				<Suspense isLoading={customersIsLoading || incidentsIsLoading} fullPage>
					{selectedAddress && (
						<Container fluid className="box-details-container p-1">
							<BoxDetails customerAddress={selectedAddress} />
						</Container>
					)}

					{incidents && incidents.items?.length > 0 && (
						<Container fluid className="p-0 mb-5 mt-4 mt-md-5 ">
							<Row className="d-flex justify-content-start m-0 px-0">
								{incidents.items.map((item: IncidentElement) => (
									<IncidentItem
										key={item.id}
										shiftId={roles.responabile.label}
										incident={item}
										icon={<IncidentManagementIcon size="lg" />}
										customer={customer}
										contract={contract}
										position={position}
										serviceAddress={serviceAddress}
									/>
								))}
							</Row>
						</Container>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default IncidentModulePage;
