import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { } from 'theme/data/dashboard/default'
import { useGetModulesMutation } from 'features/storeManagement/services/modulesService'
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys"
import { labels } from 'features/locale/it-it';
import './style.scss';
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle';

const AdminPage: FC = function AdminPage() {
	const [, { data: modulesData }] = useGetModulesMutation({ fixedCacheKey: fixedCacheKeys.getModules })
	useChangeDocumentTitle(labels.pageTile_AdminPage);
	
	return (
		<Container fluid className='p-1'>
			<p>{labels.welcomeRole}</p>
			{/* {modulesData && modulesData.roles.map((role: string) => <p key={role}>{role}</p>)} */}
		</Container>
	);
};

export default AdminPage;