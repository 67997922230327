import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';

const NewspaperIcon = (props: any) => (
	<FontAwesomeIcon icon={faNewspaper} width={24} height={24} size={props.size ? props.size : "sm"} {...props} />
)

export default NewspaperIcon


