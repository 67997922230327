import { ParcelRecipientsWithDate, parcelStatus } from "features/storeManagement/services/models/ParcelResponse"
import { format } from 'date-fns';
import { labels } from 'features/locale/it-it'
import { Form } from "react-bootstrap";
import './style.scss'
import { useNavigate, useParams } from "react-router";

export interface parcelTypeInterface {
	filteredData: ParcelRecipientsWithDate[] | undefined,
	parcelId: number | undefined
}

const ParcelItemPage = (props: parcelTypeInterface) => {
	const navigate = useNavigate()
	const { shiftId, customer, contract, position, serviceAddress } = useParams();

	const consultation = (element: ParcelRecipientsWithDate) => {
		navigate(shiftId ? `/shift/${shiftId}/parcel/${props.parcelId}/${element.id}/${element.answerId}/read` : `/parcel/${props.parcelId}/customer/${customer}/serviceAddress/${serviceAddress}/${element.id}/${element.answerId}/read`);
	}
	const deliveryParcel = (element: ParcelRecipientsWithDate) => {
		navigate(shiftId ? `/shift/${shiftId}/parcel/${props.parcelId}/${element.id}/${element.answerId}/delivery` : `/parcel/${props.parcelId}/customer/${customer}/serviceAddress/${serviceAddress}/${element.id}/${element.answerId}/delivery`);
	}

	return (
		<>
			<div className="nav_element">
				<div className="nav_element__single">{labels.parcelDestinatario}</div>
				<div className="nav_element__single">{labels.parcelArrivatoIl}</div>
				<div className="nav_element__single">{labels.parcelConsegnatoIl}</div>
				<div className="nav_element__single">{labels.parcelStato}</div>
			</div>
			{(props.filteredData !== undefined && props.filteredData.length > 0) && props.filteredData.map((element, i) => (
				<div className="item_element" key={i}>
					<div className="item_element__single"><b className="sizeb" >{element.parcelRecipient.recipientType=== "Person" ? (element.parcelRecipient.firstName ? element.parcelRecipient.firstName : "") + " " + element.parcelRecipient.lastName : element.parcelRecipient.companyName}</b></div>
					<div className="item_element__single"><b className="sizeb">{element.parcelInboundTimestamp ? format(new Date(element.parcelInboundTimestamp), 'dd/MM/yyyy HH:mm') : <span>{labels.parcelEmpty}</span>}</b></div>
					<div className="item_element__single"><b className="sizeb">{element.parcelOutboundTimestamp ? format(new Date(element.parcelOutboundTimestamp), 'dd/MM/yyyy HH:mm') : <span>{labels.parcelEmpty}</span>}</b></div>
					<div className="item_element__single">
						{(element.status.toLowerCase() === parcelStatus.stored) &&
							<div onClick={() => { deliveryParcel(element) }} key={i} className="item_element__single__type item_element__single__stored cursor-pointer">{labels.parcelDaConsegnare}</div>
						}
						{(element.status.toLowerCase() === parcelStatus.delivered) &&
							<div onClick={() => { consultation(element) }} className="item_element__single__type item_element__single__delivered cursor-pointer">{labels.parcelConsegnato}</div>
						}
						{(element.status.toLowerCase() === parcelStatus.lost) &&
							<div onClick={() => { consultation(element) }} className="item_element__single__type item_element__single__lost cursor-pointer">{labels.parcelLost}</div>
						}
						{(element.status.toLowerCase() === parcelStatus.stolen) &&
							<div onClick={() => { consultation(element) }} className="item_element__single__type item_element__single__stolen cursor-pointer">{labels.parcelStolen}</div>
						}
						{(element.status.toLowerCase() === parcelStatus.cancelled) &&
							<div onClick={() => { consultation(element) }} className="item_element__single__type item_element__single__cancelled cursor-pointer">{labels.parcelDeleted}</div>
						}
					</div>
				</div>
			))}

			{(props.filteredData && props.filteredData.length === 0) &&
				<div className="empty-search-result-container">
					<Form.Label
						htmlFor='parcel-label'
						className='parcel-label mb-0'
					>
						{labels.parcelLablesEmptyState+labels.parcelLablesEmptySearch}
					</Form.Label>
				</div>
			}
		</>
	)
}
export default ParcelItemPage