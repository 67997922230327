import {
	GuestResponseDettaglioApi,
} from "features/storeManagement/services/models/GuestResponse";
import { Col, Container, Row } from "react-bootstrap";
import { dateParser } from "features/utils/dateUtils";
import { labels } from "features/locale/it-it";
import './style.scss'
interface dettaglioInterface {
	dataDettaglio: GuestResponseDettaglioApi;
}

const ControlloAccessiDataTessera = (data: dettaglioInterface) => {
	const stateFunction = (state: boolean) => {
		if (state) {
			return labels.stateAttiva;
		} else {
			return labels.stateDisattiva;
		}
	};

	return (
		<Container className="controllo-accessi-dettaglio w-100 d-flex flex-wrap align-items-center justify-content-center mt-1 p-0">
			<div className="w-100">
				<label>
					<b className="text_dettaglio_titolo">{labels.datiSchedaLabel}:</b>
				</label>
			</div>
			<Row className="d-flex p-2 w-100 controllo-accessi-box controllo-accessi-box__text">
				<Col className="d-flex flex-wrap dati-tessera">
					<div>
						<label className="text_dettaglio_label">{labels.codiceLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{data?.dataDettaglio?.guest?.cards[0]?.number
								? data.dataDettaglio?.guest?.cards[0]?.number
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.statoLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{" " + data?.dataDettaglio?.guest?.cards[0]?.enabled
								? stateFunction(data?.dataDettaglio?.guest?.cards[0]?.enabled)
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.scadenzaLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{data?.dataDettaglio?.guest?.cards[0]?.expirationDate
								? dateParser(
									data?.dataDettaglio?.guest?.cards[0]?.expirationDate || ""
								).getDay() +
								" " +
								dateParser(
									data?.dataDettaglio?.guest?.cards[0]?.expirationDate || ""
								)
									.getHour()
									.substring(0, 5)
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.tipologiaAttivitaLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{" " + data?.dataDettaglio?.guest?.cards[0]?.guestCardCategory
								? data.dataDettaglio?.guest?.cards[0]?.guestCardCategory
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.ingressiLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{" " + data?.dataDettaglio?.guest?.cards[0]?.residualAdmissions
								? data?.dataDettaglio?.guest?.cards[0]?.residualAdmissions
								: "-"}
						</span>
					</div>
					<div>
						<label className="text_dettaglio_label">{labels.classeLabel}:</label>
						<span className="text_dettaglio_value print-blue-text">
							{" " + data?.dataDettaglio?.guest?.cards[0]?.class
								? data?.dataDettaglio?.guest?.cards[0]?.class
								: "-"}
						</span>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default ControlloAccessiDataTessera;
