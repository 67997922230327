import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Button, Col } from "react-bootstrap";
import {
	shiftsApi,
	useBeginFinishShiftActionMutation,
	useDeleteCurrentShiftMutation,
	useGetCurrentShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import BoxDetails from "components/content/BoxDetails";
import EmptyPageNote from "components/content/EmptyPageNote";
import ChecklistItem from "components/modules/Checklist/ChecklistItem";
import PatrolItem from "components/modules/Patrol/PatrolItem";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";

import "./styles.scss";
import { JournalButton } from "components/modules/Journal/JournalItem";
import { useDispatch } from "react-redux";
import ConfirmModal from "components/content/ConfirmModal";
import ArrowIcon from "assets/icons/ArrowIcon";
import { ParcelButton } from "components/modules/Parcel/ParcelButton";
import { KeyButton } from "components/modules/Key/KeyButton/KeyButton";
import IncidentItem from "components/modules/Incident/IncidentItem/IncidentItem";
import ChecklistIcon from "assets/icons/Checklist";
import PatrolIcon from "assets/icons/RondaIcon";
import IncidentManagementIcon from "assets/icons/IncidentManagement";
import BoxIcon from "assets/icons/Box";
import KeysIcon from "assets/icons/KeysIcon";
import NewspaperIcon from "assets/icons/Newspaper";
import { ControlloAccessiButton } from "components/modules/ControlloAccessi/ControlloAccessiButton/ControlloAccessiButton";
import ControlloAccessiIcon from "assets/icons/ControlloAccessiIcon";
import { AreaDocumentiButton } from "components/modules/DocumentDetail/AreaDocumentiButton/AreaDocumentiButton";
import AreaDocumentiIcon from "assets/icons/AreaDocumentiIcon";

const ServiceModulePage: FC = function ServiceModulePage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useChangeDocumentTitle(labels.pageTile_CorrispondentePage);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

	const [
		getCurrentShift,
		{
			data: currentShiftData,
			isLoading: currentShiftIsLoading,
			error: currentShiftError,
		},
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		checkInOut,
		{
			data: checkInOutData,
			isLoading: checkInOutIsLoading,
			error: checkInOutError,
			isSuccess: beginFinishShiftActinonIsSuccess,
		},
	] = useBeginFinishShiftActionMutation();

	const [
		deleteShift,
		{
			data: deletecurrentShiftData,
			isLoading: deletecurrentShiftIsLoading,
			error: deletecurrentShiftError,
			isSuccess: deletecurrentShiftSuccess,
		},
	] = useDeleteCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	useEffect(() => {
		if (!deletecurrentShiftIsLoading && !!deletecurrentShiftData?.succeeded) {
			dispatch(shiftsApi.util.resetApiState());
			if (!currentShiftData?.shiftId) {
				navigate("/");
			}
		}
	}, [
		deletecurrentShiftData,
		deletecurrentShiftIsLoading,
		deletecurrentShiftError,
		currentShiftData,
	]);

	useEffect(() => {
		if (beginFinishShiftActinonIsSuccess) {
			getCurrentShift();
		}
	}, [beginFinishShiftActinonIsSuccess]);

	useEffect(() => {
		if (isGetCurrentError404) {
			navigate("/");
		}
	}, [currentShiftError]);

	const handleBeginFinishShift = () => {
		if (shiftNotFinished) {
			setShowConfirmModal(true);
		} else {
			checkInOut({ ...checkinOutBody, notificationType: "CheckIn" });
		}
	};

	const handleFinishShift = () => {
		checkInOut({ ...checkinOutBody, notificationType: "CheckOut" });
	};

	const shiftNotFinished =
		!deletecurrentShiftIsLoading &&
		currentShiftData &&
		currentShiftData.shiftCheckinTime &&
		!currentShiftData.shiftCheckoutTime;
	const showEmptyNote =
		currentShiftData &&
		currentShiftData.checklists?.length < 1 &&
		currentShiftData.patrols?.length < 1 &&
		currentShiftData.incidents?.length < 1 &&
		currentShiftData.documents && currentShiftData.documents.length < 1 &&
		!currentShiftData.journal &&
		!currentShiftData.parcel &&
		!currentShiftData.keyManagement &&
		!currentShiftData.guestConfiguration &&
		currentShiftData.shiftCheckinTime

	const isGetCurrentError404 =
		currentShiftError &&
		(currentShiftError as FetchBaseQueryError).status === 404;

	const checkinOutBody = currentShiftData
		? {
			operatorCID: `${currentShiftData.operatorCID}`,
			referenceType: "Shift",
			referenceID: currentShiftData.shiftId,
			venueCheckType: "GPS",
			venueLat: currentShiftData.shiftCityLat,
			venueLng: currentShiftData.shiftCityLng,
		  }
		: {};

	const isMyActivitiesLabel = () => {
		return (
			(currentShiftData?.checklists && currentShiftData.checklists.length > 0) ||
			(currentShiftData?.incidents && currentShiftData.incidents.length > 0) ||
			(currentShiftData?.patrols && currentShiftData.patrols.length > 0) ||
			(currentShiftData?.documents && currentShiftData.documents.length > 0) ||
			currentShiftData?.parcel ||
			currentShiftData?.journal ||
			currentShiftData?.keyManagement
		);
	};

	return (
		<Container fluid className="audit-page-container p-0 px-2 mb-5 mt-4">
			<ErrorBoundary
				errorMessage={
					(isGetCurrentError404 ? undefined : currentShiftError) ||
					checkInOutError
				}
			>
				{currentShiftData?.shiftCheckinTime === null && (
					<p
						className="box-details-container mpt-5 x-1 container-fluid delete-prop"
						onClick={() => {
							deleteShift();
						}}
					>
						<ArrowIcon className="rotate-180deg blue-svg" />
						{labels.deleteShift}
					</p>
				)}
				{currentShiftData && (
					<div className="box-details-container">
						<BoxDetails shift={currentShiftData} isCorrispondentePage />
					</div>
				)}
				<Suspense
					isLoading={currentShiftIsLoading || checkInOutIsLoading}
					fullPage
				>
					<>
						{currentShiftData && (
							<Container
								fluid
								className="audits-container p-0 mb-5 mt-4 mt-md-5 "
							>
								{currentShiftData.checkInOutEnable && (
									<Row className="mb-3 justify-content-center">
										<Button
											variant="green"
											className="begin-finish-shift-button"
											onClick={handleBeginFinishShift}
										>
											{shiftNotFinished
												? labels.finishShift
												: labels.beginShift}
										</Button>
									</Row>
								)}
								{shiftNotFinished && (
									<Row className="internal-audits d-flex justify-content-start m-0 px-0">
										{isMyActivitiesLabel() && (
											<Row className="generic-report-title mx-2 my-2 px-0">
												<Col className="mx-0 bold-text-report blue-text px-0 py-2 font">
													{labels.myActivitiesText}
												</Col>
											</Row>
										)}
										{currentShiftData.checklists.map((checklist) => (
											<ChecklistItem
												key={checklist.id}
												checklist={checklist}
												icon={<ChecklistIcon size="lg" />}
											/>
										))}
										{currentShiftData.patrols.map((patrol) => (
											<PatrolItem
												key={patrol.id}
												patrol={patrol}
												icon={<PatrolIcon size="lg" />}
											/>
										))}
										{currentShiftData.journal && (
											<JournalButton
												shiftId={currentShiftData.shiftId}
												icon={<NewspaperIcon size="lg" />}
											/>
										)}
										{currentShiftData.incidents.map((incident) => (
											<IncidentItem
												key={incident.id}
												shiftId={currentShiftData.shiftId}
												incident={incident}
												icon={<IncidentManagementIcon size="lg" />}
											/>
										))}
										{currentShiftData.parcel && (
											<ParcelButton
												shiftId={currentShiftData.shiftId}
												icon={<BoxIcon size="lg" />}
											/>
										)}
										{currentShiftData.keyManagement && (
											<KeyButton
												shiftId={currentShiftData.shiftId}
												icon={<KeysIcon size="lg" />}
											/>
										)}

										{currentShiftData.guestConfiguration && (
											<ControlloAccessiButton
												shiftId={currentShiftData.shiftId}
												guestConfigurationId = {currentShiftData.guestConfiguration.id}
												icon={<ControlloAccessiIcon size="lg" />}
											/>
										)}
										{currentShiftData.documents && currentShiftData.documents.length > 0 && (
											<AreaDocumentiButton shiftId={currentShiftData.shiftId} icon={<AreaDocumentiIcon size="lg" />} />
										)}	
									</Row>
								)}
								<ConfirmModal
									headerMessage={labels.watchOut}
									bodyMessage={labels.cannnotModifyAfterConfirm}
									showConfirmModal={showConfirmModal}
									setShowConfirmModal={setShowConfirmModal}
									isLoading={checkInOutIsLoading}
									onConfirm={handleFinishShift}
								/>
							</Container>
						)}
						{showEmptyNote && (
							<EmptyPageNote
								className="'d-flex w-75 m-5 mx-auto align-items-center justify-content-center text-center"
								note={labels.noModulesForShift}
							/>
						)}
					</>
				</Suspense>
			</ErrorBoundary>
		</Container>
	);
};

export default ServiceModulePage;
