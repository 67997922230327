import { fetchBaseQuery, FetchArgs, FetchBaseQueryError, BaseQueryFn } from "@reduxjs/toolkit/query/react"
import { setIsLoggedIn } from "../../slices/loggedInSlice"

const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_DEV_BASE_URL })


export const baseQueryWithLogoutIfInvalidToken: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError> = async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions)

		if (result.error && result.error.status === 401) {
			api.dispatch(setIsLoggedIn(false))
		}

		if (result.error && result.error.status === 400) {
			api.dispatch(setIsLoggedIn(false))
		}

		if (result.error) {
			api.dispatch(setIsLoggedIn(false))
		}

		return result
	}