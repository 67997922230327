import { useGetParcelMutation } from "features/storeManagement/services/parcelService"
import ParcelSearchItem from "../ParcelSearchItem/ParcelSearchItem"
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useGetCurrentShiftMutation, useGetOneShiftMutation } from "features/storeManagement/services/shiftsService"
import { ParcelRecipientsWithDate, parcelSendObj } from "features/storeManagement/services/models/ParcelResponse"
import './style.scss'
import ParcelItemPage from "../ParcelItemPage/ParcelItemPage"
import { Button, Col, Container, Row } from "react-bootstrap"
import { labels } from "features/locale/it-it"
import Suspense from 'features/Suspense'
import ParcelNoResult from "components/modules/Parcel/ParcelNoResult/ParcelNoResult"
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary"
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle"

const ParcelDetailsPage = () => {
	const { shiftId } = useParams()
	useChangeDocumentTitle(labels.pageTitle_ParcelItemsPage);
	const navigate = useNavigate()
	let objParcelSend: parcelSendObj
	const [useReturnDataApi, setUseReturnDataApi] = useState<ParcelRecipientsWithDate[]>([])
	let allTypes: ParcelRecipientsWithDate[] | undefined = []
	/*----------------API----------------*/
	const [getOneShift, {
		data: shiftData,
		isLoading: shiftIsLoading,
		error: shiftError
	}] = useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData })
	
	const [
		getCurrentShift,
		{ 
			data: currentShiftData, 
			isLoading: currentShiftIsLoading 
		},
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [getParcel, {
		data: parcelRecipients,
		isLoading: parcelRecipientsIsLoading,
		error: parcelRecipientsError
	}] = useGetParcelMutation({ fixedCacheKey: fixedCacheKeys.shiftData })

	useEffect(() => {
		if (shiftId && shiftData?.parcel?.id) {
			objParcelSend = {
				shiftId: shiftId,
				parcelId: shiftData?.parcel.id
			}
			getParcel(objParcelSend)
		} else if(shiftId && currentShiftData?.parcel.id) {
			objParcelSend = {
				shiftId: shiftId,
				parcelId: currentShiftData?.parcel.id
			}
			getParcel(objParcelSend)
		}
	}, [shiftData, shiftIsLoading])

	useEffect(() => {
		if (parcelRecipients?.packages)
			setUseReturnDataApi(parcelRecipients?.packages)
	}, [parcelRecipients])

	const flagEnable = !!parcelRecipients && !!parcelRecipients.packages && parcelRecipients.packages.length > 0

	/*---------JSX------------*/
	return (
		<ErrorBoundary errorMessage={parcelRecipientsError}>
			<Suspense isLoading={parcelRecipientsIsLoading || shiftIsLoading} fullPage>
				<Container className="parcel-container p-0 px-2 mb-5 mt-4">
					{(flagEnable) &&
						<>
							<Row className="row-container-search">
								{	(shiftData?.modulesExecutable || currentShiftData?.modulesExecutable) &&
									<Col lg={3} md={4} className="text-start">
										<Button type="button" id="submit" form='form-section' className='submit-button submit-button-parcel mt-1' variant="success" onClick={() => navigate(`/shift/${shiftId}/parcel/new`)}>
											<p>{labels.parcelButton}</p>
										</Button>
									</Col>}
								<Col sm={12} md={8} lg={6}>
									<ParcelSearchItem parcelRecipients={parcelRecipients} setUseReturnDataApi={setUseReturnDataApi} type={"parcel"} />
								</Col>
							</Row>
							<ParcelItemPage filteredData={useReturnDataApi} parcelId={parcelRecipients.id}/>
						</>
					}
					{(!flagEnable) &&
						<ParcelNoResult />
					}
				</Container>
			</Suspense>
		</ErrorBoundary>
	)
}

export default ParcelDetailsPage