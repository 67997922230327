import { useGetRiconsegnaDetailsMutation } from "features/storeManagement/services/keyManagementService";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import isMobile from "ismobilejs";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { nanoid } from "@reduxjs/toolkit";
import PrintButton from "components/content/PrintButton/PrintButton";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";

const KeyDeliveredPage = () => {
	const { shiftId, keyManagementId, keyRegistryManagementId, answerId } = useParams();
	let objInfoDataOperatorKey = undefined;

	useChangeDocumentTitle(labels.pageTitle_KeysPage);

	const [
		getRiconsegnaDetails,
		{
			data: riconsegnaDetailsData,
			isLoading: riconsegnaDataIsLoading,
			error: riconsegnaDataError,
		},
	] = useGetRiconsegnaDetailsMutation({
		fixedCacheKey: fixedCacheKeys.infoDataKey,
	});

	useEffect(() => {
		if (
			shiftId &&
			keyManagementId !== undefined &&
			keyRegistryManagementId !== undefined &&
			answerId
		) {
			getRiconsegnaDetails({
				keyManagementId: keyManagementId.toString(),
				keyRegistryManagementId: Number(keyRegistryManagementId),
				shiftId: shiftId,
				answerId: Number(answerId)
			});
		}
	}, []);

	if (riconsegnaDetailsData && riconsegnaDetailsData.keyRecipient) {
		objInfoDataOperatorKey = {
			firstname: riconsegnaDetailsData.keyRecipient.firstName?.toString(),
			lastname: riconsegnaDetailsData.keyRecipient.lastName?.toString(),
			OperatorName: riconsegnaDetailsData.operatorNameOutbound?.toString(),
			time: riconsegnaDetailsData.outBoundTimeStamp?.toString(),
			companyName: riconsegnaDetailsData.keyRecipient.companyName?.toString(),
			additionalInfo: riconsegnaDetailsData.keyRecipient.additionalInfo?.toString(),
			deliveredNote: riconsegnaDetailsData.deliveredNote?.toString(),
			storedNote: riconsegnaDetailsData.storedNote?.toString(),
		};
	}

	const deliveredFromName = `${
		riconsegnaDetailsData?.keyDelivery?.firstName ?? ""
	} ${riconsegnaDetailsData?.keyDelivery?.lastName ?? ""}`;

	const checkDelivered = () => {
		if(riconsegnaDetailsData?.managementStatus === labels.keyStatusStored){
			return true
		}else{
			return false
		}
	}

	const isShowMediaQuestions = riconsegnaDetailsData && 
	riconsegnaDetailsData.keyRegistryModelOut && 
	riconsegnaDetailsData.keyRegistryModelOut.questions &&
	riconsegnaDetailsData.keyRegistryModelOut.questions.length > 0;

	return (
		<ErrorBoundary errorMessage={riconsegnaDataError}>
			<Container className="box-details-container parcel-items-page-container p-0 px-4 mb-3">
				<Suspense isLoading={riconsegnaDataIsLoading} fullPage>
					<Container fluid className='box-details-accordion p-1 mt-2 open standard d-flex justify-content-end'>
						<PrintButton />
					</Container>
					<BoxDetails
						recipientKeyData={riconsegnaDetailsData?.keyRegistryModelOut}
						objInfoDataOperatorKey={objInfoDataOperatorKey}
					/>

					{isShowMediaQuestions &&
					riconsegnaDetailsData.keyRegistryModelOut.questions.filter((el) => el.type === labels.picture).map((question: Question) => (
						question.answerDetail?.medias && question.answerDetail?.medias.length > 0 && (
							<div key={question.id} className="box-details-container px-sm-5 px-0">
								<MediaPicker
									read={true}
									mediaArray={question.answerDetail?.medias}
									mediaTitle={question.title}
									deleteIsLoading={false}
									showDeleteConfirmModal={false}
									deleteAJournalMedia={() => {}}
									setShowConfirmModal={() => {}}
									setMediaArray={() => {}}
									setShowDeleteConfirmModal={() => {}}
									setMediaIdToDel={() => {}}
									flagGiornale={false}
								/>
							</div>
						)
					)
					)}

					{checkDelivered() && (
						<div className="print-full-section">
							<div className="box-details-container mt-3 px-1 container-fluid parcel-consultation-box">
								<div className="element_container__label my-4 mt-6">
									<ModulePageTitle title={labels.infoKeyStatusConsegnata} />
								</div>
							</div>
							<div
								className={`box-details-accordion absolute p-1 mx-auto open standard`}
							>
								<Container
									fluid
									className="box-modules-container base-container fade-content mt-2 p-0"
								>
									<Row
										className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
										onClick={() => {}}
									>
										<div />
										<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
											<Col
												className="datetime-box col-7 d-flex flex-column align-items-start px-1"
												key={nanoid()}
											>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.withdrawnfromKeyStatusConsegnate}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{deliveredFromName}
																</div>
																<div className="col-12">
																	{
																		riconsegnaDetailsData?.keyDelivery
																			?.companyName
																	}{" "}
																	{riconsegnaDetailsData?.keyDelivery
																		?.additionalInfo
																		? `- ${riconsegnaDetailsData?.keyDelivery?.additionalInfo}`
																		: ""}
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.operatorKeyStatusConsegnate}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{riconsegnaDetailsData?.operatorNameInbound ? riconsegnaDetailsData?.operatorNameInbound : "-"}
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
											<Col
												key="checkout-time"
												className="datetime-box col-5 d-flex flex-column align-items-start px-1"
											>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.dateKeyStatusRiconsegnate}
													</div>

													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{riconsegnaDetailsData?.inBoundTimeStamp
																		? format(
																			new Date(
																				riconsegnaDetailsData.inBoundTimeStamp
																			),
																			"dd/MM/yyyy HH:mm"
																		  )
																		: "-"}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.noteKeyStatusRiconsegna}
													</div>
													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<div className="day bold-text">
																	{riconsegnaDetailsData?.storedNote
																		? riconsegnaDetailsData?.storedNote
																		: "-"}
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</Row>
								</Container>
							</div>
						</div>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default KeyDeliveredPage;
