import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import './style.scss'
import { PatrolItemProps } from 'features/types/patrol/PatrolItemProps'

const PatrolItem: React.FC<PatrolItemProps> = ({patrol, icon}) => {
	const navigate = useNavigate()
	const { shiftId } = useParams()

	return (
		<Col 
			className='patrol-item-column px-0 px-md-2 d-flex justify-content-center'
			onClick={() => navigate(`/shift/${shiftId}/patrol/${patrol.id}`)}
		>
			<Row className='patrol-item-container align-items-center cursor-pointer m-0 my-1 my-md-3 py-1 px-2 pos-rel'>
				<div className='container-icon'>
					{icon}
				</div>
				<Col className='patrol-item'>
					<Row className='d-flex mb-2'>
						<Col className='patrol-title blue-text d-flex align-items-center text-center p-0 col-12'>
							{patrol?.description}
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	)
}

export default PatrolItem
