import * as React from "react"

const AlertIcon = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" {...props}>
		<path
			className="orange-svg"
			d="M10 19a2 2 0 1 1 4 0 2 2 0 1 1-4 0M10 3h4v12h-4z"
		/>
	</svg>
)
export default AlertIcon
