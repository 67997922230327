import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import './style.scss'
import { ModuleStateProps, moduleStatus } from 'features/types/modules/ModuleStateProps'
import { dateParser } from 'features/utils/dateUtils'
import { labels } from 'features/locale/it-it'
import AlertIcon from 'assets/icons/AlertIcon'

const ModuleState: React.FC<ModuleStateProps> = ({ id, date, state, baseRedirectUrl, customerAddUrl, type, warning, description }) => {
	const navigate = useNavigate()
	const parsedDate = dateParser(new Date(date));
	const draftUrl = `${baseRedirectUrl}${customerAddUrl ? '/' + customerAddUrl : ""}/draft/${id}`;
	const patrolUrl = `${baseRedirectUrl}/in-progress/${id}${customerAddUrl ? '/' + customerAddUrl : ""}`;

	return (
		<Row key={id} className={type === 'incident' ? 'module-state module-state--incident' : 'module-state'}>
			<Col className={`d-flex flex-column justify-content-center align-items-start p-0 ps-2 ${type === 'incident' ? 'col-7' : 'col-6'}`}>
				{
					type === 'incident' &&
					<Row className='m-0 bold-text date-time font-b-incident'>
						{description}
					</Row>
				}
				<Row className='m-0 bold-text date-time'>
					<Col className='day p-0 text-end'>
						{parsedDate.getDay()}
					</Col>
					<Col className='hour p-0 ps-1 text-start'>
						{parsedDate.getHour().substring(0, 5)}
					</Col>
				</Row>
			</Col>
			<>
				{state === moduleStatus.draft && (
					<Col
						className={`${type === 'incident' ? 'col-4' : 'col-4'} px-2 status-draft bold-text`}
						onClick={() => navigate(type === 'patrol' ? patrolUrl : draftUrl)}>
						{type === 'patrol' ? labels.inProgress : labels.draftResume}
					</Col>
				)}
				{state === moduleStatus.saved && (
					<Col
						className={`${type === 'incident' ? 'col-4' : 'col-4'} px-2 status-read bold-text`}
						onClick={() => navigate(`${baseRedirectUrl}${customerAddUrl ? '/' + customerAddUrl : ""}/read/${id}`)}>
						{labels.checklistRead}
					</Col>
				)}
			</>
			{type !== 'incident' &&
				<>
					<Col className='col-2 px-2 d-flex align-items-center'>
						{warning && (
							<AlertIcon className='mx-0' />
						)}
					</Col>
				</>
			}

		</Row>
	)
}

export default ModuleState





