import { createApi } from '@reduxjs/toolkit/query/react'
import { headersCommon } from "./constants/headers"
import { Checklist, PostOneChecklistResponse, ChecklistInsertedAnswersType, ChecklistInsertedAnswersDraftType, PutOneChecklistResponse, ChecklistsResponse, FormDataAnswersToPostChecklistResponse } from './models/ChecklistResponse'
import { baseQueryWithReauth } from './queryFuncs/baseQueryWithReauth'
import { ChecklistsRequest, DeleteMediaChecklistRequest } from './models/ChecklistsRequest'

export const checklistApi = createApi({
	reducerPath: 'checklistService',
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({
		getOneChecklist: builder.query<Checklist, { shiftId: string | number, checklistId: string | number, answerId?: string | number }>({
			query: ids => ({
				url: ids.checklistId === "new" || ids.answerId === undefined ?
					`/fe/Checklists/${ids.checklistId}?shiftId=${ids.shiftId}` :
					`/fe/Checklists/${ids.checklistId}?shiftId=${ids.shiftId}&answerId=${ids.answerId}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		getChecklistQuestions: builder.mutation<Checklist, { shiftId: string | number, checklistId: string | number, answerId?: string | number }>({
			query: ({shiftId, checklistId, answerId}) => ({
				url: answerId ? `/fe/Checklists/${checklistId}/?answerId=${answerId}&shiftId=${shiftId}` : `/fe/Checklists/${checklistId}/?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		getChecklistCustomer: builder.mutation<any, void>({
			query: () => ({
				url: '/fe/Customers',
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		}),
		getAuditDetail: builder.mutation<Checklist, {checklistId: string | number, answerId?: string | number }>({
			query: ids => ({
				url: ids.checklistId === "new" || ids.answerId === undefined ?
					`/fe/Checklists/${ids.checklistId}` :
					`/fe/Checklists/${ids.checklistId}?answerId=${ids.answerId}`,
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				}
			})
		}),
		postAChecklist: builder.mutation<PostOneChecklistResponse, ChecklistInsertedAnswersType>({
			query: ({ checklistId, checklistInsertedAnswers }) => ({
				url: `/fe/Checklists/${checklistId}`,
				method: "POST",
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(checklistInsertedAnswers)
			})
		}),
		putAChecklist: builder.mutation<PutOneChecklistResponse, ChecklistInsertedAnswersDraftType >({
			query: ({ checklistId, answerId, checklistInsertedAnswers }) => ({
				url: `/fe/Checklists/${checklistId}/${answerId}`,
				method: "PUT",
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(checklistInsertedAnswers)
			})
		}),
		deleteChecklistMedia: builder.mutation<FormDataAnswersToPostChecklistResponse, DeleteMediaChecklistRequest>({
			query: ({checklistId, answerId, answerDetailId, deleteBody}) => ({
				url: `/fe/Checklists/${checklistId}/${answerId}/${answerDetailId}`,
				method: 'DELETE',
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
				body: JSON.stringify(
					deleteBody
				)
			})
		}),
		getAllAudits: builder.mutation<ChecklistsResponse, ChecklistsRequest>({
			query: ({ idCustomer, serviceAddress, internalAudit = true }) => ({
				url: !(idCustomer && serviceAddress) ? `/fe/Checklists?internalAudit=${internalAudit}` : `/fe/Checklists?idCustomer=${idCustomer}&ServiceAddress=${serviceAddress}`,
				method: "GET",
				headers: {
					...headersCommon,
					'deviceId': sessionStorage.deviceId || localStorage.deviceId || '',
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`
				},
			})
		}),
	})
})

export const { 
	useGetOneChecklistQuery,
	useGetChecklistQuestionsMutation, 
	usePostAChecklistMutation, 
	usePutAChecklistMutation, 
	useDeleteChecklistMediaMutation,
	useGetAllAuditsMutation,
	useGetAuditDetailMutation,
	useGetChecklistCustomerMutation
} = checklistApi