import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import {
	GetParcelDetailResponse,
	ParcelQuestion,
} from "features/storeManagement/services/models/ParcelsResponse";
import { dateParser } from "features/utils/dateUtils";
import "./style.scss";
import { useEffect } from "react";

interface parcelInterfaceType {
	parcel: GetParcelDetailResponse | undefined;
}

const ParcelDetails = (props: parcelInterfaceType) => {
	let index: number;
	let resultLeft: ParcelQuestion[] = [];
	let resultRight: ParcelQuestion[] = [];

	const parcelFirstName = props.parcel?.parcelRecipient.firstName || "";
	const parcelLastName = props.parcel?.parcelRecipient.lastName || "";
	const parcelName = `${parcelFirstName !== "" ? parcelFirstName + " " : ""}${
		parcelLastName !== "" ? parcelLastName : ""
	}`;

	const parcelFirstNameDelivery = props.parcel?.parcelDelivery.firstName || "";
	const parcelLastNameDelivery = props.parcel?.parcelDelivery.lastName || "";
	const parcelNameDelivery = `${
		parcelFirstNameDelivery !== "" ? parcelFirstNameDelivery + " " : ""
	}${parcelLastNameDelivery !== "" ? parcelLastNameDelivery : ""}`;

	const companyAdditionalInfo = `${
		props?.parcel?.parcelRecipient.companyName
	} ${
		props?.parcel?.parcelRecipient.additionalInfo
			? `- ${props?.parcel?.parcelRecipient.additionalInfo}`
			: ""
	}`;

	let filterArr = props?.parcel?.questions.filter(
		(el) => el.type !== labels.picture
	);

	if (filterArr) {
		index = Math.ceil(filterArr?.length / 2);
		resultLeft = filterArr.slice(0, index);
		resultRight = filterArr.slice(index);
	}

	const parcel = props?.parcel?.warehousing;

	const parts = [
		parcel?.building ? `Edificio: ${parcel.building}` : "",
		parcel?.buildingStory ? `Piano: ${parcel.buildingStory}` : "",
		parcel?.room ? `Stanza: ${parcel.room}` : "",
		parcel?.shelf ? `Scaffale: ${parcel.shelf}` : "",
		parcel?.shelfTier ? `Ripiano: ${parcel.shelfTier}` : "",
	].filter(Boolean);

	const optionStorageText = parts.join(" - ").replace(/ - $/, "");

	return (
		<div>
			<div className={`box-details-accordion p-1 mx-auto mt-2 open standard`}>
				<Container
					fluid
					className="box-modules-container base-container fade-content mt-0 p-0 mb-1"
				>
					<Row
						className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
						onClick={() => {}}
					>
						<Row className="d-flex flex-nowrap px-3 m-0 px-md-3 px-lg-3 px-xl-5">
							<Col className="datetime-box col-md-7 d-flex flex-column align-items-start px-1 align-self-center">
								<div className="d-flex col-12">
									<div className="blue-text bold-text col-md-5 col-7 align-self-center">
										{labels.recipient}
										{":"}
									</div>
									{props?.parcel?.parcelRecipient?.recipientType ===
										labels.carrier && (
										<div className="day bold-text col-md-6">
											<div className="info d-flex flex-column flex-md-row w-100 flex-wrap ">
												<div className="blue-text bold-text col-12 align-self-center">
													{props?.parcel?.parcelRecipient.companyName}
												</div>
											</div>
										</div>
									)}

									{props?.parcel?.parcelRecipient?.recipientType ===
										labels.person && (
										<div className="day bold-text col-md-6">
											<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
												<div className="blue-text bold-text col-12 align-self-center mt-1">
													{parcelName}
												</div>
												<div className="bold-text blue-text-additional col-12">
													{props?.parcel?.parcelRecipient.companyName}{" "}
													{props?.parcel?.parcelRecipient.additionalInfo
														? `- ${props?.parcel?.parcelRecipient.additionalInfo}`
														: ""}
												</div>
											</div>
										</div>
									)}
								</div>
							</Col>
						</Row>
					</Row>
				</Container>

				<Container
					fluid
					className="box-modules-container base-container fade-content mt-2 p-0"
				>
					<Row
						className="grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
						onClick={() => {}}
					>
						<div />
						<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
							<Col className="datetime-box col-7 d-flex flex-column align-items-start px-1">
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
									<div className="bold-text operator-div col-5">
										{labels.parcelDepositedBy}:
									</div>
									{props?.parcel?.parcelDelivery?.recipientType ===
										labels.carrier && (
										<div className="day bold-text col-md-6">
											<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
												<div className="bold-text col-12">
													{props?.parcel?.parcelDelivery.companyName}
												</div>
											</div>
										</div>
									)}

									{props?.parcel?.parcelDelivery?.recipientType ===
										labels.person && (
										<div className="day bold-text col-md-6">
											<div className="d-flex flex-column flex-md-row w-100 flex-wrap mb-2">
												<div className="bold-text col-12">
													{parcelNameDelivery}
												</div>
												{(props?.parcel?.parcelDelivery.companyName ||
													props?.parcel?.parcelDelivery.additionalInfo) && (
													<div className="bold-text col-12">
														{props?.parcel?.parcelDelivery.companyName}{" "}
														{props?.parcel?.parcelDelivery.additionalInfo
															? `- ${props?.parcel?.parcelDelivery.additionalInfo}`
															: ""}
													</div>
												)}
											</div>
										</div>
									)}
								</div>
								{resultLeft?.map((el, i) => (
									<div
										key={i}
										className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1"
									>
										<div className="bold-text operator-div col-5">
											{el.title + ":"}
										</div>
										<div className="day bold-text col-6">
											{el.answerDetail?.value ? el.answerDetail?.value : "-"}
										</div>
									</div>
								))}
							</Col>
							<Col className="datetime-box col-5 d-flex flex-column align-items-start px-1">
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
									<span className="bold-text col-5">
										{labels.arrivedDate + ":"}
									</span>
									<span className="day bold-text col-6">
										{dateParser(
											props?.parcel?.parcelInboundTimestamp || ""
										).getDay() +
											" " +
											dateParser(props?.parcel?.parcelInboundTimestamp || "")
												.getHour()
												.substring(0, 5)}
									</span>
								</div>
								{resultRight?.map((el, i) => (
									<div
										key={i}
										className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1"
									>
										<div className="bold-text operator-div col-5">
											{el.title + ":"}
										</div>
										<div className="day bold-text col-6">
											{el.answerDetail?.value ? el.answerDetail?.value : "-"}
										</div>
									</div>
								))}
							</Col>
						</Row>
					</Row>
					<Row
						className="grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
						onClick={() => {}}
					>
						<div />
						<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 row">
							<Col className="datetime-box d-flex flex-column align-items-start px-1">
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
									<span className="bold-text col-3">
										{labels.storageLabel + ":"}
									</span>
									<span className="day bold-text col-md-9 box_parcel_element">
										{optionStorageText}
									</span>
								</div>
							</Col>
						</Row>
						<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 row">
							<Col className="datetime-box d-flex flex-column align-items-start px-1">
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
									<span className="bold-text col-3">
										{labels.operator} {labels.porter + ":"}
									</span>
									<span className="day bold-text col-md-9 box_parcel_element">
										{props?.parcel?.operatorNameInbound}
									</span>
								</div>
							</Col>
						</Row>
					</Row>
				</Container>
			</div>
		</div>
	);
};
export default ParcelDetails;
