
import { useGetDataKeyMutation, useGetKeysMutation, useGetRiconsegnaDetailsMutation, useGetRecipientsListKeyMutation, useGetRecipientsListResponsabileKeyMutation, useGetRiconsegnaResponsabileDetailsMutation } from "features/storeManagement/services/keyManagementService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import useKeyDetailForm from "../KeyDeliveryPage/useKeyDetailForm";
import parseRecipientsKey from "../KeyDeliveryPage/KeyUtils";
import { selectPropsKeys } from "../Utils/utilsKey";
import isMobile from "ismobilejs";
import SignaturePage, { is_touch_enabled } from "components/pages/SignaturePage/SignaturePage";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import { useGetCurrentShiftMutation, useGetOneShiftMutation } from "features/storeManagement/services/shiftsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { KeyFormStateType, keyStrings } from "features/storeManagement/services/models/KeysResponse";
import ServiceParcelPage from "components/pages/ParcelItemsPage/ServiceParcelPage";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import SelectAutoComplete from "components/content/FormComponents/SelectAutoComplete";
import { radioProps, textAreaProps, textAreaPropsSegnalazione } from "components/pages/ParcelDeliveryPage/utils";
import Select from "components/content/FormComponents/Select";
import TextArea from "components/content/FormComponents/TextArea";
import Radio from "components/content/FormComponents/Radio";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import { parseSingleRecipientPerson } from "components/pages/ParcelItemNewPage/ParcelUtils";
import { AutocompleteOptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import Suspense from "features/Suspense";
import ConfirmModal from "components/content/ConfirmModal/ConfirmModal";
import "./style.scss";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import useKeyDetailResponsabileForm from "../KeyDeliveryPage/useKeyDetailResponsabileForm";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";
const KeyRiconsegnaResponsabilePage = () => {
	const { keyManagementId, keyRegistryId, customer, answerId, serviceAddress, keyRegistryManagementId } = useParams();
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showConfirmModalMain, setShowConfirmModalMain] = useState<boolean>(false);
	const [isReport, setIsReport] = useState<boolean>(false);
	const [signatureImage, setSignatureImage] = useState<string>();
	const navigate = useNavigate();
	const [answerArr, setAnswerArr] = useState<string[]>([])
	const device = isMobile(window.navigator);
	const [isTouch, setIsTouch] = useState<boolean>(is_touch_enabled() || device.tablet);
	const [popupData, setPopupData] = useState<GetRecipientsResponse | undefined>();
	const [defaultValues, setDefaultValues] = useState<AutocompleteOptionsType | undefined>();
	const [flagEmptySign, setFlagEmptySign] = useState<boolean>(false);
	const [firstTime, setFirstTime] = useState<boolean>(true);
	let isRiconsegna = true;
	let objInfoDataOperatorKey = null;
	useChangeDocumentTitle(labels.pageTitle_KeysPage);

	const [responseErrorModal, setResponseErrorModal] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	const handleResponseErrorModal = (err:FetchBaseQueryError | SerializedError | undefined) => {
		setResponseErrorModal(err)
	}

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [
		getRecipientsListResponsabileKey,
		{ data: recipientsListDataResponsabileKey, isLoading: recipientsListDataResponsabileKeyIsLoading },
	] = useGetRecipientsListResponsabileKeyMutation({
		fixedCacheKey: fixedCacheKeys.recipientsListDataKey,
	});

	const [
		getRiconsegnaResponsabileDetails,
		{ data: riconsegnaDetailsResponsabileData, isLoading: riconsegnaResponsabileDataIsLoading, error: riconsegnaResponsabileDataError },
	] = useGetRiconsegnaResponsabileDetailsMutation({
		fixedCacheKey: fixedCacheKeys.getRecipientKey,
	});

	useEffect(()=>{
		if(keyManagementId && keyRegistryId && answerId && !showConfirmModal && (popupData || firstTime)){
			getRecipientsListResponsabileKey({ keyManagementId, keyRegistryId })
			getRiconsegnaResponsabileDetails({ keyManagementId: keyManagementId.toString(), keyRegistryManagementId: Number(keyRegistryManagementId), answerId: Number(answerId) })
		}
	}, [showConfirmModal])
	useEffect(() => {
		setFormData((oldState: KeyFormStateType) => {
			return { ...oldState, [`${keyStrings.sign} `]: [signatureImage || ""] };
		});
	}, [signatureImage]);

	const redirectFunction = () => {
		handleSubmit()
	};

	const {
		handleSubmit,
		data: keyDetailData,
		isLoading: keyIsLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
		form,
	} = useKeyDetailResponsabileForm(riconsegnaDetailsResponsabileData?.keyRegistryModelOut.anomalies, isReport, isTouch, isRiconsegna, keyRegistryId);

	useEffect(() => {
		let arrDescription: string[] = []
		if (riconsegnaDetailsResponsabileData) {
			riconsegnaDetailsResponsabileData?.keyRegistryModelOut.anomalies?.map((anomalia) => {
				arrDescription.push(anomalia.description)
			})
			setFirstTime(false)
		}
		setAnswerArr(arrDescription)
	}, [riconsegnaDetailsResponsabileData])

	useEffect(() => {
		if (!showConfirmModal) {
			popupData && setDefaultValues(parseSingleRecipientPerson(popupData))
		}
	}, [showConfirmModal, popupData]);

	if (riconsegnaDetailsResponsabileData) {
		objInfoDataOperatorKey = {
			firstname: riconsegnaDetailsResponsabileData.keyRecipient?.firstName?.toString(),
			lastname: riconsegnaDetailsResponsabileData.keyRecipient?.lastName?.toString(),
			OperatorName: riconsegnaDetailsResponsabileData.operatorNameOutbound?.toString(),
			time: riconsegnaDetailsResponsabileData.outBoundTimeStamp?.toString(),
			companyName: riconsegnaDetailsResponsabileData.keyRecipient?.companyName?.toString(),
			additionalInfo: riconsegnaDetailsResponsabileData.keyRecipient?.additionalInfo?.toString(),
			deliveredNote: riconsegnaDetailsResponsabileData.deliveredNote?.toString(),
		}
	}

	useEffect(() => {
		if (formData[`${keyStrings.sign} `] && formData[`${keyStrings.sign} `][0] !== undefined){
			setFlagEmptySign(true)
		}
		setFormData({
			...formData,
			[`${keyStrings.keyRecipientPersonId} `]: [],
			[`${keyStrings.sign} `]: []
		})
		setDefaultValues(undefined)
	}, [formData[`${keyStrings.isSamePerson} `]])

	useEffect(() => {
		setFormData({
			[`${keyStrings.isSamePerson} `]: [labels.yesLabel],
			[`${keyStrings.reportNotes} `]: []
		})
		setDefaultValues(undefined)
	}, [isReport])

	const isShowMediaQuestions = riconsegnaDetailsResponsabileData && 
	riconsegnaDetailsResponsabileData.keyRegistryModelOut && 
	riconsegnaDetailsResponsabileData.keyRegistryModelOut.questions &&
	riconsegnaDetailsResponsabileData.keyRegistryModelOut.questions.length > 0;


	return (
		<ErrorBoundary errorMessage={riconsegnaResponsabileDataError || responseErrorModal || error}>
			<Container className="box-details-container key-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={riconsegnaResponsabileDataIsLoading || recipientsListDataResponsabileKeyIsLoading} fullPage>
					<ServiceParcelPage
						showConfirmModal={showConfirmModal}
						setShowConfirmModal={setShowConfirmModal}
						isFromKey={true}
						keyManagementId={keyManagementId}
						keyRegistryId={keyRegistryId}
						setPopupData={setPopupData}
						handleResponseErrorModal = {handleResponseErrorModal}
					/>

					{objInfoDataOperatorKey &&
						<BoxDetails
							flagKeyResponsabile={true}
							keyData={riconsegnaDetailsResponsabileData?.keyRegistryModelOut}
							setIsReport={setIsReport}
							isReport={isReport}
							objInfoDataOperatorKey={objInfoDataOperatorKey}
						/>
					}

					{isShowMediaQuestions &&
					riconsegnaDetailsResponsabileData.keyRegistryModelOut.questions.filter((el) => el.type === labels.picture).map((question: Question) => (
						question.answerDetail?.medias && question.answerDetail?.medias.length > 0 && (
							<div key={question.id} className="box-details-container px-sm-5 px-0">
								<MediaPicker
									read={true}
									mediaArray={question.answerDetail?.medias}
									mediaTitle={question.title}
									deleteIsLoading={false}
									showDeleteConfirmModal={false}
									deleteAJournalMedia={() => {}}
									setShowConfirmModal={() => {}}
									setMediaArray={() => {}}
									setShowDeleteConfirmModal={() => {}}
									setMediaIdToDel={() => {}}
									flagGiornale={false}
								/>
							</div>
						)
					)
					)}
					<Form
						ref={form}
						noValidate
						onSubmit={handleSubmit}
						validated={false}
						id="keyForm"
						className="box-details-container px-sm-5 px-0"
					>
						{!isReport && recipientsListDataResponsabileKey ? (
							<div className="container_selectKey">
								<Row className="m-0">
									<Col className="items-container px-0 mt-4 mb-2">
										<ModulePageTitle
											title={labels.KeySameDeliveredPerson}
										/>
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 my-2">
										<Radio
											{...radioProps({ formData, setFormData, validated, title: labels.keySubtitleRadioProps })}
										/>
									</Col>
								</Row>

								{formData[`${keyStrings.isSamePerson} `] && formData[`${keyStrings.isSamePerson} `][0] === labels.noLabel &&
									<div>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.keyPersonLabel} />
											</Col>
										</Row>
										<Row className="m-0">
											<SelectAutoComplete
												formData={formData}
												setFormData={setFormData}
												defaultValue={defaultValues || null}
												enabled={true}
												isClearable={true}
												options={parseRecipientsKey(recipientsListDataResponsabileKey)}
												name={labels.keyRecipientPersonId}
												title={""}
												subtitle={labels.textAggiungiNominativo}
												isMandatory={false}
												validated={false}
												newRecipientButton={true}
												clickedButton={() => setShowConfirmModal(true)}
												className="select-width-50"
											/>
										</Row>
									</div>
								}
								<Row className="m-0 mt-3">
									<Col className="items-container px-0 my-0">
										<ModulePageTitle title={labels.keyNotesRiconsegna} />
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 mb-3 mt-0">
										<TextArea

											{...textAreaProps({ formData, setFormData, validated })}
										/>
									</Col>
								</Row>

								{isTouch && (
									<>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.withdrawingPersonSignature} />
											</Col>
										</Row>
										<SignaturePage
											nameClass="mt-0"
											signatureImage={setSignatureImage}
											flagEmptySign={flagEmptySign}
											setFlagEmptySign={setFlagEmptySign}
										/>
									</>
								)}
							</div>
						) : (
							<Container>
								{answerArr.length > 0 &&
									<div>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.keyReport} />
											</Col>
										</Row>
										<Row className="m-0">
											<Col className="items-container px-0 mb-3 mt-0">
												<Select
													{...selectPropsKeys({ formData, setFormData, validated, answerArr })}
												/>
											</Col>
										</Row>
									</div>
								}
								<Row className="m-0 mt-3">
									<Col className="items-container px-0 my-0">
										<ModulePageTitle title={labels.reportNotes} />
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 mb-3 mt-0">
										<TextArea
											{...textAreaPropsSegnalazione({ formData, setFormData, validated })}
										/>
									</Col>
								</Row>
							</Container>
						)}

						<Row className="m-0">
							<Col className="items-container px-0 my-4">
								<Button
									type="button"
									variant="green"
									className="submit-and-draft-buttons"
									onClick={() => {
										const url = `/keys/customer/${customer}/keyManagementId/${keyManagementId}/serviceAddress/${serviceAddress}`;
										navigate(url);
									}}
									disabled={keyIsLoading}
								>
									{labels.cancel}
								</Button>
								<Button
									type={"button"}
									id={"submit"}
									form="keyForm"
									variant="green"
									className="submit-and-draft-buttons"
									disabled={keyIsLoading || !isFormValid}
									onClick={() => {
										if (isReport) {
											setShowConfirmModalMain(true)
										} else {
											handleSubmit()
										}
									}}>
									{keyIsLoading ? <Spinner /> : labels.save}
								</Button>
							</Col>
						</Row>
						{isReport &&
							<ConfirmModal
								idForm="keyForm"
								headerMessage={labels.keyTitleAlertRiconsegna}
								bodyMessage={labels.keyBodyAlertRiconsegna}
								showConfirmModal={showConfirmModalMain}
								setShowConfirmModal={setShowConfirmModalMain}
								isLoading={keyIsLoading}
								isFormValid={isFormValid}
								onConfirm={redirectFunction}
								confirmButtonText={labels.textButtonKeyModal}
							/>
						}
					</Form>
				</Suspense>
			</Container>
		</ErrorBoundary>

	)
}

export default KeyRiconsegnaResponsabilePage