import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import Suspense from "features/Suspense";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { labels } from "features/locale/it-it";
import {
	Journal,
	JournalLogT,
} from "features/storeManagement/services/models/JournalResponse";
import EmptyPageNote from "components/content/EmptyPageNote";
import ModulesJournal from "components/modules/Journal/ModuleJournal";

import "./style.scss";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import { dateParser } from "features/utils/dateUtils";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import {
	useGetJournalLogsQuery,
	useGetOneJournalLogDetailMutation,
} from "features/storeManagement/services/journalService";
import { JournalMediaList } from "components/modules/Journal/JournalForm/JournalMediaList";
import ImageModal from "components/content/modals/AlertModal/ImageModal";
import VideoModal from "components/content/modals/AlertModal/VideoModal";
import { JournalKeys } from "components/modules/Journal/JournalForm/JournalConstants";

const JournalItemPage: React.FC = () => {
	const { shiftId, draftId, journalLogId } = useParams();
	const {
		journalUpdateTitle,
		journalStartTitle,
		previousJournals,
		startNewJournal,
		updateJournal,
	} = labels;
	const navigate = useNavigate();

	useChangeDocumentTitle(labels.pageTitle_JournalItemsPage);

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{ data: currentShiftData, isLoading: currentShiftIsLoading },
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	useEffect(() => {
		if (isOperatoreEsterno) {
			getCurrentShift();
		} else if (shiftId) {
			getOneShift(shiftId);
		}
	}, []);

	const journalDataLogs: JournalLogT[] =
		shiftData?.journal?.logs || currentShiftData?.journal?.logs || [];
	const journalData: Journal | undefined =
		shiftData?.journal || currentShiftData?.journal;
	const parsedDate = dateParser(
		new Date(journalData?.journalLog?.lastUpdateDate || "")
	);

	const [
		getOneJournalLogDetail,
		{ data: journalGetData, isLoading: journalIsLoading, error: journalError },
	] = useGetOneJournalLogDetailMutation();

	useEffect(() => {
		if (journalData && journalData?.journalLog?.id) {
			getOneJournalLogDetail({
				shiftId: shiftId || "",
				journalId: Number(journalData.id) || 1,
				journalLogId: journalData?.journalLog.id,
			});
		}
	}, [journalData]);

	const [showPreview, setShowPreview] = useState(false);
	const [showVideoPreview, setShowVideoPreview] = useState(false);
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [base64Img, setBase64Img] = useState<string | null>(null);
	const [mediaName, setMediaName] = useState<string | null>(null);

	const togglePreview = () => {
		return setShowPreview(!showPreview);
	};

	return (
		<Container className="journal-items-page-container p-0 px-4 mb-0 mt-4 journal-container">
			<Suspense
				isLoading={shiftIsLoading || currentShiftIsLoading || journalIsLoading}
				fullPage
			>
				{showPreview && journalGetData && (
					<ImageModal
						path={imagePreview}
						setShowPreview={setShowPreview}
						showFlag={showPreview}
						base64String={base64Img}
						mediaName={mediaName}
					/>
				)}
				{showVideoPreview && (
					<VideoModal
						path={imagePreview}
						setShowPreview={setShowVideoPreview}
						showFlag={showVideoPreview}
					/>
				)}
				{journalGetData === undefined && (
					<Row>
						<Col className="px-0 my-0 items-container align-center bold-text text-center">
							{journalStartTitle}
						</Col>
					</Row>
				)}

				<Container className="form-item-container mb-3 pl-0">
					{journalGetData && (
						<Row className="mx-0 p-0">
							<p className="title-journal font-size-18 p-0">
								{labels.journalFormReadMode}
							</p>
							<p className="p-0 journal-text">{journalGetData.journalText}</p>
							{journalGetData?.medias && journalGetData?.medias.length > 0 && (
								<p className="title-journal font-size-18 p-0">
									{labels.viewMedia}
								</p>
							)}
						</Row>
					)}

					{journalGetData?.medias &&
						journalGetData?.medias.map((media, i) => {
							return (
								<JournalMediaList
									key={i}
									name={media.path}
									setJournalPreview={
										media.type === JournalKeys.videoType
											? setShowVideoPreview
											: setShowPreview
									}
									showPreview={
										media.type === JournalKeys.videoType
											? showVideoPreview
											: showPreview
									}
									setImagePreview={setImagePreview}
									chiave={media.mediaName}
									flagBin={false}
									base64String={media.mediaValue}
									setBase64String={setBase64Img}
									mediaName={media.mediaName}
									setMediaName={setMediaName}
								></JournalMediaList>
							);
						})}
				</Container>
				{journalData?.journalLog ? (
					<>
						<Row>
							<Col className="items-container align-center mt-4 mb-2">
								{(currentShiftData?.modulesExecutable || shiftData?.modulesExecutable) && 
								<Button
									variant="green"
									className="journal-btn"
									onClick={() =>
										navigate(
											`/shift/${shiftId}/journal/${journalData.id}/draft/${journalData.journalLog.id}`
										)
									}
								>
									<span>{updateJournal}</span>
								</Button>
								}
							</Col>
						</Row>
						<Row className="bold-text date-time">
							<Col className="d-flex align-center m-0 p-0">
								{parsedDate.getDay() +
									" " +
									parsedDate.getHour().substring(0, 5)}
							</Col>
						</Row>
					</>
				) : (
					<Row>
						<Col className="items-container align-center my-4">
							{(currentShiftData?.modulesExecutable || shiftData?.modulesExecutable) &&
							<Button
								variant="green"
								className="journal-btn"
								onClick={() =>
									navigate(`/shift/${shiftId}/journal/${journalData?.id}/new`)
								}
							>
								<span>{startNewJournal}</span>
							</Button>
							}
						</Col>
					</Row>
				)}
				{journalData?.shareForward && (
					<>
						<Row>
							<Col className="items-container py-1 ps-0 bold-text my-3 mx-4">
								{previousJournals}
							</Col>
						</Row>
						{journalDataLogs && journalDataLogs.length > 0 ? (
							<Row>
								{journalDataLogs.map((log: JournalLogT) => {
									return (
										<ModulesJournal
											key={log.id}
											id={log.id}
											date={log.completedAt as Date}
											baseRedirectUrl={`/shift/${shiftId}/journal/${journalData.id}/${log.id}`}
											name={log.journalCausalText}
										/>
									);
								})}
							</Row>
						) : (
							<Row>
								<Col className="items-container my-3 mx-4">
									<EmptyPageNote
										note={labels.noJournalsYet}
										isLoading={shiftIsLoading}
										className="empty-page-note"
									/>
								</Col>
							</Row>
						)}
					</>
				)}
			</Suspense>
		</Container>
	);
};

export default JournalItemPage;
