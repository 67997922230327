export enum Color {
	"blue"=  "#163072",
	"themeBlue"= "#2c7be5",
	"indigo"=  "#727cf5",
	"purple"=  "#6b5eae",
	"pink"=  "#ff679b",
	"red"=  "#e63757",
	"orange"=  "#fd7e14",
	"yellow"=  "#f5803e",
	"green"=  "#00A898",
	"teal"=  "#02a8b5",
	"cyan"=  "#27bcfd"
}

export enum MediaType {
	"image" = "image/jpeg",
	"video" = "video/mp4"
}

export enum Regex {
	"Email" = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
	"FiscalCode" = "^(?:[B-DF-HJ-NP-TV-Z](?:[AEIOU]{2}|[AEIOU]X)|[AEIOU]{2}X|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[\\dLMNP-V][1-9MNP-V]|[1-9MNP-V][0L]))[A-Z]$",
	"VatCode" = "^((AT)?U[0-9]{8}|(BE)?0?[0-9]{9}|(BG)?[0-9]{9,10}|(CHE)?-[0-9]{3}\.[0-9]{3}\.[0-9]{3} (TVA|IVA|MWST)|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z}|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]{7}[A-Z]{1,2}|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{10}01|(SI)?[0-9]{8}|(SK)?[0-9]{10})$"
}

export const isEmptyObject = (obj:Object) => {
	return Object.keys(obj).length === 0;
}

export enum guestAdmissionStatus {
	"entry" = "Entry",
	"exit" = "Exit",
	"read" = "Read",
	"disabled" = "Disabled"
}