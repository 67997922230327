import "./style.scss";
import React, { useEffect, useRef, useState } from "react";

import { Button, Container } from "react-bootstrap";
import ChecklistForm from "components/modules/Checklist/ChecklistForm";
import ChecklistReport from "components/modules/Checklist/ChecklistReport";
import ModulePageTitle from "components/modules/ModulePageTitle";
import ErrorBoundary from "features/ErrorBoundary";
import Suspense from "features/Suspense/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetAuditDetailMutation } from "features/storeManagement/services/checklistService";
import { useLocation, useParams } from "react-router-dom";
import { labels } from "features/locale/it-it";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

const AuditPage = () => {
	const { auditId, draftId } = useParams();
	const location = useLocation();
	const [errorAudit, setErrorAudit] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	let titlePage = '';

	if (location.pathname.includes("checklist")) {
		titlePage = labels.pageTile_ChecklistPage;
	} else {
		titlePage = labels.pageTile_AuditPage;
	}

	useChangeDocumentTitle(titlePage);

	const [
		getAuditDetails,
		{
			data: auditDetails,
			isLoading: auditDetailsIsLoading,
			error: auditDetailsError,
		},
	] = useGetAuditDetailMutation({ fixedCacheKey: fixedCacheKeys.auditDetail });

	useEffect(() => {
		const params = auditId
			? {
				checklistId: auditId,
				answerId: draftId,
			  }
			: null;

		params && getAuditDetails(params);
	}, [auditId, draftId]);

	return (
		<ErrorBoundary errorMessage={auditDetailsError || errorAudit}>
			<Container className="checklist-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={auditDetailsIsLoading} fullPage>
					<>
						{auditDetails && (
							<ModulePageTitle
								title={auditDetails.description}
								date={auditDetails.lastUpdateDate}
								isPrint={auditDetails.completedAt ? true : false}
								author={auditDetails.createdBy}
							/>
						)}
						{auditDetails && auditDetails.completedAt ? (
							<ChecklistReport
								sections={auditDetails && auditDetails.sections}
							/>
						) : (
							<ChecklistForm
								sections={auditDetails && auditDetails.sections}
								isExecutable={ true }
								setError = {setErrorAudit}
							/>
						)}
					</>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default AuditPage;
