import "./style.scss";
import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import ModuleState from "components/modules/ModuleState";
import ModulePageTitle from "components/modules/ModulePageTitle";
import EmptyPageNote from "components/content/EmptyPageNote";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { Incident } from "features/storeManagement/services/models/IncidentResponse";
import { useNavigate, useParams } from "react-router-dom";
import { useIsomorphicLayoutEffect } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useGetAllIncidentsMutation } from "features/storeManagement/services/IncidentService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";

const ResponsabileIncidentItemsPage = () => {
	const { customer, contract, position,serviceAddress, incidentId } = useParams();
	const navigate = useNavigate();
	useChangeDocumentTitle(labels.pageTile_IncindentManagementItemsPage);

	const [
		getAllIncidents,
		{
			data: incidents,
			isLoading: incidentsIsLoading,
			isError: incidentsIsError,
			error: incidentsError,
		},
	] = useGetAllIncidentsMutation({
		fixedCacheKey: fixedCacheKeys.getAllIncidents,
	});

	useEffect(() => {
		if (customer && !incidents && !incidentsIsLoading) {
			getAllIncidents({
				idCustomer: customer,
				serviceAddress: serviceAddress
			});
		}
	}, [customer, contract, position, incidents, incidentsIsLoading]);

	let incident = incidents?.items.find(
		(incident) => incident.id.toString() === incidentId
	);

	const nuovaSegnalazioneUrl = `/incident/${incidentId}/customer/${customer}/serviceAddress/${serviceAddress}/new`;

	return (
		<ErrorBoundary errorMessage={incidentsError}>
			<Container className="incident-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={incidentsIsLoading} fullPage>
					<Row>
						<Col className="items-container px-0 my-0">
							<ModulePageTitle
								title={incident?.description || labels.incidentTitle}
							/>
						</Col>
					</Row>
					{!incidentsIsLoading && (
						<Row className="sticky-button-container mt-4">
							<Col className="items-container p-0 my-0">
								<Button
									variant="green"
									className="start-new-draft-button--start"
									disabled={incidentsIsLoading}
									onClick={() => navigate(nuovaSegnalazioneUrl)}
								>
									<span>{labels.startNewReport}</span>
								</Button>
							</Col>
						</Row>
					)}
					{incident &&
						(incident.answers && incident.answers.length > 0 ? (
							<Row>
								<Col className="items-container">
									{incident.answers.map((item: Incident) => {
										return (
											<ModuleState
												key={item.id}
												id={item.id}
												date={item.lastUpdateDate}
												state={item.status}
												baseRedirectUrl={`/incident/${incidentId}/customer/${customer}/serviceAddress/${serviceAddress}`}
												type={"incident"}
												description={item.description}
											/>
										);
									})}
								</Col>
							</Row>
						) : (
							<Row>
								<Col className="items-container">
									<EmptyPageNote
										note={labels.noIncidentYet}
										isLoading={incidentsIsLoading}
										className="empty-page-note"
									/>
								</Col>
							</Row>
						))}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ResponsabileIncidentItemsPage;
