import BoxDetails from "components/content/BoxDetails/BoxDetails"
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetDataKeyMutation, useGetKeysMutation, useGetRiconsegnaDetailsMutation, useGetRecipientsListKeyMutation } from "features/storeManagement/services/keyManagementService";
import { useGetCurrentShiftMutation, useGetOneShiftMutation } from "features/storeManagement/services/shiftsService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import SelectAutoComplete from "components/content/FormComponents/SelectAutoComplete";
import useKeyDetailForm from "./useKeyDetailForm";
import parseRecipientsKey from "./KeyUtils";
import SignaturePage, { is_touch_enabled } from "components/pages/SignaturePage/SignaturePage";
import { textAreaProps, textAreaPropsSegnalazione } from "components/pages/ParcelDeliveryPage/utils";
import TextArea from "components/content/FormComponents/TextArea";
import { KeyFormStateType, anomalies, keyStrings } from "features/storeManagement/services/models/KeysResponse";
import Select from "components/content/FormComponents/Select";
import { selectPropsKeys } from "../Utils/utilsKey";
import ServiceParcelPage from "components/pages/ParcelItemsPage/ServiceParcelPage";
import isMobile from "ismobilejs";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import { parseSingleRecipientPerson } from "components/pages/ParcelItemNewPage/ParcelUtils";
import { AutocompleteOptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import Suspense from "features/Suspense";
import ConfirmModal from "components/content/ConfirmModal/ConfirmModal";
import "./style.scss";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";
import { renderTheFormComponent } from "components/content/FormComponents/renderTheFormComponent";

const KeyDeliveryPage = () => {
	const { shiftId, keyManagementId, keyRegistryId } = useParams();
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showConfirmModalMain, setShowConfirmModalMain] = useState<boolean>(false);
	const [isReport, setIsReport] = useState<boolean>(false);
	const [signatureImage, setSignatureImage] = useState<string>();
	const navigate = useNavigate();
	const [answerArr, setAnswerArr] = useState<string[]>([])
	const device = isMobile(window.navigator);
	const [isTouch, setIsTouch] = useState<boolean>(is_touch_enabled() || device.tablet);
	const [popupData, setPopupData] = useState<GetRecipientsResponse | undefined>();
	const [defaultValues, setDefaultValues] = useState<AutocompleteOptionsType | undefined>();
	const [firstTime, setFirstTime] = useState<boolean>(true);

	let isRiconsegna = false
	useChangeDocumentTitle(labels.pageTitle_KeysPage);

	const [responseErrorModal, setResponseErrorModal] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	const handleResponseErrorModal = (err:FetchBaseQueryError | SerializedError | undefined) => {
		setResponseErrorModal(err)
	}

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;
	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{ data: currentShiftData, isLoading: currentShiftIsLoading },
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		getDataKey,
		{ data: infoDataKey, isLoading: infoDataKeyIsLoading, error: infoDataError },
	] = useGetDataKeyMutation({
		fixedCacheKey: fixedCacheKeys.infoDataKey,
	});

	const [
		getRecipientsListKey,
		{ data: recipientsListDataKey, isLoading: recipientsListDataKeyIsLoading },
	] = useGetRecipientsListKeyMutation({
		fixedCacheKey: fixedCacheKeys.recipientsListDataKey,
	});

	useEffect(() => {
		if (shiftId && keyManagementId !== undefined && keyRegistryId !== undefined) {
			getDataKey({ keyManagementId: keyManagementId, keyRegistryId: keyRegistryId, shiftId: shiftId })
		}
	}, [])

	useEffect(() => {
		if (keyManagementId && keyRegistryId && shiftId && !showConfirmModal && (popupData || firstTime)){
			getRecipientsListKey({ keyManagementId: keyManagementId, keyRegistryId: keyRegistryId, shiftId: shiftId })
		}
	}, [showConfirmModal, infoDataKey])

	useEffect(() => {
		setFormData((oldState: KeyFormStateType) => {
			return { ...oldState, [`${keyStrings.sign} `]: [signatureImage || ""] };
		});
	}, [signatureImage]);

	useEffect(() => {
		setFormData({
			[`${keyStrings.isSamePerson} `]: [labels.yesLabel],
			[`${keyStrings.reportNotes} `]: []
		})
		setDefaultValues(undefined)
	}, [isReport])

	const {
		handleSubmit,
		data: keyDetailData,
		isLoading: keyIsLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
		form,
		serviceError
	} = useKeyDetailForm(infoDataKey?.anomalies, isReport, isTouch, isRiconsegna, infoDataKey?.keyRegistry?.id.toString());

	useEffect(() => {
		let arrDescription: string[] = []
		if (infoDataKey) {
			infoDataKey?.anomalies?.map((anomalia) => {
				arrDescription.push(anomalia.description)
			})
			setFirstTime(false)
		}
		setAnswerArr(arrDescription)
	}, [infoDataKey])

	useEffect(() => {
		if (!showConfirmModal) {
			popupData && setDefaultValues(parseSingleRecipientPerson(popupData))
		}
	}, [showConfirmModal, popupData]);

	const renderedQuestionComponents = infoDataKey?.questions?.map(
		(question: Question) => {
			const theCompenentToBeRendered =
				renderTheFormComponent({
					...question,
					setFormData: setFormData,
					formData: formData,
					sectionAndQuestionId: `${question.id}`,
					isExecutable: true,
					validated,
					fullSize: true
				});
			return (
				theCompenentToBeRendered && (
					<Row key={question.id}>
						<Col className="items-container px-0 my-3 new-item-container">
							{theCompenentToBeRendered}
						</Col>
					</Row>
				)
			);
		}
	);

	const redirectFunction = () => {
		handleSubmit()
	};

	return (	
		<ErrorBoundary errorMessage={infoDataError || responseErrorModal || error}>
			<Container className="box-details-container key-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={currentShiftIsLoading || recipientsListDataKeyIsLoading || shiftIsLoading || infoDataKeyIsLoading} fullPage>
					<ServiceParcelPage
						showConfirmModal={showConfirmModal}
						setShowConfirmModal={setShowConfirmModal}
						isFromKey={true}
						keyManagementId={keyManagementId}
						keyRegistryId={keyRegistryId}
						setPopupData={setPopupData}
						handleResponseErrorModal = {handleResponseErrorModal}
					/>

					<BoxDetails
						shift={shiftData || currentShiftData}
						keyDataConsegna={infoDataKey}
						setIsReport={setIsReport}
						isReport={isReport}
					/>
					<Form
						ref={form}
						noValidate
						validated={false}
						id="keyForm"
						className="box-details-container px-sm-5 px-0"
					>
						{!isReport && recipientsListDataKey ? (
							<div className="container_selectKey">
								<Row className="m-0">
									<Col className="items-container px-0 mt-4 mb-2">
										<ModulePageTitle title={labels.keyStatusConsegnateA} />
									</Col>
								</Row>
								<Row className="m-0">
									<SelectAutoComplete
										formData={formData}
										setFormData={setFormData}
										defaultValue={defaultValues || null}
										enabled={true}
										isClearable={true}
										options={parseRecipientsKey(recipientsListDataKey)}
										name={labels.keyRecipientPersonId}
										title={!infoDataKey?.keyRegistry?.authorizationRequired ? labels.keyDeliveredTo : labels.keyDeliveredAuthorizationRequiredTo}
										subtitle={labels.textAggiungiNominativo}
										isMandatory={false}
										validated={false}
										newRecipientButton={!infoDataKey?.keyRegistry?.authorizationRequired}
										clickedButton={() => setShowConfirmModal(true)}
										className="select-width-50"
										titleClassName="title-width mb-0"
										selectClassName={infoDataKey?.keyRegistry?.authorizationRequired ? "standard_select_width" : ""}
									/>
								</Row>
								<Row className="m-0 mt-3">
									<Col className="items-container px-0 my-0">
										<ModulePageTitle title={labels.KeyNotesDelivery} />
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 mb-3 mt-0">
										<TextArea
											{...textAreaProps({ formData, setFormData, validated })}
										/>
									</Col>
								</Row>

								{isTouch && (
									<>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.withdrawingPersonSignature} />
											</Col>
										</Row>
										<SignaturePage
											nameClass="mt-0"
											signatureImage={setSignatureImage}
										/>
									</>
								)}

								<Container>
									{renderedQuestionComponents}
								</Container>

							</div>
						) : (
							<Container>
								{
									answerArr.length > 0 &&
									<div>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.keyReport} />
											</Col>
										</Row>
										<Row className="m-0">
											<Col className="items-container px-0 mb-3 mt-0">
												<Select
													{...selectPropsKeys({ formData, setFormData, validated, answerArr })}
												/>
											</Col>
										</Row>
									</div>
								}
								<Row className="m-0 mt-3">
									<Col className="items-container px-0 my-0">
										<ModulePageTitle title={labels.reportNotes} />
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 mb-3 mt-0">
										<TextArea
											{...textAreaPropsSegnalazione({ formData, setFormData, validated })}
										/>
									</Col>
								</Row>
							</Container>
						)}

						<Row className="m-0">
							<Col className="items-container px-0 my-4">
								<Button
									type="button"
									variant="green"
									className="submit-and-draft-buttons"
									onClick={() => {
										const url = `/shift/${shiftId}/keys`;
										navigate(url);
									}}
									disabled={keyIsLoading}
								>
									{labels.cancel}
								</Button>
								<Button
									type= {"button"}
									id={"submit"}
									form="keyForm"
									variant="green"
									className="submit-and-draft-buttons"
									disabled={keyIsLoading || !isFormValid}
									onClick={() => {
										if (isReport) {
											setShowConfirmModalMain(true)
										}else {
											handleSubmit()
										}
									}}
								>
									{keyIsLoading ? <Spinner /> : labels.save}
								</Button>
							</Col>
						</Row>
						{
							isReport &&
							<ConfirmModal
								idForm="keyForm"
								headerMessage={labels.modalJournalTitle}
								bodyMessage={labels.keyBodyAlertDelivery}
								showConfirmModal={showConfirmModalMain}
								setShowConfirmModal={setShowConfirmModalMain}
								isLoading={keyIsLoading}
								isFormValid={isFormValid}
								onConfirm={redirectFunction}
								confirmButtonText={labels.textButtonKeyModal}
							/>
						}
					</Form>
				</Suspense>
			</Container>
		</ErrorBoundary>
	)
}

export default KeyDeliveryPage