import React, { FC } from 'react';
import Flex from 'theme/components/common/Flex';
import { IAssociation } from 'features/types/user/profile';

const Association: FC<IAssociation> = function Association({ image, title, description }) {
	return (
		<Flex alignItems="center" className="position-relative mb-2">
			<img className="me-2 rounded-3" src={image} width={50} alt="" />
			<div>
				<h6 className="fs-0 mb-0">
					<a className="stretched-link" href="#!">
						{title}
					</a>
				</h6>
				<p className="mb-1">{description}</p>
			</div>
		</Flex>
	)
};

export default Association;
