import { labels } from "features/locale/it-it";
import { Form } from "react-bootstrap";
import "./style.scss";
const KeyNoResult = ({ label }: { label: string }) => {
	return (
		<div className="container-key-label">
			<div className="container__elements">
				<div className="container__elements__label">
					<Form.Label htmlFor="key-label" className="key-label mb-0">
						{label}
					</Form.Label>
				</div>
			</div>
		</div>
	);
};

export default KeyNoResult;
