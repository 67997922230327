import { createContext } from 'react'
import { IAppContext } from 'features/types'
import { settings } from '../../config'



export const AppContext = createContext<IAppContext>({
	config: settings,
	setConfig: () => {},
	configDispatch: () => {}
})

export const ProductContext = createContext<any>({ products: [] })
export default AppContext
