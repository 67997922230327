import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetOnePatrolQuery } from 'features/storeManagement/services/patrolsService'

const usePatrolDetailsPage = () => {
	const { shiftId, patrolId, draftId } = useParams()

	const { data: patrolData, isLoading: patrolIsLoading, error: patrolError }
		= useGetOnePatrolQuery(
			{ shiftId: shiftId || "", patrolId: patrolId || "", draftId: draftId || "" },
			{ refetchOnMountOrArgChange: true }
		)

	return { patrolData, patrolIsLoading, patrolError }
}

export default usePatrolDetailsPage