export type ModuleStateProps = {
	id: number
	date: Date | string
	state?: moduleStatus
	baseRedirectUrl: string
	customerAddUrl?: string
	type?: string
	warning?: boolean,
	description?: string
}

export enum moduleStatus {
    'draft' = 'Draft',
	'saved' = 'Saved'
}