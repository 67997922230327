import { OptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import { KeyRecipientT } from "features/storeManagement/services/models/KeysResponse";

export const parseRecipientsKey = (
	recipients: KeyRecipientT[]
): OptionsType => {
	const returnRecipients = recipients.map(
		(recipient: KeyRecipientT) => {
			//option value in field_field_id format to be able to research more than just the name
			const value: string = `${
				recipient.companyName ? recipient.companyName + "_" : ""
			}${recipient.lastName}${
				recipient.firstName ? "_" + recipient.firstName : ""
			}${recipient.additionalInfo ? "_" + recipient.additionalInfo : ""}_${
				recipient.id
			}`;

			const label: string = `${recipient.lastName} ${
				recipient.firstName ? recipient.firstName : ""
			}`;
			const company: string = recipient.companyName || "";
			const additionalInfo: string = recipient.additionalInfo || "";
			return {
				value,
				label,
				company,
				additionalInfo,
			};
		}
	);

	return returnRecipients;
};

export default parseRecipientsKey;

