import { useContext, useEffect } from "react"
import { getValuesForUser } from 'features/utils/userRoleUtils'
import AppContext from "theme/context/Context"

const is = require('is_js')

export const useLayout = () => {
	const HTMLClassList = document.getElementsByTagName('html')[0].classList

	useContext(AppContext)

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows')
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome')
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox')
		}
	}, [HTMLClassList])
	
	const homePageForUser = getValuesForUser().getHomePageForUser()

	return { homePageForUser }
}