import { useNavigate, useParams } from "react-router";
import { Container } from "react-bootstrap";
import Suspense from "features/Suspense";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { useGetKeysMutation } from "features/storeManagement/services/keyManagementService";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import { useEffect, useState } from "react";
import "./style.scss";
import KeySearchComponent from "./KeySearchComponent";
import KeyResultsComponent from "./KeyResultsComponent";
import KeyNoResult from "./KeyNoResult";
import { StoredDeliveredKeysT } from "features/storeManagement/services/models/KeysResponse";
import { labels } from "features/locale/it-it";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";

const KeysItemPage = () => {
	const { shiftId } = useParams();
	useChangeDocumentTitle(labels.pageTitle_KeysItemsPage);
	const [filteredData, setFilteredData] = useState<StoredDeliveredKeysT[]>([]);
	const [isStorico, setIsStorico] = useState<boolean>(false);
	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{ data: currentShiftData, isLoading: currentShiftIsLoading },
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		getKeys,
		{ data: getKeysData, isLoading: getKeysIsLoading, error: getKeysError },
	] = useGetKeysMutation({ fixedCacheKey: fixedCacheKeys.keysData });

	useEffect(() => {
		if (shiftId) {
			if (shiftData && shiftData.keyManagement)
				getKeys({
					shiftId,
					keyManagementId: shiftData.keyManagement.id,
				});
			if (currentShiftData && currentShiftData.keyManagement)
				getKeys({
					shiftId,
					keyManagementId: currentShiftData.keyManagement.id,
				});
		}
	}, [shiftData, currentShiftData]);

	const resultsFound =
		!!getKeysData &&
		((!!getKeysData.deliveredKeys && getKeysData.deliveredKeys.length > 0) ||
			(!!getKeysData.storedKeys && getKeysData.storedKeys.length > 0) ||
			(!!getKeysData.keyManagements && getKeysData.keyManagements.length > 0));

	const keyManagementId =
		shiftData?.keyManagement.id || currentShiftData?.keyManagement.id;

	return (
		<ErrorBoundary errorMessage={getKeysError}>
			<Suspense
				isLoading={shiftIsLoading || currentShiftIsLoading || getKeysIsLoading}
				fullPage
			>
				<Container className="p-0 px-2 mb-5 mt-4">
					{!resultsFound ? (
						<KeyNoResult label={labels.keyLablesEmptyState}/>
					) : (
						<>
							<KeySearchComponent
								deliveredKeys={getKeysData?.deliveredKeys || []}
								storedKeys={getKeysData?.storedKeys || []}
								keyManagements={getKeysData?.keyManagements || []}
								setFilteredData={setFilteredData}
								setIsStorico={setIsStorico}
								isStorico={isStorico}
							/>
							<KeyResultsComponent
								data={filteredData}
								keyManagementId={keyManagementId?.toString()}
								deliveredKeys={getKeysData?.deliveredKeys || []}
								storedKeys={getKeysData?.storedKeys || []}
								keyManagements={getKeysData?.keyManagements || []}
								isStorico={isStorico}
							/>
						</>
					)}
				</Container>
			</Suspense>
		</ErrorBoundary>
	);
};

export default KeysItemPage;
