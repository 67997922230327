import { FC } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IFalconCardFooterLink } from 'features/types/common/falconCardFooterLink';


const FalconCardFooterLink: FC<IFalconCardFooterLink> = function FalconCardFooterLink({ title, bg, borderTop, to, className, ...rest }) {
	return (
		<Card.Footer className={classNames(className, `bg-${bg} p-0`, {
			'border-top': borderTop
		})}
		>
			<Link to={to}>
				<Button variant="link" size="lg" className="w-100 py-2"{...rest}
				>
					{title}
					<FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
				</Button></Link>
		</Card.Footer>
	)
};


export default FalconCardFooterLink;
