import * as React from "react"

const LogoutIcon = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" {...props}>
		<path
      		className="blue-svg"
			d="M10.09 15.59 11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
		/>
	</svg>
)

export default LogoutIcon