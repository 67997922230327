import "./style.scss";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import {} from "theme/data/dashboard/default";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { useNavigate } from "react-router";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { labels } from "features/locale/it-it";
import Suspense from "features/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import BoxDetails from "components/content/BoxDetails";
import ModulesListReport from "components/modules/ModuleListReport/ModuleListReport";
import { useParams } from "react-router-dom";
import ModuleListActivity from "components/modules/ModuleListActivities/ModuleListActivity";
import { useGetReportListMutation } from "features/storeManagement/services/reportService";
import {
	CustomerResponse,
	CustomerAddress,
} from "features/storeManagement/services/models/CustomerResponse";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { roles } from "features/storeManagement/services/constants/roles";

import "./style.scss";
import {} from "theme/data/dashboard/default";

import { useGetAllAuditsMutation } from "features/storeManagement/services/checklistService";
import { getValuesForUser } from "features/utils/userRoleUtils";

const CoordinatoreDashboardPage: FC = function CoordinatoreDashboardPage() {
	const navigate = useNavigate();
	useChangeDocumentTitle(labels.pageTile_CoordinatoreDashboardPage);

	const { customer, serviceAddress } = useParams();
	const customerContext = customer !== undefined;
	const customerURL = customerContext ? `/audit/list/customer/${customer}` : "";

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		navigate(customerURL);
	};

	const [
		,
		{
			data: reportData,
			isError: reportIsError,
			isLoading: reportIsLoading,
			error: reportError,
		},
	] = useGetReportListMutation({ fixedCacheKey: fixedCacheKeys.reportsData });

	let name: CustomerResponse[] = [];
	const [
		getCustomers,
		{
			data: customersData,
			isLoading: customersIsLoading,
			isError: customerError,
		},
	] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData });
	const [getAllAudits, { data: allAudits, isLoading: allAuditsIsLoading }] =
		useGetAllAuditsMutation({ fixedCacheKey: fixedCacheKeys.allAuditsData });

	useEffect(() => {
		const params =
			customer && serviceAddress
				? {
					idCustomer: customer,
					serviceAddress: serviceAddress,
				  }
				: {};
		getAllAudits(params);
	}, [customer, serviceAddress]);

	useEffect(() => {
		getCustomers();
	}, []);

	const currentCustomer: CustomerResponse | undefined = customersData?.find(
		(item: CustomerResponse) => item.idCustomer === customer
	);
	let customerInfo: CustomerAddress | null = null;
	if (currentCustomer != undefined) {
		let selectedCustomer = currentCustomer?.addresses.find(function (x) {
			return x.serviceAddress === serviceAddress;
		});

		if (selectedCustomer) {
			customerInfo = {
				id: selectedCustomer.id,
				idCustomer: selectedCustomer.idCustomer,
				contract: selectedCustomer.contract,
				position: selectedCustomer.position,
				customerName: selectedCustomer.customerName,
				fullAddress: selectedCustomer.fullAddress,
				venueName: selectedCustomer.venueName,
			};
		}
	}
	useChangeDocumentTitle(labels.pageTile_CoordinatoreDashboardPage);
	return (
		<ErrorBoundary errorMessage={reportError}>
			<Container
				fluid
				className="d-flex coordinatore-container justify-content-around align-items-start p-0 px-4 mb-0 mt-4"
			>
				<Suspense
					isLoading={
						customersIsLoading || reportIsLoading || allAuditsIsLoading
					}
					fullPage
				>
					{customerInfo && (
						<Container fluid className="box-details-container p-1">
							<BoxDetails
								flagCoordinatore={true}
								customerAddress={customerInfo}
							/>
							<ModuleListActivity />

							<ModulesListReport report={reportData} />
						</Container>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default CoordinatoreDashboardPage;
