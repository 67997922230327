import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import { DocumentToDownload } from "./models/ChecklistResponse";
import { headersCommon } from "./constants/headers";
import { downloadDocumentsRequest } from "./models/DocumentsRequest";

export const documentsApi = createApi({
	reducerPath: "documentsService",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getDocumentsToDownload: builder.mutation< DocumentToDownload[], downloadDocumentsRequest >({
			query: ({shiftId, idCustomer, serviceAddress }) => ({
				url:  shiftId ? `/fe/Documents?shiftId=${shiftId}` : ((idCustomer && serviceAddress) ? `/fe/Documents?idCustomer=${idCustomer}&serviceAddress=${serviceAddress}` : `/fe/Documents`),
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getDocumentToDownloadById: builder.mutation< DocumentToDownload, downloadDocumentsRequest >({
			query: ({ documentId, shiftId, idCustomer, serviceAddress }) => ({
				url:  shiftId ? `/fe/Documents/${documentId}?shiftId=${shiftId}` : ((idCustomer && serviceAddress) ? `/fe/Documents/${documentId}?idCustomer=${idCustomer}&serviceAddress=${serviceAddress}` : `/fe/Documents/${documentId}`),
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
	}),
});

export const { useGetDocumentsToDownloadMutation, useGetDocumentToDownloadByIdMutation } = documentsApi;
