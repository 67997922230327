import './style.scss'
import React from 'react'
import { dateParser } from 'features/utils/dateUtils'
import ShiftTimeIcon from 'assets/icons/ShiftTimeIcon'

import { PatrolReadItemProps } from 'features/storeManagement/services/models/PatrolsResponse'

const PatrolReadItem : React.FC<PatrolReadItemProps> = ({ type, title, subtitle, date, isMandatory }) => {
	const parsedDate = date ? dateParser(date) : date;
	const pointedOutClass = type === 'in-progress' && !!date ? 'green' : type === 'in-progress' && isMandatory && !date ? 'red' : 'black';

	return (
		<div className='patrol-read-item-container'>
			<div className='patrol-read-item-title-date'>
				<div className={`patrol-read-item-title-subtitle-container`}>
					<div className={`patrol-read-item-title ${pointedOutClass}`}>{title}{isMandatory && '*'}</div>
				</div>
				{parsedDate && <div className='patrol-read-item-title-date-container'>
					<div className='patrol-read-item-title-img'><ShiftTimeIcon /></div>
					<div className='patrol-read-item-title-date'>{parsedDate.getDay()} {parsedDate.getHour().substring(0, 5)}</div>
				</div>}
			</div>
			<div className='patrol-read-item-subtitle'>{subtitle}</div>
		</div>	
	)
}

export default PatrolReadItem