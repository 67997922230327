import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { headersCommon } from "./constants/headers"
import { CustomerModulesResponse, CustomerResponse } from './models/CustomerResponse'
import { baseQueryWithReauth } from './queryFuncs/baseQueryWithReauth'


export const customerService = createApi({
	reducerPath: 'customerService',
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({
		getCustomer: builder.mutation<CustomerResponse[], void>({
			query: () => ({
				url: '/fe/Customers',
				headers: {
					...headersCommon,
					'Authorization': `Bearer ${localStorage.token || sessionStorage.token}`,
					'deviceId': sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId') || '',
				}
			})
		}),
		getModuliCustomer: builder.mutation<CustomerModulesResponse, void>({
			query: () => ({
				url: `/fe/Supervisors`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
	})
})

export const { useGetCustomerMutation, useGetModuliCustomerMutation } = customerService