import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ILoginForm } from 'features/types/authentication/loginForm';

import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { labels } from 'features/locale/it-it'
import '../style.scss'

import useLoginForm from './useLoginForm'
import ErrorMessage from './ErrorMessage'

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginForm: FC<ILoginForm> = function LoginForm({ hasLabel, layout }) {
	const [showPassword, setShowPassword] = useState(false);
	const {
		formData,
		handleSubmit,
		handleFieldChange,
		errorMessage,
		setCheceked } = useLoginForm()

	return (
		<Form onSubmit={handleSubmit} className="p-4">
			{errorMessage &&
				<Row className="justify-content-between align-items-center">
					<Col xs="auto">
						<Form.Check.Label className="mb-0">
							<Row className="mb-0 justify-content-between align-items-center incorrectPassword text-danger">
								<ErrorMessage errorText={errorMessage} />
							</Row>
						</Form.Check.Label>
					</Col>
				</Row>
			}
			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>{labels.username}</Form.Label>}
				<Form.Control 
					placeholder={labels.username} 
					value={formData.username} 
					name="username" 
					onChange={handleFieldChange} 
					type="text"
					autoComplete='username'
				/>
			</Form.Group>

			<Form.Group className="mb-3">
				<Row className="justify-content-between align-items-center">
					<Col xs="auto">
						{hasLabel && <Form.Label className="fs--1">{labels.password}</Form.Label>}
					</Col>
				</Row>
				<InputGroup>
					<Form.Control
						placeholder={labels.password}
						value={formData.password}
						name="password"
						onChange={handleFieldChange}
						type={showPassword ? 'text' : 'password'}
						autoComplete="current-password"
						className="h-auto border-right-0 borderRightZero"
					/>
					<div className="fs--1 justify-content-center align-items-center icon-button-password">
						<div onClick={() => setShowPassword(!showPassword)} className="eye-icon">
							<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="fs--1" />
						</div>
					</div>
				</InputGroup>
			</Form.Group>
			<Row className="justify-content-between align-items-center">
				<Col xs="auto">
					<Form.Check type="checkbox" id="rememberMe" className="mb-0">
						<Form.Check.Input
							type="checkbox"
							name="remember"
							onChange={(e) => setCheceked(e.target.checked)}
						/>
						<Form.Check.Label className="mb-0 ">
							{labels.rememberMe}
						</Form.Check.Label>
					</Form.Check>
				</Col>
			</Row>

			<Form.Group className="d-inline-flex flex-column w-100 justify-content-center align-items-center">
				<Button type="submit" variant='primary' className="mt-3 w-100 mb-3" disabled={!formData.username || !formData.password}>
					{labels.login}
				</Button>
				<Row>
					<a className="fs--1 form-label d-inline-flex bold-text" href={process.env.REACT_APP_FORGOT_PASSWORD_URL}>
						{labels.forgotPassword}
					</a>
				</Row>
			</Form.Group>
		</Form>
	);
};

export default LoginForm;
