import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Title from './Title'
import Subtitle from './Subtitle'
import { ChecklistFormItemsPropsType } from 'features/storeManagement/services/models/ChecklistResponse'
import { updateState } from 'features/utils/formComponentsStateUpdate'
import { labels } from 'features/locale/it-it'

import './textArea.scss'
import { keyStrings } from 'features/storeManagement/services/models/KeysResponse'

const TextArea = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	answerDetail,
	setFormData,
	formData,
	sectionAndQuestionId,
	isExecutable,
	classNameJournal = ""
}: ChecklistFormItemsPropsType) => {
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`

	return (
		<Form.Group className="form-text-area-container">
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<Form.Control
				as='textarea'
				id={`question-${id}`}
				defaultValue={(formData[formDataKeyForComponent] || [])[0]}
				readOnly={!isExecutable}
				required={isMandatory}
				disabled={!enabled}
				placeholder={labels.insertYourAnswer}
				className= {`textarea-input-container ${classNameJournal}`}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					updateState.updateTextState(e.target.value, setFormData, formDataKeyForComponent)}
			/>
			<Form.Control.Feedback type='invalid'>
				{labels.fieldCannotBeEmpty}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export default TextArea