import { Question } from "./ChecklistResponse";

export interface KeyManagementT {
	contract: string;
	createdDate: Date;
	description: string;
	id: number;
	idCustomer: string;
	lastUpdateDate: Date;
	position: string;
}

export enum KeyStatus {
	"stored" = "stored",
	"delivered" = "delivered",
	"anomaly" = "anomaly",
}

export interface KeyTableType {
	description: string;
	keyCode: string;
	keyOutboundRecipient: string;
	keyOutboundTimestamp: string | null;
	keyInboundTimestamp: string | null;
	status: string;
	keyRegistryManagementId: string;
	keyRegistryId: string;
	answerId: number;
}

export interface StoredKeysT {
	id: number;
	keyCode: string;
	description: string;
	authorizationRequired: boolean;
	keyStatus: KeyStatus;
	keyRegistryManagementId: string | null;
}

export interface getRecipientKeyRequest {
	keyManagementId: string, 
	keyRegistryManagementId: number, 
	shiftId: string,
	answerId?: number
}

export interface getRecipientKeyResponsabileRequest {
	keyManagementId: string, 
	keyRegistryManagementId: number,
	answerId?: number 
}

export interface getRecipientDataKeyRequest{
	keyManagementId: string, 
	keyRegistryManagementId: number, 
	shiftId: string
}

export interface KeyRecipientT {
	id: number;
	firstName: null | string;
	lastName: string;
	email: null | string;
	phoneNumber: null | string;
	companyName: null | string;
	additionalInfo: null | string;
}

export interface KeyRecipientDataKey {
		keyRegistryModelOut: getRecipientKeyResponse,
		keyRecipient: KeyRecipientT,
		note: string,
		anomalyDescription: null,
		outBoundTimeStamp: string,
		operatorNameOutbound: string,
		inBoundTimeStamp: string,
		operatorNameInbound: string
}

export interface keyBoxDetailsButtom {
	firstname: string | undefined,
	lastname: string,
	OperatorName: string,
	time: string,
	companyName: string | undefined,
	additionalInfo: string| undefined,
	anomalyNote?: string,
	deliveredNote?: string,
	storedNote?: string
}

export interface DeliveredKeysT {
	id: number;
	keyRegistryId: number;
	keyCode: string;
	keyStatus: KeyStatus;
	keyOutboundTimestamp: Date | null;
	keyInboundTimestamp: Date | null;
	answerId: number | null;
	description: string;
	keyOutboundRecipient: KeyRecipientT;
	keyInboundRecipient: KeyRecipientT | null;
}


export interface StoredDeliveredKeysT {
	id: number;
	keyCode: string;
	authorizationRequired: boolean;
	keyStatus: KeyStatus;
	keyRegistryId: number;
	keyOutboundTimestamp: Date | null;
	keyInboundTimestamp: Date | null;
	answerId: null;
	description: string;
	keyOutboundRecipient: KeyRecipientT;
	keyInboundRecipient: KeyRecipientT | null;
	keyRegistryManagementId: string | null;
}
export interface GetKeysResponse {
	storedKeys: StoredKeysT[];
	deliveredKeys: DeliveredKeysT[];
	keyManagements: DeliveredKeysT[];
}


export interface anomalies {
	id: number,
	description: string
}
export interface postRecipientsRequest {
	keyManagementId: string, 
	keyRegistryId: string, 
	shiftId: string,
	recipientData: KeyRecipientT
}

export interface postRecipientsResponse {
	recipientData: KeyRecipientT
}

export interface getRecipientsKeyRequest {
	keyManagementId: string, 
	keyRegistryId: string, 
	shiftId: string
}

export interface getRecipientsKeyResponsabileRequest {
	keyManagementId: string, 
	keyRegistryId: string, 
}

export interface getRecipientKeyResponse {
	keyRegistry: StoredKeysT,
	questions: Question[],
	anomalies: anomalies[]
}

export type KeyFormStateType = {
	[key: string]: string[]
}

export enum keyStrings {
	keyRecipientPersonId = "keyRecipientPersonId",
	reportNotes = "reportNotes",
	reportNotesKeySegnalazione = "reportNotesSegnalazione",
	sign = "sign",
	keyPlaceSelect = "keyPlaceSelect",
	isSamePerson = "isSamePerson"
}

export interface postDeliveryDataKey {
	shiftId: string,
	personAssignedToId?: string | null,
	note?: string | null,
	signature?: string | null,
	anomalyId?: number | null,
	keyStatus: 0 | 1 | 2
	answers?: AnswerPostOneKey[]
}

export interface AnswerPostOneKey {
	questionId: number;
	value: string[];
	medias?: {
		mediaValue: string;
	}[];
}

export interface postDeliveryKeyRequest {
	keyManagementId: string, 
	keyRegistryId: string, 
	shiftId: string,
	deliveryDataKey: postDeliveryDataKey
}

export interface putRiconsegnaKeyRequest {
	keyManagementId: string, 
	keyRegistryId: string, 
	keyManagementRegistryId : string,
	objSendPut: objSendPut
}

export interface objSendPut {
		shiftId: string,
		signature?: string | null,
		deliveryPersonId?: string | null,
		keyStatus: 0|1|2,
		note?: string|null,
		anomalyId?: string|null, 
}

export interface postDeliveryKeyResponse {
	id: number,
    keyRegistryId: number,
    shiftIdOutbound: number,
    shiftIdInbound: number,
    cidOutbound: string,
    cidInbound: string,
    keyOutboundTimestamp: string,
    keyInboundTimestamp: string,
    keyStatus: string,
    keyAnomalyId: string,
    keyAnomalyDescription: string,
    keyAssignedToPersonId: number,
    keyDeliveryPersonId: number,
    note: string
}

export interface putRiconsegnaKeyResponse {
	id: number,
    keyRegistryId: number,
    shiftIdOutbound: number,
    shiftIdInbound: number,
    cidOutbound: string,
    cidInbound: string,
    keyOutboundTimestamp: string,
    keyInboundTimestamp: string,
    keyStatus: string,
    keyAnomalyId: string,
    keyAnomalyDescription: string,
    keyAssignedToPersonId: number,
    keyDeliveryPersonId: number,
    note: string
}

export interface GetKeysRequest {
	keyManagementId: number;
	shiftId: string;
}

export interface GetKeysResponsabileRequest {
	keyManagementId: number;
}


export interface GetRecipientKey {
	anomalyDescription: string;
	changeStatusEnabled: boolean;
	inBoundTimeStamp: Date;
	keyRecipient: KeyRecipientT;
	keyDelivery: KeyRecipientT;
	keyRegistryModelOut: getRecipientKeyResponse;
	anomalyNote: string,
	deliveredNote: string,
	storedNote: string
	operatorNameInbound: string;
	operatorNameOutbound: string;
	outBoundTimeStamp: Date;
	managementStatus: string;
}

export interface KeyRequestPutStatoApi {
	keyManagementId: string;
	keyRegistryId: string;
	objSendPut?: ObjectPutStato;
}

export interface ObjectPutStato {
	shiftId: string|null
}