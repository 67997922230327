import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import ChecklistForm from "components/modules/Checklist/ChecklistForm/ChecklistForm";
import ChecklistReport from "components/modules/Checklist/ChecklistReport/ChecklistReport";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import Suspense from "features/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import {
	useGetIncidentDraftsMutation,
	useGetIncidentQuestionsMutation,
} from "features/storeManagement/services/IncidentService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { Section } from "features/storeManagement/services/models/ChecklistResponse";
import { IncidentQuestionsApi } from "features/storeManagement/services/models/IncidentResponse";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
const IncidentPage = () => {
	useChangeDocumentTitle(labels.pageTitle_IncidentPage);

	const { shiftId, incidentId, draftId } = useParams();
	const [errorIncident, setErrorIncident] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	const [
		getIncidentQuestions,
		{
			data: incidentQuestionsData,
			isLoading: incidentQuestionsIsLoading,
			error: incidentError,
		},
	] = useGetIncidentQuestionsMutation({
		fixedCacheKey: fixedCacheKeys.incidentQuestionsData,
	});

	useEffect(() => {
		if (shiftId && incidentId) {
			getIncidentQuestions({ shiftId, incidentId, answerId: draftId });
		}
	}, [shiftId, incidentId, draftId]);

	const fakeSection: Section[] | undefined =
		incidentQuestionsData &&
		([
			{
				id: incidentQuestionsData.id,
				title: incidentQuestionsData.title,
				questions: incidentQuestionsData?.questions,
			},
		] as Section[]);

	const onPrint = () => {
		window.print();
	};

	return (
		<Suspense isLoading={incidentQuestionsIsLoading} fullPage>
			<ErrorBoundary errorMessage={incidentError || errorIncident}>
				<Container className="incident-page-container p-0 px-4 mb-0 mt-4 ">
					<>
						{incidentQuestionsData && (
							<ModulePageTitle
								title={incidentQuestionsData?.description}
								date={incidentQuestionsData.lastUpdateDate}
								isPrint={incidentQuestionsData && incidentQuestionsData.completedAt ? true : false}
								author={incidentQuestionsData.createdBy}
							/>
						)}

						{fakeSection && fakeSection.length && (
							<>
								{incidentQuestionsData && incidentQuestionsData.completedAt ? (
									<>
										<ChecklistReport sections={fakeSection} isIncident = {true}/>
									</>
								) : (
									<ChecklistForm
										sections={fakeSection}
										isExecutable
										isIncident
										setError = {setErrorIncident}
										refreshData={() => {
											if (shiftId && incidentId) {
												getIncidentQuestions({
													shiftId,
													incidentId,
													answerId: draftId,
												});
											}
										}}
									/>
								)}
							</>
						)}
					</>
				</Container>
			</ErrorBoundary>
		</Suspense>
	);
};
export default IncidentPage;
