import { Question } from "./ChecklistResponse";
export interface Parcel {
	contract: string;
	createdDate: Date;
	description: string;
	id: number;
	idCustomer: string;
	lastUpdateDate: Date;
	position: string;
	title: string;
}

export type ParcelQuestion = Omit<Question, "isMandatoryDraft">;

export interface WareHousings {
	id: number;
	name: string;
	title: string;
	venue: string | null;
	building: string | null;
	buildingStory: string | null;
	room: string;
	shelf: string;
	active: boolean;
	shelfTier: number | null;
}

export interface ParcelRecipient {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	companyName: string;
	building: string;
	story: string;
	room: string;
	roomPhone: string | null;
}

export interface Packages {
	id: number;
	parcelRecipient: ParcelRecipient;
	parcelInboundTimestamp: Date;
	parcelOutboundTimestamp: Date | null;
	status: string;
	answerId: number;
}

export interface GetParcelsResponse {
	id: number;
	idCustomer: string;
	contract: string;
	position: string;
	title: string;
	description: string;
	questions: ParcelQuestion[];
	warehousings: WareHousings[];
	storedPackages: Packages[];
	deliveredPackages: Packages[];
}

export interface GetParcelDetailResponse {
	status: string;
	parcelDelivery: ParcelDeliveryT;
	parcelInboundTimestamp: Date;
	operatorNameInbound: string;
	operatorNameOutbound: string | null;
	parcelOutboundTimestamp: Date | null;
	parcelRecipient: ParcelDeliveryT;
	parcelSubstituteRecipient: ParcelDeliveryT | null;
	storage: string;
	questions: ParcelQuestion[];
	warehousing: WareHousings;
	parcelSignature: null;
	note: string | null;
}

export interface GetParcelsRequest {
	parcelId: string;
	shiftId: string;
}
export interface GetParcelDetailRequest extends GetParcelsRequest {
	parcelManagementId: string;
	answerId: string | null;
}

export interface PutParcelStatusRequest {
	parcelId: string;
	parcelManagementId: string;
	body: PutParcelStatusBody;
}

export interface PutParcelStatusBody {
	shiftId: string | undefined;
	status:
		| ParcelStatusEnum.cancelled
		| ParcelStatusEnum.delivered
		| ParcelStatusEnum.lost
		| ParcelStatusEnum.stolen;
	signature: string | null;
	ParcelSubstituteRecipientId: number | null;
	note: string | null;
}

export interface PostOneParcelRequest {
	parcelBodyRequest: PostOneParcelBodyType;
	parcelId: string;
}

export interface PostOneParcelError {
	status: number
	data: DataError
}

export interface DataError {
	ErrorCode: string;
	Message: string;
	StatusCode: number;
}

export interface PostOneParcelBodyType {
	shiftId: string;
	status: 0 | 1;
	parcelDeliveryPersonId: number;
	parcelRecipientPersonId: number;
	warehouseId: number;
	warehouseShelfTier: number;
	note: string | null;
	answers: AnswerPostOneParcel[];
}

export interface PostOneParcelResponse {
	id: number;
	parcelId: number;
	shiftIdInbound: number;
	shiftIdOutbound: number | null;
	cidInbound: string | null;
	cidOutbound: string | null;
	parcelInboundTimestamp: Date | null;
	parcelOutboundTimestamp: Date | null;
	status: string;
	parcelDeliveryPersonId: number;
	parcelRecipientPersonId: number;
	parcelSubstituteRecipientId: number | null;
	parcelPickupPerson: null;
	pickupSignatureImage: null;
	warehouseId: number;
	warehouseShelfTier: number;
	note: string | null;
	body: string | null;
	bodyDelivery: string | null;
	sendNotification: boolean;
	title: string;

}

export interface AnswerPostOneParcel {
	questionId: number;
	value: string[];
	medias?: {
		mediaValue: string;
	}[];
}

export type ParcelFormStateType = {
	[key: string]: string[];
};

export interface ParcelDeliveryT {
	id: number;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phoneNumber: string | null;
	companyName: string | null;
	additionalInfo: string | null;
	recipientType: "Person" | "Carrier";
}
export interface ParcelPostRecipients {
	shiftId: string | undefined;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	phoneNumber: string | null;
	companyName: string | null;
	additionalInfo: string | null;
	recipientType?: number;
}

export enum ParcelStatusEnum {
	delivered = 1,
	lost = 2,
	stolen = 3,
	cancelled = 4,
}

export interface ParcelFormCompletation {
    flag: boolean | null;
    nome: string;
    cognome: string;
    societa: string;
    email: string;
    telefono: string;
    note: string;
}