import { useState } from "react";
import {
	Button,
	Modal,
	OverlayTrigger,
	Popover,
	Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import FalconCloseButton from "theme/components/common/FalconCloseButton";
import "./style.scss";
import { labels } from "features/locale/it-it";

export const VideoModal: React.FC<{
	path: string | null;
	setShowPreview: Function;
	showFlag: boolean;
}> = ({ path, setShowPreview, showFlag }) => {
	const [show, setShow] = useState(showFlag);

	const handleClose = () => {
		setShow(false);
		setShowPreview(false);
	};

	return (
		<>
			<Modal show={show} onHide={handleClose} keyboard={false}>
				<Modal.Header className="bg-light border-0">
					<Modal.Title>{labels.journalVideoPreview}</Modal.Title>
					<FalconCloseButton
						className="btn btn-circle btn-sm transition-base p-0"
						onClick={handleClose}
					/>
				</Modal.Header>
				<Modal.Body className="p-4">
					<div className="previewJornal">
						<div>
							{path && (
								<video className="JournalePreviewImage" controls>
									<source src={path} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default VideoModal;
