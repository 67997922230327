import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
	parcelApi,
	usePutAParcelMutation,
} from "features/storeManagement/services/parcelService";
import {
	ParcelStatusEnum,
	PutParcelStatusRequest,
} from "features/storeManagement/services/models/ParcelsResponse";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import { parcelKeys } from "features/storeManagement/services/models/ParcelResponse";
import { labels } from "features/locale/it-it";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

const useParcelDetailForm = (isTouch: boolean, isReport: boolean) => {
	const { shiftId, parcelId, parcelManagementId, customer, contract, position, serviceAddress } = useParams();
	const [formData, setFormData] = useState<ChecklistFormStateType>({
		[`${parcelKeys.isSamePerson} `]: [labels.yesLabel],
	});

	const [validated, setValidated] = useState<boolean>(false);
	const [isFormValid, setIsFormValid] = useState<boolean>(true);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [title, setTitle] = useState<string>("");
	const [bodyTextAlert, setbodyTextAlert] = useState<string>("");
	const isOperatoreResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;

	const [
		putAParcel,
		{
			data: putOneParcelData,
			isLoading: putOneParcelDataIsLoading,
			error: putOneParcelDataError,
		},
	] = usePutAParcelMutation();

	useEffect(() => {
		
		if (putOneParcelData) {
			if (
				putOneParcelData.sendNotification &&
				putOneParcelData.title &&
				(putOneParcelData.body || putOneParcelData.bodyDelivery)
			) {
				setShowAlertModal(true);
				setTitle(putOneParcelData.title);
				if (putOneParcelData.body) setbodyTextAlert(putOneParcelData.body);
				else if (putOneParcelData.bodyDelivery)
					setbodyTextAlert(putOneParcelData.bodyDelivery);
			} else {
				const url = shiftId ? `/shift/${shiftId}/parcel` : `/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}`;
				navigate(url);
			}
			dispatch(parcelApi.util.resetApiState());

		}
	}, [putOneParcelData]);

	const form = useRef(null);

	useEffect(() => {
		setFormData({ [`${parcelKeys.isSamePerson} `]: [labels.yesLabel] });
	}, [isReport]);

	useEffect(() => {
		let validity: boolean = false;
		if (
			formData[`${parcelKeys.isSamePerson} `][0].toLowerCase() ===
			labels.noLabel.toLowerCase()
		) {
			if (isTouch) {
				validity =
					formData[`${labels.parcelRecipientPersonKey} `] &&
					!!formData[`${labels.parcelRecipientPersonKey} `][0] &&
					formData[`${labels.parcelRecipientPersonKey} `][0] !== "" &&
					formData[`${parcelKeys.sign} `] &&
					!!formData[`${parcelKeys.sign} `][0] &&
					formData[`${parcelKeys.sign} `][0] !== "";
			} else {
				validity =
					formData[`${labels.parcelRecipientPersonKey} `] &&
					!!formData[`${labels.parcelRecipientPersonKey} `][0] &&
					formData[`${labels.parcelRecipientPersonKey} `][0] !== "";
			}
		} else {
			if (isTouch) {
				validity =
					formData[`${parcelKeys.sign} `] &&
					!!formData[`${parcelKeys.sign} `][0] &&
					formData[`${parcelKeys.sign} `][0] !== "";
			} else {
				validity = !isReport;
			}
		}
		const validitySegnalazione =
			formData &&
			formData[`${labels.parcelSelectKey} `] &&
			!!formData[`${labels.parcelSelectKey} `][0] &&
			formData[`${labels.parcelSelectKey} `][0] !== "" &&
			formData[`${labels.reportNotesKey} `] &&
			!!formData[`${labels.reportNotesKey} `][0] &&
			formData[`${labels.reportNotesKey} `][0] !== "" &&
			formData[`${labels.reportNotesKey} `][0].trim() !== "";
		setIsFormValid(!!(validity || validitySegnalazione));
	}, [formData, isReport]);

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		event.preventDefault();
		event.stopPropagation();
		let isValidForm: boolean =
			event.currentTarget && event.currentTarget.checkValidity();
		if (
			(isValidForm && parcelId && parcelManagementId && shiftId) ||
			(isValidForm &&
				parcelId &&
				parcelManagementId &&
				!shiftId &&
				isOperatoreResponsabile)
		) {
			let status: number = ParcelStatusEnum.delivered;
			if (formData[`${labels.parcelSelectKey} `]) {
				switch (formData[`${labels.parcelSelectKey} `][0]) {
				case "Rubato":
					status = ParcelStatusEnum.stolen;
					break;
				case "Cancellato":
					status = ParcelStatusEnum.cancelled;
					break;
				case "Perso":
					status = ParcelStatusEnum.lost;
					break;
				default:
					status = ParcelStatusEnum.delivered;
					break;
				}
			}
			const paramsPostAParcel: PutParcelStatusRequest = {
				parcelId,
				parcelManagementId,
				body: {
					shiftId: shiftId,
					status,
					signature:
						(formData[`${parcelKeys.sign} `] &&
							formData[`${parcelKeys.sign} `][0]) ||
						null,
					ParcelSubstituteRecipientId:
						Number(
							formData[`${labels.parcelRecipientPersonKey} `] &&
								formData[`${labels.parcelRecipientPersonKey} `][0]
						) || null,
					note:
						formData[`${labels.reportNotesKey} `] &&
						formData[`${labels.reportNotesKey} `][0],
				},
			};
			putAParcel(paramsPostAParcel);
		}

		setValidated(true);
	};

	return {
		handleSubmit,
		isLoading: putOneParcelDataIsLoading,
		error: putOneParcelDataError,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		showConfirmModal,
		setShowConfirmModal,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
		putOneParcelDataError
	};
};

export default useParcelDetailForm;
