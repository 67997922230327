import { FC } from 'react';
import ConfirmMailContent from './ConfirmMailContent';
import bgImg from 'theme/assets/img/generic/16.jpg';
import AuthSplitLayout from 'layout/AuthLayout';

const ConfirmMailPage: FC = function ConfirmMailPage() {
	return (
		<AuthSplitLayout bgProps={{ image: bgImg, position: '50% 30%' }}>
			<div className="text-center">
				<ConfirmMailContent email="xyz@abc.com" layout="split" titleTag="h3" />
			</div>
		</AuthSplitLayout>
	);
};

export default ConfirmMailPage;
