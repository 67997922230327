import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { IncidentPropsType } from 'features/types/incident/incidentPropsType'
import { roles } from 'features/storeManagement/services/constants/roles'

const IncidentItem: React.FC<IncidentPropsType> = ({ shiftId, incident, icon, contract, customer, position, serviceAddress }) => {
	const navigate = useNavigate()
	const navigateUrl = shiftId === roles.responabile.label ? `/incident/${incident.id}/customer/${customer}/serviceAddress/${serviceAddress}` : `/shift/${shiftId}/incident/${incident.id}`

	return (
		<Col
			className='patrol-item-column px-0 px-md-2 d-flex justify-content-center'
			onClick={() => navigate(navigateUrl)}
		>
			<Row className='patrol-item-container align-items-center cursor-pointer m-0 my-1 my-md-3 py-1 px-2 pos-rel'>
				<div className='container-icon'>
					{icon}
				</div>
				<Col className='patrol-item'>
					<Row className='d-flex mb-2'>
						<Col className='patrol-title blue-text d-flex align-items-center text-center p-0 col-12'>
							{incident?.description}
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	)
}

export default IncidentItem
