/* eslint-disable indent */
import dayjs from "dayjs";
import it from "dayjs/locale/it";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";


dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.locale(it);

export const dateParser = (date: Date | string | null) => {
	const standardDayFormat = "DD/MM/YYYY";
	const standardHourFormat = "HH:mm:ss";
	const utcFormat = "YYYY-MM-DDTHH:mm:ssZ";
	const hoursRange = 2;
	date = typeof date === 'string' ? new Date(date) : date;
	const nowUTC = date === null ? null : date.toUTCString();

	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: false,
		timeZone: "Europe/Rome",
	  };

	  const convertDate = () => {
		return date ? new Intl.DateTimeFormat("it-IT", options as Intl.DateTimeFormatOptions).format(date as Date)
		: null
	  } 


	const isValidDate = (d : Date | string | null, format = utcFormat) => {
		return d !== null && dayjs(d, format, false).isValid();
	};

	const alreadyStartedShift = (shift: Shift) => {
		if(shift.checkInOutEnable) {
			return isValidDate(shift.shiftCheckinTime) /*&& dayjs(checkInDate).isAfter(nowUTC)*/;
		} else {
			const isValidStartDate = isValidDate(shift.shiftStartTime);
			const nowUTCDatePlusMargin = new Date(dayjs(nowUTC).add(hoursRange, 'hour').toString()).toUTCString();
			
			return isValidStartDate && dayjs(nowUTCDatePlusMargin).isAfter(shift.shiftStartTime);
		}
	};

	const notOverYetShift = (shift: Shift) => {
		if(shift.checkInOutEnable) {
			const endDatePlusMargin = new Date(dayjs(shift.shiftEndTime).add(hoursRange, 'hour').toString()).toUTCString();
			return !isValidDate(shift.shiftCheckoutTime) && !dayjs(nowUTC).isAfter(endDatePlusMargin);
		} else {
			const nowUTCDateSubtractMargin = new Date(dayjs(nowUTC).subtract(hoursRange, 'hour').toString()).toUTCString();
			
			return dayjs(shift.shiftEndTime).isAfter(nowUTCDateSubtractMargin);
		}
	};

	return {
		getDay() {
			return dayjs(date).format(standardDayFormat);
		},
		getHour() {
			return dayjs(date).format(standardHourFormat);
		},
		isDateWithinRange(startDate: Date, endDate: Date) {
			return dayjs(date).isBetween(startDate, endDate);
		},
		isVisibleShift (startDate: Date, endDate: Date) {
			const startRange = dayjs(startDate).startOf('day').format(utcFormat);
			const endRange = dayjs(endDate).endOf('day').format(utcFormat);
			
			return this.isDateWithinRange(new Date(startRange), new Date(endRange));
		},
		isVisibleModulesList(shift : Shift) {
			const isStarting = alreadyStartedShift(shift);
			const isNotOverYetShift = notOverYetShift(shift);
			
			return isStarting && isNotOverYetShift; 
		},
		needToShowCheckInMessage(shift : Shift) {
			const alreadyStarted = alreadyStartedShift(shift);
			const isNotOverYetShift = notOverYetShift(shift);
			
			return !alreadyStarted && isNotOverYetShift; 
		},
		isValid() {
			return isValidDate(date);
		},
		convertToDateTime() {
			return convertDate(); 
		}
	};
};