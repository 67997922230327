import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import { headersCommon } from "./constants/headers";
import { responsabileRequest } from "./models/ResponsabileRequest";
import { responsabileChecklist, responsabileResponse } from "./models/ResponsabileResponse";

export const responsabileApi = createApi({
	reducerPath: "responsabileApi",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getModuliResponsabile: builder.mutation<responsabileResponse, responsabileRequest>({
			query: ({ idCustomer, serviceAddress }) => ({
				url: `/fe/Supervisors?customerId=${idCustomer}&ServiceAddress=${serviceAddress}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		getModuliCustomerMutation: builder.mutation<responsabileResponse, void>({
			query: () => ({
				url: `/fe/Supervisors`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		getChecklistResponsabile: builder.mutation<responsabileChecklist[], responsabileRequest>({
			query: ({ idCustomer, serviceAddress }) => ({
				url: `/fe/Checklists?idCustomer=${idCustomer}&ServiceAddress=${serviceAddress}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
	}),
});

export const { useGetModuliResponsabileMutation, useGetChecklistResponsabileMutation, useGetModuliCustomerMutationMutation} = responsabileApi;
