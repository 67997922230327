import classNames from 'classnames'
import { labels } from 'features/locale/it-it'
import React, { FC, useContext } from 'react'
import { Button, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap'
import AppContext from 'theme/context/Context'

const renderTooltip: FC<TooltipProps & React.RefAttributes<HTMLDivElement>> = props => (
	<Tooltip id='button-tooltip' {...props}>
		{labels.apriChiudiMenu}
	</Tooltip>
)

const ToggleButton: FC = function ToggleButton() {
	const {
		config: { isNavbarVerticalCollapsed, showMenu, isMobile },
		setConfig
	} = useContext(AppContext)

	const handleClick = (): void => {
		document
			.getElementsByTagName('html')[0]
			.classList.toggle('navbar-vertical-collapsed')
		setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
	}

	return (
		<div className={classNames('toggle-icon-wrapper m-0 p-0', {
			'hidden' : !(showMenu || isMobile),
		})}>
			<Button
				variant='link'
				className='navbar-toggler-humburger-icon navbar-vertical-toggle'
				id='toggleNavigationTooltip'
				onClick={handleClick}
			>
				<span className='navbar-toggle-icon'>
					<span className='toggle-line' />
				</span>
			</Button>
		</div>
	)
}

export default ToggleButton
