import "./style.scss";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import {
	useGetDocumentToDownloadByIdMutation,
	useGetDocumentsToDownloadMutation,
} from "features/storeManagement/services/documentsService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import Suspense from "features/Suspense";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import DocumentDetail from "components/modules/DocumentDetail/DocumentDetail";
import EmptyPageNote from "components/content/EmptyPageNote/EmptyPageNote";
import { DocumentToDownload } from "features/storeManagement/services/models/ChecklistResponse";
import GenericFilter from "components/content/GenericFilter/GenericFilter";

const AreaDocumentiPage = () => {
	useChangeDocumentTitle(labels.pageTitle_areaDocumenti);
	const { shiftId, customer, serviceAddress } = useParams();
	const [searchWord, setSearchWord] = useState<string>("");

	const [
		getDocuments,
		{
			data: documentsData,
			isLoading: documentsDataIsLoading,
			isError: isDocumentsDataError,
			error: documentsDataError,
		},
	] = useGetDocumentsToDownloadMutation({
		fixedCacheKey: fixedCacheKeys.documentsToDownload,
	});

	const [
		getDocumentById,
		{
			data: documentByIdData,
			isLoading: documentByIdDataIsLoading,
			isError: isDocumentByIdDataError,
			error: documentByIdDataError,
		},
	] = useGetDocumentToDownloadByIdMutation({});

	useEffect(() => {
		if (shiftId) {
			getDocuments({ shiftId });
		} else if (customer && serviceAddress) {
			getDocuments({ idCustomer: customer, serviceAddress });
		} else {
			getDocuments({});
		}
	}, [shiftId, customer, serviceAddress]);

	const filteredDocuments = documentsData?.filter((item: DocumentToDownload) => {
		return (
		  item.mediaName.toLowerCase().includes(searchWord.toLowerCase()) ||
		  item.mediaDescription.toLowerCase().includes(searchWord.toLowerCase())
		);
	  });

	

	const handleDownloadById = (documentId: string) => {
		if (shiftId) {
			getDocumentById({ documentId, shiftId });
		} else if (customer && serviceAddress) {
			getDocumentById({ documentId, idCustomer: customer, serviceAddress });
		} else {
			getDocumentById({ documentId });
		}
	};

	const handleDownload = (value: string, name: string) => {
		const link = document.createElement("a");
		link.href = value;
		link.download = name;
		link.click();
	};

	useEffect(() => {
		if (documentByIdData) {
			handleDownload(documentByIdData.mediaValue, documentByIdData.mediaName);
		}
	}, [documentByIdData]);

	return (
		<ErrorBoundary errorMessage={documentsDataError || documentByIdDataError}>
			<Suspense
				isLoading={documentsDataIsLoading || documentByIdDataIsLoading}
				fullPage
			>
				<div className="areaDocumenti_container">
					<div className="areaDocumenti_title mb-4">
						<ModulePageTitle title={labels.documentsArea} />
						{documentsData && documentsData.length > 0 &&
						(<div className="areaDocumenti_title__filter">
							<GenericFilter
								searchWord={searchWord}
								setSearchWord={setSearchWord}
								placeholder={labels.searchDocumentPlaceholder} />
						</div>)}
					</div>

					{filteredDocuments && filteredDocuments.length > 0 && (
						<div className="documentDetail_container">
							<div className="documentDetail_icon"></div>
							<div className="documentDetail_text">
								<div className="intestazione documentDetail_labelFont">
									<p>{labels.fileText}</p>
								</div>
							</div>

							<div className="intestazione documentDetail_date documentDetail_labelFont">
								<p>{labels.dataCaricamento}</p>
							</div>
						</div>)}

					{filteredDocuments && filteredDocuments.length > 0 ? (
						filteredDocuments.map(
							(documentData: DocumentToDownload, index: number) => {
								return (
									<div key={index}>
										<DocumentDetail
											documentData={documentData}
											handleDownloadById={handleDownloadById}
											documentByIdData={documentByIdData}
										/>
										<div className="areaDocumenti_separatore">
											<hr />
										</div>
									</div>
								);
							}
						)
					) : (
						<EmptyPageNote note={documentsData && documentsData.length > 0 ? labels.noSearchResultsDocuments : labels.noDocuments} />
					)}
				</div>
			</Suspense>
		</ErrorBoundary>
	);
};

export default AreaDocumentiPage;
