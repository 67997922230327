import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query/react"
import { authenticationApi } from "./services/loginService"
import { modulesServiceApi } from "./services/modulesService"
import { privacyPolicyApi } from "./services/privacyPolicyService"
import { checklistApi } from "./services/checklistService"
import { shiftsApi } from "./services/shiftsService"
import { partolsApi } from "./services/patrolsService"
import loggedInSlice from "./slices/loggedInSlice"
import { customerService } from 'features/storeManagement/services/customerService'
import { incidentApi } from "./services/IncidentService"
import { journalApi } from "./services/journalService"
import { parcelApi } from "./services/parcelService"
import { keyManagementApi } from "./services/keyManagementService"
import { recipientApi } from "./services/recipientService"
import { reportApi } from "./services/reportService"
import { turniApi } from "./services/turniService"
import { responsabileApi } from "./services/responsabileService"
import { guestApi } from "./services/guestService"
import { documentsApi } from "./services/documentsService"

export const store = configureStore({
	reducer: {
		loggedInSlice: loggedInSlice,
		[authenticationApi.reducerPath]: authenticationApi.reducer,
		[modulesServiceApi.reducerPath]: modulesServiceApi.reducer,
		[privacyPolicyApi.reducerPath]: privacyPolicyApi.reducer,
		[checklistApi.reducerPath]: checklistApi.reducer,
		[journalApi.reducerPath]: journalApi.reducer,
		[parcelApi.reducerPath]: parcelApi.reducer,
		[keyManagementApi.reducerPath]: keyManagementApi.reducer,
		[recipientApi.reducerPath]: recipientApi.reducer,
		[shiftsApi.reducerPath]: shiftsApi.reducer,
		[customerService.reducerPath]: customerService.reducer,
		[partolsApi.reducerPath]: partolsApi.reducer,
		[incidentApi.reducerPath]: incidentApi.reducer,
		[incidentApi.reducerPath]: incidentApi.reducer,
		[keyManagementApi.reducerPath]: keyManagementApi.reducer,
		[reportApi.reducerPath]: reportApi.reducer,
		[turniApi.reducerPath]:turniApi.reducer,
		[responsabileApi.reducerPath]:responsabileApi.reducer,
		[guestApi.reducerPath]:guestApi.reducer,
		[documentsApi.reducerPath]: documentsApi.reducer,
	},

	middleware: getDefaultMiddleware => getDefaultMiddleware({
		serializableCheck: false
	}).concat(
		authenticationApi.middleware,
		modulesServiceApi.middleware,
		privacyPolicyApi.middleware,
		checklistApi.middleware,
		journalApi.middleware,
		parcelApi.middleware,
		keyManagementApi.middleware,
		recipientApi.middleware,
		shiftsApi.middleware,
		customerService.middleware,
		partolsApi.middleware,
		incidentApi.middleware,
		keyManagementApi.middleware,
		reportApi.middleware,
		turniApi.middleware,
		responsabileApi.middleware,
		guestApi.middleware,
		documentsApi.middleware
	),
	devTools: process.env.NODE_ENV !== 'production'
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)