import { Button, Modal } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import { ReactElement } from "react";

const AlertModal = ({
	showAlertModal,
	setShowAlertModal,
	headerMessage,
	bodyMessage,
	okButtonText,
	redirectFunction,
	onHide,
}: {
	showAlertModal: boolean;
	setShowAlertModal: Function;
	headerMessage?: string;
	bodyMessage: string | ReactElement;
	okButtonText?: string;
	redirectFunction?: Function;
	onHide?: boolean;
}) => {
	const handleclick = () => {
		if (redirectFunction) {
			setShowAlertModal(false);
			redirectFunction();
		}
	};


	return (
		<Modal
			show={showAlertModal}
			onHide={onHide ? () => {} : () => setShowAlertModal(false)}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			{headerMessage && (
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						{headerMessage}
					</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{bodyMessage}</Modal.Body>
			{redirectFunction ? (
				<Modal.Footer>
					<Button variant="green" onClick={() => handleclick()}>
						{okButtonText || labels.closeWindow}
					</Button>
				</Modal.Footer>
			) : (
				<Modal.Footer>
					<Button
						variant="green"
						onClick={() => {
							setShowAlertModal(false);
						}}
					>
						{okButtonText || labels.closeWindow}
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};
export default AlertModal;
