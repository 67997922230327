import { Form } from "react-bootstrap";
import Title from "./Title";
import Subtitle from "./Subtitle";
import { PictureChecklistFormItemsPropsType } from "features/storeManagement/services/models/ChecklistResponse";

import "./picture.scss";
import { labels } from "features/locale/it-it";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";

const Picture = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	answerDetail,
	formData,
	setFormData,
	sectionAndQuestionId,
	isExecutable,
	mediaArray,
	medias,
	deleteIsLoading,
	showDeleteConfirmModal,
	mediaIdToDel,
	deleteAJournalMedia,
	setShowConfirmModal,
	setMediaIdToDel,
	setShowDeleteConfirmModal,
	setMediaArray,
}: PictureChecklistFormItemsPropsType) => {
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`
	
	return (
		<Form.Group className={"picture-group-container"}>
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<MediaPicker
				read={!isExecutable}
				isMandatory={isMandatory}
				deleteIsLoading={deleteIsLoading ? deleteIsLoading : false}
				mediaArray={mediaArray}
				showDeleteConfirmModal={
					showDeleteConfirmModal ? showDeleteConfirmModal : false
				}
				mediaIdToDel={mediaIdToDel}
				deleteAJournalMedia={
					deleteAJournalMedia ? deleteAJournalMedia : () => {}
				}
				setShowConfirmModal={
					setShowConfirmModal ? setShowConfirmModal : () => {}
				}
				setMediaArray={setMediaArray ? setMediaArray : () => {}}
				setShowDeleteConfirmModal={
					setShowDeleteConfirmModal ? setShowDeleteConfirmModal : () => {}
				}
				setMediaIdToDel={setMediaIdToDel ? setMediaIdToDel : () => {}}
				setFormData={setFormData}
				sectionAndQuestionIdAndID={formDataKeyForComponent}
				answerDetailId={answerDetail && answerDetail?.id.toString()}
			/>
			<Form.Control.Feedback type="invalid">
				{labels.mustUploadPhoto}
			</Form.Control.Feedback>
		</Form.Group>
	);
};

export default Picture;
