import { FC, useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { nanoid } from '@reduxjs/toolkit';
import IconButton from 'theme/components/common/IconButton';
import { v4 as uuid } from 'uuid';
import { IEventCustomField } from 'features/types/events/createEvent';
import EventCustomFieldItem from './EventCustomFieldItem';



const EventCustomField: FC<IEventCustomField> = function EventCustomField({ register, setValue }) {
	const [name, setName] = useState('');
	const [type, setType] = useState('text');
	const [options, setOptions] = useState('');
	const [hasOptions, setHasOptions] = useState(false);
	const [, setDisabled] = useState(true);
	const [customFields, setCustomFields] = useState<{ name: string; type: string, options?: string[], id: string }[]>([]);

	const handleRemove = (id: any): void => {
		const items = customFields.filter((fields: any) => fields.id !== id);
		setCustomFields(items);
	};

	const handleSubmit = (): void => {
		if (hasOptions) {
			const updatedOptions = options.split(',');
			setCustomFields([...customFields, { name, type, options: updatedOptions, id: uuid() }]);
		} else {
			setCustomFields([...customFields, { name, type, id: uuid() }]);
		}

		setName('');
		setType('text');
		setOptions('');
		setHasOptions(false);
		setDisabled(true);
	};

	// Handler
	useEffect(() => {
		setHasOptions(
			type === 'select' || type === 'checkboxes' || type === 'radio'
		);
	}, [type]);

	useEffect(() => {
		let disableState = true;
		if (!hasOptions && name.length) {
			disableState = false;
		}
		if (hasOptions && options.length && name.length) {
			disableState = false;
		}
		setDisabled(disableState);
	}, [name, type, hasOptions, options]);

	return (
		<Card>
			<Card.Header as="h5">Custom Fields</Card.Header>
			{customFields.length > 0 && (
				<Card.Body>
					{customFields.map((customField, index) => (
						<EventCustomFieldItem {...customField} name='' type="" options={[]} index={index} register={register} setValue={setValue} id='' handleRemove={handleRemove} key={nanoid()} />
					))}
				</Card.Body>
			)}
			<Card.Body className="bg-light">
				<div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
					<Row className="gx-2 gy-3">
						<Col md="6">
							<Form.Group controlId="name">
								<Form.Label>Name</Form.Label>
								<Form.Control size="sm" type="text" placeholder="Name (e.g. T-shirt)" value={name} onChange={({ target }): void => setName(target.value)}
								/>
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group>
								<Form.Label>Type</Form.Label>
								<Form.Select size="sm" aria-label="Default select example" value={type} onChange={({ target }): void => setType(target.value)}
								>
									<option value="text">Text</option>
									<option value="number">Number</option>
									<option value="email">Email</option>
									<option value="password">Password</option>
									<option value="date">Date</option>
									<option value="time">Time</option>
									<option value="url">Url</option>
									<option value="checkboxes">Checkboxes</option>
									<option value="radio">Radio Buttons</option>
									<option value="textarea">Textarea</option>
									<option value="select">Select</option>
									<option value="file">File</option>
								</Form.Select>
							</Form.Group>
						</Col>
						{hasOptions && (
							<Col md="12">
								<Form.Group>
									<Form.Label>Field Option</Form.Label>
									<Form.Control as="textarea" rows={3} value={options} onChange={({ target }): any => setOptions(target.value)}
									/>
									<Form.Text className="text-warning fs--1">
										* Separate your options with comma
									</Form.Text>
								</Form.Group>
							</Col>
						)}
						<Col>
							<IconButton icon="plus" transform="shrink-3" iconClassName="smr" iconAlign='center' rest={{}} onClick={(): any => handleSubmit()}>
								Add Item
							</IconButton>
						</Col>
					</Row>
				</div>
			</Card.Body>
		</Card>
	);
};

export default EventCustomField;
