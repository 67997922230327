import { turnoResponse } from "features/storeManagement/services/models/TurniResponse";
import { Col, Container, Row } from "react-bootstrap";
import './style.scss'
import { labels } from "features/locale/it-it";
import { dateParser } from "features/utils/dateUtils";
import ArrowIcon from "assets/icons/ArrowIcon";
import { useNavigate, useParams } from "react-router"
interface turnoInterface {
	turnoData: turnoResponse
}
const TurniCard = (props: turnoInterface) => {
	const navigate = useNavigate()
	const { customer, serviceAddress } = useParams();

	return (

		<Row className="d-flex justify-content-space-between align-items-center turni-box flex-nowrap cursor-pointer"
			onClick={() => { navigate(`/turno/customer/${customer}/shiftId/${props.turnoData.shiftId}/serviceAddress/${serviceAddress}`) }}>
			<Col className="col-1 mx-1"></Col>
			<Col className="col-9 turni-details ps-sm-4 ps-md-2">
				<Row className="turni-type">
					<Col className="blue-text bold-text">
						<div>{props.turnoData.shiftJobDescription}</div>
					</Col>
				</Row>
				<Row className="client-name">
					<Col className="blue-text bold-text">{props.turnoData.shiftCustomerName}</Col>
				</Row>
				<Row className="address">
					<Col className="">{props.turnoData.shiftFullAddress.toLowerCase()}</Col>
				</Row>
				<Row className="address">
					<Col className="">{props.turnoData.shiftVenueName.toLowerCase()}</Col>
				</Row>
				<Row className="d-flex flex-nowrap turni-time-container">
					<Col className="datetime-box small start-date px-0 col-6">
						<div className="info d-flex flex-column">
							<span className="label label-turni">{labels.dataInizio}</span>
							<span className='day day--turni label-turni'>{dateParser(props.turnoData.shiftStartTime).getDay()}</span>
						</div>
					</Col>
					<Col className="datetime-box small start-hour px-0 col-6">
						<div className="info d-flex flex-column">
							<span className="label label-turni">{labels.oraInizio}</span>
							<span className='hour label-turni'>{dateParser(props.turnoData.shiftStartTime).getHour().substring(0, 5)}</span>
						</div>
					</Col>
				</Row>
			</Col>
			<Col className="col-2 arrow p-0 text-start">
				<ArrowIcon />
			</Col>
		</Row>
	);
};

export default TurniCard