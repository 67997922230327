import { createApi } from "@reduxjs/toolkit/query/react";
import { headersCommon } from "./constants/headers";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import {
	JournalDeleteRequest,
	JournalGetLogDetailRequest,
	JournalGetLogDetailResponsabileRequest,
	JournalGetLogDetailResponse,
	JournalGetLogsRequest,
	JournalGetLogsResponse,
	JournalPutRequest,
	JournalRequest,
	JournalResponse,
} from "./models/JournalResponse";

export const journalApi = createApi({
	reducerPath: "journalService",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getJournalLogs: builder.query<JournalGetLogsResponse, JournalGetLogsRequest>({
			query: ({ journalId, shiftId }) => ({
				url: `/fe/Journals/${journalId}?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getOneJournalLogDetail: builder.mutation<JournalGetLogDetailResponse, JournalGetLogDetailRequest>({
			query: ({ journalId, shiftId,journalLogId }) => ({
				url: `/fe/Journals/${journalId}/${journalLogId}?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getOneJournalLogResponsabileDetail: builder.mutation<JournalGetLogDetailResponse, JournalGetLogDetailResponsabileRequest>({
			query: ({ journalId,journalLogId }) => ({
				url: `/fe/Journals/${journalId}/${journalLogId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		postAJournal: builder.mutation<JournalResponse, JournalRequest>({
			query: ({ journalId, journalBodyRequest }) => ({
				url: `/fe/Journals/${journalId}`,
				method: "POST",
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: journalBodyRequest,
			}),
		}),
		putAJournal: builder.mutation<JournalResponse, JournalPutRequest>({
			query: ({ journalId, journalLogId, journalBodyRequest }) => ({
				url: `/fe/Journals/${journalId}/${journalLogId}`,
				method: "PUT",
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: journalBodyRequest,
			}),
		}),
		deleteAJournal: builder.mutation<JournalResponse, JournalDeleteRequest>({
			query: ({ journalId, journalLogId, journalBodyDeleteRequest }) => ({
				url: `/fe/Journals/${journalId}/${journalLogId}`,
				method: "DELETE",
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: journalBodyDeleteRequest,
			}),
		}),
	}),
});

export const {
	useGetOneJournalLogDetailMutation,
	useGetJournalLogsQuery,
	usePostAJournalMutation,
	usePutAJournalMutation,
	useDeleteAJournalMutation,
	useGetOneJournalLogResponsabileDetailMutation
} = journalApi;
