import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./style.scss";

interface ActivityItemProps {
    description: string;
	redirectUrl: string;
	icon: ReactElement<any, any>;
}

const ActivityItem: React.FC<ActivityItemProps> = ({ description, redirectUrl, icon }) => {
	const navigate = useNavigate();

	return (
		<>
			<Col
				className="activity-item-column px-0 px-md-2 d-flex justify-content-center"
				onClick={() => redirectUrl && redirectUrl !== '' ? navigate(redirectUrl) : null}
			>
				<Row className="activity-item-container align-items-center cursor-pointer m-0 my-1 my-md-3 py-1 px-2 pos-rel">
					<div className="container-icon">{icon}</div>
					<Col className="activity-item">
						<Row className="d-flex mb-2">
							<Col className="activity-title blue-text d-flex align-items-center text-center p-0 col-12">
								{description}
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default ActivityItem;
