import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetGuestDettaglioMutation, usePutCambioStatoGuestMutation, usePutGuestMutation } from "features/storeManagement/services/guestService";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import ControlloAccessiDettaglio from "../../modules/ControlloAccessi/ControlloAccessiDatiUtente/ControlloAccessiDatiUtente";
import ControlloAccessiDataTessera from "components/modules/ControlloAccessi/ControlloAccessiDatiTessera/ControlloAccessiDataTessera";
import "./style.scss";
import ControlloAccessiDataVeicolo from "components/modules/ControlloAccessi/ControlloAccessiDatiVeicolo/ControlloAccessoDatiVeicolo";
import ControlloAccessiDatiAccesso from "components/modules/ControlloAccessi/ControlloAccessiDatiAccesso/ControlloAccessiDatiAccesso";
import { ObjectType } from "features/types";
import Suspense from "features/Suspense/Suspense";
import { GuestResponseDettaglioApi } from "features/storeManagement/services/models/GuestResponse";
import { labels } from "features/locale/it-it";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";
import TextArea from "components/content/FormComponents/TextArea";
import { JournalMedia, MediaTypeRequest } from "features/storeManagement/services/models/JournalResponse";
import { guestAdmissionStatus } from "components/global/Utils";
import { QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import { GuestRequestPutApi, GuestRequestPutStatoApi } from "features/storeManagement/services/models/GuestRequest";
import AlertModal from "components/content/modals/AlertModal/AlertModal";
import PrintButton from "components/content/PrintButton/PrintButton";
import ConfirmModal from "components/content/ConfirmModal/ConfirmModal";
import ImageModal from "components/content/modals/AlertModal/ImageModal";

const ControlloAccessiDettaglioPage: React.FC<{read?: boolean}> = ({read}) => {
	const {
		shiftId,
		guestConfigurationId,
		id,
		customer,
		serviceAddress,
	} = useParams();
	useChangeDocumentTitle(labels.pageTitle_controlloAccessiDettaglio);

	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showConfirmModalPut, setShowConfirmModalPut] = useState<boolean>(false);
	const [mediaArray, setMediaArray] = useState<JournalMedia[] | null | undefined>();
	const [showPreview, setShowPreview] = useState(false);

	let confirmModalHeader:string = "";
	let confirmModalBody:string = "";

	const navigate = useNavigate()

	const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
		useState<boolean>(false);
	const [mediaIdToDel, setMediaIdToDel] = useState<number>();


	const [
		guestDettaglio,
		{
			data: guestDettaglioData,
			isLoading: guestDettaglioDataIsLoading,
			isError: isGuestDettaglioDataError,
			error: guestDettaglioDataError,
		},
	] = useGetGuestDettaglioMutation();

	useEffect(()=>{
		if(guestDettaglioData) {
			setMediaArray(guestDettaglioData.media)
		}
	}, [guestDettaglioData])

	if(guestDettaglioData?.admissionStatus === guestAdmissionStatus.entry){
		confirmModalHeader = labels.modalControlloAccessiEntryHeader;
		confirmModalBody = labels.modalControlloAccessiEntryBody
	}else if(guestDettaglioData?.admissionStatus === guestAdmissionStatus.exit){
		confirmModalHeader = labels.modalControlloAccessiExitHeader;
		confirmModalBody = labels.modalControlloAccessiExitBody;
	}

	const [
		guestPutData,
		{
			data: guestPutDettaglioData,
			isLoading: guestPutDettaglioDataIsLoading,
			isError: isGuestPutDettaglioDataError,
			error: guestPutDettaglioDataError,
		},
	] = usePutGuestMutation();

	const [
		guestPutStato,
		{
			data: guestPutStatoData,
			isLoading: guestPutStatoDataIsLoading,
			isError: isGuestPutStatoDataError,
			error: guestPutStatoDataError,
		},
	] = usePutCambioStatoGuestMutation();

	useEffect(() => {
		if (
			shiftId &&
			guestConfigurationId &&
			id
		) {
			guestDettaglio({
				shiftId: shiftId,
				guestConfigurationId: Number(guestConfigurationId),
				guestAdmissionId: Number(id),
			});
		} else if (guestConfigurationId && id && customer && serviceAddress) {

			guestDettaglio({
				guestConfigurationId: Number(guestConfigurationId),
				guestAdmissionId: Number(id),
				customerId: customer,
				serviceAddress: Number(serviceAddress),
			});
		}
	}, [guestPutStatoData]);

	const checkDatiAccesso = (data: GuestResponseDettaglioApi | undefined) => {
		if (
			(data && data?.checkin) ||
			data?.checkinScheduled ||
			data?.checkout ||
			data?.checkoutScheduled
		) {
			return true;
		} else {
			return false;
		}
	};

	const [formData, setFormData] = useState<ObjectType>((Object.create({[labels.controlloAccessiNote + " "]: []})));

	useEffect(()=>{
		if(guestDettaglioData){
			setFormData(Object.create({[labels.controlloAccessiNote + " "]: [guestDettaglioData?.note]}))
		}

	}, [guestDettaglioData])

	const textAreaProps = {
		id: 0,
		title: "",
		subtitle: "",
		enabled: true,
		type: "textarea" as QuestionType,
		answers: null,
		isMandatory: false,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.controlloAccessiNote,
		isExecutable: true,
		validated: true,
	};

	const handlePutData = () => {
		let ObjSend:GuestRequestPutApi | null = null;
		const mediaSend: MediaTypeRequest[] = [];
		mediaArray?.map((el)=> {
			if(el.mediaB64){
				mediaSend.push({mediaValue : el.mediaB64})
			}
		})

		if(formData[labels.controlloAccessiNote + " "] && shiftId){
			ObjSend = {
				shiftId: shiftId,
				idCustomer: null,
				serviceAddress: null,
				note: formData[labels.controlloAccessiNote + " "][0],
				media: mediaSend || null
			}
		}else {
			ObjSend = {
				shiftId: null,
				idCustomer: customer,
				serviceAddress: serviceAddress,
				note: formData[labels.controlloAccessiNote + " "][0],
				media: mediaSend || null
			}
		}

		guestPutData({guestConfigurationId: Number(guestConfigurationId), guestAdmissionId: Number(id), objSend : ObjSend})
	}

	useEffect(()=>{	
		if(guestPutDettaglioData){
			setShowConfirmModalPut(true)
		}

	}, [guestPutDettaglioData])

	const handleSegnalazione = () => {
		let redirectUrl:string = ""
		if(shiftId){
			redirectUrl = `/shift/${shiftId}/controlloAccessi/${guestConfigurationId}/${id}/${guestDettaglioData?.guestId}/dettaglioSegnalazione`
		}else{
			redirectUrl = `/controlloAccessi/${guestConfigurationId}/customer/${customer}/serviceAddress/${serviceAddress}/${id}/${guestDettaglioData?.guestId}/dettaglioSegnalazione`
		}
		navigate(redirectUrl)
	}

	const handlePutStato = () => {
		let ObjSend:GuestRequestPutStatoApi | null = null;

		if(guestDettaglioData?.checkin && guestDettaglioData?.checkout === null && guestDettaglioData.guest.status !== labels.statusBlocked) {
			setShowConfirmModal(true)
		}else {
			if(shiftId){
				ObjSend = {
					shiftId: shiftId,
					serviceAddress: null,
					idCustomer: null
				}
				guestPutStato({ guestConfigurationId: Number(guestConfigurationId), guestId: Number(guestDettaglioData?.guestId), guestAdmissionId: Number(id), objSend: ObjSend })
			} else {
				ObjSend = {
					shiftId: null,
					serviceAddress: serviceAddress,
					idCustomer: customer
				}
				guestPutStato({ guestConfigurationId: Number(guestConfigurationId), guestId: Number(guestDettaglioData?.guestId), guestAdmissionId: Number(id), objSend: ObjSend })
			}
		}
	}

	useEffect(() => {
		if (
			shiftId &&
			guestConfigurationId &&
			id
		) {
			guestDettaglio({
				shiftId: shiftId,
				guestConfigurationId: Number(guestConfigurationId),
				guestAdmissionId: Number(id),
			});
		} else if (guestConfigurationId && id && customer && serviceAddress) {

			guestDettaglio({
				guestConfigurationId: Number(guestConfigurationId),
				guestAdmissionId: Number(id),
				customerId: customer,
				serviceAddress: Number(serviceAddress),
			});
		}
	}, [guestPutStatoData]);

	const confirmModalPut = () => {
		let ObjSend:GuestRequestPutStatoApi | null = null;

		if(shiftId){
			ObjSend = {
				shiftId: shiftId,
				serviceAddress: null,
				idCustomer: null
			}
		} else {
			ObjSend = {
				shiftId: null,
				serviceAddress: serviceAddress,
				idCustomer: customer
			}
		}
		guestPutStato({ guestConfigurationId: Number(guestConfigurationId), guestId: Number(guestDettaglioData?.guestId), guestAdmissionId: Number(id), objSend: ObjSend })
	}

	return (
		<ErrorBoundary errorMessage={guestDettaglioDataError || guestPutDettaglioDataError || guestPutStatoDataError}>
			<Suspense isLoading={guestDettaglioDataIsLoading || guestPutDettaglioDataIsLoading || guestPutStatoDataIsLoading} fullPage>
				{guestDettaglioData && <div className="controllo-accessi-container p-0 px-2 mb-5 mt-4">
					
					{read && 
					(
						<Container className="controllo-accessi-dettaglio w-100 d-flex flex-wrap align-items-center justify-content-end mt-4 p-0">
							<PrintButton />
						</Container>
					)}
					
					{guestDettaglioData && (
						<ControlloAccessiDettaglio dataDettaglio={guestDettaglioData} setShowPreview={setShowPreview}/>
					)}
					{guestDettaglioData && guestDettaglioData.guest.cards.length > 0 && (
						<ControlloAccessiDataTessera dataDettaglio={guestDettaglioData} />
					)}
					{guestDettaglioData &&
					guestDettaglioData.guest.vehicles.length > 0 && (
						<ControlloAccessiDataVeicolo dataDettaglio={guestDettaglioData} />
					)}
					{guestDettaglioData && checkDatiAccesso(guestDettaglioData) && (
						<ControlloAccessiDatiAccesso dataDettaglio={guestDettaglioData} />
					)}
					<Container className="controllo-accessi-dettaglio flex-column w-100 d-flex flex-wrap mt-4 p-0">
						{(guestDettaglioData?.admissionStatus === guestAdmissionStatus.entry &&
								!read && guestDettaglioData?.showNoteMedia) ? (
								<div>
									<div className={guestDettaglioData?.note ? "w-100 mb-2" : "w-100 mb-1"}>
										<label>
											<b className="text_dettaglio_titolo">{labels.noteAccessoLabel}:</b>
										</label>
									</div>
									<Row className="m-0">
										<Col className="items-container px-0 mb-1 mt-0">
											<TextArea {...textAreaProps} />
										</Col>
									</Row>
									<div className="w-100 mt-3">
										<label>
											<b className="text_dettaglio_titolo">{labels.mediaLabel}:</b>
										</label>
									</div>
									<MediaPicker
										read={false}
										mediaArray={mediaArray}
										deleteIsLoading={false}
										showDeleteConfirmModal={showDeleteConfirmModal}
										deleteAJournalMedia={() => {}}
										setShowConfirmModal={setShowConfirmModal}
										setMediaArray={setMediaArray}
										setShowDeleteConfirmModal={setShowDeleteConfirmModal}
										setMediaIdToDel={setMediaIdToDel}
										mediaIdToDel={mediaIdToDel}
									/>
								</div>
							) : (
								<div>
									{guestDettaglioData?.showNoteMedia &&
									<>
										<div className="print-full-section">
											<div className={guestDettaglioData?.note ? "w-100 mb-2" : "w-100 mb-1"}>
												<label>
													<b className="text_dettaglio_titolo">{labels.noteAccessoLabel}:</b>
												</label>
											</div>
											<Row className="m-0">
												<Col md={12} className="items-container px-0 mb-2 mt-0">
													<p>{guestDettaglioData?.note || labels.emptyAnswer}</p>
												</Col>
											</Row>
										</div>
										<div className="print-full-section">
											<div>
												<label className="mb-0">
													<b className="text_dettaglio_titolo">{labels.mediaLabel}:</b>
												</label>
											</div>
											<MediaPicker
												read={true}
												mediaArray={mediaArray}
												deleteIsLoading={false}
												showDeleteConfirmModal={showDeleteConfirmModal}
												deleteAJournalMedia={() => {}}
												setShowConfirmModal={setShowConfirmModal}
												setMediaArray={setMediaArray}
												setShowDeleteConfirmModal={setShowDeleteConfirmModal}
												setMediaIdToDel={setMediaIdToDel}
												flagGiornale={true}
												isControlloAccessi = {true}
											/>
										</div>
									</>
									}
								</div>
							)}
					</Container>

					{!read &&
					<Container className="controllo-accessi-media w-100 d-flex flex-wrap align-items-center justify-content-center mt-4 p-0">
						{guestDettaglioData?.showCardCheck &&
							<Button
								className="controlloAccessi__button__dettaglio m-2"
								variant="green"
								onClick={handleSegnalazione}
							>
								{labels.segnalazioneLabel}
							</Button>
						}

						{(guestDettaglioData?.admissionStatus === guestAdmissionStatus.entry) &&
							<Button
								className="controlloAccessi__button__dettaglio m-2"
								variant="green"
								onClick={handlePutData}
							>
								{labels.registraIngressoLabel}
							</Button>
						}

						{(guestDettaglioData?.admissionStatus === guestAdmissionStatus.exit) &&

							<Button
								className="controlloAccessi__button__dettaglio m-2"
								variant="green"
								onClick={handlePutData}
							>
								{labels.registraUscitaLabel}
							</Button>
						}

						{
							(guestDettaglioData?.guest.status === labels.statusEnabled && guestDettaglioData.blockGuest) && 

							<Button
								className="controlloAccessi__button__dettaglio m-2 red"
								onClick={() => { handlePutStato() }}
							>
								{labels.bloccaUtenteBottone}
							</Button>
						}

						{
							((guestDettaglioData?.guest.status === labels.statusDisabled || guestDettaglioData?.guest.status === labels.statusBlocked) && guestDettaglioData.blockGuest) && 
							<Button
								className="controlloAccessi__button__dettaglio m-2"
								variant="green"
								onClick={() => { handlePutStato() }}
							>
								{labels.sbloccaUtenteBottone}
							</Button>
						}

						<Button
							className=" controlloAccessi__button__dettaglio m-2"
							variant="secondary"
							onClick={()=>{	
								let redirectStoricoUrl:string = ""								
								if(shiftId){
									redirectStoricoUrl = `/shift/${shiftId}/controlloAccessi/${guestConfigurationId}/${guestDettaglioData?.guestId}/${id}/storico`
								}else{
									redirectStoricoUrl = `/controlloAccessi/${guestConfigurationId}/customer/${customer}/serviceAddress/${serviceAddress}/${guestDettaglioData?.guestId}/${id}/storico`
								}
								navigate(redirectStoricoUrl)}}
						>
							{labels.storicoSegnalazioniLabel}
						</Button>

						{
							<AlertModal
								headerMessage={confirmModalHeader}
								bodyMessage={confirmModalBody}
								okButtonText={labels.ok}
								showAlertModal={showConfirmModalPut}
								setShowAlertModal={setShowConfirmModalPut}
								onHide={true}
								redirectFunction={() => {
									setShowConfirmModalPut(false);
									let redirectUrl:string = ""
									if(shiftId){
										redirectUrl = `/shift/${shiftId}/controlloAccessi/${guestConfigurationId}`
									}else{
										redirectUrl = `/controlloAccessi/${guestConfigurationId}/customer/${customer}/serviceAddress/${serviceAddress}`
									}
									navigate(redirectUrl)
								}}
							/>
						}

						<ConfirmModal
							headerMessage={labels.warning}
							bodyMessage={labels.controlloAccessiBlockMessage}
							showConfirmModal={showConfirmModal}
							setShowConfirmModal={setShowConfirmModal}
							isLoading={guestDettaglioDataIsLoading}
							onConfirm={() => {
								confirmModalPut()
								setShowConfirmModal(false)
							}}
						/>

						{showPreview && guestDettaglioData && (
							<ImageModal
								path={guestDettaglioData?.guest?.picture?.path}
								setShowPreview={setShowPreview}
								showFlag={showPreview}
								base64String={guestDettaglioData?.guest?.picture?.mediaB64?.toString()}
								mediaName={guestDettaglioData?.guest?.picture?.mediaName}
								labelHeader = {labels.controlloAccessiLabelImmagineProfilo}

							/>
						)}
					</Container>}
				</div>}
			</Suspense>
		</ErrorBoundary>
	);
};

export default ControlloAccessiDettaglioPage;
