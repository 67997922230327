import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetTurniDataMutation } from "features/storeManagement/services/turniService";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TurniCard from "components/modules/Turni/TurniCard";
import { Col, Container, Row } from "react-bootstrap";
import Suspense from "features/Suspense";

import "./style.scss";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import {
	CustomerAddress,
	CustomerResponse,
} from "features/storeManagement/services/models/CustomerResponse";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { labels } from "features/locale/it-it";
import TurniNoResult from "./TurniNoResult";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import EmptyPageNote from "components/content/EmptyPageNote";
const GestioneTurniPage = () => {
	const { serviceAddress, customer } = useParams();
	useChangeDocumentTitle(labels.pageTitle_gestioneTurni);
	const [customerInfo, setCustomerInfo] = useState<CustomerAddress | null>(
		null
	);

	const [
		getTurniData,
		{
			data: turniData,
			isLoading: turniDataIsLoading,
			isError: turniDataError,
			error: turniError,
		},
	] = useGetTurniDataMutation({
		fixedCacheKey: fixedCacheKeys.coordinatorData,
	});

	let name: CustomerResponse[] = [];
	const [
		getCustomers,
		{
			data: customersData,
			isLoading: customersIsLoading,
			isError: customerError,
		},
	] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData });
	useEffect(() => {
		getCustomers();
	}, []);

	const currentCustomer: CustomerResponse | undefined = customersData?.find(
		(item: CustomerResponse) => item.idCustomer === customer
	);

	useEffect(() => {
		let customerInfoData: CustomerAddress | null = null;
		if (currentCustomer != undefined) {
			let selectedCustomer = currentCustomer?.addresses.find(function (x) {
				return x.serviceAddress === serviceAddress;
			});

			if (selectedCustomer) {
				customerInfoData = {
					id: selectedCustomer.id,
					idCustomer: selectedCustomer.idCustomer,
					contract: selectedCustomer.contract,
					position: selectedCustomer.position,
					customerName: selectedCustomer.customerName,
					fullAddress: selectedCustomer.fullAddress,
					venueName: selectedCustomer.venueName,
				};
			}
			setCustomerInfo(customerInfoData);
		}
	}, [currentCustomer, customersData]);

	useEffect(() => {
		if (customerInfo && customerInfo.idCustomer && serviceAddress) {
			getTurniData({
				idCustomer: customerInfo.idCustomer,
				serviceAddress: serviceAddress,
			});
		}
	}, [customersData, customerInfo]);

	let resultsFound = !!turniData && turniData.length > 0;

	return (
		<Container
			fluid
			className="turni-container justify-content-around align-items-start p-0 px-4 mb-0 mt-4"
		>
			<Suspense isLoading={turniDataIsLoading || customersIsLoading} fullPage>
				{customerInfo && (
					<div className="box-details-container">
						<BoxDetails
							flagCoordinatore={true}
							customerAddress={customerInfo}
						/>
					</div>
				)}
				{!resultsFound ? (
					<EmptyPageNote note={labels.emptyPageTurni} />
				) : (
					<>
						{turniData && turniData.length > 0 && (
							<Row className="d-flex">
								{turniData &&
									turniData.map((turno, i) => {
										return (
											<Col
												className="px-4 px-lg-5 py-3 py-md-4"
												md={6}
												lg={6}
												xl={4}
												key={i}
											>
												<TurniCard turnoData={turno} />
											</Col>
										);
									})}
							</Row>
						)}
					</>
				)}
			</Suspense>
		</Container>
	);
};

export default GestioneTurniPage;
