import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { nanoid } from '@reduxjs/toolkit';
import experiences from 'theme/data/experiences';
import FalconCardHeader from 'theme/components/common/FalconCardHeader';
import Experience from '../Experience';

const Experiences: FC = function Experineces() {
	return (
		<Card className="mb-3">
			<FalconCardHeader title="Experience" light />
			<Card.Body className="fs--1">
				{experiences.map((experience, index) => (
					<Experience key={nanoid()} experience={experience} isLast={index === experiences.length - 1} />
				))}
			</Card.Body>
		</Card>
	);
};

export default Experiences;
