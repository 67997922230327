import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'theme/components/common/FalconCardHeader';
import FalconCardFooterLink from 'theme/components/common/FalconCardFooterLink'
import Event from 'theme/components/app/events/event-list/Event';
import { IEvents } from 'features/types/user/profile';
import { nanoid } from '@reduxjs/toolkit';

const Events: FC<IEvents> = function Events({ cardTitle, events, ...rest }) {
	return (
		<Card {...rest}>
			<FalconCardHeader title={cardTitle} light />
			<Card.Body className="fs--1 border-bottom">
				{events.map((event, index) => (
					<Event key={nanoid()} isLast={index === events.length - 1} id={event.id} title={event.title} organizer={event.organizer} badge={event.badge} time={event.time} place={event.place} location={event.location} duration={event.duration} interested={event.interested} calendar={{ month: event.calendar.month, day: event.calendar.day }} />
				))}
			</Card.Body>
			<FalconCardFooterLink title="All Events" to="/events/event-list" bg="" borderTop={false} className="" />
		</Card>
	);
};

export default Events;
