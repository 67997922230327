import './style.scss'
import React, { FC, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Nav, Row } from 'react-bootstrap'
import RequestIcon from 'assets/icons/Request'
import { labels } from 'features/locale/it-it'
import IncidentManagementIcon from 'assets/icons/IncidentManagement'
import { IProfileItemsProps } from 'features/types/user/profile'
import NavbarLabel from 'components/navbar/vertical/NavbarLabel'
import { nanoid } from 'nanoid'
import { ModulesDictionaryType } from 'features/types/data/modules'
import NavbarVerticalMenuItem from 'components/navbar/vertical/NavbarVerticalMenuItem'
import NavbarVerticalMenu from 'components/navbar/vertical/NavbarVerticalMenu'
import TermsAndConditionsIcon from 'assets/icons/TermsAndConditionsIcon'

const ProfileItems: FC<IProfileItemsProps> = function ProfileItems({ isDropdown }) {
	const [show, setShow] = useState<boolean>()
	const rowClass = !!isDropdown ? 'drop-down-row' : 'sidebar-menu-row';
	const [additionalRoutes, setAdditionalRoutes] = useState<ModulesDictionaryType[]>([])

	useEffect(() => {
		if (!isDropdown && !additionalRoutes.length) {
			setAdditionalRoutes([
				{
					name: labels.termsAndConditions,
					icon: <TermsAndConditionsIcon />,
					to: `/terms-and-conditions`,
					component: 'terms-and-conditions'
				},
				{
					name: labels.changePassword,
					icon: <RequestIcon />,
					to: process.env.REACT_APP_FORGOT_PASSWORD_URL,
					component: 'change-passsword-page'
				}]);
		}
	}, [])

	return (
		<>


			{!isDropdown && additionalRoutes.length && (
				<>
					<NavbarLabel label={labels.usefulLinks} />
					<NavbarVerticalMenu routes={additionalRoutes as ModulesDictionaryType[]} />
				</>
			)}

			{isDropdown && (
				<>
					<Row className={`${rowClass}`}>
						<Link className='blue-text' to="/terms-and-conditions" key="terms-and-conditions-link">
							<TermsAndConditionsIcon /> <span>{labels.termsAndConditions}</span>
						</Link>
					</Row>
					<Row className={`${rowClass}`}>
						<a className='blue-text' href={process.env.REACT_APP_FORGOT_PASSWORD_URL} key="change-password-link">
							<RequestIcon /> <span>{labels.changePassword}</span>
						</a>
					</Row>
				</>
			)}
		</>
	)
}

export default ProfileItems
