import { Container, Form } from "react-bootstrap";
import "./SearchLive.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
	ParcelRecipientsWithDate,
	ParcelResponseApi,
	parcelStatus,
} from "features/storeManagement/services/models/ParcelResponse";
import { labels } from "features/locale/it-it";
import { KeyTableType } from "features/storeManagement/services/models/KeysResponse";
import { KeySearchComponentProps } from "components/pages/KeysItemPage/KeySearchComponent";
import { format } from "date-fns";

const SearchLive = ({
	deliveredKeys,
	storedKeys,
	keyManagements,
	isStorico,
	setFilteredData,
}: KeySearchComponentProps) => {
	const [stringInsert, setStringInsert] = useState<string>("");
	useEffect(() => {
		if (isStorico) {
			searchHistory();
		} else {
			searchDelivery();
		}
	}, [stringInsert]);

	useEffect(() => {
		if (isStorico) {
			setFilteredData(keyManagements);
			setStringInsert("");
		} else {
			setFilteredData([...deliveredKeys, ...storedKeys]);
			setStringInsert("");
		}
	}, [isStorico]);

	const searchDelivery = () => {
		if (deliveredKeys || storedKeys) {
			const riconsegnaKeys = deliveredKeys.filter((deliveredKey) => {
				let found = true;
				const nomeCognome = `${deliveredKey.keyOutboundRecipient.lastName}${
					deliveredKey.keyOutboundRecipient.firstName
						? "-" + deliveredKey.keyOutboundRecipient.firstName
						: ""
				}`;
				const lookUpString = `
				${deliveredKey.description ? deliveredKey.description + "_" : ""}${deliveredKey.keyCode}${nomeCognome}`.toLowerCase();
				const searchString = nomeCognome
					.concat(
						deliveredKey.keyCode !== null ? "-" + deliveredKey.keyCode : ""
					)
					.concat(
						deliveredKey.description !== null
							? "-" + deliveredKey.description
							: ""
					).toLowerCase();
				stringInsert
					.toLowerCase()
					.split(" ")
					.map((singleString) => {
						if (!searchString.includes(singleString)) {
							found = false;
						}
					});
				return found;
			});
			const consegnaKeys = storedKeys.filter((storedKey) => {
				let found = true;
				const searchString =
				(storedKey.keyCode !== null ? storedKey.keyCode : "")
					.concat(
						storedKey.description !== null
							? "-" + storedKey.description
							: ""
					).toLowerCase();
				stringInsert
					.toLowerCase()
					.split(" ")
					.map((singleString) => {
						if (!searchString.includes(singleString)) {
							found = false;
						}
					});
				return found;
			});
			setFilteredData([...riconsegnaKeys, ...consegnaKeys]);
		}
	};

	const searchHistory = () => {
		if (keyManagements) {
			const keys = keyManagements.filter((key) => {
				if (key.keyOutboundRecipient) {
					let found = true;
					const nomeCognome = `${key.keyOutboundRecipient.lastName}${
						key.keyOutboundRecipient.firstName
							? "_" + key.keyOutboundRecipient.firstName
							: ""
					}`;
					const lookUpString = `
					${key.description ? key.description + "_" : ""}${key.keyCode}${nomeCognome}`.toLowerCase();
					stringInsert.split(" ").map((singleString) => {
						if (!lookUpString.toLowerCase().includes(singleString.toLowerCase())) {
							found = false;
						}
					});
					return found;
				}
			});
			setFilteredData(keys);
		}
	};

	return (
		<Container fluid className="p-1">
			<Form.Group className="search-live-form-group">
				<Form.Control
					className={`search-control-form  ${stringInsert.length === 0 ? "background-grey-important" : ""}`}
					placeholder={labels.keyCercaPlaceholder}
					type="text"
					onChange={({ target }): void => {
						setStringInsert(target.value);
					}}
					value={stringInsert}
				/>
			</Form.Group>
		</Container>
	);
};

export default SearchLive;
