import "./style.scss";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import {} from "theme/data/dashboard/default";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { useNavigate } from "react-router";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { labels } from "features/locale/it-it";
import Suspense from "features/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import BoxDetails from "components/content/BoxDetails";
import ModulesListReport from "components/modules/ModuleListReport/ModuleListReport";
import { useParams } from "react-router-dom";
import {
	CustomerResponse,
	CustomerAddress,
} from "features/storeManagement/services/models/CustomerResponse";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useGetModuliResponsabileMutation } from "features/storeManagement/services/responsabileService";

import "./style.scss";
import {} from "theme/data/dashboard/default";
import ModulesResponsabile from "../../../../modules/ModulesResponsabile/ModulesResponsabile";
import { useGetAllAuditsMutation } from "features/storeManagement/services/checklistService";

const ResponsabileDashboardPage: FC = function ResponsabileDashboardPage() {
	const navigate = useNavigate();
	useChangeDocumentTitle(labels.pageTile_ResponsabilePostazione);

	const { customer, serviceAddress } = useParams();
	const customerContext =
		customer !== undefined;
	const customerURL = customerContext
		? `/audit/list/customer/${customer}`
		: "";

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		navigate(customerURL);
	};

	let name: CustomerResponse[] = [];
	const [
		getCustomers,
		{
			data: customersData,
			isLoading: customersIsLoading,
			error: customersError,
			isError: customerIsError,
		},
	] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData });

	useEffect(() => {
		if (!customersData) {
			getCustomers();
		}
	}, [customersData]);

	const currentCustomer: CustomerResponse | undefined = customersData?.find(
		(item: CustomerResponse) => item.idCustomer === customer
	);

	let customerInfo: CustomerAddress | null = null;
	if (currentCustomer != undefined) {
		let address = currentCustomer.addresses.filter(item => item.serviceAddress === serviceAddress)[0];
		customerInfo = {
			id: address.id,
			idCustomer: address.idCustomer,
			contract: address.contract,
			position: address.position,
			customerName: address.customerName,
			fullAddress: address.fullAddress,
			venueName: address.venueName,
		};
	}

	const [
		getModuliResponsabile,
		{
			data: moduliResponsabile,
			isLoading: moduliResponsabileIsLoading,
			isError: moduliResponsabileError,
			error: moduliResponsabileErrorBoundary,
		},
	] = useGetModuliResponsabileMutation({
		fixedCacheKey: fixedCacheKeys.moduliResponsabile,
	});

	useChangeDocumentTitle(labels.pageTile_ResponsabilePostazione);

	return (
		<ErrorBoundary errorMessage={moduliResponsabileErrorBoundary || customersError}>
			<Container
				fluid
				className="d-flex coordinatore-container justify-content-around align-items-start p-0 px-4 mb-0 mt-4"
			>
				<Suspense
					isLoading={
						customersIsLoading ||
						moduliResponsabileIsLoading
					}
					fullPage
				>
					{customerInfo && (
						<Container fluid className="box-details-container p-1">
							<BoxDetails
								flagCoordinatore={true}
								customerAddress={customerInfo}
							/>

							<ModulesResponsabile
								checklists={moduliResponsabile?.checklists}
								incidents={moduliResponsabile?.incidents}
								journal={moduliResponsabile?.journal}
								parcel={moduliResponsabile?.parcel}
								keys={moduliResponsabile?.keyManagement}
								keyManagement={moduliResponsabile?.keyManagement?.id}
								guestConfiguration = {moduliResponsabile?.guestConfiguration}
								documents= {moduliResponsabile?.documents}
							/>

							<ModulesListReport report={moduliResponsabile?.reports} />
						</Container>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ResponsabileDashboardPage;
