import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./style.scss";
import { ReportItemsProps } from "features/storeManagement/services/models/ReportResponse";

const ReportItem: React.FC<ReportItemsProps> = ({ report, icon }) => {

	const { customer, serviceAddress } = useParams();
	let navigationPath = '';
	if (customer && serviceAddress) {
		navigationPath = `/report/${report.id}/customer/${customer}/serviceAddress/${serviceAddress}`;
	} else {
		navigationPath = `/report/${report.id}`;
	}
	const navigate = useNavigate();
	return (
		<Col
			className="report-item-column px-0 px-md-2 d-flex justify-content-center"
			onClick={() => navigate(navigationPath)}
		>
			<Row className="report-item-container align-items-center cursor-pointer m-0 my-1 my-md-3 py-1 px-2 pos-rel">
				<div className="container-icon">{icon}</div>
				<Col className="report-item">
					<Row className="d-flex mb-2">
						<Col className="report-title blue-text d-flex align-items-center text-center col-12">
							{report?.title}
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default ReportItem;
