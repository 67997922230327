import BoxDetails from "components/content/BoxDetails";
import "./style.scss";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
	CustomerAddress,
	CustomerResponse,
} from "features/storeManagement/services/models/CustomerResponse";
import { useParams } from "react-router-dom";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetAllAuditsMutation, useGetChecklistCustomerMutation } from "features/storeManagement/services/checklistService";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary";
import ChecklistItem from "components/modules/Checklist/ChecklistItem";
import ChecklistIcon from "assets/icons/Checklist";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { useGetModuliCustomerMutationMutation } from "features/storeManagement/services/responsabileService";

const CustomerModulePage = () => {
	const { customer, serviceAddress } = useParams();
	useChangeDocumentTitle(labels.checklistText);
	const [selectedCustomer, setSelectedCustomer] = React.useState<
		CustomerResponse | undefined | null
	>(undefined);
	const [selectedAddress, setSelectedAddress] = React.useState<
		CustomerAddress | undefined | null
	>(undefined);

	const company = sessionStorage.getItem('companies') || localStorage.getItem('companies')

	const [
		getModuliCustomer,
		{
			data: moduliCustomerData,
			isLoading: moduliCustomerDataIsLoading,
			isError: isModuliCustomerDataError,
			error: moduliCustomerDataError,
		},
	] = useGetModuliCustomerMutationMutation({ fixedCacheKey: fixedCacheKeys.customersData });

	useEffect(() => {
		getModuliCustomer();
	}, []);

	return (
		<ErrorBoundary errorMessage={moduliCustomerDataError}>
			<Container
				fluid
				className="checklist-module-page-container p-0 px-2 mb-5 mt-4"
			>
				<Suspense isLoading={moduliCustomerDataIsLoading} fullPage>
					
					{
						company && 
						<Container fluid className="box-details-container p-1">
							<BoxDetails flagClientePage={true} companyName={JSON.parse(company)[0]} />
						</Container>
					}

					{moduliCustomerData && moduliCustomerData.checklists?.length && (
						<Container
							fluid
							className="audits-container p-0 mb-5 mt-4 mt-md-5 "
						>
							<Row className="d-flex justify-content-start m-0 px-0">
								{moduliCustomerData.checklists?.map((item) => (
									<ChecklistItem
										key={item.id}
										checklist={item}
										internal={false}
										icon={<ChecklistIcon size="lg" />}
									/>
								))}
							</Row>
						</Container>
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default CustomerModulePage;
