import React, { FC, useContext } from "react";
import "./style.scss";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAttemptLogoutMutation } from "features/storeManagement/services/loginService";
import { labels, getLabel } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useSelector } from "react-redux";
import { RootState } from "features/storeManagement/store";
import Logout from "assets/icons/Logout";
import Logo from "theme/components/common/Logo";
import ProfileItemsDropdown from "../ProfileItemsDropdown";
import AppContext from "theme/context/Context";

const TopNavRightSideNavItem: FC = function TopNavRightSideNavItem() {
	const {
		config: { isMobile },
	} = useContext(AppContext);

	const [
		logoutCall,
		{ data: logoutData, isLoading: logoutIsLoading, error: errorIsLoading },
	] = useAttemptLogoutMutation({ fixedCacheKey: fixedCacheKeys.logout });

	const handleLogout = () => {
		logoutCall(localStorage.token || sessionStorage.token);
	};

	const firstName = useSelector(
		(store: RootState) => store.loggedInSlice.firstName
	);
	const lastName = useSelector(
		(store: RootState) => store.loggedInSlice.lastName
	);

	return (
		<Nav
			navbar
			className="d-flex navbar-nav-icons flex-row align-items-center w-100 justify-content-end"
			as="ul"
		>
			{!isMobile && (
				<>
					{firstName && lastName && (
						<div id="username">
							<Nav.Item
								as="li"
								className="welcome-message blue-text text-capitalize lh-1 d-sm-flex"
								id="username"
							>
								{getLabel("welcome", [
									{ i: "firstname", v: firstName },
									{ i: "lastname", v: lastName },
								]).toLowerCase()}
							</Nav.Item>
						</div>
					)}
					<ProfileItemsDropdown />
					<Nav.Item as="li">
						<Nav.Link as={Link} to="/" onClick={handleLogout}>
							<div className="text-logout" id="logout">
								<Logout />
								<p className="bold">{labels.labelEsci}</p>
							</div>
						</Nav.Link>
					</Nav.Item>
				</>
			)}
		</Nav>
	);
};

export default TopNavRightSideNavItem;
