import './style.scss'
import { useEffect } from 'react'
import useChecklistForm from './useChecklistForm'

import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import Section from '../../../content/FormComponents/Section'

import { ChecklistFormPropsType, ChecklistFormStateType } from 'features/storeManagement/services/models/ChecklistResponse'
import { labels } from 'features/locale/it-it'
import ConfirmModal from 'components/content/ConfirmModal'
import EmptyPageNote from 'components/content/EmptyPageNote'

const ChecklistForm = ({ sections, isExecutable, isIncident, refreshData, setError }: ChecklistFormPropsType) => {
	const {
		handleSubmit,
		isLoading,
		error,
		validated,
		isValidForDraft,
		formData,
		setFormData,
		isFormValid,
		form,
		showConfirmModal,
		setShowConfirmModal
	} = useChecklistForm(sections || [], isIncident)

	const hasOneMandatory = sections?.some(section => section.questions.some(question => question.isMandatory));

	const checkAllEmptyProperties = (obj: ChecklistFormStateType): boolean => {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (!(typeof value === 'string' && value === '') &&
					!(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) &&
					!(Array.isArray(value) && (value.length === 0 || value[0] === '')) &&
					!(value === null) &&
					!(value === undefined)) {
					return false;
				}
			}
		}

		return true;
	}

	useEffect(()=>{
		if(error && setError){
			setError(error)
		}
	},[error])

	return (
		<Container className='checklist-form-container'>
			{isExecutable && (
				<>
					<Form
						ref={form}
						noValidate
						onSubmit={handleSubmit}
						// validated={validated} => 1/2 Uncomment this line and the second one on Sections props here, to show the validation, once submitted and there were invalid fields.
						// Comment out the line below instead.
						validated={false}
						id="checklistForm"
					>
						{sections && sections.map(section =>
							<Section
								key={section.id}
								section={section}
								formData={formData}
								setFormData={setFormData}
								isExecutable={isExecutable}
								// 1/2 Uncomment this line and the second one on Form props here, to show the validation, once submitted and there were invalid fields.
								// Comment out the line below instead.
								// You will need to enable submit button, when the form is not valid, eliminating !isFormValid as disable condition
								validated={false}
								isIncident={isIncident}
								refreshData={refreshData}
							/>)}
					</Form>
					<div className={'checklist-form-buttons-container'}>
						<ConfirmModal
							idForm='checklistForm'
							headerMessage={isIncident ? labels.incidentFormTitle : labels.sureToSubmit}
							bodyMessage={isIncident ? labels.incidentFormBody : labels.onceSubmittedCantEdit}
							showConfirmModal={showConfirmModal}
							setShowConfirmModal={setShowConfirmModal}
							isLoading={isLoading}
							isFormValid={isFormValid}
						/>

						<Button
							type="button"
							id="submit"
							form='checklistForm'
							variant='green'
							className='submit-and-draft-buttons'
							disabled={isLoading || !isFormValid || Object.keys(formData).length === 0 || checkAllEmptyProperties(formData)}
							onClick={() => {setShowConfirmModal(true)}}
						>
							{(showConfirmModal && isLoading) ? <Spinner /> : labels.submit}
						</Button>

						<Button
							type="submit"
							id="draft"
							form='checklistForm'
							variant='green'
							className='submit-and-draft-buttons'
							disabled={isLoading || Object.keys(formData).length <= 0 || !isValidForDraft || checkAllEmptyProperties(formData)}
						>
							{(!showConfirmModal && isLoading) ? <Spinner /> : labels.saveAsDraft}
						</Button>
					</div>
				</>
			)}
			{!isExecutable && (
				<Row>
					<Col className='items-container mt-5'>
						<EmptyPageNote note={labels.notExecutableChecklist} isLoading={isLoading} className='empty-page-note' />
					</Col>
				</Row>
			)}
		</Container>
	)
}

export default ChecklistForm