/* eslint-disable no-unused-vars */
export enum fixedCacheKeys {
	getModules = "getModules",
	logout = "logout",
	login = "login",
	privacyPolicyText = "privacyPolicyText",
	privacyPolicyAccepted = "privacyPolicyAccepted",
	shiftData = "shiftData",
	customersData = "customersData",
	allAuditsData = "allAuditsData",
	auditDetail = "auditDetail",
	currentShiftData = "currentShiftData",
	incidentQuestionsData = "incidentQuestionsData",
	checklistQuestionsData = "checklistQuestionsData",
	incidentDraftsData = "incidentDraftsData",
	parcelDetailData = "parcelDetailData",
    recipientsParcelData = "recipientsParcelData",
	keyData = "keyData",
	infoDataKey ="infoDataKey",
	recipientsListDataKey = "recipientsListDataKey",
	recipientsData = "recipientsData",
	parcelData = "parcelData",
    keysData = "keysData",
	getRecipientKey = "getRecipientKey",
	reportsData = "reportsData",
	coordinatorData="coordinatorData",
	moduliResponsabile="moduliResponsabile",
	getAllIncidents = "getAllIncidents",
	guestData = "guestData",
	guestResponsabileData = "guestResponsabileData",
	guestCercaData = "guestCercaData",
	guestRegistroData = "guestRegistroData",
	guestAggiungiData = "guestAggiungiData",
	guestStoricoSegnalazioneData = "guestStoricoSegnalazioneData",
	moduliCustomer="moduliCustomer",
	getDettaglioSegnalazione = "getDettaglioSegnalazione",
	getDettaglioLetturaSegnalazione = "getDettaglioLetturaSegnalazione",
	documentsToDownload = "documentsToDownload"
}