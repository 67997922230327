import React, { FC, ReactNode } from 'react'
import Flex from 'theme/components/common/Flex'
import SoftBadge from 'theme/components/common/SoftBadge'
import { INavbarVerticalMenuItem } from 'features/types'
import './style.scss'

const NavbarVerticalMenuItem: FC<INavbarVerticalMenuItem> =({ route })=> {
	return (
		<Flex alignItems='center'>
			{route.icon && (
				<span className='nav-link-icon'>
					{(route.icon as ReactNode)}
				</span>
			)}
			<span className='nav-link-text ps-1 first-upper'>{route.name}</span>
			{route.badge && (
				<SoftBadge pill bg={route.badge.type} className='ms-2'>
					{route.badge.text}
				</SoftBadge>
			)}
		</Flex>
	)
}
export default React.memo(NavbarVerticalMenuItem)
