import { FC, useContext, useEffect, useState } from 'react'
import { Navbar } from 'react-bootstrap'
import classNames from 'classnames'
import { AppContext } from 'theme/context/Context'
import Logo from 'theme/components/common/Logo'
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../../config'
import { useLocation } from 'react-router-dom'
import TopNavRightSideNavItem from '../TopNavRightSideNavItem'
import './style.scss'

const NavbarTop: FC = function NavbarTop() {
	const {
		config: { 
			showMenu, 
			isMobile, 
			showBurgerMenu, 
			navbarPosition, 
			navbarCollapsed 
		},
		setConfig
	} = useContext(AppContext)

	const { pathname } = useLocation()
	const isChat = pathname.includes('chat')
	const openMenu = showMenu || isMobile;
	const [showDropShadow, setShowDropShadow] = useState(false)
	
	const handleBurgerMenu = (): void => {
		navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed)
		;(navbarPosition === 'vertical' || navbarPosition === 'combo') &&
			setConfig('showBurgerMenu', !showBurgerMenu)
	}

	const setDropShadow = (): void => {
		const el = document.documentElement
		if (el.scrollTop > 0) {
			setShowDropShadow(true)
		} else {
			setShowDropShadow(false)
		}
	}

	// useEffect(() => {
	// 	//window.addEventListener('scroll', setDropShadow)
	// 	//return () => window.removeEventListener('scroll', setDropShadow)
	// }, [])

	return (
		<Navbar
			className={classNames('navbar-glass  fs--1 p-0 px-1 m-0 sticky-kit navbar-top justify-content-xl-end', {
				'navbar-glass-shadow': showDropShadow && !isChat
			})}
			expand={
				navbarPosition === 'top' || navbarPosition === 'combo'
					? topNavbarBreakpoint
					: true
			}
		>

			<Navbar.Toggle
				className={classNames('toggle-icon-wrapper m-0 p-0', {
					'hidden' : !openMenu,
					'd-lg-none': navbarPosition === 'top',
					[`d-${navbarBreakPoint}-none`]:
						openMenu && (navbarPosition === 'vertical' || navbarPosition === 'combo'),
					'd-flex': !openMenu,
				})}
				as='div'
			>
				<button
					type='button'
					className='navbar-toggler-humburger-icon btn btn-link d-flex flex-center'
					onClick={handleBurgerMenu}
					id='burgerMenu'
				>
					<span className='navbar-toggle-icon'>
						<span className='toggle-line' />
					</span>
				</button>
			</Navbar.Toggle>

			<Logo at='navbar-top' id='topLogo' className='m-0 ms-1' />
			<TopNavRightSideNavItem />
		</Navbar>
	)
}

export default NavbarTop
