import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";
import React from "react";

interface BoxDetailsWrapperProps {
    children: React.ReactNode;
    withButton?: React.ReactNode;
}

export const BoxDetailsWrapper = (props: BoxDetailsWrapperProps) => {
	return (
		props.withButton ? (
			<Container className="box-details-wrapper">
				<Col md={12} xxl={{span: 10, offset: 1}}>
					{props.children}
				</Col>
				<Container fluid className='box-details-container button-right d-flex px-1 justify-content-end'>
					{props.withButton}
				</Container>
			</Container>
		) : (
			<>{props.children}</>
		)
	);
}