import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { AutocompleteOptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import {
	Parcel,
	ParcelQuestion,
} from "features/storeManagement/services/models/ParcelsResponse";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import { useGetParcelMutation } from "features/storeManagement/services/parcelService";
import {
	useGetRecipientsMutation,
	useGetRecipientsResponsabileMutation,
} from "features/storeManagement/services/recipientService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useParcelForm from "./useParcelNewForm";
import { QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { RenderPictureFormComponent, renderTheFormComponent } from "../../content/FormComponents/renderTheFormComponent";
import { parseRecipientsAll, parseSingleRecipientPerson } from "./ParcelUtils";
import ErrorBoundary from "features/ErrorBoundary";
import Suspense from "features/Suspense";
import ServiceParcelPage from "../ParcelItemsPage/ServiceParcelPage";
import PageTitle from "components/modules/PageTitle";
import ModulePageTitle from "components/modules/ModulePageTitle";
import SelectAutoComplete from "components/content/FormComponents/SelectAutoComplete";
import Select from "../../content/FormComponents/Select";
import AlertModal from "components/content/modals/AlertModal";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

const ResponsabileParcelItemNewPage = () => {
	const { parcelId, customer, contract, position, serviceAddress } =
		useParams();
	useChangeDocumentTitle(labels.pageTitle_ParcelPage);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [popupData, setPopupData] = useState<
		GetRecipientsResponse | undefined
	>();
	const [parcelRecipientDefaultValue, setParcelRecipientDefaultValue] =
		useState<AutocompleteOptionsType | undefined>();
	const [parcelDeliveryDefaultValue, setParcelDeliveryDefaultValue] = useState<
		AutocompleteOptionsType | undefined
	>();
	const [autoCompleteType, setAutoCompleteType] = useState<string>();

	const redirectFunction = () => {
		const url = `/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}`;
		navigate(url);
	};

	const [
		getRecipients,
		{ data: recipientsData, isLoading: recipientsIsLoading },
	] = useGetRecipientsResponsabileMutation({
		fixedCacheKey: fixedCacheKeys.recipientsParcelData,
	});

	const [
		getParcelMutations,
		{ data: parcelsGetData, isLoading: parcelIsLoading, error: parcelError },
	] = useGetParcelMutation({
		fixedCacheKey: fixedCacheKeys.parcelData,
	});

	const [responseErrorModal, setResponseErrorModal] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	const handleResponseErrorModal = (err:FetchBaseQueryError | SerializedError | undefined) => {
		setResponseErrorModal(err)
	}

	useEffect(() => {
		if (parcelId && !showConfirmModal) {
			getParcelMutations({
				shiftId: "",
				parcelId: parseInt(parcelId),
			});

			getRecipients({
				shiftId: "",
				parcelId: parcelId,
			});
		}
	}, [parcelId, showConfirmModal]);

	// const parcelData: Parcel | undefined =
	// shiftData?.parcel || currentShiftData?.parcel;

	const {
		handleSubmit,
		isLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
	} = useParcelForm(String(parcelId), parcelsGetData?.warehousings);

	const separators = ['a', 'b', 'c', 'd']

	const selectProps = {
		id: 1,
		title: labels.parcelSelectWarehouse,
		subtitle: "",
		enabled: true,
		type: "select" as QuestionType,
		answers:
			parcelsGetData?.warehousings.map((warehousing) => {
				let arrLabel = [
					warehousing.building || null,
					warehousing.buildingStory || null,
					warehousing.room || null,
					warehousing.shelf || null,
					warehousing.shelfTier || null
				];
				
				const parcel = warehousing;

				const parts = [
					parcel?.building ? `Edificio: ${parcel.building}` : "",
					parcel?.buildingStory ? `Piano: ${parcel.buildingStory}` : "",
					parcel?.room ? `Stanza: ${parcel.room}` : "",
					parcel?.shelf ? `Scaffale: ${parcel.shelf}` : "",
					parcel?.shelfTier ? `Ripiano: ${parcel.shelfTier}` : "",
				].filter(Boolean); 

				const optionToReturn = parts.join(" - ").replace(/ - $/, "");
				
				return  optionToReturn;
			}) || null,
		isMandatory: true,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: labels.parcelSelectKey,
		isExecutable: true,
		validated: validated,
		fullSize: true,
		customClass: null
	};

	const renderedQuestionComponents = parcelsGetData?.questions.map(
		(question: ParcelQuestion) => {
			const theCompenentToBeRendered =
				renderTheFormComponent({
					...question,
					setFormData: setFormData,
					formData: formData,
					sectionAndQuestionId: `${question.id}`,
					isExecutable: true,
					validated,
					fullSize: true
				});
			return (
				theCompenentToBeRendered && (
					<Row key={question.id}>
						<Col className="items-container px-0 my-3 new-item-container">
							{theCompenentToBeRendered}
						</Col>
					</Row>
				)
			);
		}
	);

	useEffect(() => {
		if (!showConfirmModal && popupData) {
			if (autoCompleteType === "parcelRecipientPersonId") {
				setParcelDeliveryDefaultValue(parcelDeliveryDefaultValue);
				setParcelRecipientDefaultValue(parseSingleRecipientPerson(popupData));
			} else {
				setParcelDeliveryDefaultValue(parseSingleRecipientPerson(popupData));
				setParcelRecipientDefaultValue(parcelRecipientDefaultValue);
			}
		}
	}, [showConfirmModal, popupData]);

	useEffect(() => {
		if (showConfirmModal) setPopupData(undefined);
	}, [showConfirmModal]);
	
	return (
		<ErrorBoundary errorMessage={parcelError || responseErrorModal || error}>
			<Container className="parcel-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={parcelIsLoading} fullPage>
					<ServiceParcelPage
						setPopupData={setPopupData}
						showConfirmModal={showConfirmModal}
						setShowConfirmModal={setShowConfirmModal}
						isFromKey = {false}
						parcelId={(parcelId || "")?.toString()}
						handleResponseErrorModal = {handleResponseErrorModal}
					/>
					<Form
						ref={form}
						noValidate
						onSubmit={handleSubmit}
						validated={false}
						id="parcelForm"
					>
						<Row className="m-0">
							<Col className="items-container px-0 my-0">
								<PageTitle title={labels.parcelTitle}></PageTitle>
							</Col>
						</Row>

						<Container className="parcel_item_container">
							<Col className="items-container px-0 my-2 mt-3">
								<ModulePageTitle title={labels.recipientLabel} />
							</Col>

							<SelectAutoComplete
								formData={formData}
								setFormData={setFormData}
								defaultValue={parcelRecipientDefaultValue || null}
								enabled={true}
								isClearable={true}
								name={`${labels.parcelRecipientPersonKey}`}
								options={parseRecipientsAll(recipientsData || [])}
								title={labels.parcelDestinatarioLabel}
								subtitle={labels.textAggiungiNominativo}
								isMandatory={true}
								validated={false}
								newRecipientButton={true}
								clickedButton={() => setShowConfirmModal(true)}
								setType={setAutoCompleteType}
								setClickedValue={setParcelRecipientDefaultValue}
								className="select-width"
							/>
						</Container>

						<Container className="parcel_item_container mt-3">
							<Col className="items-container px-0 my-2 mt-3">
								<ModulePageTitle title={labels.recipientPersonLabel} />
							</Col>

							<SelectAutoComplete
								formData={formData}
								setFormData={setFormData}
								defaultValue={parcelDeliveryDefaultValue || null}
								enabled={true}
								isClearable={true}
								name={`${labels.parcelDeliveryPersonKey}`}
								options={parseRecipientsAll(recipientsData || [])}
								title={labels.parcelDepositarioLabel}
								subtitle={labels.textAggiungiNominativo}
								isMandatory={true}
								validated={false}
								newRecipientButton={true}
								clickedButton={() => setShowConfirmModal(true)}
								setType={setAutoCompleteType}
								setClickedValue={setParcelDeliveryDefaultValue}
								className="select-width"
							/>
						</Container>

						<Container className="parcel_item_container mt-3">
							<Col className="items-container px-0 my-2 mt-3">
								<ModulePageTitle title={labels.storageLabel} />
							</Col>
							<Col className="items-container px-0 my-4">
								<Select {...selectProps} customClass="standard_select_width" />
							</Col>
						</Container>

						{
							parcelsGetData?.questions && parcelsGetData?.questions.length > 0 && (
								<Container className="parcel_item_container mt-3">
									<div className="parcel_dynamic_items_container">
										{renderedQuestionComponents}
									</div>
								</Container>
							)
						}
						
						<Row>
							<Col className="items-container px-0 my-4">
								<Button
									type="button"
									variant="green"
									className="submit-and-draft-buttons"
									onClick={() => {
										const url = `/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}`;
										navigate(url);
									}}
									disabled={isLoading}
								>
									{labels.cancel}
								</Button>
								<Button
									type="submit"
									id="submit"
									form="parcelForm"
									variant="green"
									className="submit-and-draft-buttons"
									disabled={isLoading || !isFormValid}
								>
									{isLoading ? <Spinner /> : labels.save}
								</Button>
							</Col>
						</Row>
						<AlertModal
							headerMessage={title}
							bodyMessage={bodyTextAlert}
							okButtonText={labels.ok}
							showAlertModal={showAlertModal}
							setShowAlertModal={setShowAlertModal}
							redirectFunction={redirectFunction}
						/>
					</Form>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ResponsabileParcelItemNewPage;
