import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Title from './Title'
import Subtitle from './Subtitle'
import { ChecklistFormItemsPropsType } from 'features/storeManagement/services/models/ChecklistResponse'
import { labels } from 'features/locale/it-it'
import { updateState } from 'features/utils/formComponentsStateUpdate'
import './select.scss'
import { preventSubmitOnEnter } from 'features/utils/preventSubmitOnEnter'

const Select = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	isMandatoryDraft,
	answerDetail,
	formData,
	setFormData,
	sectionAndQuestionId,
	isExecutable,
	validated,
	fullSize,
	customClass
}: ChecklistFormItemsPropsType) => {
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`
	const [selectedValue, setSelectedValue] = useState<string>((formData[formDataKeyForComponent] && formData[formDataKeyForComponent][0]) || "")

	const selectClassNames: string = fullSize? `${selectedValue.length < 1 && 'select-field-question-checklist-placeholder'}`: `select-field-question-checklist ${selectedValue.length < 1 && 'select-field-question-checklist-placeholder'}`;
		
	return (
		<Form.Group
			className={'select-field-container-checklist' + customClass ? customClass : ''}
		>
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<Form.Select
				as='select'
				id={`question-${id}`}
				disabled={!enabled}
				className={selectClassNames}
				onKeyDown={e => preventSubmitOnEnter(e)}
				onChange={e => {
					setSelectedValue(e.target.value)
					updateState.updateTextState(e.target.value, setFormData, formDataKeyForComponent)
				}}
				value={selectedValue}
				required={isMandatory}
			>
				<option key='blankChoice' value="">
					{labels.selectAnAnswer}
				</option>
				{
					answers && answers.map(answer => <option
						key={answer}
						value={answer}
						disabled={!isExecutable || !enabled}
						className="select-field-options"
					>
						{answer}
					</option>)
				}
			</Form.Select>
			{validated && <Form.Control.Feedback type='invalid'>
				{labels.mustSelectOne}
			</Form.Control.Feedback>}
		</Form.Group>
	)
}

export default Select