import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import Subtitle from './Subtitle'
import Title from './Title'

import './datePicker.scss'

import { updateState } from 'features/utils/formComponentsStateUpdate'
import { labels } from 'features/locale/it-it'
import { ChecklistFormItemsPropsType } from 'features/storeManagement/services/models/ChecklistResponse'
import { preventSubmitOnEnter } from 'features/utils/preventSubmitOnEnter'
import { registerLocale } from "react-datepicker"
import it from 'date-fns/locale/it'

registerLocale('it', it)

const Datepicker = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	answerDetail,
	formData,
	setFormData,
	sectionAndQuestionId,
	isExecutable,
	validated
}: ChecklistFormItemsPropsType) => {
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`
	const dateFromForm = formData[formDataKeyForComponent] ? (formData[formDataKeyForComponent][0]) : null
	const theDate = new Date(dateFromForm as string)
	const [date, setDate] = useState<Date | null | undefined>(dateFromForm ? theDate : null)

	const handleDateChangeAndSelect = (changedDate: Date) => {
		setDate(changedDate)
		updateState.updateTextState(changedDate ? `${changedDate.toISOString()}` : "", setFormData, formDataKeyForComponent)
	}

	return (
		<Form.Group className='date-picker-group-container'>
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<ReactDatePicker
				onKeyDown={e => preventSubmitOnEnter(e)}
				className='date-picker-container'
				disabled={!enabled}
				readOnly={!isExecutable}
				required={isMandatory}
				selected={date}
				placeholderText={labels.insertDate}
				locale='it'
				dateFormat='dd/MM/yyyy HH:mm'
				timeIntervals={1}
				onChange={handleDateChangeAndSelect}
				onSelect={handleDateChangeAndSelect}
				showTimeSelect
			/>
			{(validated && !(date && !isNaN((date as Date).getTime()))) && <div className='feedback-message'>{labels.mustChooseADate}</div>}
		</Form.Group>
	)
}

export default Datepicker