import { Button, Col, Container, Row } from "react-bootstrap";
import ParcelSearchItem from "../ParcelSearchItem/ParcelSearchItem";
import { labels } from "features/locale/it-it";
import { useNavigate, useParams } from "react-router-dom";
import ParcelItemPage from "../ParcelItemPage/ParcelItemPage";
import { useGetParcelMutation } from "features/storeManagement/services/parcelService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useEffect, useState } from "react";
import ParcelNoResult from "components/modules/Parcel/ParcelNoResult/ParcelNoResult";
import {
	ParcelRecipientsWithDate,
	parcelSendObj,
} from "features/storeManagement/services/models/ParcelResponse";
import Suspense from "features/Suspense";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";

const ResponsabileParcelDetailPage = () => {
	const { parcelId, customer, serviceAddress } =
		useParams();
	useChangeDocumentTitle(labels.pageTitle_ParcelItemsPage);
	let objParcelSend: parcelSendObj;
	const [useReturnDataApi, setUseReturnDataApi] = useState<
		ParcelRecipientsWithDate[]
	>([]);
	const navigate = useNavigate();

	const [
		getParcel,
		{
			data: parcelRecipients,
			isLoading: parcelRecipientsIsLoading,
			error: parcelRecipientsError,
		},
	] = useGetParcelMutation({
		fixedCacheKey: fixedCacheKeys.shiftData,
	});

	useEffect(() => {
		if (parcelId) {
			objParcelSend = {
				shiftId: "",
				parcelId: parseInt(parcelId),
			};
			getParcel(objParcelSend);
		} else if (parcelId) {
			objParcelSend = {
				shiftId: "",
				parcelId: parseInt(parcelId),
			};
			getParcel(objParcelSend);
		}
	}, [parcelId]);

	useEffect(() => {
		if (parcelRecipients?.packages)
			setUseReturnDataApi(parcelRecipients?.packages);
	}, [parcelRecipients]);

	const flagEnable =
		!!parcelRecipients &&
		!!parcelRecipients.packages &&
		parcelRecipients.packages.length > 0;

	return (
		<ErrorBoundary errorMessage={parcelRecipientsError}>
			<Suspense
				isLoading={parcelRecipientsIsLoading}
				fullPage
			>
				<Container className="parcel-container p-0 px-2 mb-5 mt-4">
					{flagEnable && (
						<>
							<Row className="row-container-search">
								<Col lg={3} md={4} className="text-start">
									<Button
										type="button"
										id="submit"
										form="form-section"
										className="submit-button submit-button-parcel mt-1"
										variant="success"
										onClick={() => navigate(`/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}/new`)}
									>
										<p>{labels.parcelButton}</p>
									</Button>
								</Col>
								<Col sm={12} md={8} lg={6}>
									<ParcelSearchItem
										parcelRecipients={parcelRecipients}
										setUseReturnDataApi={setUseReturnDataApi}
										type={"parcel"}
									/>
								</Col>
							</Row>
							<ParcelItemPage
								filteredData={useReturnDataApi}
								parcelId={parcelRecipients.id}
							/>
						</>
					)}
					{!flagEnable && <ParcelNoResult />}
				</Container>
			</Suspense>
		</ErrorBoundary>
	);
};

export default ResponsabileParcelDetailPage;
