import { labels } from "features/locale/it-it"
import { QuestionType } from "features/storeManagement/services/models/ChecklistResponse"
import { ParcelFormStateType } from "features/storeManagement/services/models/ParcelsResponse"
import { Dispatch, SetStateAction } from "react"

const formDataParcelResponse = (setFormData: Dispatch<SetStateAction<ParcelFormStateType>>, formData: any, isFormValid: boolean, isFromKey: boolean, isValidEmail?: boolean) => {

	const responseParcelRadio = (textName: string) => {

		const objParcelRadio = {
			id: 1,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "radio" as QuestionType,
			answers: [labels.yesLabel, labels.noLabel],
			isMandatory: true,
			answerDetail: null,
			formData,
			setFormData,
			sectionAndQuestionId: labels.isCarrier,
			isExecutable: true,
			validated: isFormValid,
		};

		return objParcelRadio
	}

	const responseParcelName = (textName: string) => {

		const objParcelName = {
			id: 2,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "text" as QuestionType,
			answers: null,
			isMandatory: false,
			answerDetail: null,
			setFormData,
			formData,
			sectionAndQuestionId: labels.parcelFirstname,
			isExecutable: true,
			validated: isFormValid,
			placeholder: textName
		};

		return objParcelName
	}

	const responseParcelCognome = (textName: string) => {

		const objParcelCognome = {
			id: 3,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "text" as QuestionType,
			answers: null,
			isMandatory: isFromKey ? true : false,
			answerDetail: null,
			setFormData,
			formData,
			sectionAndQuestionId: labels.parcelLastname,
			isExecutable: true,
			validated: isFormValid,
			placeholder: textName
		};

		if (formData[`${labels.isCarrier} `])
			if (formData[`${labels.isCarrier} `][0].toLowerCase() === labels.noLabel.toLowerCase()) {
				objParcelCognome.isMandatory = true
			}

		return objParcelCognome
	}

	const responseParcelSocieta = (textName: string) => {

		const objParcelSocieta = {
			id: 3,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "text" as QuestionType,
			answers: null,
			isMandatory: false,
			answerDetail: null,
			setFormData,
			formData,
			sectionAndQuestionId: labels.parcelCompany,
			isExecutable: true,
			validated: isFormValid,
			placeholder: textName
		};

		if (formData[`${labels.isCarrier} `])
			if (formData[`${labels.isCarrier} `][0].toLowerCase() === labels.yesLabel.toLowerCase()) {
				objParcelSocieta.isMandatory = true
			}

		return objParcelSocieta
	}

	const responseParcelEmail = (textName: string) => {

		const objParcelEmail = {
			id: 3,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "text" as QuestionType,
			answers: null,
			isMandatory: false,
			answerDetail: null,
			setFormData,
			formData,
			sectionAndQuestionId: labels.parcelEmail,
			isExecutable: true,
			validated: isFormValid,
			placeholder: textName,
			isValidEmail: isValidEmail
		};

		return objParcelEmail
	}

	const responseParcelTelefono = (textName: string) => {

		const objParcelTelefono = {
			id: 3,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "text" as QuestionType,
			answers: null,
			isMandatory: false,
			answerDetail: null,
			setFormData,
			formData,
			sectionAndQuestionId: labels.parcelPhone,
			isExecutable: true,
			validated: isFormValid,
			placeholder: textName
		};

		return objParcelTelefono
	}

	const responseParcelNote = (textName: string) => {

		const objParcelNote = {
			id: 3,
			title: textName,
			subtitle: "",
			enabled: true,
			type: "text" as QuestionType,
			answers: null,
			isMandatory: false,
			answerDetail: null,
			setFormData,
			formData,
			sectionAndQuestionId: labels.parcelNote,
			isExecutable: true,
			validated: isFormValid,
			placeholder: textName
		};

		return objParcelNote
	}



	return {
		responseParcelRadio,
		responseParcelName,
		responseParcelCognome,
		responseParcelSocieta,
		responseParcelEmail,
		responseParcelTelefono,
		responseParcelNote
	}
}




export default formDataParcelResponse;