import React from "react"
import { ModulesDictionaryType } from "./types/data/modules"
// import ChecklistIcon from "assets/icons/Checklist"
// import ShiftIcon from "assets/icons/ShiftIcon"
// import ShiftPage from "components/pages/ShiftPage"

export const modulesDictionary: { [key: string]: ModulesDictionaryType } = {
	// shift: {
	// 	name: 'SHIFT',
	// 	to: '/shift',
	// 	icon: <ShiftIcon />,
	// 	component: 'shift'
	// },
	// checklist: {
	// 	name: 'CHECKLIST',
	// 	to: '/checklist',
	// 	icon: <ChecklistIcon />,
	// 	component: 'checklist'
	// },
}

// To become like this, so it won't have to iterate over the array everytime to find one
export const modulesComponents: { [key: string]: React.ReactNode } = {
	//shift: <ShiftPage />,
	//checklist: <ChecklistPage />,
}