import React, { FC } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import createMarkup from 'theme/helpers/createMarkup'
import Avatar from 'theme/components/common/Avatar'
import { INotification } from 'features/types'

const Notification: FC<INotification> = function Notification({
	avatar,
	time,
	className,
	unread = false,
	flush = false,
	emoji,
	children
}) {
	return (
		<Link
			className={classNames(
				'notification',
				{ 'notification-unread': unread, 'notification-flush': flush },
				className
			)}
			to='#!'
		>
			{avatar && (
				<div className='notification-avatar'>
					<Avatar {...avatar} className='me-3' />
				</div>
			)}
			<div className='notification-body'>
				<p
					className='mb-1'
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={createMarkup(children as string)}
				/>
				<span className='notification-time'>
					{emoji && (
						<span className='me-2' role='img' aria-label='Emoji'>
							{emoji}
						</span>
					)}
					{time}
				</span>
			</div>
		</Link>
	)
}
export default Notification
