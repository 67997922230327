import './style.scss';
import React, { FC } from 'react';
import { Card, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IAuthSplitLayout } from 'features/types/authentication/split/authSplit';
import SicurforceLogo from 'assets/SicurforceLogo';
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle';

const AuthLayout: FC<IAuthSplitLayout> = function AuthLayout({ children, bgProps }) {
	useChangeDocumentTitle('Accedi');
	return (
		<Container fluid className='d-flex auth-layout-container p-0'>
			<Row className="min-vh-100 min-vw-100 g-0 d-flex justify-content-center auth-layout-box">
				<Col xs={11} sm={8} md={6} lg={5} xl={4} xxl={3} className='d-flex align-items-center justify-content-center'>
					<Card className="auth-layout-card b-100 px-3">
						<Card.Header className="d-flex justify-content-center w-100 text-center p-0 px-5 mt-5">
							<Link className="text-white d-flex light font-sans-serif fw-bolder fs-4 z-index-1 position-relative" to="/">
								<SicurforceLogo width='300px' />
							</Link>
						</Card.Header>
						<Card.Body className="px-xs-4 px-md-5">{children}</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default AuthLayout;
