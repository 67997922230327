import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'theme/components/common/Flex';
import Follower from 'theme/components/app/social/followers/Follower';
import { IFollowers } from 'features/types/user/profile';
import { nanoid } from '@reduxjs/toolkit';

const Followers: FC<IFollowers> = function Followers({
	totalFollowers,
	followers,
	colBreakpoints = { xs: 6, md: 4, lg: 3, xxl: 2 }
}) {
	return (
		<Card className="p-0">
			<Card.Header className="bg-light">
				<Flex justifyContent="between">
					<h5 className="mb-0">Followers ({totalFollowers}) </h5>
					<Link to="/social/followers" className="font-sans-serif">
						All Members
					</Link>
				</Flex>
			</Card.Header>
			<Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
				<Row className="gx-0 gy-1 text-center">
					{followers.map(follower => (
						<Col key={nanoid()} {...colBreakpoints}>
							<Follower avatarSrc={follower.avatarSrc} name={follower.name} institution={follower.institution} id={follower.id} />
						</Col>
					))}
				</Row>
			</Card.Body>
		</Card>
	);
};
export default Followers;
