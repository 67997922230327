import React from "react";
import Title from "../../../content/FormComponents/Title";
import {
	Question,
	Section,
} from "features/storeManagement/services/models/ChecklistResponse";

import "./checklistReportSection.scss";

import { labels } from "features/locale/it-it";
import { dateParser } from "features/utils/dateUtils";
import Picture from "components/content/FormComponents/Picture";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";
import Subtitle from "components/content/FormComponents/Subtitle";
const getAnswerDetail = (question: Question | undefined) => {
	
	if (question && question.answerDetail) {
		if (question.type === "checkbox") {
			if (question.answerDetail.value.length === 0) {
				return labels.emptyAnswer;
			}
		}
		if (question.type === "datepicker") {
			if (question.answerDetail.value[0] === '') {
				return labels.emptyAnswer;
			}
			
			const dateParserType = dateParser(
				new Date(question.answerDetail.value[0])
			);
			return question.answerDetail && dateParserType.convertToDateTime();
		} else {
			if (question.answerDetail.value[0] === '') {
				return labels.emptyAnswer;
			}
			return question.answerDetail && question.answerDetail.value.join(",");
		}
	} else {
		return labels.emptyAnswer;
	}
};

const ChecklistReportSection = ({ section, sectionIndex }: { section: Section, sectionIndex:number }) => {
	const renderedComponents = section.questions.map(
		(question: Question, index) => {
			const theCompenentToBeRendered = (
				<div className="report-single-question-container print-full-section">
					{index > 0 && <hr className="hr-style" />}
					
					 <Title
						text={question.title}
						key={question.title}
						isMandatory={question.isMandatory}
					/>

					<Subtitle text={question.subtitle} />

					<div
						className={`report-answer ${
							question.type === labels.picture && "report-answer-blue-text"
						}`}
					>
						{getAnswerDetail(question)}
					</div>
				</div>
			);
			
			const pictureComponent = (
				<>
					{index > 0 && <hr className="hr-style"/>}
					
					<MediaPicker
						read={true}
						isMandatory={question.isMandatory}
						mediaArray={question.answerDetail?.medias}
						mediaTitle={question.title}
						mediaSubtitle={question.subtitle}
						deleteIsLoading={false}
						showDeleteConfirmModal={false}
						deleteAJournalMedia={() => {}}
						setShowConfirmModal={() => {}}
						setMediaArray={() => {}}
						setShowDeleteConfirmModal={() => {}}
						setMediaIdToDel={() => {}}
						flagGiornale={false}
					/>
				</>
			);
			
			return (
				theCompenentToBeRendered && (
					<div key={question.id}>
						{question.type === labels.picture
							? pictureComponent
							: theCompenentToBeRendered}
					</div>
				)
			);
		}
	);

	return (
		<div className="report-section-container">
			<div className="report-questions-container">{renderedComponents}</div>
		</div>
	);
};

export default ChecklistReportSection;
