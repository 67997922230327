import "./style.scss";
import ModulePageTitle from "components/modules/ModulePageTitle";
import ErrorBoundary from "features/ErrorBoundary";
import Suspense from "features/Suspense/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetDettaglioSegnalazioneMutation, usePostCreaSegnalazioneMutation } from "features/storeManagement/services/guestService";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistFormStateType, QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";
import { MediaTypeRequest } from "features/storeManagement/services/models/JournalResponse";
import TextArea from "components/content/FormComponents/TextArea";
import Select from "components/content/FormComponents/Select";
import { selectPropsKeys } from "../Key/Utils/utilsKey";
import { WarningOutcome } from "features/storeManagement/services/models/GuestResponse";
import { CreaSegnalazioneBody } from "features/storeManagement/services/models/GuestRequest";
import { JournalMedia } from "features/storeManagement/services/models/JournalResponse";
import { keyStrings } from "features/storeManagement/services/models/KeysResponse";
import AlertModal from "components/content/modals/AlertModal/AlertModal";
import { useGetDettaglioLetturaSegnalazioneMutation } from "features/storeManagement/services/guestService";

const ControlloAccessiDettaglioSegnalazionePage: React.FC<{ read?: boolean }> = ( {read} ) => {
	useChangeDocumentTitle(labels.pageTitle_controlloAccessiDettaglioSegnalazione);
	const { shiftId, guestConfigurationId, id, guestId, customer, serviceAddress, guestCardCheckId } = useParams();
	const navigate = useNavigate()
	const [formDataNote, setFormDataNote] = useState<ChecklistFormStateType>(Object.create({[labels.controlloAccessiNote + " "]: [""]}));
	const [formDataSegnalazione, setFormDataSegnalazione] = useState<ChecklistFormStateType>(Object.create({[labels.controlloAccessiNote + " "]: [""]}));
	const [mediaArray, setMediaArray] = useState<JournalMedia[] | null | undefined>();
	const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
	const [mediaIdToDel, setMediaIdToDel] = useState<number>();
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [options, setOptions] = useState<string[]>([]);
	const [showConfirmModalSegnalazioneSent, setShowConfirmModalSegnalazioneSent] = useState<boolean>(false);

	const [
		getDettaglioSegnalazione,
		{
			data: dettaglioSegnalazioneData,
			isLoading: dettaglioSegnalazioneIsLoading,
			isError: isDettaglioSegnalazioneError,
			error: dettaglioSegnalazioneError,
		},
	] = useGetDettaglioSegnalazioneMutation({
		fixedCacheKey: fixedCacheKeys.getDettaglioSegnalazione,
	});

	useEffect(() => {
		if(!read) {
			if (shiftId) {
				getDettaglioSegnalazione({
					shiftId: shiftId,
					guestConfigurationId: Number(guestConfigurationId),
					guestId: Number(guestId),
				});
			} else {
				getDettaglioSegnalazione({
					customerId: customer,
					serviceAddress: Number(serviceAddress),
					guestConfigurationId: Number(guestConfigurationId),
					guestId: Number(guestId),
				});
			}
		}
	}, []);

	const [
		getDettaglioLetturaSegnalazione,
		{
			data: dettaglioLetturaSegnalazioneData,
			isLoading: dettaglioLetturaSegnalazioneIsLoading,
			isError: isDettaglioLetturaSegnalazioneError,
			error: dettaglioLetturaSegnalazioneError,
		},
	] = useGetDettaglioLetturaSegnalazioneMutation({
		fixedCacheKey: fixedCacheKeys.getDettaglioSegnalazione,
	});
	
	useEffect(() => {
		if (read) {
			if (shiftId) {
				getDettaglioLetturaSegnalazione({
					shiftId: shiftId,
					guestConfigurationId: Number(guestConfigurationId),
					guestId: Number(guestId),
					guestCardCheckId: Number(guestCardCheckId)
				});
			} else {
				getDettaglioLetturaSegnalazione({
					customerId: customer,
					serviceAddress: Number(serviceAddress),
					guestConfigurationId: Number(guestConfigurationId),
					guestId: Number(guestId),
					guestCardCheckId: Number(guestCardCheckId)
				});
			}
		}
	}, []);

	useEffect(() => {
		if (dettaglioSegnalazioneData?.warningOutcomes) {
			const optionsArr: string[] = [];
			dettaglioSegnalazioneData.warningOutcomes.map((el: WarningOutcome) => {
				optionsArr.push(el.description);
			});
			setOptions(optionsArr);
		}
	}, [dettaglioSegnalazioneData]);

	let [
		creaSegnalazione,
		{
			data: creaData,
			isLoading: creaSegnalazioneIsLoading,
			isError: isCreaSegnalazioneError,
			error: creaSegnalazioneError,
		},
	] = usePostCreaSegnalazioneMutation({});

	useEffect(()=>{	
		if(creaData){
			setShowConfirmModalSegnalazioneSent(true)
		}

	}, [creaData])

	const textAreaProps = {
		id: 0,
		title: "",
		subtitle: "",
		enabled: true,
		type: "textarea" as QuestionType,
		answers: null,
		isMandatory: false,
		answerDetail: null,
		setFormData: setFormDataNote,
		formData: formDataNote,
		sectionAndQuestionId: labels.controlloAccessiNote,
		isExecutable: true,
		validated: true,
	};

	const redirectToDettaglioPage = () => {
		let redirectUrl: string = "";
		if(shiftId){
			redirectUrl = `/shift/${shiftId}/controlloAccessi/${guestConfigurationId}/${id}/dettaglio`
		}else{
			redirectUrl = `/controlloAccessi/${guestConfigurationId}/customer/${customer}/serviceAddress/${serviceAddress}/${id}/dettaglio`
		}
		navigate(redirectUrl)
	}

	const convertOutcomeId = (outCome: string) => {
		let outComeId: number = 0;
		dettaglioSegnalazioneData?.warningOutcomes.map((el: WarningOutcome) => {
			if (el.description === outCome.toString()) {
				outComeId = el.id
			}
		});

		return outComeId;
	}

	const handleAnnullaButton = () => {
		redirectToDettaglioPage();
	}

	const handleSalvaButton = () => {
		const mediaSend: MediaTypeRequest[] = [];
		mediaArray?.map((el)=> {
			if(el.mediaB64){
				mediaSend.push({mediaValue : el.mediaB64})
			}
		})

		let body: CreaSegnalazioneBody = {
			outComeId: convertOutcomeId(formDataSegnalazione[`${keyStrings.keyPlaceSelect} `][0]),
			note: formDataNote[labels.controlloAccessiNote + " "][0],
			media: mediaSend
		}
		if (shiftId) {
			body = {...body, shiftId}
		} else {
			body = {...body, idCustomer: customer, serviceAddress}
		}
		creaSegnalazione({guestConfigurationId: Number(guestConfigurationId), guestId: Number(guestId), filters: body});
	}

	let dateString:Date | null = null;
	if(dettaglioLetturaSegnalazioneData){
		dateString = new Date(dettaglioLetturaSegnalazioneData?.lastUpdateDate)
	}

	return (
		<ErrorBoundary errorMessage={dettaglioSegnalazioneError || creaSegnalazioneError || dettaglioLetturaSegnalazioneError}>
			<Suspense isLoading={dettaglioSegnalazioneIsLoading || creaSegnalazioneIsLoading || dettaglioLetturaSegnalazioneIsLoading} fullPage>
				<div className="dettaglio-controllo-accessi-container">
					{
						dettaglioLetturaSegnalazioneData && ( 
							read ?
								<ModulePageTitle title={labels.segnalazioneLabel} author={ dettaglioLetturaSegnalazioneData?.createdBy || "-" } date = {dateString} isPrint = {true} />
								:
								<ModulePageTitle title={labels.segnalazioneLabel} />
						)
					}
					<div className="w-100 mt-3">
						<label>
							<b className="text_dettaglio_titolo">{labels.datiUtenteLabel}:</b>
						</label>
					</div>
					<div className="dettaglio-controllo-accessi-box">
						<div className="dettaglio-controllo-accessi-box_item">
							<label className="text_dettaglio_label label-mobile">
								{labels.textCognome}:
							</label>
							<span className="text_dettaglio_value text-mobile print-blue-text">
								{dettaglioSegnalazioneData?.guest.lastName || "-"}
							</span>
						</div>
						<div className="dettaglio-controllo-accessi-box_item">
							<label className="text_dettaglio_label label-mobile">{labels.textName}:</label>
							<span className="text_dettaglio_value text-mobile print-blue-text">
								{dettaglioSegnalazioneData?.guest.firstName || "-"}
							</span>
						</div>
						<div className="dettaglio-controllo-accessi-box_item">
							<label className="text_dettaglio_label label-mobile">{labels.azienda}:</label>
							<span className="text_dettaglio_value text-mobile print-blue-text">
								{dettaglioSegnalazioneData?.guest.companyName || "-"}
							</span>
						</div>
						<div className="dettaglio-controllo-accessi-box_item">
							{(dettaglioSegnalazioneData?.guest.fiscalCode ||
							(!dettaglioSegnalazioneData?.guest.fiscalCode && !dettaglioSegnalazioneData?.guest.vatCode))
								? 
								(<>
									<label className="text_dettaglio_label label-mobile">
										{labels.codiceFiscaleText}:
									</label>
									<span className="text_dettaglio_value text-mobile print-blue-text">
										{dettaglioSegnalazioneData?.guest.fiscalCode || "-"}
									</span>
								</>)
								:
								(<>
									<label className="text_dettaglio_label label-mobile">{labels.pivaText}:</label>
									<span className="text_dettaglio_value text-mobile print-blue-text">
										{dettaglioSegnalazioneData?.guest.vatCode || "-"}
									</span>
								</>)}
						</div>
						<div className="dettaglio-controllo-accessi-box_item">
							<label className="text_dettaglio_label label-mobile">
								{labels.tipologiaLabel}:
							</label>
							<span className="text_dettaglio_value text-mobile print-blue-text">
								{dettaglioSegnalazioneData?.guest.type || "-"}
							</span>
						</div>
						<div className="dettaglio-controllo-accessi-box_item">
							<label className="text_dettaglio_label label-mobile">
								{labels.customerAddress}:
							</label>
							<span className="text_dettaglio_value text-mobile print-blue-text">
								{dettaglioSegnalazioneData?.guest.address || "-"}
							</span>
						</div>
					</div>

					<div className="w-100 mt-3">
						<label>
							<b className="text_dettaglio_titolo">{labels.risultatoVerifica} {read ? "" : "*"}:</b>
						</label>
					</div>
					{
						read ? 
							<p>{dettaglioLetturaSegnalazioneData?.description || labels.emptyAnswer}</p>
							:
							<Col sm={5}>
								<Select
									{...selectPropsKeys({ formData: formDataSegnalazione, setFormData: setFormDataSegnalazione, validated: true, answerArr: options })}
								/>
							</Col>
					}

					<div className="print-full-section">
						<div className="w-100 mt-3">
							<label>
								<b className="text_dettaglio_titolo">{labels.notes}:</b>
							</label>
						</div>

						<Col sm={5} className="items-container px-0 mb-1 mt-0">
							{
								read ? 
									<p>{dettaglioLetturaSegnalazioneData?.note || labels.emptyAnswer}</p>
									:
									<TextArea {...textAreaProps} />
							}
						</Col>
					</div>
					
					<div className="print-full-section">
						<div className="w-100 mt-3">
							<label>
								<b className="text_dettaglio_titolo">{labels.mediaLabel}:</b>
							</label>
						</div>

						<MediaPicker
							read={read}
							mediaArray={read ? dettaglioLetturaSegnalazioneData?.media : mediaArray}
							deleteIsLoading={false}
							showDeleteConfirmModal={showDeleteConfirmModal}
							deleteAJournalMedia={() => {}}
							setShowConfirmModal={setShowConfirmModal}
							setMediaArray={setMediaArray}
							setShowDeleteConfirmModal={setShowDeleteConfirmModal}
							setMediaIdToDel={setMediaIdToDel}
							mediaIdToDel={mediaIdToDel}
						/>
					</div>

					{!read && 
						<div className="controlloAccessiDettaglioSegnalazione_buttons w-100 mt-3">
							<Button
								className="controlloAccessiDettaglioSegnalazione__button__dettaglio m-2"
								variant="green"
								onClick={handleAnnullaButton}
							>
								{labels.cancel}
							</Button>
							<Button
								className="controlloAccessiDettaglioSegnalazione__button__dettaglio m-2"
								variant="green"
								disabled={Object.getOwnPropertyNames(formDataSegnalazione).length === 0 || formDataSegnalazione[`${keyStrings.keyPlaceSelect} `][0] === ""}
								onClick={handleSalvaButton}
							>
								{labels.save}
							</Button>
						</div>
					}
					

					<AlertModal
						headerMessage={labels.invioSegnalazioneModalHeader}
						bodyMessage={labels.invioSegnalazioneModalBody}
						okButtonText={labels.ok}
						showAlertModal={showConfirmModalSegnalazioneSent}
						setShowAlertModal={setShowConfirmModalSegnalazioneSent}
						onHide={true}
						redirectFunction={() => {
							setShowConfirmModalSegnalazioneSent(false);
							redirectToDettaglioPage();
						}}
					/>
				</div>
			</Suspense>
		</ErrorBoundary>
	);
};

export default ControlloAccessiDettaglioSegnalazionePage;
