import { FC } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButtonInterface } from "features/types/common/iconButton";

const IconButton: FC<IconButtonInterface> = function IconButton(
	props: IconButtonInterface
) {
	const {
		icon,
		iconAlign,
		iconClassName,
		transform,
		children,
		onClick,
		colorIcon,
		...rest
	} = props;
	return (
		<Button {...rest} onClick={()=>onClick && onClick()} className="btn-falcon-default">
			{iconAlign === "right" && children}
			<FontAwesomeIcon
				icon={icon}
				className={classNames(iconClassName, {
					"me-1": children && iconAlign === "left",
					"ms-1": children && iconAlign === "right",
				})}
				style={{color:colorIcon}}
				transform={transform}
			/>
			{iconAlign === "left" && children}
		</Button>
	);
};

export default IconButton;
