import { appName } from "../../config";
import { useEffect } from "react";

export const useChangeDocumentTitle = (title: string) => {

	useEffect(() => {
		document.title = `${title} - ${appName}`;
	}, [title]);

	return null;
}