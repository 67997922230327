import React from 'react'
import { labels } from 'features/locale/it-it'
import { Col, Row } from 'react-bootstrap'
import { dateParser } from "features/utils/dateUtils"
import { ShiftDetailsProps } from 'features/types/modules/ShiftPageProps'

import './style.scss'
import { nanoid } from '@reduxjs/toolkit'

const ShiftDetailsBottom: React.FC<ShiftDetailsProps> = ({ shift }) => {
	let checkinTime = shift.shiftCheckinTime && dateParser(new Date(shift.shiftCheckinTime))
	let checkoutTime = shift.shiftCheckoutTime && dateParser(new Date(shift.shiftCheckoutTime))

	return (
		<>
			<Row className='d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 pt-3'>
				<Col key={nanoid()} className='datetime-box col-6 d-flex flex-column align-items-start px-1'>
					<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
						<span className="label bold-text">{labels.shiftCheckinTime}</span>
						<span className='day bold-text'>{!!checkinTime ? checkinTime.getDay() + ' ' + checkinTime.getHour().substring(0, 5) : '-'}</span>
					</div>
				</Col>
				{shift.checkInOutEnable && (
					<Col key='checkout-time' className='datetime-box col-6 d-flex flex-column align-items-start px-1'>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
							<span className="label bold-text">{labels.shiftCheckoutTime}</span>
							<span className='day bold-text'>{!!checkoutTime ? checkoutTime.getDay() + ' ' + checkoutTime.getHour().substring(0, 5) : '-'}</span>
						</div>
					</Col>
				)}
			</Row>
			{shift?.jobDescriptionLink && (
				<Row className='d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 pt-3'>
					<Col key={nanoid()} className='datetime-box col-6 d-flex flex-column align-items-start px-1'>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
							<span className="label bold-text">{labels.jobDescriptionLink}</span>
							<span className='day bold-text'><a href={shift.jobDescriptionLink} target="_blank" className='color_link'>{labels.read}</a></span>
						</div>
					</Col>
				</Row>
			)}
			{(shift.jobDescriptionNotes) &&
				<Row className='notes d-flex flex-column px-3 m-0 px-md-3 px-lg-3 px-xl-5 pb-2'>
					<Col className='datetime-box col-12 d-flex flex-column align-items-start px-1'>
						<div className="info d-flex flex-column flex-md-column w-100 flex-wrap">
							<span className='label bold-text'>{labels.notes}</span>
							<span className="label bold-text text-note" key="jobDescriptionNotes">{shift.jobDescriptionNotes}</span>
						</div>
					</Col>
				</Row>
			}
		</>
	)
}

export default ShiftDetailsBottom