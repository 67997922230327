import SearchLive from "components/content/FormComponents/SearchLive";
import { labels } from "features/locale/it-it";
import { parcelStatus } from "features/storeManagement/services/models/ParcelResponse";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { format } from "date-fns";
import {
	DeliveredKeysT,
	KeyStatus,
	KeyTableType,
	StoredDeliveredKeysT,
	StoredKeysT,
} from "features/storeManagement/services/models/KeysResponse";
import { useNavigate, useParams } from "react-router-dom";
import KeyNoResult from "./KeyNoResult";

export interface KeyResultsComponentProps {
	data: StoredDeliveredKeysT[];
	keyManagementId?: string;
	isStorico: boolean;
	deliveredKeys: DeliveredKeysT[];
	storedKeys: StoredKeysT[];
	keyManagements: DeliveredKeysT[];
}
const KeyResultsComponent = ({
	data,
	keyManagementId,
	isStorico,
	deliveredKeys,
	storedKeys,
	keyManagements,
}: KeyResultsComponentProps) => {
	const navigate = useNavigate();
	const { shiftId } = useParams();
	const { keyRegistryId, customer, contract, position, serviceAddress } = useParams();

	const keysFormatted: KeyTableType[] = data.map(
		(key: StoredDeliveredKeysT) => {
			return {
				keyCode: key.keyCode,
				description: key.description ? key.description : "-",
				keyOutboundTimestamp: key.keyOutboundTimestamp
					? format(new Date(key.keyOutboundTimestamp), "dd/MM/yyyy HH:mm")
					: "-",
				keyInboundTimestamp: key.keyInboundTimestamp
					? format(new Date(key.keyInboundTimestamp), "dd/MM/yyyy HH:mm")
					: key.keyOutboundTimestamp ? format(new Date(key.keyOutboundTimestamp), "dd/MM/yyyy HH:mm") : "-",
				keyOutboundRecipient: key.keyOutboundRecipient
					? `${key.keyOutboundRecipient.lastName} ${key.keyOutboundRecipient.firstName ? key.keyOutboundRecipient.firstName : ""}` : "-",
				status: key.keyStatus,
				keyRegistryId: key.keyRegistryId || key.id.toString(),
				keyRegistryManagementId: key.keyRegistryManagementId || key.id,
				answerId: key.answerId ? key.answerId : 0
			} as KeyTableType;
		}
	);

	const deliveryKey = (element: KeyTableType) => {
		if(!shiftId){
			navigate(
				`/keys/customer/${customer}/keyManagementId/${keyManagementId}/serviceAddress/${serviceAddress}/delivery/keyRegistryId/${element.keyRegistryId}/keyRegistryManagementId/${element.keyRegistryManagementId}`			
			);
		}else{
			navigate(
				`/shift/${shiftId}/keys/${keyManagementId}/${element.keyRegistryManagementId}/delivery`
			);
		}
	};

	const riconsegnaKey = (element: KeyTableType) => {
		if(!shiftId){
			navigate(
				`/keys/customer/${customer}/keyManagementId/${keyManagementId}/serviceAddress/${serviceAddress}/riconsegna/keyRegistryId/${element.keyRegistryId}/keyRegistryManagementId/${element.keyRegistryManagementId}/answerId/${element.answerId}`
			);
		}else{
			navigate(
				`/shift/${shiftId}/keys/${keyManagementId}/${element.keyRegistryManagementId}/${element.keyRegistryId}/answerId/${element.answerId}/riconsegna`
			);
		}
	};
	const deliveredKey = (element: KeyTableType) => {
		if(!shiftId){
			navigate(
				`/keys/customer/${customer}/keyManagementId/${keyManagementId}/serviceAddress/${serviceAddress}/delivered/keyRegistryId/${element.keyRegistryId}/keyRegistryManagementId/${element.keyRegistryManagementId}/answerId/${element.answerId}`			
			);
		}else{
			navigate(
				`/shift/${shiftId}/keys/${keyManagementId}/${element.keyRegistryManagementId}/answerId/${element.answerId}/delivered`
			);
		}
	};

	const bloccataKey = (element: KeyTableType) => {
		if(!shiftId){
			navigate(
				`/keys/customer/${customer}/keyManagementId/${keyManagementId}/serviceAddress/${serviceAddress}/bloccata/keyRegistryId/${element.keyRegistryId}/keyRegistryManagementId/${element.keyRegistryManagementId}/answerId/${element.answerId}`			
			);
		}else{
			navigate(
				`/shift/${shiftId}/keys/${keyManagementId}/${element.keyRegistryManagementId}/answerId/${element.answerId}/bloccata`
			);
		}
	};

	const noKeysFound: boolean =
		(isStorico && keyManagements.length == 0) ||
		(!isStorico && storedKeys.length == 0 && deliveredKeys.length == 0);

	return (
		<Container className="container-result-component mt-3 p-0">
			{noKeysFound ? (
				<KeyNoResult label={labels.keyStoricoEmptyState}/>
			) : (
				<>
					<div className="nav_element">
						<div className="nav_element__single">{labels.keyCodeLabel}</div>
						<div className="nav_element__single">
							{labels.keyDescriptionLabel}
						</div>
						<div className="nav_element__single">
							{isStorico ? labels.keyClosedLabel : labels.keyDeliveredTheLabel}
						</div>
						<div className="nav_element__single">
							{labels.keyDeliveredToLabel}
						</div>
						<div className="nav_element__single">{labels.keyStatusLabel}</div>
					</div>
					{keysFormatted !== undefined &&
						keysFormatted.length > 0 &&
						keysFormatted.map((element: KeyTableType, i: number) => (
							<div className="item_element" key={i}>
								<div className="item_element__single">
									<b className="sizeb">{element.keyCode}</b>
								</div>
								<div className="item_element__single">
									<b className="sizeb">{element.description}</b>
								</div>
								<div className="item_element__single">
									<b className="sizeb">
										{isStorico
											? element.keyInboundTimestamp
											: element.keyOutboundTimestamp}
									</b>
								</div>
								<div className="item_element__single">
									<b className="sizeb">{element.keyOutboundRecipient}</b>
								</div>
								<div className="item_element__single">
									{element.status.toLowerCase() === KeyStatus.stored && (
										<div
											onClick={() => {
												!isStorico
													? deliveryKey(element)
													: deliveredKey(element);
											}}
											className={`item_element__single__type ${
												isStorico
													? "item_element__single__consegnato"
													: "item_element__single__key_stored"
											} cursor-pointer`}
										>
											{isStorico
												? labels.keyStatusConsegnate
												: labels.keyStatusDisponibile}
										</div>
									)}
									{element.status.toLowerCase() === KeyStatus.delivered && (
										<div
											onClick={() => {
												riconsegnaKey(element);
											}}
											className="item_element__single__type item_element__single__key_delivered cursor-pointer"
										>
											{labels.keyStatusRiconsegna}
										</div>
									)}
									{element.status.toLowerCase() === KeyStatus.anomaly && (
										<div
											onClick={() => {
												bloccataKey(element);
											}}
											className="item_element__single__type item_element__single__lost  cursor-pointer"
										>
											{labels.keyStatusBloccata}
										</div>
									)}
								</div>
							</div>
						))}

					{keysFormatted && keysFormatted.length === 0 && (
						<div className="empty-search-result-container">
							<Form.Label htmlFor="key-label" className="key-label mb-0">
								{labels.keyLablesNoResultState}
							</Form.Label>
						</div>
					)}
				</>
			)}
		</Container>
	);
};

export default KeyResultsComponent;
