import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Title from './Title'
import Subtitle from './Subtitle'

import { updateState } from 'features/utils/formComponentsStateUpdate'
import { preventSubmitOnEnter } from 'features/utils/preventSubmitOnEnter'

import './text.scss'

import { labels } from 'features/locale/it-it'
import { ChecklistFormItemsPropsType } from 'features/storeManagement/services/models/ChecklistResponse'

const Text = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	answerDetail,
	formData,
	setFormData,
	sectionAndQuestionId,
	isExecutable,
	placeholder,
	isValidEmail,
	isExistingEmail,
	onChangeHandler
}: ChecklistFormItemsPropsType) => {
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`
	const [errorMessage, setErrorMessage] = useState<string>("")

	useEffect(()=>{
		if(typeof isValidEmail !== "undefined" && isValidEmail === false){
			setErrorMessage(labels.errorEmail)
		}else if(typeof isExistingEmail !== "undefined" && isExistingEmail === true){
			setErrorMessage(labels.errorExistingEmail)
		}
		else{
			setErrorMessage("")
		}
	},[isValidEmail, isExistingEmail])

	
	return (
		<Form.Group className='text-group-container'>
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<Form.Control
				type='text'
				id={`question-${id}`}
				defaultValue={(formData[formDataKeyForComponent] || [])[0]}
				readOnly={!isExecutable}
				required={isMandatory}
				disabled={!enabled}
				placeholder={placeholder ? placeholder : labels.insertYourAnswer}
				className='text-container'
				onKeyDown={e => preventSubmitOnEnter(e)}
				onKeyUp={() => onChangeHandler && onChangeHandler()}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{
					updateState.updateTextState(e.target.value, setFormData, formDataKeyForComponent)
				}
				}
			/>
			<Form.Control.Feedback type='invalid'>
				{labels.mustSelectOne}
			</Form.Control.Feedback>
			<div className={'text-feedback-message'}>{errorMessage}</div>

		</Form.Group>
	)
}

export default Text