import { FC, useContext } from 'react'
import { CloseButton } from 'react-bootstrap'
import classNames from 'classnames'
import AppContext from 'theme/context/Context'
import { IFalconCloseButton } from 'features/types/common/falconCloseButton'

const FalconCloseButton: FC<IFalconCloseButton> = function FalconCloseButton({
	size = 'lg',
	onClick,
	noOutline,
	variant = 'white',
	className,
	...rest
}) {
	const {
		config: { isDark }
	} = useContext(AppContext)
	return (
		<CloseButton
			variant={variant || (isDark ? 'white' : undefined)}
			className={classNames(className, {
				[`btn-${size}`]: size,
				'outline-none': noOutline
				// [className]: className
			})}
			onClick={onClick}
			{...rest}
		/>
	)
}

export default FalconCloseButton
