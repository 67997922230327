import React, { FC } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'theme/components/common/Flex';
import VerifiedBadge from 'theme/components/common/VerifiedBadge';
import { IProfileExperience } from 'features/types/user/profile';

const Experience: FC<IProfileExperience> = function Experience({ experience, isLast }) {
	const {
		logo,
		title,
		company,
		startDate,
		endDate,
		duration,
		location,
		verified
	} = experience;

	return (
		<Flex>
			<Link to="#!">
				<Image fluid src={logo} width={56} />
			</Link>
			<div className="flex-1 position-relative ps-3">
				<h6 className="fs-0 mb-0">
					{title}
					{verified && <VerifiedBadge placement={undefined} />}
				</h6>
				<p className="mb-1">
					<Link to="#!">{company}</Link>
				</p>
				<p className="text-1000 mb-0">
					{`${startDate} - ${endDate} • ${duration}`}
				</p>
				<p className="text-1000 mb-0">{location}</p>
				{!isLast && <div className="border-dashed border-bottom my-3" />}
			</div>
		</Flex>
	);
}
export default Experience;
