import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
	parcelApi,
	usePostAParcelMutation,
} from "features/storeManagement/services/parcelService";
import {
	PostOneParcelRequest,
	WareHousings,
} from "features/storeManagement/services/models/ParcelsResponse";
import { formDataParcelAnswersToApiRequestTransformer } from "features/utils/formComponentsStateUpdate";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import { labels } from "features/locale/it-it";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

const useParcelForm = (parcelId?: string, warehouses?: WareHousings[]) => {
	const [formData, setFormData] = useState<ChecklistFormStateType>({});

	const [validated, setValidated] = useState<boolean>(false);
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const { shiftId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { customer, contract, position, serviceAddress } =
		useParams();
	const [title, setTitle] = useState<string>("");
	const [bodyTextAlert, setbodyTextAlert] = useState<string>("");
	const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
	const isOperatoreResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;

	const [
		postAParcel,
		{
			data: postOneParcelData,
			isLoading: postOneParcelDataIsLoading,
			error: postOneParcelDataError,
		},
	] = usePostAParcelMutation();

	useEffect(() => {
		if (postOneParcelData) {
			if (
				(postOneParcelData.sendNotification &&
					postOneParcelData.title &&
					postOneParcelData.body) ||
				postOneParcelData.bodyDelivery
			) {
				setShowAlertModal(true);
				setTitle(postOneParcelData.title);
				if (postOneParcelData.body) setbodyTextAlert(postOneParcelData.body);
				else if (postOneParcelData.bodyDelivery)
					setbodyTextAlert(postOneParcelData.bodyDelivery);
				dispatch(parcelApi.util.resetApiState());
			} else {
				const url = isOperatoreResponsabile
					? `/parcel/${parcelId}/customer/${customer}/serviceAddress/${serviceAddress}`
					: `/shift/${shiftId}/parcel`;
				navigate(url);
				dispatch(parcelApi.util.resetApiState());
			}
		}
	}, [postOneParcelData]);

	const form = useRef(null);

	useEffect(
		() =>
			setIsFormValid(
				!!(
					form &&
					form.current &&
					(form.current as HTMLFormElement).checkValidity() &&
					formData[`${labels.parcelSelectKey} `]?.length > 0 &&
					formData[`${labels.parcelRecipientPersonKey} `]?.length > 0 &&
					formData[`${labels.parcelDeliveryPersonKey} `]?.length > 0
				)
			),
		[formData]
	);

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		event.preventDefault();
		event.stopPropagation();
		let isValidForm: boolean =
			event.currentTarget && event.currentTarget.checkValidity();
		if (isValidForm && parcelId) {
			const paramsPostAParcel: PostOneParcelRequest = {
				parcelId,
				parcelBodyRequest: {
					shiftId: shiftId || "",
					status: 0,
					note: null,
					...formDataParcelAnswersToApiRequestTransformer(
						formData,
						warehouses
					).toPost(),
				},
			};
			postAParcel(paramsPostAParcel);
		}

		setValidated(true);
	};

	return {
		handleSubmit,
		isLoading: postOneParcelDataIsLoading,
		error: postOneParcelDataError,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		showConfirmModal,
		setShowConfirmModal,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
	};
};

export default useParcelForm;
