import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const CircleArrowRightIcon = (props: any) => (
	<FontAwesomeIcon
		icon={faCircleArrowRight}
		width={24}
		height={24}
		size={props.size ? props.size : "sm"}
		{...props}
	/>
);

export default CircleArrowRightIcon;
