import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'theme/assets/img/icons/spot-illustrations/45.png';
import { ILogoutContent } from 'features/types/authentication/logoutContent';

const LogoutContent: FC<ILogoutContent> = function LogoutContent({ layout, titleTag: TitleTag }) {
	return (
		<>
			<img className="d-block mx-auto mb-4" src={logoutImg} alt="shield" width={100} />
			<TitleTag>See you again!</TitleTag>
			<p>
				Thanks for using Falcon. You are <br className="d-none d-sm-block" />
				now successfully signed out.
			</p>
			<Link to={`/authentication/login`}>
				<Button color="green" size="sm" className="mt-3">
					<FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="me-1" />
					Return to Login
				</Button>
			</Link>
		</>
	);
};

export default LogoutContent;
