import ModulePageTitle from "components/modules/ModulePageTitle";
import { useGetIncidentQuestionsResponsabileMutation } from "features/storeManagement/services/IncidentService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Section } from "features/storeManagement/services/models/ChecklistResponse";
import ChecklistReport from "components/modules/Checklist/ChecklistReport/ChecklistReport";
import ChecklistForm from "components/modules/Checklist/ChecklistForm/ChecklistForm";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import Suspense from "features/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const ResponsabileIncidentPage = () => {
	const { incidentId, draftId } = useParams();
	useChangeDocumentTitle(labels.pageTile_IncidentPage);
	const [errorIncident, setErrorIncident] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	const [
		getIncidentQuestionsResponsabile,
		{
			data: incidentQuestionsData,
			isLoading: incidentQuestionsIsLoading,
			error: incidentError,
		},
	] = useGetIncidentQuestionsResponsabileMutation({
		fixedCacheKey: fixedCacheKeys.incidentQuestionsData,
	});

	useEffect(() => {
		if (incidentId) {
			getIncidentQuestionsResponsabile({ incidentId, answerId: draftId });
		}
	}, []);

	const fakeSection: Section[] | undefined =
		incidentQuestionsData &&
		([
			{
				id: incidentQuestionsData.id,
				title: incidentQuestionsData.title,
				questions: incidentQuestionsData?.questions,
			},
		] as Section[]);

	return (
		<Suspense isLoading={incidentQuestionsIsLoading} fullPage>
			<ErrorBoundary errorMessage={incidentError || errorIncident}>
				<Container className="incident-page-container p-0 px-4 mb-0 mt-4 ">
					{incidentQuestionsData && (
						<ModulePageTitle
							title={incidentQuestionsData?.description}
							date={incidentQuestionsData.lastUpdateDate}
							isPrint={
								incidentQuestionsData && incidentQuestionsData.completedAt
									? true
									: false
							}
							author={incidentQuestionsData.createdBy}
						/>
					)}

					{fakeSection && fakeSection.length && (
						<>
							{incidentQuestionsData && incidentQuestionsData.completedAt ? (
								<ChecklistReport sections={fakeSection} />
							) : (
								<ChecklistForm
									sections={fakeSection}
									isExecutable
									isIncident
									setError = {setErrorIncident}
									refreshData={() => {
										if (incidentId) {
											getIncidentQuestionsResponsabile({
												incidentId,
												answerId: draftId,
											});
										}
									}}
								/>
							)}
						</>
					)}
				</Container>
			</ErrorBoundary>
		</Suspense>
	);
};

export default ResponsabileIncidentPage;
