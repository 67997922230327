import { useGetReportMutation } from "features/storeManagement/services/reportService";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./style.scss";
import { Container } from "react-bootstrap";
import Suspense from "features/Suspense";
import {
	FilterReport,
	PBIFilterTypes,
} from "features/storeManagement/services/models/ReportResponse";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { LogicalOperator, ReportPBISchema } from "./ReportPageConstants";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";

export interface BasicFilter {
	$schema: string;
	target: { table: string; column: string };
	operator: string;
	values: string[];
	filterType: models.FilterType;
}

export interface FilterCondition {
	operator: string;
	value: string;
}

export interface AdvancedFilter {
	$schema: string;
	target: { table: string; column: string };
	logicalOperator: string;
	conditions: FilterCondition[];
	filterType: models.FilterType;
}

const ReportPage: FC = function ClientePage() {
	const { reportId } = useParams();
	useChangeDocumentTitle(labels.pageTitle_Report);

	const [
		getReport,
		{ data: reportData, isError: reportIsError, isLoading: reportIsLoading, error: reportError },
	] = useGetReportMutation({});

	useEffect(() => {
		if (reportId) {
			getReport(reportId);
		}
	}, [reportId]);

	const filtersArray: (BasicFilter | AdvancedFilter)[] = [];

	useEffect(() => {
		if (reportData?.filters && reportData?.filters.length > 0) {
			reportData.filters.forEach((report: FilterReport) => {
				if (report.operatorType === PBIFilterTypes.Basic) {
					// caso BASIC filters
					filtersArray.push({
						$schema: ReportPBISchema.BasicFilterSchema,
						target: {
							table: report.tableName,
							column: report.key,
						},
						operator: report.operator,
						values: report.value,
						filterType: models.FilterType.Basic,
					});
				} else if (report.operatorType === PBIFilterTypes.Advanced) {
					// caso ADVANCED filters
					filtersArray.push({
						$schema: ReportPBISchema.AdvancedFilterSchema,
						target: {
							table: report.tableName,
							column: report.key,
						},
						logicalOperator: report.logicalOperator,
						conditions: report.conditions,
						filterType: models.FilterType.Advanced,
					});
				}
			});
		}
	}, [reportData]);

	return (
		<ErrorBoundary errorMessage={reportError}>
			<Container className="report-container p-0">
				<Suspense isLoading={reportIsLoading} fullPage>
					<PowerBIEmbed
						embedConfig={{
							type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
							id: reportData?.powerBiReportId,
							embedUrl: reportData?.embedUrl,
							accessToken: reportData?.powerBiToken,
							tokenType: models.TokenType.Embed,
							filters: filtersArray,
							settings: {
								panes: {
									filters: {
										expanded: false,
										visible: false,
									},
								},
								background: models.BackgroundType.Transparent,
							},
						}}
						cssClassName="reportClass"
					/>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ReportPage;
