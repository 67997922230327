import ArrowIcon from "assets/icons/ArrowIcon";
import ShiftDateIcon from "assets/icons/ShiftDateIcon";
import ShiftTimeIcon from "assets/icons/ShiftTimeIcon";
import { labels } from "features/locale/it-it";
import { dateParser } from "features/utils/dateUtils";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './style.scss'

interface ShiftItemProps {
	customer : string;
    type: string;
	id: string | number;
	address: string;
	venueName: string;
	startDate: Date;
	endDate: Date;
}

const ShiftItem: React.FC <ShiftItemProps> = ({id, customer, type, address, venueName, startDate, endDate}) => {	
	const parser = dateParser(startDate);
	const navigate = useNavigate();
	return (
		<Row className="d-flex justify-content-space-between align-items-center shift-box flex-nowrap cursor-pointer"
			onClick={() => navigate(`/shift/${id}`)}>
			<Col className="col-1 mx-1"></Col>
			<Col className="col-9 shift-details ps-sm-4 ps-md-2">
				<Row className="shift-type">
					<Col className="blue-text bold-text">
						<div>{type}</div>
					</Col>
				</Row>
				<Row className="client-name">
					<Col className="blue-text bold-text">{customer.toLowerCase()}</Col>
				</Row> 
				<Row className="address">
					<Col className="">{address.toLowerCase()}</Col>
				</Row>
				<Row className="address">
					<Col className="">{venueName.toLowerCase()}</Col>
				</Row>
				<Row className="d-flex flex-nowrap shift-time-container">
					<Col className="datetime-box small start-date px-0 col-6">
						<div className="info d-flex flex-column">
							<span className="label">{labels.dataInizio}</span>
							<span className='day'>{parser.getDay()}</span>
						</div>
					</Col>
					<Col className="datetime-box small start-hour px-0 col-6">
						<div className="info d-flex flex-column">
							<span className="label">{labels.oraInizio}</span>
							<span className='hour'>{parser.getHour().substring(0,5)}</span>
						</div>
					</Col>
				</Row>
			</Col>
			<Col className="col-2 arrow p-0 text-start">
				<ArrowIcon />
			</Col>
		</Row>
	)
}

export default ShiftItem