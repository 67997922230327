import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoggedIn: null,
	rememberMe: false,
	firstName : '',
	lastName : '',
}

export const loggedInSlice = createSlice({
	name: 'loggedInSlice',
	initialState,
	reducers: {
		setIsLoggedIn: (state, action) => { state.isLoggedIn = action.payload },
		setRememberMe: (state, action) => { state.rememberMe = action.payload },
		setUserInfo: (state, action) => { 
			const userInfo = window.atob(action.payload).split(':');
			state.firstName =  userInfo[0] as string;
			state.lastName =  userInfo[1] as string;
		},

	}
})

export const { setIsLoggedIn, setRememberMe, setUserInfo } = loggedInSlice.actions

export default loggedInSlice.reducer