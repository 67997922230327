import "./style.scss";
import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Answer } from "features/storeManagement/services/models/ChecklistResponse";
import { labels } from "features/locale/it-it";
import ModuleState from "components/modules/ModuleState";
import ModulePageTitle from "components/modules/ModulePageTitle";
import EmptyPageNote from "components/content/EmptyPageNote";
import Suspense from "features/Suspense";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { useGetAllAuditsMutation } from "features/storeManagement/services/checklistService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { Checklist } from "features/storeManagement/services/models/ChecklistResponse";
import {
	CustomerAddress,
	CustomerResponse,
} from "features/storeManagement/services/models/CustomerResponse";
import { useGetCustomerMutation } from "features/storeManagement/services/customerService";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

const AuditItemsPage = () => {
	const navigate = useNavigate();
	const { auditId, customer, serviceAddress, internal } =
		useParams();
	const isResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;
	const isCliente =
		getValuesForUser().getUserRole() === roles.cliente.key;
	const [selectedCustomer, setSelectedCustomer] = React.useState<
		CustomerResponse | undefined | null
	>(undefined);
	const [selectedAddress, setSelectedAddress] = React.useState<
		CustomerAddress | undefined | null
	>(undefined);
	const customerContext =
		customer !== undefined;
	const customerURL = customerContext
		? `customer/${customer}/serviceAddress/${serviceAddress}/internal/${internal}`
		: "";
	const responsabileUrl = customerContext
		? `customer/${customer}/serviceAddress/${serviceAddress}`
		: "";


	const redirectUrl = () => {
		if(isResponsabile && currentAudit) {
			return `/checklist/${currentAudit.id}/${responsabileUrl}/new`
		}else if(isCliente){
			return `/checklist/${auditId}/new`
		}else{
			return `/audit/${auditId}/customer/${customer}/serviceAddress/${serviceAddress}/internal/${internal}/new`
		}
	}
	
	const location = useLocation();
	let titlePage = '';

	if (location.pathname.includes("checklist")) {
		titlePage = labels.pageTile_ChecklistItemsPage;
	} else {
		titlePage = labels.pageTile_AuditItemsPage;
	}
	useChangeDocumentTitle(titlePage);

	const [currentAudit, setCurrentAudit] = React.useState<Checklist | undefined>(
		undefined
	);

	const [getAllAudits, { data: allAudits, isLoading: allAuditsIsLoading }] =
		useGetAllAuditsMutation({ fixedCacheKey: fixedCacheKeys.allAuditsData });

	const [
		getCustomers,
		{
			data: customersData,
			isLoading: customersIsLoading,
			isError: customerError,
		},
	] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData });

	useEffect(() => {
		if (!customersIsLoading && !!customersData) {
			const currentCustomer = customersData?.find(
				(item: CustomerResponse) => item.idCustomer === customer
			);
			setSelectedCustomer(currentCustomer || null);
		}
	}, [customersIsLoading, customersData]);

	useEffect(() => {
		if (!customersData && !customerError && !customersIsLoading) {
			getCustomers();
		}
	}, [customersData, customersIsLoading]);

	useEffect(() => {
		if (
			!customersIsLoading &&
			customersData &&
			(selectedCustomer || selectedCustomer === null)
		) {
			const currentAddress = selectedCustomer?.addresses.find(
				(item: CustomerAddress) =>
					customer &&
					serviceAddress &&
					item.idCustomer === customer &&
					item.serviceAddress === serviceAddress
			);
			setSelectedAddress(currentAddress || null);
		}
	}, [customersIsLoading, selectedCustomer]);

	useEffect(() => {
		const readyForGenericAuditCall =
			selectedCustomer === null && selectedAddress === null;
		if (!customersIsLoading && (selectedAddress || readyForGenericAuditCall)) {
			const params = selectedAddress
				? {
					idCustomer: selectedAddress.idCustomer,
					serviceAddress: selectedAddress.serviceAddress,
				  }
				: {
					internalAudit: internal,
				  };
			getAllAudits(params);
		}
	}, [customersIsLoading, selectedCustomer, selectedAddress]);

	useEffect(() => {
		if (!allAuditsIsLoading) {
			const filtered =
				allAudits &&
				allAudits.items.find((item) => item.id === Number(auditId));
			setCurrentAudit(filtered);
		}
	}, [allAudits, auditId]);


	return (
		<Container className="audit-items-page-container p-0 px-4 mb-0 mt-4 ">
			<Suspense isLoading={allAuditsIsLoading} fullPage>
				<Row>
					<Col className="items-container px-0 my-0">
						<ModulePageTitle title={currentAudit && currentAudit.description} />
					</Col>
				</Row>
				{!allAuditsIsLoading && currentAudit && (
					<Row className="sticky-button-container mt-4">
						<Col className="items-container p-0 my-0">
							<Button
								className="start-new-draft-button--start"
								variant="green"
								disabled={allAuditsIsLoading}
								onClick={() => {
									navigate(
										redirectUrl()
									);
								}}
							>
								<span>{labels.startNewCompilation}</span>
							</Button>
						</Col>
					</Row>
				)}
				{currentAudit &&
					currentAudit.answers &&
					(currentAudit.answers.length > 0 ? (
						<Row>
							<Col className="items-container">
								{currentAudit.answers.map((answer: Answer) => {
									return (
										<ModuleState
											key={answer.id}
											id={answer.id}
											date={answer.lastUpdateDate}
											state={answer.status}
											baseRedirectUrl={(isResponsabile || isCliente) ? `/checklist/${currentAudit.id}` : `/audit/${currentAudit.id}`}
											customerAddUrl={isResponsabile ? responsabileUrl : customerURL}
											type={"checklist"}
										/>
									);
								})}
							</Col>
						</Row>
					) : (
						<Row>
							<Col className="items-container">
								<EmptyPageNote
									note={labels.noChecklistYet}
									isLoading={allAuditsIsLoading}
									className="empty-page-note"
								/>
							</Col>
						</Row>
					))}
			</Suspense>
		</Container>
	);
};

export default AuditItemsPage;
