import { useState, FC } from 'react';
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css';
import { IFalconLightBoxGallery } from 'features/types/common/falconLightBox';

const FalconLightBoxGallery: FC<IFalconLightBoxGallery> = function FalconLightBoxGallery({ images, children }) {
	const [imgIndex, setImgIndex] = useState<number>(0);
	return (
		<div>
			{children(setImgIndex)}
			{imgIndex !== null && (
				<Lightbox mainSrc={images[imgIndex]} nextSrc={
					imgIndex + 1 > images.length ? undefined : images[imgIndex + 1]
				} prevSrc={imgIndex - 1 < 0 ? undefined : images[imgIndex - 1]} onCloseRequest={(): void => setImgIndex(0)} onMovePrevRequest={(): void =>
					setImgIndex((imgIndex + images.length - 1) % images.length)
				} onMoveNextRequest={(): void => setImgIndex((imgIndex + 1) % images.length)} reactModalStyle={{ overlay: { zIndex: 999999 } }}
				/>
			)}
		</div>
	);
};

export default FalconLightBoxGallery;
