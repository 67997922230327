import { useState, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, Dropdown } from 'react-bootstrap';
import Flex from 'theme/components/common/Flex';
import cloudUpload from 'theme/assets/img/icons/cloud-upload.svg';
import CardDropdown from 'theme/components/common/CardDropdown';
import { getSize } from 'theme/helpers/utils';
import { IEventUpload } from 'features/types/events/createEvent';
import { nanoid } from '@reduxjs/toolkit';

const EventUpload: FC<IEventUpload> = function EventUpload({ setValue }) {
	const [files, setFiles] = useState<Object[]>([]);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: acceptedFiles => {
			setFiles([...files, ...acceptedFiles.map(file =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			)
			]);
		}
	});

	const handleRemove = (path: any): void => {
		setFiles(files.filter((file: any) => file.path !== path));
	};

	return (
		<Card className="mb-3">
			<Card.Header as="h5">Upload Photos</Card.Header>
			<Card.Body className="bg-light">
				<div {...getRootProps({ className: 'dropzone-area py-6' })}>
					<input name="uploadedFiles"{...(setValue && { ...setValue('uploadedFiles', files) })}{...getInputProps()}
					/>
					<Flex justifyContent="center">
						<img src={cloudUpload} alt="" width={25} className="me-2" />
						<p className="fs-0 mb-0 text-700">Drop your images here</p>
					</Flex>
				</div>
				<div>
					{files.map((file: any) => (
						<Flex alignItems="center" className="py-3 border-bottom btn-reveal-trigger" key={nanoid()}
						>
							<img className="rounded" width={40} height={40} src={file.preview} alt={file.path}
							/>

							<Flex justifyContent="between" alignItems="center" className="ms-3 flex-1"
							>
								<div>
									<h6>{file.path}</h6>
									<Flex className="position-relative" alignItems="center">
										<p className="mb-0 fs--1 text-400 line-height-1">
											<strong>{getSize(file.size)}</strong>
										</p>
									</Flex>
								</div>
							</Flex>
							<CardDropdown>
								<div className="py-2">
									<Dropdown.Item className="text-danger" onClick={(): void => handleRemove(file.path)}
									>
										Remove
									</Dropdown.Item>
								</div>
							</CardDropdown>
						</Flex>
					))}
				</div>
			</Card.Body>
		</Card>
	);
};


export default EventUpload;
