import Error403 from "components/content/errors/Error403"
import CoordinatorePage from "components/pages/HomePages/Coordinatore"
import CorrispondentePage from "components/pages/HomePages/Corrispondente"
import OperatorePage from "components/pages/HomePages/Operatore"
import AdminPage from "components/pages/HomePages/Admin"

import { roles } from 'features/storeManagement/services/constants/roles'
import ResponsabilePage from "components/pages/HomePages/Resposnabile/ResponsabilePage"
import CustomerPage from "components/pages/HomePages/Cliente/CustomerPage"

export const getValuesForUser = () => {
	const group = sessionStorage.getItem('primaryGroup') || localStorage.getItem('primaryGroup');

	const getHomePageForUser = () => {
		switch (group) {
		case roles.operatore.key:
			return { label: roles.operatore.label, component: <OperatorePage /> };
		case roles.corrispondente.key:
			return { label: roles.corrispondente.label, component: <CorrispondentePage /> };
		case roles.coordinatore.key:
			return { label: roles.coordinatore.label, component: <CoordinatorePage /> };
		case roles.admin.key:
			return { label: roles.admin.label, component: <AdminPage /> };
		case roles.cliente.key:
			return { label: roles.cliente.label, component: <CustomerPage /> };
		case roles.responabile.key:
			return { label: roles.responabile.label, component: <ResponsabilePage /> };
		default:
			return { label: '', component: <Error403 /> };
		}
	}

	const getUserRole = () => group

	return { getHomePageForUser, getUserRole }
}