import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IVerifiedBadge } from 'features/types/user/profile';

const VerifiedBadge: FC<IVerifiedBadge> = function VerifiedBadge({ placement = 'top' }) {
	return (
		<OverlayTrigger placement={placement} overlay={<Tooltip>Verified</Tooltip>}>
			<span>
				<FontAwesomeIcon icon="check-circle" transform="shrink-4 down-2" className="text-primary me-1" />
			</span>
		</OverlayTrigger>
	);
};



export default VerifiedBadge;
