import './style.scss'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { ChecklistItemProps } from 'features/types/checklist/ChecklistItemProps'
import { Link } from 'react-router-dom'
import { getValuesForUser } from 'features/utils/userRoleUtils'
import { roles } from 'features/storeManagement/services/constants/roles'

const ChecklistItem: React.FC<ChecklistItemProps> = ({checklist, icon, internal}) => {
	const navigate = useNavigate()
	const { shiftId, customer, serviceAddress } = useParams();
	const isResponsabile = getValuesForUser().getUserRole() === roles.responabile.key;
	const isCliente = getValuesForUser().getUserRole() === roles.cliente.key;

	const getUrlToNavigate = () => {
		if(!!shiftId) {
			return `/shift/${shiftId}/checklist/${checklist.id}`
		}

		let url = `/checklist/${checklist.id}`; 
		if (isResponsabile) {
			return url+`/customer/${checklist.idCustomer}/serviceAddress/${serviceAddress}`
		}
		url = `/audit/${checklist.id}`; 
		if(!!checklist.idCustomer && !!serviceAddress) {
			return url+`/customer/${checklist.idCustomer}/serviceAddress/${serviceAddress}/internal/${internal}`
		} else if(!!customer && !!serviceAddress) {
			return url+`/customer/${customer}/serviceAddress/${serviceAddress}/internal/${internal}`
		}else if(isCliente){
			return `/checklist/${checklist.id}`
		}

		return url;
	}

	return (
		<Col 
			className='checklist-item-column px-0 px-md-2 d-flex justify-content-center'
			onClick={() => navigate(getUrlToNavigate())}
		>
			<Link to={getUrlToNavigate()} className='d-none' />
			<Row className='checklist-item-container align-items-center cursor-pointer m-0 my-1 my-md-3 py-1 px-2 pos-rel'>
				<div className='container-icon'>
					{icon}
				</div>
				<Col className='checklist-item'>
					<Row className='d-flex mb-2'>
						<Col className='checklist-title blue-text d-flex align-items-center text-center p-0 col-12'>
							{checklist?.description}
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	)
}

export default ChecklistItem
