import { IActivities } from 'features/types/data'

const activities: IActivities[] = [
	{
		id: 1,
		avatar: {
			emoji: '🔍',
			size: 'xl'
		},
		children:
			'<strong>Anthony Hopkins</strong> Followed <strong>Massachusetts Institute of Technology</strong>',
		time: 'Just Now'
	},
	{
		id: 2,
		avatar: {
			emoji: '📌',
			size: 'xl'
		},
		children:
			'<strong>Anthony Hopkins</strong> Save a <strong>Life Event</strong>',
		time: 'Yesterday'
	},
	{
		id: 3,
		avatar: {
			emoji: '🏷️',
			size: 'xl'
		},
		children:
			'<strong>Rowan Atkinson</strong> Tagged <strong>Anthony Hopkins</strong> in a live video',
		time: 'December 1, 8:00 PM'
	},
	{
		id: 4,
		avatar: {
			emoji: '💬',
			size: 'xl'
		},
		children:
			'<strong>Robert Downey</strong> mention <strong>Anthony Hopkins</strong> in a comment',
		time: 'November 27, 12:00 AM'
	},
	{
		id: 5,
		avatar: {
			emoji: '😂',
			size: 'xl'
		},
		children:
			'<strong>Anthony Hopkins</strong> reacted to a comment of <strong>Anna Karinina</strong>',
		time: 'November 20, 8:00 Am'
	},
	{
		id: 6,
		avatar: {
			emoji: '🎁',
			size: 'xl'
		},
		children:
			'<strong>Jennifer Kent</strong> Congratulated <strong>Anthony Hopkins</strong>',
		time: 'November 13, 5:00 Am'
	},
	{
		id: 7,
		avatar: {
			emoji: '🏷️',
			size: 'xl'
		},
		children:
			'<strong>California Institute of Technology</strong> tagged <strong>Anthony Hopkins</strong> in a post.',
		time: 'November 8, 5:00 PM'
	},
	{
		id: 8,
		avatar: {
			emoji: '📋️',
			size: 'xl'
		},
		children:
			'<strong>Anthony Hopkins</strong> joined <strong>Victory day cultural Program</strong> with <strong>Tony Stark</strong>',
		time: 'November 01, 11:30 AM'
	},
	{
		id: 9,
		avatar: {
			emoji: '📅️',
			size: 'xl'
		},
		children:
			'<strong>Massachusetts Institute of Technology</strong> invited <strong>Anthony Hopkin</strong> to an event',
		time: 'October 28, 12:00 PM'
	}
]
export default activities
