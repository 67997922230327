import React from "react";
import {
	Question,
	SectionPropsType,
} from "features/storeManagement/services/models/ChecklistResponse";

import {
	renderTheFormComponent,
	RenderPictureFormComponent,
} from "./renderTheFormComponent";
import { Container } from "react-bootstrap";
import "./section.scss";
import { labels } from "features/locale/it-it";

const Section = ({
	section,
	setFormData,
	formData,
	isExecutable,
	validated,
	isIncident = false,
	mediaArray,
	medias,
	deleteIsLoading,
	showDeleteConfirmModal,
	mediaIdToDel,
	deleteAJournalMedia,
	setShowConfirmModal,
	setMediaIdToDel,
	setShowDeleteConfirmModal,
	setMediaArray,
	refreshData
}: SectionPropsType) => {
	const renderedComponents = section.questions.map(
		(question: Question, index) => {
			const theCompenentToBeRendered =
				question.type === labels.picture
					? RenderPictureFormComponent({
						...question,
						setFormData: setFormData,
						formData: formData,
						sectionAndQuestionId: `${section.id} ${question.id}`,
						isExecutable,
						validated,
						mediaArray,
						setMediaArray,
						medias: question.answerDetail?.medias,
						deleteIsLoading,
						showDeleteConfirmModal,
						mediaIdToDel,
						deleteAJournalMedia,
						setShowConfirmModal,
						setMediaIdToDel,
						setShowDeleteConfirmModal,
						refreshData
					  })
					: renderTheFormComponent({
						...question,
						setFormData: setFormData,
						formData: formData,
						sectionAndQuestionId: `${section.id} ${question.id}`,
						isExecutable,
						validated,
					  });
			return (
				theCompenentToBeRendered && (
					<Container key={question.id} className="form-item-container">
						{index !== 0 && <hr />}
						{theCompenentToBeRendered}
					</Container>
				)
			);
		}
	);

	return (
		<div className="section-container">
			{!isIncident && <span className="section-title">{section.title}</span>}
			<div className="questions-container">{renderedComponents}</div>
		</div>
	);
};

export default Section;
