import * as React from "react"

const SquaresMenuIcon = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" {...props}>
		<path
      		className="blue-svg"
			d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"
		/>
	</svg>
)

export default SquaresMenuIcon