import './style.scss'

import React from 'react'
import { labels } from 'features/locale/it-it'
import { Col, Container, Row } from 'react-bootstrap'
import { dateParser } from "features/utils/dateUtils";
import { ShiftDetailsProps } from 'features/types/modules/ShiftPageProps';
import { nanoid } from '@reduxjs/toolkit';

const ShiftDetailsBottom: React.FC<ShiftDetailsProps> = ({ shift }) => {
	let checkinTime = shift.shiftCheckinTime && dateParser(new Date(shift.shiftCheckinTime));
	let checkoutTime = shift.shiftCheckoutTime && dateParser(new Date(shift.shiftCheckoutTime));
	let startTime = shift.shiftStartTime && dateParser(new Date(shift.shiftStartTime));
	let endTime = shift.shiftEndTime && dateParser(new Date(shift.shiftEndTime));

	return (
		<>
			<Row className='d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5 pt-3'>
				<Col key={nanoid()} className='datetime-box col-6 d-flex flex-column align-items-start px-1'>
					<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
						<div className="label bold-text">{labels.shiftStartTime}</div>
						<div className='day bold-text'>{!!startTime ? startTime.getDay() + ' ' + startTime.getHour().substring(0, 5) : '-'}</div>
					</div>
					<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
						<div className="label bold-text">{labels.shiftEndTime}</div>
						<div className='day bold-text'>{!!endTime ? endTime.getDay() + ' ' + endTime.getHour().substring(0, 5) : '-'}</div>
					</div>
					{shift?.jobDescriptionLink && (
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
							<div className="label bold-text">{labels.jobDescriptionLink}</div>
							<div className='day bold-text'><a href={shift.jobDescriptionLink} target="_blank" className='color_link'>{labels.read}</a></div>
						</div>
					)}
				</Col>
				{shift.checkInOutEnable && (
					<Col key='checkout-time' className='datetime-box col-6 d-flex flex-column align-items-start px-1'>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
							<span className="label bold-text">{labels.shiftCheckinTime}</span>
							<span className='day bold-text'>{!!checkinTime ? checkinTime.getDay() + ' ' + checkinTime.getHour().substring(0, 5) : '-'}</span>
						</div>
						<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
							<span className="label bold-text">{labels.shiftCheckoutTime}</span>
							<span className='day bold-text'>{!!checkoutTime ? checkoutTime.getDay() + ' ' + checkoutTime.getHour().substring(0, 5) : '-'}</span>
						</div>
					</Col>
				)}
			</Row>
		</>
	)
}

export default ShiftDetailsBottom