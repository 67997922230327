import { GuestResponseApi, GuestResponseDettaglioApi, Vehicles } from "features/storeManagement/services/models/GuestResponse";
import { Col, Container, Row } from "react-bootstrap";
import { dateParser } from "features/utils/dateUtils";
import { labels } from "features/locale/it-it";
interface dettaglioInterface {
	dataDettaglio: GuestResponseDettaglioApi;
}

const ControlloAccessiDataVeicolo = (data: dettaglioInterface) => {


	return (
		<Container className="controllo-accessi-dettaglio w-100 d-flex flex-wrap align-items-center justify-content-center mt-1 p-0 print-full-section">
			<div className="w-100">
				<label><b className="text_dettaglio_titolo">{labels.veicoliLabel}:</b></label>
			</div>
			<div className="operators_table__container mt-1">
				<div className="operators__nav_element print-title">
					<div className="operators__nav_element__single operators__headers_font">
						{"Targa"}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{"Marca"}
					</div>
					<div className="operators__nav_element__single operators__headers_font">
						{"Modello"}
					</div>
					
					<div className="operators__item_element__single operators__headers_font">
						{"Classe"}
					</div>
				</div>

				{!!data &&
				data?.dataDettaglio?.guest?.vehicles?.length > 0 &&
				data?.dataDettaglio?.guest?.vehicles.map((element: Vehicles, i: number) => (
					<div className="operators__item_element" key={i}>
						<div className="operators__item_element__single">
							<b className="operators__font">	{element.plate !== null ? element.plate: "-"}</b>
						</div>
						<div className="operators__item_element__single">
							<b className="operators__font">	{element.vendor !== null ? element.vendor: "-"}</b>
						</div>
						<div className="operators__item_element__single">
							<b className="operators__font">	{element.model !== null ? element.model: "-"}</b>
						</div>
						<div className="operators__item_element__single">
							<b className="operators__font">	{element.class !== null ? element.class: "-"}</b>
						</div>
					</div>
				))}
			</div>
		</Container>
	);
};

export default ControlloAccessiDataVeicolo;
