import { FC } from 'react';
import PasswordResetForm from './PasswordResetForm';
import bgImg from 'theme/assets/img/generic/20.jpg';
import AuthSplitLayout from 'layout/AuthLayout';

const PasswordResetPage: FC = function PasswordResetPage() {
	return (
		<AuthSplitLayout bgProps={{ image: bgImg }}>
			<div className="text-center">
				<h3>Reset password</h3>
			</div>
			<PasswordResetForm hasLabel />
		</AuthSplitLayout>
	);
};

export default PasswordResetPage;
