import React, { ReactNode } from 'react'
import SicurForceSpinner from 'components/content/SicurforceSpinner'

const Suspense = ({ isLoading, children, fullPage }: { isLoading: boolean, children: ReactNode, fullPage?: boolean }) => {
	return isLoading ?
		fullPage ?
			<SicurForceSpinner.FullPage /> :
			<SicurForceSpinner.Spinner />
		:
		<>{children}</>
}

export default Suspense