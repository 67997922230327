import { FC } from 'react';
import ForgetPasswordForm from './ForgetPasswordForm';
import bgImg from 'theme/assets/img/generic/17.jpg';
import AuthSplitLayout from 'layout/AuthLayout';

const ForgetPasswordPage: FC = function ForgetPasswordPage() {
	return (
		<AuthSplitLayout bgProps={{ image: bgImg, position: '50% 76%', overlay: true }}>
			<div className="text-center">
				<h4 className="mb-0"> Forgot your password?</h4>
				<small>Enter your email and we&apos;ll send you a reset link.</small>
				<ForgetPasswordForm />
			</div>
		</AuthSplitLayout>
	);
};

export default ForgetPasswordPage;
