import { FC, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys"
import { labels } from 'features/locale/it-it';
import './style.scss';
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle';
import { useNavigate } from 'react-router-dom';
import { useGetCustomerMutation } from 'features/storeManagement/services/customerService';
import { Form, Button } from 'react-bootstrap'
import { stringUtils } from 'features/utils/stringUtils';
import { shiftsApi, useGetCurrentShiftMutation, usePostCurrentShiftMutation } from 'features/storeManagement/services/shiftsService';
import EmptyPageNote from 'components/content/EmptyPageNote/EmptyPageNote';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import AlertModal from 'components/content/modals/AlertModal/AlertModal';
import ErrorBoundary from 'features/ErrorBoundary'
import Suspense from 'features/Suspense'
import { useDispatch } from 'react-redux';
import { selectOptionObject } from 'features/storeManagement/services/models/ResponsabileResponse';
import { CustomerAddress, CustomerResponse } from 'features/storeManagement/services/models/CustomerResponse';
import Select from 'react-select'


const CorrispondentePage: FC = function CorrispondentePage() {
	useChangeDocumentTitle(labels.pageTile_CorrispondentePage);

	const navigate = useNavigate()
	const [showEmptyNote, setShowEmptyNote] = useState<boolean>(false);
	const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
	const [customerSelection, setCustomerSelection] = useState<number>(-1);
	const [customerAddressSelection, setCustomerAddressSelection] = useState<selectOptionObject>({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""});
	const [isCustomerAddressSelectionEnabled, setIsCustomerAddressSelectionEnabled] = useState<boolean>(false);
	const [optionsAddress, setOptionsAddress] = useState<selectOptionObject[]>([{id: -1, value: "", label: labels.customerSelectDefault, serviceAddress: ""}]);

	
	const [postCurrentShift, {
		data: postCurrentShiftData,
		isLoading: postCurrentShiftIsLoading,
		error: postCurrentShiftError
	}] = usePostCurrentShiftMutation()

	const [getCustomers, {
		data: customersData,
		isLoading: customersIsLoading,
		error: customerError
	}] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData })

	const [, {
		data: currentShiftData,
		isLoading: currentShiftIsLoading,
		error: currentShiftError
	}] = useGetCurrentShiftMutation({ fixedCacheKey: fixedCacheKeys.currentShiftData })

	useEffect(() => {
		if (!currentShiftIsLoading) {
			if (currentShiftError && (currentShiftError as FetchBaseQueryError).status === 404) {
				getCustomers()
			} else if(currentShiftData && !currentShiftError) {
				const shiftIdCurrentShift = currentShiftData.shiftId
				const customerCurrentShift = currentShiftData.shiftCustomerId
				navigate(`shift/${shiftIdCurrentShift}/customer/${customerCurrentShift}`)
			}
		}
	}, [currentShiftIsLoading, currentShiftData, currentShiftError])

	useEffect(() => {
		setShowEmptyNote(!!(customersData && customersData.length === 0));
	}, [customersData])


	useEffect(() => {
		if (postCurrentShiftData) {
			const shiftId = postCurrentShiftData.shiftId
			const shiftCustomerId = postCurrentShiftData.shiftCustomerId
			navigate(`shift/${shiftId}/customer/${shiftCustomerId}`)
		}
	}, [postCurrentShiftData])


	useEffect(() => {
		if (postCurrentShiftError) {
			setShowAlertModal(true)
		}
	}, [postCurrentShiftError])


	const handleCustomerSelectionChange = (event: any) => {
		setCustomerSelection(event.id);
		setIsCustomerAddressSelectionEnabled(event.id != -1);
		setCustomerAddressSelection({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""})
	};

	const handleCustomerAddressSelectionChange = (event: any) => {
		setCustomerAddressSelection(event)
	};

	const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
		if (customersData !== undefined) {
			const customerdataId = customersData[customerSelection].addresses[customerAddressSelection.id].id;
			const serviceAddress = customersData[customerSelection].addresses[customerAddressSelection.id].serviceAddress;
			if(customerdataId !== undefined && serviceAddress){
				const postObjectShift = {
					id: customerdataId.toString(),
					serviceAddress: serviceAddress
				}
				postCurrentShift(postObjectShift)
			}
		}
	}


	const options:selectOptionObject[] = [{id: -1, value: labels.customerSelectDefault, label: labels.customerSelectDefault, serviceAddress: ""}]

	customersData?.map((singleData: CustomerResponse, i: number) => {

		options.push({id: i, value: singleData.customerName, label: stringUtils(singleData.customerName).capitalize(), serviceAddress: singleData.serviceAddress})

	})

	useEffect(()=> {
		setCustomerAddressSelection({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""});
		let addressesOptions: any[] = [];
		customersData && customersData[customerSelection]?.addresses.map((singleData: CustomerAddress, i: number) => {
			addressesOptions.push({id: i, value: singleData.fullAddress, label: stringUtils(singleData.fullAddress).capitalize(), serviceAddress: singleData.serviceAddress || ""});
		})
		setOptionsAddress(addressesOptions);
	}, [customerSelection])



	return (
		<ErrorBoundary errorMessage={customerError}>
			<Suspense isLoading={currentShiftIsLoading || customersIsLoading || postCurrentShiftIsLoading || !!postCurrentShiftData} fullPage>
				<Container fluid className='p-1'>
					{showEmptyNote &&
						<EmptyPageNote note={labels.noCurrentShiftAndCustomers} isLoading={currentShiftIsLoading} />
					}
					{customersData && !!customersData.length && !showEmptyNote &&
						<div className="select-element">
							<div className='select-element-content'>
								<Form id="form-section" onSubmit={handleSubmit}>
									<Form.Label
										htmlFor='customer-selection'
										className='customer-selection-label mb-0'
									>
										{labels.customer}
									</Form.Label>


									<Select
										placeholder={labels.customerSelectDefault}
										aria-label={labels.customer}
										onChange={handleCustomerSelectionChange}
										options={options}
									/>

									<Form.Label
										htmlFor='customer-address-selection'
										className='customer-address-selection-label mb-0'
									>
										{labels.customerAddress}
									</Form.Label>

									<Select 
										placeholder={labels.addressSelectDefault}
										aria-label={labels.addressSelectDefault}
										className={`select-field-question mb-4 w-100`}
										onChange={handleCustomerAddressSelectionChange}
										isDisabled={!isCustomerAddressSelectionEnabled}
										value={customerAddressSelection}
										options={optionsAddress}
									/>

									<div className='button-content'>
										<Button
											type="submit"
											id="submit"
											form='form-section'
											className='submit-button'
											variant="green"
											disabled={customerAddressSelection.id < 0}
										>
											{labels.homeSubmit}
										</Button>
									</div>
								</Form>
							</div>
						</div>
					}
					<AlertModal
						headerMessage={labels.watchOut}
						bodyMessage={labels.tryLaterPlease}
						okButtonText={labels.ok}
						showAlertModal={showAlertModal}
						setShowAlertModal={setShowAlertModal}
					/>
				</Container>
			</Suspense>
		</ErrorBoundary>
	);
};

export default CorrispondentePage;