import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import Suspense from "features/Suspense/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { useGetStoricoSegnalazioniMutation } from "features/storeManagement/services/guestService";
import { useParams } from "react-router";
import { useEffect } from "react";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import ModuleState from "components/modules/ModuleState/ModuleState";
import ModuleStateControlloAccessi from "components/modules/ModuleStateControlloAccessi/ModuleStateControlloAccessi";
import { Col, Container, Row } from "react-bootstrap";
import EmptyPageNote from "components/content/EmptyPageNote/EmptyPageNote";
import { guestElement } from "features/storeManagement/services/models/GuestResponse";
import './style.scss'

const StoricoSegnalazioniPage = () => {
	const { shiftId, guestConfigurationId, guestId, customer, serviceAddress, id } =
		useParams();
	const [
		guestStoricoSegnalazione,
		{
			data: guestStoricoSegnalazioneData,
			isLoading: guestStoricoSegnalazioneIsLoading,
			isError: isGuestStoricoSegnalazioneDataError,
			error: guestStoricoSegnalazioneDataError,
		},
	] = useGetStoricoSegnalazioniMutation({
		fixedCacheKey: fixedCacheKeys.guestStoricoSegnalazioneData,
	});

	useEffect(() => {
		if (shiftId && guestConfigurationId && guestId) {
			guestStoricoSegnalazione({
				shiftId: shiftId,
				guestConfigurationId: Number(guestConfigurationId),
				guestAdmissionId: Number(guestId),
			});
		} else if (guestConfigurationId && guestId && customer && serviceAddress) {
			guestStoricoSegnalazione({
				guestConfigurationId: Number(guestConfigurationId),
				guestAdmissionId: Number(guestId),
				customerId: customer,
				serviceAddress: serviceAddress,
			});
		}
	}, []);

	useChangeDocumentTitle(labels.pageTitle_storicoSegnalazioniControlloAccesso);

	let redirectStoricoUrl:string = ""								

	const redirectFunction = (guestCardCheckId:number) => {
		if(shiftId){
			return redirectStoricoUrl = `/shift/${shiftId}/controlloAccessi/${guestConfigurationId}/${id}/${guestId}/dettaglioSegnalazione/guestCardCheckId/${guestCardCheckId}`
		}else{
			return redirectStoricoUrl = `/controlloAccessi/${guestConfigurationId}/customer/${customer}/serviceAddress/${serviceAddress}/${id}/${guestId}/dettaglioSegnalazione/guestCardCheckId/${guestCardCheckId}`
		}
	
	}

	return (
		<ErrorBoundary errorMessage={guestStoricoSegnalazioneDataError}>
			<Suspense isLoading={guestStoricoSegnalazioneIsLoading} fullPage>
				<Container className="controllo-accessi-items-page-container p-0 mb-0 mt-4 px-4">
					<Row>
						<Col className="items-container px-0 my-0">
							<ModulePageTitle title={labels.storicoSegnalazioniLabel} />
						</Col>
					</Row>
					{guestStoricoSegnalazioneData && guestStoricoSegnalazioneData?.length > 0 ? (
						<>
							<div className="controllo-accessi-items-page-container p-0 mb-0 mt-4 ">
								<Row>
									<Col className="items-container">
										{guestStoricoSegnalazioneData?.map((el: guestElement) => {
											return (
												<ModuleStateControlloAccessi
													key={el.id}
													id={el.id}
													date={el.lastUpdateDate}
													baseRedirectUrl={redirectFunction(el.id)}
													description={el.description}
												/>
											);
										})}
									</Col>
								</Row>
							</div>
						</>
					) : (
						<Row>
							<Col className="items-container">
								<EmptyPageNote
									note={labels.noStoricoSegnalazioniYet}
									isLoading={guestStoricoSegnalazioneIsLoading}
									className="empty-page-note mt-3"
								/>
							</Col>
						</Row>
					)}
				</Container>
			</Suspense>
		</ErrorBoundary>
	);
};

export default StoricoSegnalazioniPage;
