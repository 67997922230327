import { OptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";

export const parseSingleRecipientPerson = (
	recipients: GetRecipientsResponse
) => {
	const value: string = `${
		recipients.companyName ? recipients.companyName + "_" : ""
	}${recipients.lastName}${
		recipients.firstName ? "_" + recipients.firstName : ""
	}${recipients.additionalInfo ? "_" + recipients.additionalInfo : ""}_${
		recipients.id
	}`;

	const fullName = recipients.lastName + " " + (recipients.firstName ? recipients.firstName : "");
	const label: string = `${
		!recipients.lastName
			? recipients.companyName
			: fullName
	}`;
	const company: string = recipients.companyName || "";
	const additionalInfo: string = recipients.additionalInfo || "";
	return {
		value,
		label,
		company,
		additionalInfo,
	};
};

export const parseSingleRecipientCarrier = (
	recipients: GetRecipientsResponse
) => {
	const value: string = `${recipients.companyName}${
		recipients.firstName ? "_" + recipients.firstName : ""
	}${recipients.lastName ? "_" + recipients.lastName : ""}${
		recipients.additionalInfo ? "_" + recipients.additionalInfo : ""
	}_${recipients.id}`;

	const label: string = `${recipients.companyName} ${
		recipients.lastName ? recipients.lastName : ""
	}`;

	const company: string = recipients.companyName || "";
	const additionalInfo: string = recipients.additionalInfo || "";

	return {
		value,
		label,
		company,
		additionalInfo,
	};
};

export const parseRecipientsPersons = (
	recipients: GetRecipientsResponse[]
): OptionsType => {
	const filterRecipients = recipients.filter(
		(recipients) => recipients.recipientType === "Person"
	);
	const returnRecipients = filterRecipients.map(parseSingleRecipientPerson);

	return returnRecipients;
};

export const parseRecipientsCarrier = (
	recipients: GetRecipientsResponse[]
): OptionsType => {
	const filterRecipients = recipients.filter(
		(recipients) => recipients.recipientType === "Carrier"
	);
	const returnRecipients = filterRecipients.map(parseSingleRecipientCarrier);
	return returnRecipients;
};

export const parseRecipientsAll = (
	recipients: GetRecipientsResponse[]
): OptionsType => {
	return [
		...parseRecipientsPersons(recipients),
		...parseRecipientsCarrier(recipients),
	];
};
