import React, { FC } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import educationData from 'theme/data/educations';
import FalconCardHeader from 'theme/components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import EducationItem from '../EducationItem';

const Education: FC = function Education() {
	return (
		<Card className="mb-3">
			<FalconCardHeader title="Education" light />
			<Card.Body className="fs--1">
				{educationData.map((item, index) => (
					<EducationItem key={nanoid()} details={item} isLast={index === educationData.length - 1} />
				))}
			</Card.Body>
		</Card>
	);
};

export default Education;
