import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import ConfirmModal from "../../../content/ConfirmModal/ConfirmModal";

import { labels } from "features/locale/it-it";
import { JournalMediaList } from "./JournalMediaList";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import IconButton from "theme/components/common/IconButton";
import { JournalMedia } from "features/storeManagement/services/models/JournalResponse";
import ImageModal from "components/content/modals/AlertModal/ImageModal";
import { resizeFile } from "components/pages/ImageUploadPage/ImageUploadPage";
import VideoModal from "components/content/modals/AlertModal/VideoModal";
import AlertModal from "components/content/modals/AlertModal";
import { JournalKeys } from "./JournalConstants";
import { useParams } from "react-router-dom";
import { Color, MediaType } from "components/global/Utils";
import Subtitle from "components/content/FormComponents/Subtitle";

export interface MediaPickerPropsT {
	read?: boolean;
	deleteIsLoading: boolean;
	deleteAJournalMedia: Function;
	mediaIdToDel?: number;
	mediaArray: JournalMedia[] | null | undefined;
	mediaTitle?: string;
	mediaSubtitle?: string;
	showDeleteConfirmModal: boolean;
	flagGiornale?: boolean;
	setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
	setMediaIdToDel: React.Dispatch<React.SetStateAction<number | undefined>>;
	setShowDeleteConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
	setMediaArray: React.Dispatch<
		React.SetStateAction<JournalMedia[] | null | undefined>
	>;
	sectionAndQuestionIdAndID?: string;
	setFormData?: Function;
	answerDetailId?: string | null;
	isMandatory?: boolean;
	isControlloAccessi?: boolean;
}
const MediaPicker = ({
	read,
	isMandatory,
	deleteIsLoading,
	deleteAJournalMedia,
	setMediaArray,
	mediaArray,
	showDeleteConfirmModal,
	setShowDeleteConfirmModal,
	setMediaIdToDel,
	mediaIdToDel,
	flagGiornale = false,
	setFormData,
	sectionAndQuestionIdAndID,
	answerDetailId,
	mediaTitle,
	mediaSubtitle,
	isControlloAccessi
}: MediaPickerPropsT) => {
	const { shiftId, incidentId, checklistId, auditId, draftId } = useParams();
	const [isTemp, setIsTemp] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState(false);
	const [showVideoPreview, setShowVideoPreview] = useState(false);
	const [showErrorMediaModal, setShowErrorMediaModal] = useState(false);
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [base64Img, setBase64Img] = useState<string | null>(null);
	const [mediaName, setMediaName] = useState<string | null>(null);
	const [currentFile, setCurrentFile] = useState<File>();
	const url = window.location.href;

	const setFormDataFunction = () => {
		if (mediaArray)
			if (setFormData && sectionAndQuestionIdAndID) {
				setFormData((oldState: ChecklistFormStateType) => {
					return {
						...oldState,
						[sectionAndQuestionIdAndID]: mediaArray?.map((answer) => {
							return answer.mediaB64 || answer.path;
						}),
					};
				});
			}
	};

	const deleteMedia = (id: number) => {
		if (isTemp) {
			mediaArray?.splice(id, 1);
			setMediaArray(mediaArray);
			setFormDataFunction();
			setShowDeleteConfirmModal(false);
			setIsTemp(false);
		} else {
			if (!sectionAndQuestionIdAndID) {
				deleteAJournalMedia(id);
			} else {
				if (incidentId) {
					deleteAJournalMedia({
						incidentId,
						answerId: draftId,
						answerDetailId,
						deleteBody: {
							shiftId: shiftId ? shiftId : "",
							mediaId: id,
						},
					});
				} else if(checklistId) {
					deleteAJournalMedia({
						checklistId,
						answerId: draftId,
						answerDetailId,
						deleteBody: {
							shiftId: shiftId ? shiftId : "",
							mediaId: id,
						},
					});
				} else if (auditId) {
					deleteAJournalMedia({
						checklistId: auditId,
						answerId: draftId,
						answerDetailId,
						deleteBody: {
							shiftId: shiftId ? shiftId : "",
							mediaId: id,
						},
					});
				}
			}
		}
	};
	const checkIfCanUpload = (file: File): boolean => {
		const videoNumber =
			mediaArray?.reduce((curr, agg) => {
				if (agg.type === JournalKeys.videoType) {
					curr += 1;
				}
				return curr;
			}, 0) || 0;
		const imageNumber =
			mediaArray?.reduce((curr, agg) => {
				if (agg.type !== JournalKeys.videoType) {
					curr += 1;
				}
				return curr;
			}, 0) || 0;
		if (file.type === JournalKeys.videoType) {
			if (
				videoNumber !== undefined &&
				videoNumber < JournalKeys.maxNumberVideos
			) {
				return true;
			}
			return false;
		} else {
			if (
				imageNumber !== undefined &&
				imageNumber < JournalKeys.maxNumberImages
			) {
				return true;
			}
			return false;
		}
	};

	const checkMediaSizeReach = (file: File): boolean => {
		if (
			file.type !== JournalKeys.videoType &&
			file.size < JournalKeys.maxImageSize
		) {
			return true;
		}
		if (
			file.type === JournalKeys.videoType &&
			file.size < JournalKeys.maxVideoSize
		) {
			return true;
		}
		return false;
	};

	const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		if (
			checkIfCanUpload(selectedFiles?.[0]) &&
			checkMediaSizeReach(selectedFiles?.[0])
		) {
			if (selectedFiles?.[0].type !== JournalKeys.videoType)
				resizeFile(selectedFiles?.[0], setCurrentFile);
			else {
				setCurrentFile(selectedFiles?.[0]);
			}
			if (hiddenFileInput && hiddenFileInput.current) {
				hiddenFileInput.current.value = "";
			}
		} else {
			setShowErrorMediaModal(true);
		}
	};

	const fileToBase64 = (file: File) => {
		return new Promise<string | ArrayBuffer | null>((resolve) => {
			var reader = new FileReader();
			reader.onload = function (event) {
				event && event.target && resolve(event.target.result);
			};
			reader.readAsDataURL(file);
		});
	};

	useEffect(() => {
		if (currentFile) {
			fileToBase64(currentFile)
				.then((result: string | ArrayBuffer | null) => {
					if (mediaArray) {
						setMediaArray([
							...mediaArray,
							{
								id: 0,
								journalLogId: 0,
								path: URL.createObjectURL(currentFile),
								type: currentFile.type,
								mediaName: currentFile.name,
								mediaSize: currentFile.size,
								mediaB64: result,
							},
						]);
					} else {
						setMediaArray([
							{
								id: 0,
								journalLogId: 0,
								path: URL.createObjectURL(currentFile),
								type: currentFile.type,
								mediaName: currentFile.name,
								mediaSize: currentFile.size,
								mediaB64: result,
							},
						]);
					}
				})
				.catch((e) => {
					console.log("🚀 ~ file: JournalForm.tsx:119 ~ useEffect ~ e:", e);
				});
		}
	}, [currentFile]);

	useEffect(() => {
		setFormDataFunction();
	}, [mediaArray]);

	const showDeleteModal = (mediaId: number, temp: boolean) => {
		setShowDeleteConfirmModal(true);
		setIsTemp(temp);
		setMediaIdToDel(mediaId);
	};

	const hiddenFileInput = useRef<HTMLInputElement>(null);

	return (
		<>
			{showPreview && (
				<ImageModal
					path={imagePreview}
					setShowPreview={setShowPreview}
					showFlag={showPreview}
					base64String={base64Img}
					mediaName={mediaName}
				/>
			)}
			{showVideoPreview && (
				<VideoModal
					path={imagePreview}
					setShowPreview={setShowVideoPreview}
					showFlag={showVideoPreview}
				/>
			)}
			<Form.Control
				ref={hiddenFileInput}
				type="file"
				style={{ display: "none" }}
				onChange={selectFile}
				accept="image/png, image/jpeg, video/mp4"
				required={isMandatory && mediaArray?.length === 0}
			/>
			<div className="journal-form-container">
				<div
					className={`form-item-container ${flagGiornale ? "mx-3" : ""}`}
				>
					{!read && (
						<IconButton
							icon="paperclip"
							iconClassName=""
							iconAlign="left"
							transform=""
							rest={{}}
							onClick={() => {
								hiddenFileInput?.current?.click();
							}}
							colorIcon={Color.green}
						>
							{labels.mediaAttachment}
						</IconButton>
					)}
				</div>
				
				{mediaArray ? (
					<>
						{read && <div
							className={`form-item-container mb-2 ${
								flagGiornale ? "mx-3" : ""
							}`}
						>
							<div className="mx-0 p-0 bold-text">{mediaTitle} {isMandatory && '*'}</div>
							{mediaSubtitle && <Subtitle text={mediaSubtitle} />}
							
						</div>}

						{mediaArray.length > 0 ? mediaArray.map((media, i) => {
							return (
								<div className="print-full-section" key={i}>
									<JournalMediaList
										index={i}
										temp={media.mediaB64 ? true : false}
										name={media.path}
										setJournalPreview={
											media.type === JournalKeys.videoType
												? setShowVideoPreview
												: setShowPreview
										}
										showPreview={
											media.type === JournalKeys.videoType
												? showVideoPreview
												: showPreview
										}
										setImagePreview={setImagePreview}
										chiave={media.mediaName}
										flagBin={!read}
										mediaId={media.id}
										deleteFunc={showDeleteModal}
										className={isControlloAccessi ? "" : "editPage"}
										error={media.error}
										flagGiornale={flagGiornale}
										base64String={media.mediaValue}
										setBase64String={setBase64Img}
										mediaName={media.mediaName}
										setMediaName={setMediaName}
									/>
									{media.type && media.type === MediaType.image && (
										<div>
											<img
												className="images_preview"
												src={media.path}
												alt="Preview"
											></img>
										</div>
									)}
									{media.type && media.type === MediaType.video && (
										<div>
											<video className="video_preview">
												<source src={media.path} type="video/mp4"></source>
											</video>
										</div>
									)}
								</div>
							);
						}) 
							: (
								<>
									{(read && !url.includes("journal")) && labels.emptyAnswer}
								</>
							)}
					</>
				) : (
					<>
						<div
							className={`form-item-container mb-2 ${
								flagGiornale ? "mx-3" : ""
							}`}
						>
							<div className="mx-0 p-0 bold-text">{mediaTitle} {isMandatory && '*'}</div>
							{mediaSubtitle && <Subtitle text={mediaSubtitle} />}
						</div>
						{read && labels.emptyAnswer}
					</>
				)
				}
				{!read && (
					<div className={"journal-form-buttons-container align-center"}>
						<ConfirmModal
							headerMessage={labels.modalJournalTitle}
							bodyMessage={labels.modalJournalDeleteBody}
							showConfirmModal={showDeleteConfirmModal}
							setShowConfirmModal={setShowDeleteConfirmModal}
							isLoading={deleteIsLoading}
							onConfirm={() =>
								mediaIdToDel !== undefined && deleteMedia(mediaIdToDel)
							}
						/>
						<AlertModal
							headerMessage={labels.modalJournalTitle}
							bodyMessage={labels.journalMediaErrorPopup}
							okButtonText={labels.ok}
							showAlertModal={showErrorMediaModal}
							setShowAlertModal={setShowErrorMediaModal}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default MediaPicker;
