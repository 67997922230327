import { Form } from "react-bootstrap";
import { labels } from "features/locale/it-it";
import "./style.scss";
import { useEffect, useState } from "react";
import formDataParcelResponse from "./formDataParcelResponse";
import Text from "components/content/FormComponents/Text";
import Radio from "components/content/FormComponents/Radio";
import { useParams } from "react-router";
import { RecipientForm } from "components/content/ConfirmModal/ConfirmModalProps";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
const ParcelRecipientForm = ({
	handleSubmit,
	isLoading,
	error,
	validated,
	formData,
	setFormData,
	isFormValid,
	setIsFormValid,
	form,
	isFromKey = false,
	isValidEmail,
	isExistingEmail,
	setIsExistingEmail,
	showConfirmModal
}: RecipientForm) => {
	const { shiftId } = useParams();

	const radioPropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFormValid,
		isFromKey
	).responseParcelRadio(labels.textRadio);
	const namePropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFormValid,
		isFromKey
	).responseParcelName(labels.textName);
	const cognomePropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFormValid,
		isFromKey
	).responseParcelCognome(labels.textCognome);
	const societaPropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFormValid,
		isFromKey
	).responseParcelSocieta(labels.textSocieta);
	const emailPropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFormValid,
		isFromKey,
		isValidEmail
	).responseParcelEmail(labels.textEmail);
	const telefonoPropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFromKey,
		isFormValid
	).responseParcelTelefono(labels.textTelefono);
	const notePropsParcel = formDataParcelResponse(
		setFormData,
		formData,
		isFromKey,
		isFormValid
	).responseParcelNote(labels.textNote);

	useEffect(() => {}, [radioPropsParcel]);
	
	const onChangeHandler = () => {
		if(setIsExistingEmail){
			setIsExistingEmail(false)
		}
	}

	return (
		<div className="contrainer_form">
			<Form ref={form} noValidate validated={false} id="newParcelUserForm">
				<div className="contrainer_form__question">
					<div className="contrainer_form__question__element">
						<h2 className="header_title">{labels.newParcelUser}</h2>
					</div>
					{!isFromKey && (
						<div className="contrainer_form__question__element">
							<Radio {...radioPropsParcel} />
						</div>
					)}
					<div className="contrainer_form__question__element">
						<div>
							<Text {...namePropsParcel} />
						</div>
					</div>
					<div className="contrainer_form__question__element">
						<Text {...cognomePropsParcel} />
					</div>
					<div className="contrainer_form__question__element">
						<Text {...societaPropsParcel} />
					</div>
					<div className="contrainer_form__question__element">
						<Text {...emailPropsParcel} isExistingEmail = {isExistingEmail} onChangeHandler = {onChangeHandler} />
					</div>
					<div className="contrainer_form__question__element">
						<Text {...telefonoPropsParcel} />
					</div>
					<div className="contrainer_form__question__element">
						<Text {...notePropsParcel} />
					</div>
				</div>
			</Form>
		</div>
	);
};

export default ParcelRecipientForm;
