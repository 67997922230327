import OperatorsForm from "components/modules/Turni/Operators/OperatorsForm";
import OperatorsTable from "components/modules/Turni/Operators/OperatorsTable";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";
import {
	OperatoreResponse,
	turnoSingoloShift,
} from "features/storeManagement/services/models/TurniResponse";
import { useGetOneShiftMutation } from "features/storeManagement/services/shiftsService";
import { useGetTurnoSingoloMutation } from "features/storeManagement/services/turniService";
import {
	useGetOperatorsMutation,
	useReplaceOperatorMutation,
} from "features/storeManagement/services/turniService";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import "./style.scss";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import Suspense from "features/Suspense";
import { labels } from "features/locale/it-it";
import AlertModal from "components/content/modals/AlertModal";
import ErrorBoundary from "features/ErrorBoundary";
import ModulesList from "components/modules/ModulesList/ModulesList";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";

const TurnoPage = () => {
	const { shiftId, serviceAddress } = useParams();
	useChangeDocumentTitle(labels.pageTitle_gestioneTurno);
	let shiftDataObj: turnoSingoloShift | null = null;
	const [shiftData, setShiftData] = useState<turnoSingoloShift | null>(null);
	const [showPopUp, setShowPopup] = useState<boolean>(false);
	const [operator, setOperator] = useState<OperatoreResponse[] | undefined>();

	const [
		getTurnoSingolo,
		{
			data: turnoSingoloData,
			isLoading: turnoSingoloIsLoading,
			isError: turnoSingoloIsError,
			error: turnoSingoloError
		},
	] = useGetTurnoSingoloMutation({
		fixedCacheKey: fixedCacheKeys.customersData,
	});

	useEffect(() => {
		if (shiftId) {
			getTurnoSingolo({ shiftId });
		}
	}, []);

	const [
		getOperatorsList,
		{
			data: operatorsData,
			isError: operatorsIsError,
			isLoading: operatorsIsLoading,
			error: operatorsError,
		},
	] = useGetOperatorsMutation({});

	const [
		replaceOperator,
		{
			data: replaceOperatorData,
			isError: replaceOperatorIsError,
			isLoading: replaceOperatorIsLoading,
			error: replaceOperatorError,
		},
	] = useReplaceOperatorMutation();

	useEffect(() => {
		setOperator(operatorsData);
	}, [operatorsData]);

	useEffect(() => {
		if (showPopUp === true) {
			setOperator(undefined);
		}
	}, [showPopUp]);

	useEffect(() => {
		if (turnoSingoloData) {
			shiftDataObj = {
				shiftCheckinTime: turnoSingoloData?.shiftCheckinTime
					? new Date(turnoSingoloData?.shiftCheckinTime)
					: null,
				shiftCheckoutTime: turnoSingoloData?.shiftCheckoutTime
					? new Date(turnoSingoloData?.shiftCheckoutTime)
					: null,
				shiftStartTime: turnoSingoloData?.shiftStartTime
					? new Date(turnoSingoloData?.shiftStartTime)
					: null,
				shiftEndTime: turnoSingoloData?.shiftEndTime
					? new Date(turnoSingoloData?.shiftEndTime)
					: null,
				shiftFullAddress: turnoSingoloData?.shiftFullAddress,
				shiftJobDescription: turnoSingoloData?.shiftJobDescription,
				shiftCustomerName: turnoSingoloData?.shiftCustomerName,
				checkInOutEnable: turnoSingoloData?.checkInOutEnable,
				shiftVenueName: turnoSingoloData?.shiftVenueName
			};

			setShiftData(shiftDataObj);
		}
	}, [turnoSingoloData]);

	useEffect(() => {
		if (replaceOperatorData) {
			setShowPopup(true);
		}
	}, [replaceOperatorData]);

	useEffect(() => {
		if (!showPopUp) {
			shiftId && getTurnoSingolo({ shiftId });
		}
	}, [showPopUp]);

	return (
		<Container
			fluid
			className="box-details-container turno-container d-flex justify-content-center p-0 px-4 mb-0 mt-4 p-0 px-2 mb-5 mt-4"
		>
			<Suspense isLoading={turnoSingoloIsLoading} fullPage>
				<ErrorBoundary errorMessage={turnoSingoloError || replaceOperatorError}>
					<AlertModal
						headerMessage={labels.operatorPopupTitle}
						bodyMessage={
							<>
								<span>{labels.operatorPopupBodyLabel}</span>
								<br />
								<span className="red-text bold-text">
									{labels.operatorPopupBodyAttenzione}{" "}
								</span>
								<span>{labels.operatorPopupBodyRicordati} </span>
								<span className="bold-text">
									{labels.operatorPopupBodyNecessarioBold}{" "}
								</span>
								<span>{labels.operatorPopupBodyCambio} </span>
								<span className="bold-text">
									{labels.operatorPopupBodySapBold}
								</span>
							</>
						}
						okButtonText={labels.ok}
						showAlertModal={showPopUp}
						setShowAlertModal={setShowPopup}
					/>
					<Container fluid className="p-1">
						{shiftData && <BoxDetails flagTurno={true} shift={shiftData} />}
						<Row className="p-1 mt-3 mx-auto">
							<Col>
								<span className="bold-text blue-text opeator-title">
									{labels.textLabelOperatore}
								</span>
								<span className="opeator-value">
									{" "}
									{turnoSingoloData?.operatorCid} -{" "}
									{turnoSingoloData?.operatorFirstName}{" "}
									{turnoSingoloData?.operatorLastName}
								</span>
							</Col>
						</Row>

						<Row className="p-1 mt-3 mx-auto">
							<Col>
								<p className="grey opeator-title">{labels.textNewOperator}</p>
							</Col>
						</Row>

						<Row className="p-1 mx-auto">
							<OperatorsForm
								getOperatorsList={getOperatorsList}
								operatorsIsLoading={operatorsIsLoading}
							/>
						</Row>

						<Row className="p-1 mt-3">
							<ErrorBoundary errorMessage={operatorsError}>
								{!!operator && (
									<OperatorsTable
										data={operator}
										replaceOperator={replaceOperator}
										shiftId={turnoSingoloData?.id.toString()}
									/>
								)}
							</ErrorBoundary>
						</Row>
					</Container>
				</ErrorBoundary>
			</Suspense>
		</Container>
	);
};

export default TurnoPage;
