/* eslint-disable indent */
import "./style.scss";
import React, { useMemo } from "react";
import { useGetOneShiftMutation } from "features/storeManagement/services/shiftsService";
import ModulesList from "components/modules/ModulesList";
import { labels } from "features/locale/it-it";
import { Container } from "react-bootstrap";
import { dateParser } from "features/utils/dateUtils";
import EmptyPageNote from "components/content/EmptyPageNote";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import Suspense from "features/Suspense";
import BoxDetails from "components/content/BoxDetails";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import ModulesListReport from "components/modules/ModuleListReport/ModuleListReport";
import { useGetReportListMutation } from "features/storeManagement/services/reportService";

const ShiftPage = () => {
	useChangeDocumentTitle("Dettaglio Turno");

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading, error: shiftError }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const { unstampedShift, noActiveShiftDetail, shiftIsOver, noModulesForShift } = labels;

	const showModulesList = useMemo(() => {
		const now = new Date().toUTCString();
		return shiftData && dateParser(now).isVisibleModulesList(shiftData);
	}, [shiftData]);

	const showNoModulesMessage = useMemo(() => {
		const now = new Date().toUTCString();
		return (
			shiftData &&
			shiftData.checkInOutEnable &&
			dateParser(now).needToShowCheckInMessage(shiftData)
		);
	}, [shiftData]);

	const isActiveShift =
		shiftData?.shiftStartTime &&
		shiftData?.shiftEndTime &&
		dateParser(new Date().toUTCString()).isVisibleShift(
			shiftData?.shiftStartTime,
			shiftData?.shiftEndTime
		);

		const [
			,
			{
				data: reportData,
				isError: reportIsError,
				isLoading: reportIsLoading,
				error: reportError,
			},
		] = useGetReportListMutation({ fixedCacheKey: fixedCacheKeys.reportsData });

		const showNoModulesForShiftMessage =
								shiftData &&
								shiftData.checklists?.length < 1 &&
								shiftData.patrols?.length < 1 &&
								shiftData.incidents?.length < 1 &&
								shiftData.documents && shiftData.documents.length < 1 &&
								!shiftData.guestConfiguration &&
								!shiftData.journal &&
								!shiftData.parcel &&
								!shiftData.keyManagement &&
								!shiftData.reportEnabled;

		return (
		<ErrorBoundary errorMessage={shiftError}>
			<Container className="shift-container p-0 px-2 mb-5 mt-4">
				<Suspense isLoading={shiftIsLoading || reportIsLoading} fullPage>
					{!shiftIsLoading && !isActiveShift && (
						<EmptyPageNote
							note={noActiveShiftDetail}
							isLoading={shiftIsLoading}
						/>
					)}

					{shiftData && isActiveShift && (
						<div className="box-details-container ">
							<BoxDetails shift={shiftData} />
						</div>
					)}

					{showModulesList && isActiveShift && (
						<ModulesList
						shiftId={shiftData.shiftId}
							checklists={shiftData.checklists}
							patrols={shiftData.patrols}
							incidents={shiftData.incidents}
							journal={shiftData.journal}
							parcel={shiftData.parcel}
							keys={shiftData.keyManagement}
							guestConfiguration = {shiftData.guestConfiguration}
							areaDocumenti={shiftData.documents}
						/>
					)}

					{shiftData && shiftData.reportEnabled && showModulesList && isActiveShift &&<ModulesListReport report={reportData} />}

					{showModulesList && isActiveShift && showNoModulesForShiftMessage && (
						<EmptyPageNote note={noModulesForShift} isLoading={shiftIsLoading} />
					)}

					{showNoModulesMessage && isActiveShift && (
						<EmptyPageNote note={unstampedShift} isLoading={shiftIsLoading} />
					)}

					{(shiftData && !shiftData.modulesExecutable && isActiveShift &&
						<EmptyPageNote note={shiftIsOver} isLoading={shiftIsLoading} />
					)}
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ShiftPage;
