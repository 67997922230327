import './style.scss'
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Answer } from 'features/storeManagement/services/models/ChecklistResponse'
import { labels } from 'features/locale/it-it'
import ModuleState from 'components/modules/ModuleState'
import ModulePageTitle from 'components/modules/ModulePageTitle'
import EmptyPageNote from 'components/content/EmptyPageNote'
import Suspense from 'features/Suspense'
import ErrorBoundary from 'features/ErrorBoundary/ErrorBoundary'
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle'
import { Incident } from 'features/storeManagement/services/models/IncidentResponse'
import { useNavigate, useParams } from 'react-router-dom'
import { useIsomorphicLayoutEffect } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useGetIncidentDraftsMutation } from 'features/storeManagement/services/IncidentService'
import { fixedCacheKeys } from 'features/storeManagement/services/constants/fixedCacheKeys'
import { useGetCurrentShiftMutation, useGetOneShiftMutation } from 'features/storeManagement/services/shiftsService'

const IncidentItemsPage = () => {
	useChangeDocumentTitle(labels.pageTile_IncindentManagementItemsPage);
	const navigate = useNavigate()
	const { shiftId, incidentId } = useParams()

	const [getIncidentDrafts,
		{
			data: incidentDraftsData,
			isLoading: incidentDraftsIsLoading,
			error: incidentsListError
		}
	] = useGetIncidentDraftsMutation({ fixedCacheKey: fixedCacheKeys.incidentDraftsData })


	useEffect(() => {
		if (shiftId && incidentId) {
			getIncidentDrafts({ shiftId, incidentId })
		}
	}, [shiftId, incidentId])

	const [, {
		data: shiftData,
	}] = useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData })

	const [, {
		data: currentShiftData,
	}] = useGetCurrentShiftMutation({ fixedCacheKey: fixedCacheKeys.currentShiftData })

	const shift = shiftData || currentShiftData
	let incident = shift?.incidents.find(incident => incident.id.toString() === incidentId)


	return (
		<ErrorBoundary errorMessage={incidentsListError}>
			<Container className='incident-items-page-container p-0 px-4 mb-0 mt-4 '>
				<Suspense isLoading={incidentDraftsIsLoading} fullPage>
					<Row>
						<Col className='items-container px-0 my-0'>
							<ModulePageTitle title={incident?.description || labels.incidentTitle} />
						</Col>
					</Row>
					{!incidentDraftsIsLoading && <Row className='sticky-button-container mt-4'>
						<Col className='items-container p-0 my-0'>
							{shift?.modulesExecutable &&
							<Button
								variant='green'
								className='start-new-draft-button--start'
								disabled={incidentDraftsIsLoading}
								onClick={() => navigate(`/shift/${shiftId}/incident/${incidentId}/new`)}>
								<span>{labels.startNewReport}</span>
							</Button>}
						</Col>
					</Row>}
					{incidentDraftsData &&
						((incidentDraftsData.items && incidentDraftsData.items.length > 0) ? <Row>
							<Col className='items-container'>
								{incidentDraftsData.items.map((item: Incident) => {
									return (
										<ModuleState
											key={item.id}
											id={item.id}
											date={item.lastUpdateDate}
											state={item.status}
											baseRedirectUrl={`/shift/${shiftId}/incident/${incidentId}`}
											type={'incident'}
											description={item.description}
										/>
									)
								})}
							</Col>
						</Row> : <Row>
							<Col className='items-container'>
								<EmptyPageNote note={labels.noIncidentYet} isLoading={incidentDraftsIsLoading} className='empty-page-note' />
							</Col>
						</Row>)
					}
				</Suspense>
			</Container>
		</ErrorBoundary>
	)
}

export default IncidentItemsPage