import { FC } from 'react';
import Avatar from 'theme/components/common/Avatar';
import LockScreenForm from './LockScreenForm';
import { Col, Row } from 'react-bootstrap';
import team1 from 'theme/assets/img/team/1.jpg';
import bgImg from 'theme/assets/img/generic/18.jpg';
import AuthSplitLayout from 'layout/AuthLayout';
import Flex from 'theme/components/common/Flex';

const LockScreenPage: FC = function LockScreenPage() {
	return (
		<AuthSplitLayout bgProps={{ image: bgImg }}>
			<Row className="justify-content-center">
				<Col xs="auto">
					<Flex alignItems="center">
						<Avatar src={team1} size="4xl" className="me-4" />
						<div>
							<h4>Hi! Emma</h4>
							<p className="mb-0">
								Enter your password <br />
								to access the admin.
							</p>
						</div>
					</Flex>
					<LockScreenForm className="mt-4" type="" />
				</Col>
			</Row>
		</AuthSplitLayout>
	);
};

export default LockScreenPage;
