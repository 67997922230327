import React, { useEffect, useState } from "react";
import { labels } from "features/locale/it-it";
import { Row, Col } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import IFile from "./MediaTypes";

export const resizeFile = (file: any, setCurrentFileResize: Function) => {
	new Promise(() => {
		try {
			Resizer.imageFileResizer(
				file,
				1600,
				1200,
				"JPEG",
				80,
				0,
				(uri) => {
					setCurrentFileResize(uri);
				},
				"file"
			);
		} catch (e) {
			console.log("🚀 ~ file: MediaUploadPage.tsx:28 ~ resizeFile ~ e:", e);
		}
	});
};

const ImageUploadPage = () => {
	const [currentFile, setCurrentFile] = useState<File>();
	const [currentFileResize, setCurrentFileResize] = useState<File>();
	const [message, setMessage] = useState<string>("");
	const [fileInfos, setFileInfos] = useState<Array<IFile>>([]);
	const [previewImage, setPreviewImage] = useState<string>("");
	const [previewImageResize, setPreviewImageResize] = useState<string>("");

	const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		setCurrentFile(selectedFiles?.[0]);
		setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
	};

	const uploadClick = () => {
		resizeFile(currentFile, setCurrentFileResize);
	};

	useEffect(() => {
		if (currentFileResize)
			setPreviewImageResize(URL.createObjectURL(currentFileResize));
	}, [currentFileResize]);

	return (
		<section className="pt-2">
			<div>
				<div className="row">
					<div className="col-8">
						<label className="btn btn-default p-0">
							<input type="file" onChange={selectFile} accept="image/png, image/jpeg"/>
						</label>
					</div>

					<div className="col-4">
						<button
							className="btn btn-success btn-sm"
							disabled={!currentFile}
							onClick={uploadClick}
						>
							Upload
						</button>
					</div>
				</div>
				<div>
					<img src={previewImage} width={500} />
					{currentFile && (
						<span>{` ${currentFile?.name} - ${
							currentFile && Math.floor(currentFile.size / 1000)
						}kb`}</span>
					)}
				</div>
				<div>
					<img src={previewImageResize} width={500} />
					{currentFileResize && (
						<span>{` ${currentFileResize?.name} - ${
							currentFileResize && Math.floor(currentFileResize.size / 1000)
						}kb`}</span>
					)}
				</div>
			</div>
		</section>
	);
};

export default ImageUploadPage;
