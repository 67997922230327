import './style.scss'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { ShiftDetailsHeaderProps } from 'features/types/modules/ShiftPageProps'

const ShiftDetailsTop: React.FC<ShiftDetailsHeaderProps> = ({shift, customerAddress, isOpen, setIsOpen}) => {
	return (
		<>
			<Row 
				className='accordion-box flex-nowrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0'
				onClick={() => setIsOpen(!isOpen)}
			>
				<Col className='col-2 col-sm-1 col-md-2 accordion-type mx-0 text-start p-0 d-flex align-items-center'>
					<div className='card-accordion-type ms-0'>{shift.shiftJobDescription}</div>
				</Col>
				<Col className='col-8 col-sm-10 col-md-8 accordion-details ps-sm-4 ps-md-2'>
					<Row className="client-name">
						<Col className="blue-text bold-text">{shift.shiftCustomerName?.toLowerCase()}</Col>
					</Row> 
					<Row className="address">
						<Col className="">{shift.shiftFullAddress?.toLowerCase()}</Col>
					</Row>
					<Row className="address">
						<Col className="">{shift?.shiftVenueName?.toLowerCase()}</Col>
					</Row>
				</Col>
				<Col className='d-flex align-items-center justify-content-center justify-content-md-end col-2 col-sm-1 col-md-2 arrow p-0 text-start text-md-end'>
					<ArrowIcon
						className={isOpen ? 'arrow-angle-up' : 'arrow-angle-down'} 
					/>
				</Col>
			</Row>
		</>
	)
}

export default ShiftDetailsTop