import { createApi } from "@reduxjs/toolkit/query/react";
import { headersCommon } from "./constants/headers";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import { GetKeysRequest, GetKeysResponsabileRequest, GetKeysResponse, GetRecipientKey, KeyRecipientDataKey, KeyRecipientT, KeyRequestPutStatoApi, getRecipientDataKeyRequest, getRecipientKeyRequest, getRecipientKeyResponsabileRequest, getRecipientKeyResponse, getRecipientsKeyRequest, getRecipientsKeyResponsabileRequest, postDeliveryKeyRequest, postDeliveryKeyResponse, postRecipientsRequest, postRecipientsResponse, putRiconsegnaKeyRequest, putRiconsegnaKeyResponse } from "./models/KeysResponse";

export const keyManagementApi = createApi({
	reducerPath: "keyManagementService",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getKeys: builder.mutation<GetKeysResponse, GetKeysRequest>({
			query: ({ keyManagementId, shiftId }) => ({
				url: `/fe/Keys/${keyManagementId}?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		getKeysResponsabile: builder.mutation<GetKeysResponse, GetKeysResponsabileRequest>({
			query: ({ keyManagementId }) => ({
				url: `/fe/Keys/${keyManagementId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		
		postRegistrationDataKey: builder.mutation<postRecipientsResponse, postRecipientsRequest>({
			query: ({ keyManagementId, keyRegistryId, shiftId, recipientData }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}/Recipients?shiftId=${shiftId}`,
				method: 'POST',
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		postDeliveryKey: builder.mutation<postDeliveryKeyResponse, postDeliveryKeyRequest>({
			query: ({ keyManagementId, keyRegistryId, shiftId, deliveryDataKey }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}?shiftId=${shiftId}`,
				method: 'POST',
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: deliveryDataKey,
			}),
		}),

		putRiconsegnaKey: builder.mutation<putRiconsegnaKeyResponse, putRiconsegnaKeyRequest>({
			query: ({ keyManagementId, keyRegistryId, keyManagementRegistryId, objSendPut }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}/${keyManagementRegistryId}`,
				method: 'PUT',
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: objSendPut,
			}),
		}),

		getDataKey: builder.mutation<getRecipientKeyResponse, getRecipientsKeyRequest>({
			query: ({ keyManagementId, keyRegistryId, shiftId }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		getDataResponsabileKey: builder.mutation<getRecipientKeyResponse, getRecipientsKeyResponsabileRequest>({
			query: ({ keyManagementId, keyRegistryId }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		getRecipientsListKey: builder.mutation<KeyRecipientT[], getRecipientsKeyRequest>({
			query: ({ keyManagementId, keyRegistryId, shiftId }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}/Recipients?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		getRecipientsListResponsabileKey: builder.mutation<KeyRecipientT[], getRecipientsKeyResponsabileRequest>({
			query: ({ keyManagementId, keyRegistryId }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}/Recipients`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		
		getRiconsegnaDetails: builder.mutation<GetRecipientKey, getRecipientKeyRequest>({
			query: ({ keyManagementId, keyRegistryManagementId, shiftId, answerId }) => ({
				url: answerId ? `/fe/Keys/${keyManagementId}/${keyRegistryManagementId}/KeyRegistryManagement?shiftId=${shiftId}&answerId=${answerId}` : `/fe/Keys/${keyManagementId}/${keyRegistryManagementId}/KeyRegistryManagement?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		
		getRiconsegnaResponsabileDetails: builder.mutation<GetRecipientKey, getRecipientKeyResponsabileRequest>({
			query: ({ keyManagementId, keyRegistryManagementId, answerId }) => ({
				url: answerId ? `/fe/Keys/${keyManagementId}/${keyRegistryManagementId}/KeyRegistryManagement?answerId=${answerId}` : `/fe/Keys/${keyManagementId}/${keyRegistryManagementId}/KeyRegistryManagement`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),

		putCambioStatoKey: builder.mutation<putRiconsegnaKeyResponse, KeyRequestPutStatoApi>({
			query: ({ keyManagementId, keyRegistryId, objSendPut }) => ({
				url: `/fe/Keys/${keyManagementId}/${keyRegistryId}/Status`,
				method: 'PUT',
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
				body: objSendPut,
			}),
		}),
	}),
});

export const { useGetKeysMutation, useGetKeysResponsabileMutation, usePostRegistrationDataKeyMutation, useGetDataKeyMutation, useGetDataResponsabileKeyMutation, useGetRecipientsListKeyMutation, useGetRecipientsListResponsabileKeyMutation, usePostDeliveryKeyMutation, useGetRiconsegnaDetailsMutation, useGetRiconsegnaResponsabileDetailsMutation, usePutRiconsegnaKeyMutation, usePutCambioStatoKeyMutation} = keyManagementApi;

