import { useEffect, useMemo, useState } from "react"
import { useAttemptLoginMutation } from "features/storeManagement/services/loginService"
import { setRememberMe } from "features/storeManagement/slices/loggedInSlice"
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys"
import { labels } from "features/locale/it-it"
import { useDispatch, useSelector } from "react-redux"
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition } from "@reduxjs/toolkit/dist/query/react"
import { RootState } from "features/storeManagement/store"
import { v4 as uuid } from 'uuid';
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate"
import { LoginCredentials, AuthenticationResponse } from "features/storeManagement/services/models/AuthenticationResponse"

const useLoginForm = () => {
	const dispatch = useDispatch()
	const rememberMe = useSelector((store: RootState) => store.loggedInSlice.rememberMe)

	const [deviceId, setDeviceId] = useState(
		sessionStorage.getItem('deviceId') || 
		localStorage.getItem('deviceId') || 
		uuid()
	);
	const [formData, setFormData] = useState({
		username: '',
		password: '',
		remember: false
	})

	useEffect(()=>{
		dispatch(setRememberMe(false))
	}, [])
	const [getAuthenticated, {
		data: authenticationData,
		isLoading: authenticationIsLoading,
		error: authenticationError
	}] = useAttemptLoginMutation({ fixedCacheKey: fixedCacheKeys.login })


	const errorMessage = useMemo(() => {
		const authenticationErrorTyped = authenticationError as FetchBaseQueryError
		return authenticationErrorTyped && (authenticationErrorTyped?.status == 401 ?
			labels.wrongCredentials :
			labels.oopsThereWasAProblem)
	}
	, [authenticationError])

	const handleSubmit = (e: React.SyntheticEvent): void => {
		e.preventDefault()
		
		if(!(sessionStorage.getItem('deviceId') || localStorage.getItem('deviceId'))) {
			storageSetup(deviceId);
		}
		
		getAuthenticated({
			credentials : {
				username: formData.username,
				password: formData.password
			}
		});
		
	}

	const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const storageSetup = (id : string) => {
		if(rememberMe) {
			localStorage.setItem('deviceId', id);
			sessionStorage.removeItem('deviceId');
		} else {
			sessionStorage.setItem('deviceId', id);
			localStorage.removeItem('deviceId');
		}
	}

	const setCheceked = (isChecked: boolean) => dispatch(setRememberMe(isChecked))

	useEffect(() => {
		storageSetup(deviceId);
	}, [rememberMe, deviceId])


	return { formData, handleSubmit, handleFieldChange, errorMessage, setCheceked }
}

export default useLoginForm

function checkDeviceIdStorage(s: string) {
	throw new Error("Function not implemented.")
}
