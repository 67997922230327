import { labels } from "features/locale/it-it";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import IconItem from "theme/components/common/icon/IconItem";
import "./style.scss";
import { Color } from "components/global/Utils";

export const JournalMediaList: React.FC<{
	index?: number;
	temp?: boolean;
	name: string;
	read?: boolean;
	setJournalPreview?: Function;
	showPreview?: boolean;
	setImagePreview?: Function;
	chiave?: string;
	flagBin?: boolean;
	deleteFunc?: Function;
	mediaId?: number;
	className?: string;
	error?: boolean;
	flagGiornale?:boolean;
	base64String?: string;
	setBase64String?: Function;
	mediaName?: string;
	setMediaName?: Function;
}> = ({
	index,
	temp,
	name,
	read,
	setJournalPreview,
	showPreview,
	setImagePreview,
	chiave,
	flagBin,
	deleteFunc,
	mediaId,
	className,
	error,
	flagGiornale = false,
	base64String,
	setBase64String,
	mediaName,
	setMediaName
}) => {

	return (
		<>
			<Row
				className={`module-journal--page print-full-section ${
					className ? className : ""
				} ${flagGiornale ? "mx-0 p-0 m-0" : "px-3"}`}
			>
				<Col className="d-flex me-3 p-0">
					<Row className="m-0 bold-text date-time">
						<Col className="d-flex p-0 center-name">
							<span className="font-span">{chiave}</span>
						</Col>
						<Col className="d-flex p-0">
							<IconItem
								icon="eye"
								colorIcon={Color.green}
								width="23"
								height="23"
								onClick={() => {
									setJournalPreview && setJournalPreview(!showPreview);
									setImagePreview && setImagePreview(name);
									setBase64String && setBase64String(base64String);
									setMediaName && setMediaName(mediaName);
								}}
							/>
							{!read && flagBin && (
								<IconItem
									icon="trash"
									width="23"
									height="23"
									onClick={() => {
										deleteFunc && deleteFunc(temp ? index : mediaId, temp);
									}}
									colorIcon={Color.green}
								/>
							)}
						</Col>
						{error &&
							<Col className="d-flex p-0 checkbox-question-container">
								<div className="feedback-message">{labels.dimensioneError}</div>
							</Col>
						}
					</Row>
				</Col>
			</Row>
		</>
	);
};
