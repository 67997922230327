import React, { useState } from 'react'
import { Form, InputGroup, Row } from 'react-bootstrap'
import Title from './Title'
import Subtitle from './Subtitle'
import './radio.scss'
import { ChecklistFormItemsPropsType } from 'features/storeManagement/services/models/ChecklistResponse'
import { updateState } from 'features/utils/formComponentsStateUpdate'
import { labels } from 'features/locale/it-it'
import { preventSubmitOnEnter } from 'features/utils/preventSubmitOnEnter'

const Radio = ({
	id,
	title,
	subtitle,
	enabled,
	type,
	answers,
	isMandatory,
	answerDetail,
	formData,
	setFormData,
	sectionAndQuestionId,
	isExecutable,
	validated
}: ChecklistFormItemsPropsType) => {
	const [chosenOne, setChosenOne] = useState<string>()
	const formDataKeyForComponent = `${sectionAndQuestionId} ${answerDetail ? answerDetail!.id : ""}`

	return (
		<Form.Group 
			id={`question-${id}`} 
			key={`question-${id}`} 
			className='radio-question-and-buttons-container'
		>
			<Title text={title} isMandatory={isMandatory} />
			<Subtitle text={subtitle} />
			<Row className='radio-buttons-container'>
				<InputGroup
					hasValidation
				>
					{answers && answers.map(answer => {
						if (answer.length > 0) {
							const answer_id = `${id}_answer-${answer.toLowerCase().trim().replaceAll(' ', '-')}`;
							return (
								<div className='question-container' key={`content-${answer_id}`}>
									<Form.Check
										key={`question-${answer_id}`}
										id={answer_id}
										className='radio-button'
										defaultChecked={(formData[formDataKeyForComponent] || [])[0] === answer}
										name={`${title}${id}`}
										type='radio'
										required={isMandatory && !chosenOne}
										disabled={!enabled || !isExecutable}
										onKeyDown={e => preventSubmitOnEnter(e)}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											updateState.updateRadioState(answer, setFormData, formDataKeyForComponent)
											setChosenOne(answer)
										}}
									/>
									<Form.Check.Label 
										key={`label-${answer_id}`}
										htmlFor={answer_id}
									>
										{answer}
									</Form.Check.Label>
								</div>
							)
						}
					}
					)}
				</InputGroup>
				{(isMandatory && validated && !chosenOne && chosenOne !== undefined) && <div className={'feedback-message'}>{labels.mustChooseOne}</div>}
			</Row>
		</Form.Group>
	)
}

export default Radio