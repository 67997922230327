import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseSignal } from '@fortawesome/free-solid-svg-icons';

const RondeSignal = (props: any) => (
	<FontAwesomeIcon icon={faHouseSignal} width={24} height={24} size={props.size ? props.size : "sm"} {...props} />
)
export default RondeSignal




