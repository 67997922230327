import React, { useEffect, useRef, useState } from "react";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import { useGetRiconsegnaResponsabileDetailsMutation, usePostDeliveryKeyMutation, usePostRegistrationDataKeyMutation, usePutRiconsegnaKeyMutation } from "features/storeManagement/services/keyManagementService";
import { useNavigate, useParams } from "react-router";
import { AnswerPostOneKey, anomalies, keyStrings, objSendPut, postDeliveryDataKey } from "features/storeManagement/services/models/KeysResponse";
import { labels } from "features/locale/it-it";
import { parcelKeys } from "features/storeManagement/services/models/ParcelResponse";
import { useForm } from "react-hook-form";
const useKeyDetailResponsabileForm = (anomalies: anomalies[] | undefined, isReport: boolean, isTouch: boolean, isRiconsegna?: boolean, keyRegistryId?: string | undefined) => {
	const [formData, setFormData] = useState<ChecklistFormStateType>({
		[`${parcelKeys.isSamePerson} `]: [labels.yesLabel],
	});
	const form = useRef(null);
	const [validated, setValidated] = useState<boolean>(false);
	const [isFormValid, setIsFormValid] = useState<boolean>(true);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
	const [title, setTitle] = useState<string>("");
	const [bodyTextAlert, setbodyTextAlert] = useState<string>("");
	const { shiftId, keyManagementId, keyRegistryManagementId, customer, contract, position, serviceAddress } = useParams();
	const navigate = useNavigate();

	const [
		postRegistrationKey,
		{
			data: postRegistrationKeyData,
			isLoading: postRegistrationKeyIsLoading,
			error: postRegistrationKeyError,
		},
	] = usePostRegistrationDataKeyMutation();

	const [
		putRiconsegnaKey,
		{
			data: putRiconsegnaKeyData,
			isLoading: putRiconsegnaKeyDataIsLoading,
			error: putRiconsegnaKeyDataError,
		},
	] = usePutRiconsegnaKeyMutation();

	const [
		postDeliveryKey,
		{
			data: postDeliveryKeyData,
			isLoading: postDeliveryKeyDataIsLoading,
			error: postDeliveryKeyDataError,
		},
	] = usePostDeliveryKeyMutation();

	useEffect(() => {
		if (postDeliveryKeyData || putRiconsegnaKeyData) {
			navigate(`/keys/customer/${customer}/keyManagementId/${keyManagementId}/serviceAddress/${serviceAddress}`);
		}
	}, [postDeliveryKeyData, putRiconsegnaKeyData])

	useEffect(() => {
		let validity: boolean = false;
		/*Se sono in RICONSEGNA e la persona è NO*/
		if (
			formData[`${keyStrings.isSamePerson} `] && formData[`${keyStrings.isSamePerson} `][0].toLowerCase() ===
			labels.noLabel.toLowerCase() && isRiconsegna
		) {
			/*Se sono su mobile*/
			if (isTouch) {
				validity =
					formData[`${keyStrings.isSamePerson} `] &&
					formData[`${keyStrings.isSamePerson} `][0] !== "" &&
					formData[`${keyStrings.keyRecipientPersonId} `] &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== "" &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== undefined &&
					formData[`${keyStrings.sign} `] &&
					formData[`${keyStrings.sign} `][0] !== "" &&
					formData[`${keyStrings.sign} `][0] !== undefined
				/*Se sono su desktop*/
			} else {
				validity =
					formData[`${keyStrings.isSamePerson} `] &&
					formData[`${keyStrings.isSamePerson} `][0] !== "" &&
					formData[`${keyStrings.keyRecipientPersonId} `] &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== "" &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== "" &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== undefined
			}
		}
		/*Se sono in RICONSEGNA e la persona è SI*/
		else if (formData[`${keyStrings.isSamePerson} `] && formData[`${keyStrings.isSamePerson} `][0].toLowerCase() ===
			labels.yesLabel.toLowerCase() && isRiconsegna) {
			/*Se sono su mobile*/
			if (isTouch) {
				validity =
					formData[`${keyStrings.isSamePerson} `] &&
					formData[`${keyStrings.isSamePerson} `][0] !== "" &&
					formData[`${keyStrings.sign} `] &&
					formData[`${keyStrings.sign} `][0] !== "" &&
					formData[`${keyStrings.sign} `][0] !== undefined
				/*Se sono su desktop*/
			} else {
				validity =
					formData[`${keyStrings.keyRecipientPersonId} `] &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== ""
			}
		}
		/*Se sono in CONSEGNA e sono su mobile*/
		else if (!isRiconsegna && isTouch) {
			validity =
				!!(
					form &&
					form.current &&
					(form.current as HTMLFormElement).checkValidity() &&
					formData[`${keyStrings.keyRecipientPersonId} `] &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== "" &&
					formData[`${keyStrings.sign} `] &&
					formData[`${keyStrings.sign} `][0] !== ""
				)
			/*Se sono in CONSEGNA e NON sono su mobile*/
		} else {			
			validity =
				!!(
					form &&
					form.current &&
					(form.current as HTMLFormElement).checkValidity() &&
					formData[`${keyStrings.keyRecipientPersonId} `] &&
					formData[`${keyStrings.keyRecipientPersonId} `][0] !== ""
				)
		}
		/*Se sono in APRI SEGNALAZIONE*/
		if (isReport) {
			const validitySegnalazione =
				formData &&
				formData[`${keyStrings.keyPlaceSelect} `] &&
				formData[`${keyStrings.keyPlaceSelect} `][0] !== "" &&
				formData[`${keyStrings.keyPlaceSelect} `][0] !== undefined &&
				formData[`${keyStrings.reportNotesKeySegnalazione} `] &&
				formData[`${keyStrings.reportNotesKeySegnalazione} `][0].trim() !== "" &&
				formData[`${keyStrings.reportNotesKeySegnalazione} `][0] !== undefined
			setIsFormValid(!!(validitySegnalazione));
			/*Invio dati se NON sono in APRI SEGNALAZIONE*/
		} else {
			setIsFormValid(!!(validity));
		}
	}, [formData, isReport]);

	const handleSubmit = () => {
		if (isRiconsegna) {
			let descriptionId: number | null = null;
			let objSendPut: objSendPut | null = null
			if (isReport) {
				anomalies?.map((anomalia) => {
					if (anomalia.description === formData[`${keyStrings.keyPlaceSelect} `][0]) {
						descriptionId = anomalia.id
					}
				})
			}
			if (descriptionId !== null) {
				objSendPut = {
					shiftId: "",
					signature: (formData[`${keyStrings.sign} `] &&
						formData[`${keyStrings.sign} `][0]) ||
						null,
					note: formData[`${keyStrings.reportNotesKeySegnalazione} `] &&
						formData[`${keyStrings.reportNotesKeySegnalazione} `][0]
						|| null,
					anomalyId: descriptionId,
					keyStatus: 2
				}
			} else if (formData[`${keyStrings.isSamePerson} `][0] === labels.noLabel) {
				objSendPut = {
					shiftId: "",
					deliveryPersonId: (formData[`${keyStrings.keyRecipientPersonId} `] &&
						formData[`${keyStrings.keyRecipientPersonId} `][0]) ||
						null,
					note: formData[`${keyStrings.reportNotes} `] &&
						formData[`${keyStrings.reportNotes} `][0]
						|| null,
					signature: (formData[`${keyStrings.sign} `] &&
						formData[`${keyStrings.sign} `][0]) ||
						null,
					keyStatus: 0
				}
			} else if (keyManagementId && keyRegistryManagementId) {
				objSendPut = {
					shiftId: "",
					signature: (formData[`${keyStrings.sign} `] &&
						formData[`${keyStrings.sign} `][0]) ||
						null,
					note: formData[`${keyStrings.reportNotes} `] &&
						formData[`${keyStrings.reportNotes} `][0]
						|| null,
					keyStatus: 0
				}
			}
			if (keyManagementId && keyRegistryManagementId && keyRegistryId && objSendPut) {
				putRiconsegnaKey({ keyManagementId: keyManagementId, keyRegistryId: keyRegistryId, keyManagementRegistryId: keyRegistryManagementId, objSendPut: objSendPut })
			}
		} else {
			let descriptionId: number | null = null;
			if (isReport) {
				anomalies?.map((anomalia) => {
					if (anomalia.description === formData[`${keyStrings.keyPlaceSelect} `][0]) {
						descriptionId = anomalia.id
					}
				})
			}
			if (keyManagementId && keyRegistryId) {
				let answersToPost: AnswerPostOneKey[] = [];
				for (const answerKey in formData) {
					if (formData[answerKey].length > 0 &&
						formData[answerKey][0] !== "" &&
						answerKey !== `${keyStrings.keyRecipientPersonId} ` &&
						answerKey !== `${keyStrings.sign} ` &&
						answerKey !== `${keyStrings.reportNotesKeySegnalazione} ` &&
						answerKey !== `${keyStrings.isSamePerson} ` &&
						answerKey !== `${keyStrings.keyPlaceSelect} ` &&
						answerKey !== `${keyStrings.reportNotes} `	) {
						answersToPost.push(
							(() => { {}
								const theAnswer: AnswerPostOneKey = formData[answerKey][0] && formData[answerKey][0].includes("base64") ? {
									questionId: Number(answerKey),
									medias: formData[answerKey].map(media => {
										return {
											mediaValue: media
										}
									}),
									value: []
								} : {
									questionId: Number(answerKey),
									value: formData[answerKey],
								};
								return theAnswer;
							})()
						)
					}
				}

				let deliveryDataKey: postDeliveryDataKey
				deliveryDataKey = {
					shiftId: "",
					personAssignedToId:
						formData[`${keyStrings.keyRecipientPersonId} `] &&
						formData[`${keyStrings.keyRecipientPersonId} `][0]
						|| null,
					signature: (formData[`${keyStrings.sign} `] &&
						formData[`${keyStrings.sign} `][0]) ||
						null,
					note: formData[`${keyStrings.reportNotesKeySegnalazione} `] &&
						formData[`${keyStrings.reportNotesKeySegnalazione} `][0] &&
						formData[`${keyStrings.reportNotesKeySegnalazione} `][0] ||
						formData[`${keyStrings.reportNotes} `] &&
						formData[`${keyStrings.reportNotes} `][0]
						|| null,
					anomalyId: descriptionId,
					keyStatus: isReport ? 2 : 1,
					answers: answersToPost
				};
				postDeliveryKey({ keyManagementId: keyManagementId, keyRegistryId: keyRegistryId?.toString(), shiftId: "", deliveryDataKey: deliveryDataKey })
			}
			setValidated(true);
		}
	};

	return {
		handleSubmit,
		data: postRegistrationKeyData,
		isLoading: postRegistrationKeyIsLoading || putRiconsegnaKeyDataIsLoading || postDeliveryKeyDataIsLoading,
		error: postDeliveryKeyDataError || putRiconsegnaKeyDataError,
		validated,
		formData,
		setFormData,
		isFormValid,
		showConfirmModal,
		setShowConfirmModal,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
		form,
		serviceError: postRegistrationKeyError || putRiconsegnaKeyDataError || postDeliveryKeyDataError
	};
};

export default useKeyDetailResponsabileForm;
