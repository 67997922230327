import logoApple from 'theme/assets/img/logos/apple.png'
import logoG from 'theme/assets/img/logos/g.png'
import logoIntel from 'theme/assets/img/logos/intel.png'
import logofacebook from 'theme/assets/img/logos/facebook.png'
import mailblusterLogo from 'theme/assets/img/logos/mailbluster.png'
import logoDigitalocean from 'theme/assets/img/logos/digitalocean.png'
import logoNvidia from 'theme/assets/img/logos/nvidia.png'
import logoHP from 'theme/assets/img/logos/hp.png'
import logoSpotify from 'theme/assets/img/logos/spotify.png'
import { IAssociations } from 'features/types/data'

const associations: IAssociations[] = [
	{
		image: logoApple,
		title: 'Apple',
		description: '3243 associates'
	},
	{ image: logoG, title: 'Google', description: '34598 associates', to: '#!' },
	{
		image: logoIntel,
		title: 'Intel',
		description: '7652 associates'
	},
	{
		image: logofacebook,
		title: 'Facebook',
		description: '765 associates'
	},
	{
		image: mailblusterLogo,
		title: 'Mailbluster',
		description: '54 associates'
	},
	{
		image: logoDigitalocean,
		title: 'Digital Ocean',
		description: '487 associates'
	},
	{
		image: logoNvidia,
		title: 'Nvidia',
		description: '98732 associates'
	},
	{
		image: logoHP,
		title: 'Hewlett Packard',
		description: '2345 associates'
	},
	{
		image: logoSpotify,
		title: 'Spotify',
		description: '2345 associates'
	}
]
export default associations
