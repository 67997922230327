import SearchLive from "components/content/FormComponents/SearchLive";
import { labels } from "features/locale/it-it";
import {
	DeliveredKeysT,
	KeyManagementT,
	StoredKeysT,
} from "features/storeManagement/services/models/KeysResponse";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

export interface KeySearchComponentProps {
	deliveredKeys: DeliveredKeysT[];
	storedKeys: StoredKeysT[];
	keyManagements: DeliveredKeysT[];
	setFilteredData: Function;
	isStorico: boolean;
	setIsStorico?: Function;
}

const KeySearchComponent = ({
	deliveredKeys,
	storedKeys,
	setFilteredData,
	keyManagements,
	isStorico,
	setIsStorico
}: KeySearchComponentProps) => {

	return (
		<Row className="row-container-search">
			<Col lg={3} md={4}>
				<Button
					type="button"
					id="submit"
					form="form-section"
					className="m-1 submit-button-keys"
					variant={isStorico ? "green" : "primary"}
					onClick={() => setIsStorico ? setIsStorico(!isStorico) : null}
				>
					<p>{isStorico ? labels.deliverKeys : labels.historyKeysDelivery}</p>
				</Button>
			</Col>
			<Col sm={12} md={8} lg={6}>
				<SearchLive
					deliveredKeys={deliveredKeys}
					keyManagements={keyManagements}
					storedKeys={storedKeys}
					isStorico={isStorico}
					setFilteredData={setFilteredData}
				/>
			</Col>
		</Row>
	);
};

export default KeySearchComponent;
