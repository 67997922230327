import { useState, FC } from 'react';
import {
	Button,
	Card,
	Form,
	FormControl,
	InputGroup,
	Table
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'theme/components/common/IconButton';
import { IEventTicket, ITicketRow } from 'features/types/events/createEvent';
import { nanoid } from '@reduxjs/toolkit';



const TicketRow: FC<ITicketRow> = function TicketRow({ name, price, checked = false, id, handleChange, handleRemove }) {
	return (
		<tr>
			<td>
				<Form.Control size="sm" type="text" placeholder="Option Name" value={name} onChange={({ target }): void => handleChange(id, 'name', target.value)}
				/>
			</td>
			<td>
				<InputGroup size="sm">
					<InputGroup.Text className="d-none d-sm-block">$</InputGroup.Text>
					<FormControl type="number" placeholder="Price" value={price} aria-label="Dollar amount (with dot and two decimal places)" step="0.01" onChange={({ target }): void => handleChange(id, 'price', target.value)}
					/>
				</InputGroup>
			</td>
			<td className="text-center align-middle">
				<Form.Check type="radio" id={`ticketChecked${id + 1}`} checked={checked} onChange={({ target }): void => handleChange(id, 'checked', target.checked)}
				/>
			</td>
			<td className="text-center align-middle">
				<Button variant="link" size="sm" onClick={(): void => handleRemove(id)}>
					<FontAwesomeIcon className="text-danger" icon="times-circle" />
				</Button>
			</td>
		</tr>
	);
};

const EventTicket: FC<IEventTicket> = function EventTicket() {
	const [tickets, setTickets] = useState([
		{ name: 'Front desks', price: 0.0, checked: true },
		{ name: 'Green gallery', price: 5.0 },
		{ name: 'VIP', price: 20.0 }
	]);

	// Change Ticket
	const changeTicket = (id: any, name: any, value: any): void => {
		const updatedTickets =
			name === 'checked'
				? tickets.map(ticket => ({ ...ticket, checked: false }))
				: [...tickets];
		const updatedTicket = { ...tickets[id], [name]: value };

		setTickets([
			...updatedTickets.slice(0, id),
			updatedTicket,
			...updatedTickets.slice(id + 1)
		]);
	};

	// Remove Ticket
	const removeTicket = (id: any): void =>
		setTickets([...tickets.slice(0, id), ...tickets.slice(id + 1)]);

	const handleAddTicket = (): void => {
		setTickets([...tickets, { name: '', price: 0 }]);
	};

	return (
		<Card className="mb-3">
			<Card.Header as="h5">Ticket Price</Card.Header>
			<Card.Body className="bg-light">
				<Button variant="falcon-default" size="sm">
					Free <span className="d-none d-sm-inline">Ticket</span>
				</Button>
				<Button variant="falcon-primary" className="ms-2" size="sm">
					Paid <span className="d-none d-sm-inline">Ticket</span>
				</Button>
				<Button variant="falcon-default" className="ms-2" size="sm">
					Donation
				</Button>

				<hr />
				<h6 className="fs-0">Pricing Options:</h6>
				<Form.Check type="checkbox" id="variablePricingCheckbox" label="Enable varible pricing" defaultChecked
				/>
				<Form.Check type="checkbox" id="purchaseModeCheckbox" label="Enable multi-option purchase mode."
				/>
				<SimpleBarReact>
					<Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
						<thead>
							<tr className="fs--1">
								<th scope="col">Option Name</th>
								<th scope="col">Price</th>
								<th scope="col">Default</th>
							</tr>
						</thead>
						<tbody className="event-ticket-body">
							{tickets.map((item, index) => (
								<TicketRow {...item} id={index} key={nanoid()} handleChange={changeTicket} handleRemove={removeTicket} name={item.name} price={item.price} checked
								/>
							))}
						</tbody>
					</Table>
				</SimpleBarReact>

				<IconButton onClick={handleAddTicket} icon="plus" transform="shrink-3" iconAlign='' iconClassName='falcon-default' rest={{}}                >
					Add Item
				</IconButton>
			</Card.Body>
		</Card>
	);
};

export default EventTicket;
