import "./style.scss";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { dateParser } from "features/utils/dateUtils";
import { ModuleJournalProps } from "features/types/modules/ModuleJournalProps";
import { labels } from "features/locale/it-it";

const ModulesJournal: React.FC<ModuleJournalProps> = ({
	id,
	date,
	baseRedirectUrl,
}) => {
	const navigate = useNavigate();
	const parsedDate = dateParser(new Date(date));

	return (
		<Row key={id} className="module-journal p-0 mx-4">
			<Col className="d-flex me-3 p-0">
				<Row className="m-0 bold-text date-time">
					<Col className="day p-0">{parsedDate.getDay()}</Col>
					<Col className="hour p-0 ps-1">
						{parsedDate.getHour().substring(0, 5)}
					</Col>
				</Row>
			</Col>
			<Col
				className="status-read bold-text mt-1 p-1"
				onClick={() => navigate(`${baseRedirectUrl}/read`)}
			>
				{labels.journalRead}
			</Col>
			<hr></hr>
		</Row>
	);
};

export default ModulesJournal;
