import { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { IRegistration } from 'features/types/authentication/registration';


const RegistrationForm: FC<IRegistration> = function RegistrationForm({ hasLabel }) {
	// State
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
		isAccepted: false
	});

	// Handler
	const handleSubmit = (e: any): void => {
		e.preventDefault();
		toast.success(`Successfully registered as ${formData.name}`, {
			theme: 'colored'
		});
	};

	const handleFieldChange = (e: any): void => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>Name</Form.Label>}
				<Form.Control placeholder={!hasLabel ? 'Name' : ''} value={formData.name} name="name" onChange={handleFieldChange} type="text" />
			</Form.Group>

			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>Email address</Form.Label>}
				<Form.Control placeholder={!hasLabel ? 'Email address' : ''} value={formData.email} name="email" onChange={handleFieldChange} type="text" />
			</Form.Group>

			<Row className="g-2 mb-3">
				<Form.Group as={Col} sm={6}>
					{hasLabel && <Form.Label>Password</Form.Label>}
					<Form.Control placeholder={!hasLabel ? 'Password' : ''} value={formData.password} name="password" onChange={handleFieldChange} type="password" />
				</Form.Group>
				<Form.Group as={Col} sm={6}>
					{hasLabel && <Form.Label>Confirm Password</Form.Label>}
					<Form.Control placeholder={!hasLabel ? 'Confirm Password' : ''} value={formData.confirmPassword} name="confirmPassword" onChange={handleFieldChange} type="password" />
				</Form.Group>
			</Row>

			<Form.Group className="mb-3">
				<Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
					<Form.Check.Input type="checkbox" name="isAccepted" checked={formData.isAccepted} onChange={(e): void =>
						setFormData({
							...formData,
							isAccepted: e.target.checked
						})
					}
					/>
					<Form.Check.Label className="form-label">
						I accept the <Link to="#!">terms</Link> and{' '}
						<Link to="#!">privacy policy</Link>
					</Form.Check.Label>
				</Form.Check>
			</Form.Group>

			<Form.Group className="mb-4">
				<Button className="w-100" variant='green' type="submit" disabled={
					!formData.name ||
					!formData.email ||
					!formData.password ||
					!formData.confirmPassword ||
					!formData.isAccepted
				}
				>
					Register
				</Button>
			</Form.Group>
		</Form>
	);
};

export default RegistrationForm;
