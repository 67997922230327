import AllarmiIspettiviIcon from 'assets/icons/AllarmiIspettivi';
import AntitaccheggioIcon from 'assets/icons/AntitaccheggioIcon';
import BookOpenIcon from 'assets/icons/BookOpenIcon';
import BoxIcon from 'assets/icons/Box';
import BuildingShieldIcon from 'assets/icons/BuildingShieldIcon';
import CalendarIcon from 'assets/icons/Calendar';
import ChecklistIcon from 'assets/icons/Checklist'
import ControlloAccessiIcon from 'assets/icons/ControlloAccessiIcon';
import CsvIcon from 'assets/icons/CsvIcon';
import ExcelIcon from 'assets/icons/ExcelIcon';
import FileShieldIcon from 'assets/icons/FileShieldIcon';
import IdCardIcon from 'assets/icons/IdCardIcon';
import ImageIcon from 'assets/icons/ImageIcon';
import IncidentManagementIcon from 'assets/icons/IncidentManagement';
import KeysIcon from 'assets/icons/KeysIcon';
import NewspaperIcon from 'assets/icons/Newspaper';
import ParcelManagementIcon from 'assets/icons/ParcelManagement';
import PdfIcon from 'assets/icons/PdfIcon';
import PowerPointIcon from 'assets/icons/PowerPointIcon';
import ReportClienteIcon from 'assets/icons/ReportClienteIcon';
import PatrolIcon from 'assets/icons/RondaIcon';
import RondeSignal from 'assets/icons/RondeSignal';
import VideoIcon from 'assets/icons/VideoIcon';
import VisiteIcon from 'assets/icons/VisiteIcon';
import WordIcon from 'assets/icons/WordIcon';
import { labels } from 'features/locale/it-it';
import Calendar from 'theme/components/common/Calendar';

const IconFunction = (description: string, size: string) => {
	switch (description) {
	case labels.checklistText:
		return <ChecklistIcon size={size} />
		break;
	case labels.patrolText:
		return <PatrolIcon size={size}/>
		break;
	case labels.incidentText:
		return <IncidentManagementIcon size={size}/>
		break;
	case labels.journalText:
		return <NewspaperIcon size={size}/>
		break;
	case labels.parcelText:
		return <BoxIcon size={size}/>
		break;
	case labels.shiftCheckText:
		return <ReportClienteIcon size={size} />
		break;
	case labels.shiftText:
		return <ReportClienteIcon size={size} />
		break;
	case labels.keysText:
		return <KeysIcon size={size}/>
		break;
	case labels.turnieTimbratureText:
		return <ReportClienteIcon size={size}/>
		break;
	case labels.pianificazioneTurniText:
		return <CalendarIcon size={size}/>
		break;
	case labels.turniCorrispondentiText:
		return <ReportClienteIcon size={size}/>
		break;
	case labels.auditText:
		return <ChecklistIcon size={size}/>
		break;
	case labels.rondeText:
		return <RondeSignal size={size}/>
		break;
	case labels.giornaleDiServizioText:
		return <NewspaperIcon size={size}/>
		break;
	case labels.eventiSegnalatiText:
		return <IncidentManagementIcon size={size}/>
		break;
	case labels.gestionePacchiePostaText:
		return <ParcelManagementIcon size={size}/>
		break;
	case labels.gestioneChiaviText:
		return <KeysIcon size={size}/>
		break;
	case labels.controlloTessereText:
		return <IdCardIcon size={size}/>
		break;
	case labels.registroAttivitàText:
		return <BookOpenIcon size={size}/>
		break;
	case labels.reportControlRoomText:
		return <FileShieldIcon size={size}/>
		break;
	case labels.reportControlloQualita:
		return <VisiteIcon size={size}/>
		break;
	case labels.reportAntiTaccheggio:
		return <AntitaccheggioIcon size={size}/>
		break;
	case labels.reportControlloAccessi:
		return <ControlloAccessiIcon size={size}/>
		break;
	case labels.reportRegistroAccessi:
		return <ControlloAccessiIcon size={size}/>
		break;
	case labels.reportAllarmiIspettivi:
		return <AllarmiIspettiviIcon size={size}/>
		break;
	case labels.word:
		return <WordIcon size={size}/>
		break;
	case labels.pdf:
		return <PdfIcon size={size}/>
		break;
	case labels.powerpoint:
		return <PowerPointIcon size={size}/>
		break;
	case labels.image:
		return <ImageIcon size={size}/>
		break;
	case labels.video:
		return <VideoIcon size={size}/>
		break;
	case labels.excel:
		return <ExcelIcon size={size}/>
		break;
	case labels.csv:
		return <CsvIcon size={size}/>
		break;
	}
	  
}
export default IconFunction