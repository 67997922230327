import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck } from '@fortawesome/free-solid-svg-icons';

const ChecklistIcon = (props: any) => (
	<FontAwesomeIcon icon={faListCheck} width={24} height={24} size = {props.size ? props.size : "sm"}{...props} />
)

export default ChecklistIcon


