import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import { headersCommon } from "./constants/headers";
import { OperatoreResponse, ReplaceOperatorResponse, turnoResponse, turnoSingoloResponse } from "./models/TurniResponse";
import { OperatoreRequest, ReplaceOperatorRequest, turnoRequest, turnoSingoloRequest } from "./models/TurniRequest";

export const turniApi = createApi({
	reducerPath: "turniApi",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getTurniData: builder.mutation<turnoResponse[], turnoRequest>({
			query: ({ idCustomer, serviceAddress }) => ({
				url: `/fe/Coordinators/Shifts?CustomerId=${idCustomer}&ServiceAddress=${serviceAddress}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getTurnoSingolo: builder.mutation<
			turnoSingoloResponse,
			turnoSingoloRequest
		>({
			query: ({ shiftId }) => ({
				url: `/fe/Coordinators/Shift?shiftId=${shiftId}`,
				headers: {
					...headersCommon,
					deviceId: sessionStorage.deviceId || localStorage.deviceId || "",
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
				},
			}),
		}),
		getOperators: builder.mutation<OperatoreResponse[], OperatoreRequest>({
			query: ({ firstName, lastName }) => ({
				url: `/fe/Coordinators/Operators?firstName=${firstName}&lastName=${lastName}`,
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
			}),
		}),
		replaceOperator: builder.mutation<
			ReplaceOperatorResponse,
			ReplaceOperatorRequest
		>({
			query: ({ shiftId, replaceOperatorBody }) => ({
				url: `/fe/Coordinators/Shifts/${shiftId}`,
				method: "PUT",
				headers: {
					...headersCommon,
					Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
					deviceId:
						sessionStorage.getItem("deviceId") ||
						localStorage.getItem("deviceId") ||
						"",
				},
				body: JSON.stringify(replaceOperatorBody),
			}),
		}),
	}),
});

export const { useGetTurniDataMutation, useGetTurnoSingoloMutation, useGetOperatorsMutation, useReplaceOperatorMutation } = turniApi;
