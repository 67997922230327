import * as React from "react"

const ArrowIcon = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" {...props}>
		<path
			className="grey-svg" 
			d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"
		/>
	</svg>
)

export default ArrowIcon