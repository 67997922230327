import { useState, FC } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { ILockScreenForm } from 'features/types/authentication/lockScreen';

const LockScreenForm: FC<ILockScreenForm> = function LockScreenForm({ type, ...rest }) {
	// State
	const [password, setPassword] = useState('');

	// Handler
	const handleSubmit = (e: any): void => {
		e.preventDefault();
		// setRedirect(true);
		toast.success(`Logged in as Emma Watson`, {
			theme: 'colored'
		});
	};

	return (
		<Row {...rest} as={Form} className={classNames('gx-2 mt-4', {
			'mx-sm-4 mb-2': type === 'simple'
		})} onSubmit={handleSubmit}>
			<Col>
				<Form.Control placeholder="Enter your password" value={password} name="password" onChange={({ target }): void => setPassword(target.value)} type="password" />
			</Col>
			<Col xs={type === 'simple' ? 'auto' : 4}>
				<Button variant="green" type="submit" disabled={!password} className={classNames({ 'w-100': type !== 'simple' })}>
					Login
				</Button>
			</Col>
		</Row>
	);
};

export default LockScreenForm;
