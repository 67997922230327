import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import useJournalForm from "./useJournalForm";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import ConfirmModal from "../../../content/ConfirmModal/ConfirmModal";
import ErrorBoundary from "features/ErrorBoundary";

import { labels } from "features/locale/it-it";
import { JournalMediaList } from "./JournalMediaList";
import TextArea from "../../../content/FormComponents/TextArea";
import { QuestionType } from "features/storeManagement/services/models/ChecklistResponse";
import IconButton from "theme/components/common/IconButton";
import {
	JournalFormInputT,
	JournalMedia,
} from "features/storeManagement/services/models/JournalResponse";
import { dateParser } from "features/utils/dateUtils";
import ImageModal from "components/content/modals/AlertModal/ImageModal";
import { resizeFile } from "components/pages/ImageUploadPage/ImageUploadPage";
import { useDeleteAJournalMutation } from "features/storeManagement/services/journalService";
import { useParams } from "react-router-dom";
import VideoModal from "components/content/modals/AlertModal/VideoModal";
import AlertModal from "components/content/modals/AlertModal";
import { JournalKeys } from "./JournalConstants";
import MediaPicker from "./MediaPicker";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import PrintButton from "components/content/PrintButton/PrintButton";

const JournalForm = ({
	text,
	journalId,
	journalLogId,
	journalCausalId,
	operator,
	lastUpdate,
	read,
	medias,
}: JournalFormInputT) => {
	const { shiftId } = useParams();

	const [mediaArray, setMediaArray] = useState<
		JournalMedia[] | null | undefined
	>(medias);
	const [mediaIdToDel, setMediaIdToDel] = useState<number>();
	const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
		useState<boolean>(false);
	const parsedDate = dateParser(new Date(lastUpdate || ""));
	const isResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;
	const [showErrorMediaModal, setShowErrorMediaModal] =
		useState<boolean>(false);

	const {
		handleSubmit,
		isLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		showConfirmModal,
		setShowConfirmModal,
		postOneJournalDataError,
		putOneJournalError
	} = useJournalForm({
		text,
		journalId,
		journalLogId,
		journalCausalId,
		medias: mediaArray,
	});

	const textAreaTitle = read
		? labels.journalFormReadMode
		: labels.journalFormWriteMode;

	const textAreaProps = {
		id: 1,
		title: textAreaTitle,
		subtitle: "",
		enabled: true,
		type: "textarea" as QuestionType,
		answers: null,
		isMandatory: !read,
		answerDetail: null,
		setFormData,
		formData,
		sectionAndQuestionId: "JournalNote",
		isExecutable: !read,
		validated: validated,
		classNameJournal: "journalTextArea",
	};

	const saveJournal = () => {
		const isNew = !text;
		let showErrorMedia: boolean = false;
		mediaArray?.map((media) => {
			if (media.type === JournalKeys.videoType) {
				if (media.mediaSize && media.mediaSize > JournalKeys.maxVideoSize) {
					showErrorMedia = true;
					media.error = true;
				}
			} else {
				if (media.mediaSize && media.mediaSize > JournalKeys.maxImageSize) {
					showErrorMedia = true;
					media.error = true;
				}
			}
		});
		setMediaArray(mediaArray);
		if (!isNew) {
			setShowConfirmModal(true);
		}
	};

	const [
		deleteAJournalMedia,
		{
			data: deleteAJournalMediaData,
			isLoading: deleteAJournalMediaIsLoading,
			error: deleteAJorunalMediaError,
			isError: isdeleteAJorunalMediaError,
		},
	] = useDeleteAJournalMutation();

	useEffect(() => {
		if (deleteAJournalMediaData) {
			setShowDeleteConfirmModal(false);
			const tempMediaArray = mediaArray?.filter((media) => {
				return media.id !== mediaIdToDel;
			});
			setMediaArray(tempMediaArray);
		}
	}, [deleteAJournalMediaData]);
	const deleteAJournalMediaWrap = (mediaId: number) => {
		if (journalLogId && (shiftId || isResponsabile) && journalId)
			deleteAJournalMedia({
				journalId,
				journalLogId,
				journalBodyDeleteRequest: {
					shiftId: shiftId || "",
					mediaId,
				},
			});
	};

	useEffect(() => {
		if (isdeleteAJorunalMediaError) {
			setShowDeleteConfirmModal(false);
			setShowErrorMediaModal(true);
		}
	}, [isdeleteAJorunalMediaError]);

	return (
		<>
			<ErrorBoundary errorMessage={postOneJournalDataError || putOneJournalError || error}>
				<Container className="journal-form-container">
					{read && (
						<>
							<Container className="box-details-container journal_centerd_button mb-3">
								<PrintButton />
							</Container>
							<Container
								fluid
								className="box-details-container blue-text bold-text journal-box-container px-5 mb-5 py-2"
							>
								<Row>
									<Col className="d-flex">{labels.operator}:</Col>
									<Col className="d-flex">{operator}</Col>
								</Row>
								<Row>
									<Col className="d-flex">{labels.fillInDate}:</Col>
									<Col className="d-flex">
										{parsedDate.getDay()} {parsedDate.getHour().substring(0, 5)}
									</Col>
								</Row>
							</Container>
						</>
					)}
					<Form
						ref={form}
						noValidate
						onSubmit={handleSubmit}
						className="mb-5"
						validated={false}
						id="JournalForm"
					>
						<Container className="form-item-container mb-3">
							<TextArea {...textAreaProps} />
						</Container>
						<MediaPicker
							read={read}
							deleteIsLoading={deleteAJournalMediaIsLoading}
							mediaArray={mediaArray}
							showDeleteConfirmModal={showDeleteConfirmModal}
							mediaIdToDel={mediaIdToDel}
							deleteAJournalMedia={deleteAJournalMediaWrap}
							setShowConfirmModal={setShowConfirmModal}
							setMediaArray={setMediaArray}
							setShowDeleteConfirmModal={setShowDeleteConfirmModal}
							setMediaIdToDel={setMediaIdToDel}
							flagGiornale={true}
						/>
					</Form>
					{!read && (
						<div className={"journal-form-buttons-container align-center"}>
							<ConfirmModal
								idForm="JournalForm"
								headerMessage={labels.modalJournalTitle}
								bodyMessage={labels.modalJournalBody}
								showConfirmModal={showConfirmModal}
								setShowConfirmModal={setShowConfirmModal}
								isLoading={isLoading}
								isFormValid={isFormValid}
							/>

							<Button
								type={text ? "button" : "submit"}
								id={text ? "draft" : "submit"}
								form="JournalForm"
								variant="green"
								className="submit-and-draft-buttons mb-3"
								disabled={
									isLoading || Object.keys(formData).length < 1 || !isFormValid
								}
								onClick={saveJournal}
							>
								{isLoading ? <Spinner /> : labels.save}
							</Button>
						</div>
					)}
				</Container>
			</ErrorBoundary>
			<AlertModal
				headerMessage={labels.warning}
				bodyMessage={labels.modalErrorIncidentBody}
				okButtonText={labels.ok}
				showAlertModal={showErrorMediaModal}
				setShowAlertModal={setShowErrorMediaModal}
			/>
		</>
	);
};

export default JournalForm;
