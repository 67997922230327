import { labels } from "features/locale/it-it";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import "./styles.scss";

export interface OperatorsFormProps {
	getOperatorsList: Function;
	operatorsIsLoading: boolean;
}

const OperatorsForm: React.FC<OperatorsFormProps> = ({
	getOperatorsList,
	operatorsIsLoading,
}) => {
	const [firstNameInsert, setFirstNameInsert] = useState<string>("");
	const [lastNameInsert, setLastNameInsert] = useState<string>("");

	const handleSubmit = (
		event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
	) => {
		event.preventDefault();
		getOperatorsList({
			firstName: firstNameInsert.trim(),
			lastName: lastNameInsert.trim(),
		});
	};

	return (
		<Form
			className="p-0"
			onSubmit={handleSubmit}
			id="operators-form-section"
		>
			<Row>
				<Col sm={4} className="pe-sm-1">
					<Form.Control
						className={`operators-search-control-form  ${
							lastNameInsert.length === 0 ? "background-grey-important" : ""
						}`}
						placeholder={labels.textCognome}
						type="text"
						onChange={({ target }): void => {
							setLastNameInsert(target.value);
						}}
						value={lastNameInsert}
					/>
				</Col>
				<Col sm={4} className="pe-sm-1">
					<Form.Control
						className={`operators-search-control-form  ${
							firstNameInsert.length === 0 ? "background-grey-important" : ""
						}`}
						placeholder={labels.textName}
						type="text"
						onChange={({ target }): void => {
							setFirstNameInsert(target.value);
						}}
						value={firstNameInsert}
					/>
				</Col>
				<Col sm={4} className="pe-sm-1">
					<div className="operators-button-content">
						<Button
							type="submit"
							id="submit"
							form="operators-form-section"
							className="submit-button-operators-form"
							variant="green"
							disabled={
								firstNameInsert.length <= 0 ||
								lastNameInsert.length <= 0 ||
								firstNameInsert.trim() === "" ||
								lastNameInsert.trim() === ""
							}
						>
							{operatorsIsLoading ? <Spinner /> : labels.search}
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
};

export default OperatorsForm;
