import ChecklistIcon from "assets/icons/Checklist";
import { labels } from "features/locale/it-it";
import { Checklist, DocumentToDownload } from "features/storeManagement/services/models/ChecklistResponse";
import { Col, Container, Row } from "react-bootstrap";
import { ActivityList } from "../ModulesResponsabile/ModulesResponsabile";
import ActivityItem from "../Activity/ActivityItem";
import AreaDocumentiIcon from "assets/icons/AreaDocumentiIcon";

interface CustomerProps {
	checklists?: Checklist[];
	documents?: DocumentToDownload[];
}

const ModulesCustomer: React.FC<CustomerProps> = (props: CustomerProps) => {
	let activityList: ActivityList[] = [];

	if (props.checklists && props.checklists.length > 0) {
		activityList.push({
			id: "checklist",
			description: labels.checklistText,
			redirectUrl: `/checklist/list`,
			icon: <ChecklistIcon size="lg" />,
		});
	}

	if(props.documents){
		activityList.push ({
			id: "documents",
			description: labels.documentsArea,
			redirectUrl: `/documents`,
			icon: <AreaDocumentiIcon size="lg" />,
		})
	}

	const isActivityList = (props?.checklists && props.checklists.length > 0 ||
							props?.documents && props.documents.length > 0);

	return (
		<>
			<Container fluid className="modules-container p-0 mb-5 mt-2 mt-md-5 ">
				{isActivityList && (
					<>
						<Row className="generic-report-title mx-2 my-2 px-0">
							<Col className="mx-0 bold-text-report blue-text px-0 py-2">
								{labels.myActivitiesText}
							</Col>
						</Row>
						<Row className="d-flex justify-content-start m-0">
							{activityList.map((activity) => {
								return (
									<ActivityItem
										key={activity.id}
										description={activity.description}
										redirectUrl={activity.redirectUrl}
										icon={activity.icon}
									/>
								);
							})}
						</Row>
					</>
				)}
			</Container>
		</>
	);
};

export default ModulesCustomer;
