/* eslint-disable no-unused-vars */
export const roles = {
	operatore : {
		key : 'operatore_interno', 
		label : 'Operatore'
	},
	corrispondente : {
		key : 'operatore_esterno', 
		label : 'Corrispondente'
	},
	coordinatore : {
		key : 'coordinatore', 
		label : 'Coordinatore'
	},
	admin : {
		key : 'admin', 
		label : 'Admin'
	},
	cliente : {
		key : 'cliente', 
		label : 'Cliente'
	},
	responabile : {
		key: 'responsabile_postazione_no_turno',
		label: 'Responsabile'
	}
}