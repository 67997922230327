
import { useGetRiconsegnaDetailsMutation, usePutCambioStatoKeyMutation } from "features/storeManagement/services/keyManagementService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import ModulePageTitle from "components/modules/ModulePageTitle/ModulePageTitle";
import Suspense from "features/Suspense";
import "./style.scss";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { nanoid } from "@reduxjs/toolkit";
import PrintButton from "components/content/PrintButton/PrintButton";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";
import AlertModal from "components/content/modals/AlertModal/AlertModal";
import { KeyRequestPutStatoApi, ObjectPutStato } from "features/storeManagement/services/models/KeysResponse";
const KeyBloccataPage: React.FC<{read?: boolean}> = ({read}) => {
	const { shiftId, keyManagementId, keyRegistryManagementId, answerId } = useParams();
	let objInfoDataOperatorKey = undefined;
	const [showErrorMediaModal, setShowErrorMediaModal] = useState<boolean>(false);
	const navigate = useNavigate();

	useChangeDocumentTitle(labels.pageTitle_KeysPage);

	const [
		getRiconsegnaDetails,
		{ data: riconsegnaDetailsData, isLoading: riconsegnaDataIsLoading, error:riconsegnaError },
	] = useGetRiconsegnaDetailsMutation({
		fixedCacheKey: fixedCacheKeys.infoDataKey,
	});

	useEffect(() => {
		if (shiftId && keyManagementId !== undefined && keyRegistryManagementId !== undefined && answerId) {
			getRiconsegnaDetails({ keyManagementId: keyManagementId.toString(), keyRegistryManagementId: Number(keyRegistryManagementId), shiftId: shiftId, answerId: Number(answerId) !== 0 ? Number(answerId) : undefined })
		}
	}, [])

	if (riconsegnaDetailsData && riconsegnaDetailsData.keyRecipient) {
		objInfoDataOperatorKey = {
			firstname: riconsegnaDetailsData.keyRecipient.firstName?.toString(),
			lastname: riconsegnaDetailsData.keyRecipient.lastName?.toString(),
			OperatorName: riconsegnaDetailsData.operatorNameOutbound?.toString(),
			time: riconsegnaDetailsData.outBoundTimeStamp?.toString(),
			companyName: riconsegnaDetailsData.keyRecipient.companyName?.toString(),
			additionalInfo: riconsegnaDetailsData.keyRecipient.additionalInfo?.toString(),
			deliveredNote: riconsegnaDetailsData.deliveredNote?.toString(),
		}
	}
	
	const checkDate = () => {
		if(riconsegnaDetailsData){
			if(riconsegnaDetailsData && riconsegnaDetailsData?.keyRecipient){
				return riconsegnaDetailsData?.inBoundTimeStamp ? format(
					new Date(riconsegnaDetailsData?.inBoundTimeStamp),
					"dd/MM/yyyy HH:mm") : "-"
			}else {
				return riconsegnaDetailsData?.outBoundTimeStamp ? format(
					new Date(riconsegnaDetailsData?.outBoundTimeStamp),
					"dd/MM/yyyy HH:mm") : "-"
			}
		}
	}

	const isShowMediaQuestions = riconsegnaDetailsData && 
	riconsegnaDetailsData.keyRegistryModelOut && 
	riconsegnaDetailsData.keyRegistryModelOut.questions &&
	riconsegnaDetailsData.keyRegistryModelOut.questions.length > 0;

	const [
		keyStatoPutData,
		{
			data: keyStatoPutDettaglioData,
			isLoading: keyStatoPutDettaglioDataIsLoading,
			isError: isKeyStatoPutDettaglioDataError,
			error: keyStatoPutDettaglioDataError,
		},
	] = usePutCambioStatoKeyMutation();


	const confirmModalPut = () => {
		let ObjSend:ObjectPutStato | null = null;

		if(shiftId){
			ObjSend = {
				shiftId: shiftId,
			}

			if(keyManagementId && riconsegnaDetailsData?.keyRegistryModelOut.keyRegistry.id) {
				keyStatoPutData({ keyManagementId: keyManagementId?.toString(), keyRegistryId: riconsegnaDetailsData?.keyRegistryModelOut.keyRegistry.id.toString(), objSendPut: ObjSend })
			}
		}
	}

	useEffect(()=>{
		if(!keyStatoPutDettaglioDataError && keyStatoPutDettaglioData){
			setShowErrorMediaModal(true)
		}
	}, [keyStatoPutDettaglioDataIsLoading])

	const redirectFunction = () => {
		navigate(`/shift/${shiftId}/keys/${keyManagementId}/${riconsegnaDetailsData?.keyRegistryModelOut.keyRegistry.id.toString()}/delivery`)
	}

	return (
		<ErrorBoundary errorMessage={riconsegnaError || keyStatoPutDettaglioDataError}>
			<Container className="box-details-container key-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={riconsegnaDataIsLoading || keyStatoPutDettaglioDataIsLoading} fullPage>
					<Container fluid className='box-details-accordion p-1 mt-2 open standard d-flex justify-content-end'>
						<PrintButton />
					</Container>
					<BoxDetails
						recipientKeyData={riconsegnaDetailsData?.keyRegistryModelOut}
						objInfoDataOperatorKey={objInfoDataOperatorKey}
					/>

					{isShowMediaQuestions &&
					riconsegnaDetailsData.keyRegistryModelOut.questions.filter((el) => el.type === labels.picture).map((question: Question) => (
						question.answerDetail?.medias && question.answerDetail?.medias.length > 0 && (
							<div key={question.id} className="box-details-container px-sm-5 px-0">
								<MediaPicker
									read={true}
									mediaArray={question.answerDetail?.medias}
									mediaTitle={question.title}
									deleteIsLoading={false}
									showDeleteConfirmModal={false}
									deleteAJournalMedia={() => {}}
									setShowConfirmModal={() => {}}
									setMediaArray={() => {}}
									setShowDeleteConfirmModal={() => {}}
									setMediaIdToDel={() => {}}
									flagGiornale={false}
								/>
							</div>
						)
					)
					)}

					<div className="print-full-section">
						<div className="box-details-container px-1 mt-3 container-fluid key-consultation-box">
							<div className="element_container__label my-2 mt-5">
								<ModulePageTitle title={labels.infoKeyStatusBloccata} />
							</div>
						</div>
						<div
							className={`box-details-accordion p-1 mx-auto mt-2 open standard mb-3`}
						>
							<Container fluid className="box-modules-container base-container fade-content mt-2 p-0">
								<Row
									className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
									onClick={() => {}}
								>
									<div />
									<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
										<Col className="datetime-box col-7 d-flex flex-column align-items-start px-1">
											<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
												<div className="bold-text operator-div col-5">
													{labels.descriptionKeyStatusBloccata}
												</div>

												<div className="day bold-text col-md-6">
													<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
														<div className="bold-text col-12">
															<div className="day bold-text">
																{riconsegnaDetailsData?.anomalyDescription}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
												<div className="bold-text operator-div col-5">
													{labels.operatorKeyStatusBloccata}
												</div>
												<div className="day bold-text col-6">
													<span>
														{riconsegnaDetailsData?.operatorNameOutbound ? riconsegnaDetailsData?.operatorNameOutbound : "-"}
													</span>
												</div>
											</div>
										</Col>
										<Col
											key="checkout-time"
											className="datetime-box col-5 d-flex flex-column align-items-start px-1"
										>
											<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
												<div className="label-left bold-text">
													{labels.dateKeyStatusBloccata}
												</div>
												<span className="day bold-text margin-inline">
													{checkDate()}
												</span>
											</div>
											<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
												<div className="bold-text operator-div col-5">
													{labels.noteKeyStatusBloccata}
												</div>

												<div className="day bold-text col-md-6">
													<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
														<div className="bold-text col-12">
															<div className="day bold-text">
																{riconsegnaDetailsData?.anomalyNote}
															</div>
														</div>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</Row>							
							</Container>
							<Container className="key-sblocca-button-container w-100 d-flex flex-wrap align-items-center justify-content-center mt-4 p-0">
								{
									riconsegnaDetailsData?.changeStatusEnabled && !read &&
									<Button
										className="key-sblocca-button m-2"
										variant="green"
										onClick={()=>{	
											confirmModalPut()						
										}}
									>
										{labels.buttonSbloccaChiavi}
									</Button>
								}
							</Container>

							<AlertModal
								headerMessage={labels.controlloAccessiSbloccaHeader}
								bodyMessage={labels.controlloAccessiSbloccaBody}
								okButtonText={labels.ok}
								showAlertModal={showErrorMediaModal}
								setShowAlertModal={setShowErrorMediaModal}
								redirectFunction={redirectFunction}
								onHide={true}
							/>
						</div>
					</div>
				</Suspense>
			</Container>
		</ErrorBoundary>
	)
}

export default KeyBloccataPage