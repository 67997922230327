import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { labels } from "features/locale/it-it";
import isMobile from "ismobilejs";

import "./style.scss";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import ModulePageTitle from "components/modules/ModulePageTitle";
import { useGetParcelDetailMutation } from "features/storeManagement/services/parcelService";
import useParcelDetailForm from "./useParcelDetailForm";
import SelectAutoComplete from "components/content/FormComponents/SelectAutoComplete";
import { useGetRecipientsMutation } from "features/storeManagement/services/recipientService";
import { parseRecipientsAll, parseSingleRecipientPerson } from "../ParcelItemNewPage/ParcelUtils";
import { ChecklistFormStateType, Question } from "features/storeManagement/services/models/ChecklistResponse";
import Radio from "components/content/FormComponents/Radio";
import Suspense from "features/Suspense";
import { radioProps, textAreaProps, selectProps } from "./utils";
import Select from "components/content/FormComponents/Select";
import TextArea from "components/content/FormComponents/TextArea";
import SignaturePage from "../SignaturePage";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import { parcelKeys } from "features/storeManagement/services/models/ParcelResponse";
import AlertModal from "components/content/modals/AlertModal/AlertModal";
import ServiceParcelPage from "../ParcelItemsPage/ServiceParcelPage";
import { is_touch_enabled } from "../SignaturePage/SignaturePage";
import { useDispatch } from "react-redux";
import { GetRecipientsResponse } from "features/storeManagement/services/models/RecipientsResponse";
import { AutocompleteOptionsType } from "features/storeManagement/services/models/AutocompleteSelect";
import ErrorBoundary from "features/ErrorBoundary/ErrorBoundary";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import MediaPicker from "components/modules/Journal/JournalForm/MediaPicker";

const ParcelDeliveryPage: React.FC = () => {
	const { shiftId, parcelId, parcelManagementId, answerId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [globalLoader, setGlobalLoader] = useState(true);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isReport, setIsReport] = useState<boolean>(false);
	const [signatureImage, setSignatureImage] = useState<string>();
	const [popupData, setPopupData] = useState<GetRecipientsResponse | undefined>();
	const [defaultValues, setDefaultValues] = useState<AutocompleteOptionsType | undefined>();
	const [flagEmptySign, setFlagEmptySign] = useState<boolean>(false);

	useChangeDocumentTitle(labels.pageTitle_ParcelPage);

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{ data: currentShiftData, isLoading: currentShiftIsLoading },
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		getRecipients,
		{ data: recipientsData, isLoading: recipientsIsLoading },
	] = useGetRecipientsMutation();

	const [
		getParcelDetailMutations,
		{
			data: parcelDetailData,
			isLoading: parcelDetailIsLoading,
			error: parcelDetailError,
		},
	] = useGetParcelDetailMutation();

	const device = isMobile(window.navigator);
	const [isTouch, setIsTouch] = useState<boolean>(is_touch_enabled() || device.tablet);

	const {
		handleSubmit,
		isLoading,
		error,
		validated,
		formData,
		setFormData,
		isFormValid,
		form,
		setShowAlertModal,
		showAlertModal,
		title,
		bodyTextAlert,
		putOneParcelDataError
	} = useParcelDetailForm(isTouch, isReport);

	useEffect(() => {
		if (
			shiftId &&
			(shiftData || currentShiftData) &&
			parcelId &&
			parcelManagementId &&
			answerId
		) {
			getParcelDetailMutations({
				parcelId,
				parcelManagementId,
				answerId,
				shiftId,
			});

			getRecipients({
				shiftId,
				parcelId,
			});
		}
	}, [shiftData, currentShiftData, showConfirmModal, showAlertModal]);

	const [responseErrorModal, setResponseErrorModal] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)

	const handleResponseErrorModal = (err:FetchBaseQueryError | SerializedError | undefined) => {
		setResponseErrorModal(err)
	}

	useEffect(() => {
		if (parcelDetailData && recipientsData) {
			setGlobalLoader(false);
		}
	}, [parcelDetailData, recipientsData]);

	useEffect(() => {
		setFormData((oldState: ChecklistFormStateType) => {
			return { ...oldState, [`${parcelKeys.sign} `]: [signatureImage || ""] };
		});
	}, [signatureImage]);

	const redirectFunction = () => {
		const url = `/shift/${shiftId}/parcel`;
		navigate(url);
	};

	const formDataPersonCompare =
		formData[`${parcelKeys.isSamePerson} `] &&
		formData[`${parcelKeys.isSamePerson} `][0].toLowerCase() ===
			labels.noLabel.toLowerCase();

	useEffect(() => {
		if (!showConfirmModal) {
			popupData && setDefaultValues(parseSingleRecipientPerson(popupData))
		}
	}, [showConfirmModal, popupData]);

	useEffect(() => {
		if (formData[`${parcelKeys.sign} `] && formData[`${parcelKeys.sign} `][0] !== undefined){
			setFlagEmptySign(true)
		}
		setFormData({
			...formData,
			[`${parcelKeys.sign} `]: [],
			[`${parcelKeys.parcelRecipientPersonId} `]: []
		})
		setDefaultValues(undefined)
	}, [formData[`${parcelKeys.isSamePerson} `]])

	return (
		<ErrorBoundary errorMessage={parcelDetailError || putOneParcelDataError || responseErrorModal}>
			<Container className="parcel-items-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={globalLoader || parcelDetailIsLoading || isLoading} fullPage>
					<ServiceParcelPage
						setPopupData={setPopupData}
						showConfirmModal={showConfirmModal}
						setShowConfirmModal={setShowConfirmModal}
						handleResponseErrorModal = {handleResponseErrorModal}
						isFromKey = {false}
						parcelId={(
							shiftData?.parcel.id ||
							currentShiftData?.parcel.id ||
							""
						)?.toString()}
					/>
					<BoxDetails
						shift={shiftData || currentShiftData}
						parcel={parcelDetailData}
						setIsReport={setIsReport}
						isReport={isReport}
					/>
						
					<Form
						ref={form}
						noValidate
						onSubmit={handleSubmit}
						validated={false}
						id="parcelForm"
						className="box-details-container px-sm-5 px-0"
					>

						<Row>
							{!!parcelDetailData && 
							parcelDetailData.questions && 
							parcelDetailData.questions.filter((el) => el.type === labels.picture).map((question: Question) => (
								question.answerDetail?.medias && question.answerDetail?.medias.length > 0 && (
									<div key={question.id}>
										<MediaPicker
											read={true}
											mediaArray={question.answerDetail?.medias}
											mediaTitle={question.title}
											deleteIsLoading={false}
											showDeleteConfirmModal={false}
											deleteAJournalMedia={() => {}}
											setShowConfirmModal={() => {}}
											setMediaArray={() => {}}
											setShowDeleteConfirmModal={() => {}}
											setMediaIdToDel={() => {}}
											flagGiornale={false}
										/>
									</div>
								)
							)
							)}
						</Row>

						{!isReport ? (
							<>
								<Row className="m-0">
									<Col className="items-container px-0 mt-4 mb-2">
										<ModulePageTitle
											title={labels.recipientSameDeliveredPerson}
										/>
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 my-2">
										<Radio
											{...radioProps({ formData, setFormData, validated, title: "" })}
										/>
									</Col>
								</Row>
								{formDataPersonCompare && (
									<>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.recipientPersonRLabel} />
											</Col>
										</Row>
										<Row className="m-0">
											<SelectAutoComplete
												formData={formData}
												setFormData={setFormData}
												defaultValue={defaultValues || null}
												enabled={true}
												isClearable={true}
												name="parcelRecipientPersonId"
												options={parseRecipientsAll(recipientsData || [])}
												title={""}
												subtitle={labels.textAggiungiNominativo}
												isMandatory={false}
												validated={false}
												newRecipientButton={true}
												clickedButton={() => setShowConfirmModal(true)}
												className="select-width-50"
											/>
										</Row>
									</>
								)}
								{isTouch && (
									<>
										<Row className="m-0">
											<Col className="items-container px-0 my-2">
												<ModulePageTitle title={labels.withdrawingPersonSignature} />
											</Col>
										</Row>
										<SignaturePage
											nameClass="mt-0"
											signatureImage={setSignatureImage}
											flagEmptySign={flagEmptySign}
											setFlagEmptySign={setFlagEmptySign}
										/>
									</>
								)}
							</>
						) : (
							<Container>
								<Row className="m-0 mt-4">
									<Col className="items-container px-0 my-0">
										<ModulePageTitle title={labels.parcelStatusLabel} />
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 mb-3 mt-0">
										<div className="form-select-container">
											<Select
												{...selectProps({ formData, setFormData, validated })}
											/>
										</div>
									</Col>
								</Row>
								<Row className="m-0 mt-3">
									<Col className="items-container px-0 my-0">
										<ModulePageTitle title={labels.reportNotes} />
									</Col>
								</Row>
								<Row className="m-0">
									<Col className="items-container px-0 mb-3 mt-0">
										<TextArea
											{...textAreaProps({ formData, setFormData, validated })}
										/>
									</Col>
								</Row>
							</Container>
						)}
						<Container className="p-0">
							<Row className="m-0">
								<Col
									className={
										"items-container px-0 my-4" +
										(isReport || !isTouch ? "" : "space-between")
									}
								>
									<Button
										type="button"
										variant="green"
										className="submit-and-draft-buttons"
										onClick={() => {
											const url = `/shift/${shiftId}/parcel`;
											navigate(url);
										}}
										disabled={isLoading}
									>
										{labels.cancel}
									</Button>
									<Button
										type="submit"
										id="submit"
										form="parcelForm"
										variant="green"
										className="submit-and-draft-buttons"
										disabled={isLoading || !isFormValid}
									>
										{isLoading ? <Spinner /> : labels.save}
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
					<AlertModal
						headerMessage={title}
						bodyMessage={bodyTextAlert}
						okButtonText={labels.ok}
						showAlertModal={showAlertModal}
						setShowAlertModal={setShowAlertModal}
						redirectFunction={redirectFunction}
					/>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ParcelDeliveryPage;
