import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import './style.scss'
import { ModuleStateProps, moduleStatus } from 'features/types/modules/ModuleStateProps'
import { dateParser } from 'features/utils/dateUtils'
import { labels } from 'features/locale/it-it'
import AlertIcon from 'assets/icons/AlertIcon'

const ModuleStateControlloAccessi: React.FC<ModuleStateProps> = ({ id, date, baseRedirectUrl, description }) => {
	const navigate = useNavigate()
	const parsedDate = dateParser(new Date(date));

	return (
		<Row key={id} className={'module-state'}>
			<Col className={`d-flex flex-column justify-content-center align-items-start p-0 ps-2 col-6`}>
				{
					description &&
					<Row className='text-controlloAccessi m-0 bold-text date-time'>
						{description}
					</Row>
				}
				<Row className='m-0 bold-text date-time'>
					<Col className='day p-0 text-end'>
						{parsedDate && parsedDate.getDay()}
					</Col>
					<Col className='hour p-0 ps-1 text-start'>
						{parsedDate && parsedDate.getHour().substring(0, 5)}
					</Col>
				</Row>
			</Col>
			<Col
				className={`col-4 px-2 status-read bold-text`}
				onClick={() => { navigate(baseRedirectUrl) }}>
				{labels.read}
			</Col>
		</Row>
	)
}

export default ModuleStateControlloAccessi





