import { useState, FC } from 'react';
import { Card } from 'react-bootstrap';
import { nanoid } from '@reduxjs/toolkit';
import IconButton from 'theme/components/common/IconButton';
import { IEventSchedule } from 'features/types/events/createEvent';
import EventScheduleItem from './EventScheduleItem';


const EventSchedule: FC<IEventSchedule> = function EventSchedule({ register, setValue }) {
	const [items, setItems] = useState([
		{
			title: '',
			startDate: new Date(),
			startTime: new Date(),
			endDate: new Date(),
			endTime: new Date()
		}
	]);
	const handleAddSchedule = (): void => {
		setItems([
			...items,
			{
				title: '',
				startDate: new Date(),
				startTime: new Date(),
				endDate: new Date(),
				endTime: new Date()
			}
		]);
	};
	const handleRemoveItem = (index: number): void => {
		setItems(items.filter((item, ind) => index !== ind));
	};
	const handleChange = (id: any, name: any, value: any): void => {
		const newItems = [...items];
		const updatedItem = { ...newItems[id], [name]: value };
		setItems([
			...newItems.slice(0, id),
			updatedItem,
			...newItems.slice(id + 1)
		]);
	};

	return (
		<Card className="mb-3">
			<Card.Header as="h5">Schedule</Card.Header>
			<Card.Body className="bg-light">
				{items.map((item, index) => (
					<EventScheduleItem {...item} register={register} setValue={setValue} key={nanoid()} index={index} handleRemove={handleRemoveItem} handleChange={handleChange}
					/>
				))}

				<IconButton onClick={handleAddSchedule} icon="plus" transform="shrink-3" iconAlign='center' iconClassName='sm' rest={{}}                >
					Add Item
				</IconButton>
			</Card.Body>
		</Card>
	);
};

export default EventSchedule;
