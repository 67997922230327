import { Container, Form } from "react-bootstrap";
import { useMediaQuery } from "features/hooks/useMediaQuery";

interface GenericFilterProps {
	searchWord: string;
	setSearchWord: Function;
	placeholder: string;
}

const GenericFilter: React.FC<GenericFilterProps> = ({ searchWord, setSearchWord, placeholder }) => {
	const isScreenBiggerThan768 = useMediaQuery("(min-width:768px)");

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchWord(event.target.value);
	};

	return (
		<Container fluid className={isScreenBiggerThan768 ? "px-5" : "px-0"}>
			<Form.Group>
				<Form.Control
					className={`${searchWord.length === 0 ? "background-grey-important" : ""}`}
					placeholder={placeholder}
					type="text"
					onChange={handleSearchChange}
				/>
			</Form.Group>
		</Container>
	);
};

export default GenericFilter;
