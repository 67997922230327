import { labels } from "features/locale/it-it";
import "./style.scss";
import {
	getRecipientKeyResponse,
	keyBoxDetailsButtom,
} from "features/storeManagement/services/models/KeysResponse";
import { Col, Container, Row } from "react-bootstrap";
import ArrowIcon from "assets/icons/ArrowIcon";
import { dateParser } from "features/utils/dateUtils";
import { Question } from "features/storeManagement/services/models/ChecklistResponse";

interface keyInterfaceType {
	keyData: getRecipientKeyResponse;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	flagConsegnaChiavi: boolean;
	objInfoDataOperatorKey: keyBoxDetailsButtom | undefined;
}
const KeyDetails = (props: keyInterfaceType) => {
	const keyFirstName = props.objInfoDataOperatorKey?.firstname || "";
	const keyLastName = props.objInfoDataOperatorKey?.lastname || "";
	const keyName = `${
		keyFirstName !== "" ? keyFirstName + " " : ""
	}${keyLastName !== "" ? keyLastName : ""}`;

	let objInfoDataOperatorKey = undefined;
	let index:number;
	let resultLeft:Question[] = [];
	let resultRight:Question[] = [];


	let filterArr = props.keyData?.questions?.filter((el) => el.type !== labels.picture);

	if (filterArr) {
		index = Math.ceil(filterArr?.length / 2);
		resultRight = filterArr.slice(0, index);
		resultLeft = filterArr.slice(index);
	}

	let checkPariDispari;
	
	if(filterArr){
		checkPariDispari = filterArr.length % 2;
	}

	return (
		<div>
			<div className={`box-details-accordion p-1 mx-auto mt-2 open standard`}>
				<Container
					fluid
					className="box-modules-container base-container fade-content mt-2 p-0 mb-1"
				>
					<Row
						className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
						onClick={() => {}}
					>
						<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
							<Col className="datetime-box col-7 d-flex flex-column align-items-start px-1">
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
									<div className="blue-text bold-text operator-div col-5">
										<span>{labels.codeKey}{":  "}</span>
									</div>

									<div className="day bold-text col-md-6">
										<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
											<div className="bold-text col-12">
												<div className="bold-text blue-text align-self-center">
													<span className="text">{" " + props.keyData?.keyRegistry?.keyCode}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
									<div className="blue-text bold-text operator-div col-5">
										{labels.RequestAuthorizationKey}{":  "}
									</div>
									<div className="day bold-text col-md-6">
										<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
											<div className="bold-text col-12">
												<div className="blue-text bold-text align-self-center">
													<span className="text">{props.keyData?.keyRegistry?.authorizationRequired === false ? labels.noLabel : labels.yesLabel}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
							<Col
								key="checkout-time"
								className="datetime-box col-5 d-flex flex-column align-items-start px-1"
							>
								<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
									<div className="blue-text bold-text operator-div col-5">
										{labels.descriptionKey}{":  "}
									</div>
									<div className="day bold-text col-md-6">
										<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
											<div className="bold-text col-12">
												<div className="blue-text bold-text align-self-center">
													<span className="text-left">{props.keyData?.keyRegistry?.description}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Row>
				</Container>
				{
					!props.flagConsegnaChiavi && (
						<Container
							fluid
							className="box-modules-container base-container fade-content mt-2 p-0"
						>
							<Row
								className="accordion-box grey-text flex-wrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0"
								onClick={() => {}}
							>
								<div />
								<Row className="d-flex flex-nowrap shift-time-container px-3 m-0 px-md-3 px-lg-3 px-xl-5">
									<Col className="datetime-box col-7 d-flex flex-column align-items-start px-1">
										<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
											<div className="bold-text operator-div col-5">
												{labels.keyStatusConsegnateA}:
											</div>

											<div className="day bold-text col-md-6">
												<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
													<div className="bold-text col-12">
														<div className="day bold-text">
															{keyName}
														</div>
														<div className="col-12">
															{props?.objInfoDataOperatorKey?.companyName}{" "}
															{props?.objInfoDataOperatorKey?.additionalInfo
																? `- ${props?.objInfoDataOperatorKey?.additionalInfo}`
																: ""}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="info d-flex flex-column flex-md-row w-100 flex-wrap mb-1">
											<div className="bold-text operator-div col-5 item-box-margin">
												{labels.KeyNotesDelivery}:
											</div>
											<div className="day bold-text col-md-6">
												<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
													<div className="bold-text col-12">
														<div className="day bold-text">
															{props?.objInfoDataOperatorKey?.deliveredNote
																? props?.objInfoDataOperatorKey?.deliveredNote
																: "-"}
														</div>
													</div>
												</div>
											</div>
											{resultLeft?.map((el, i) => (
												<div key= {i} className="mt-1 d-flex flex-column flex-md-row w-100 flex-wrap item-box-margin">
													<div className="bold-text operator-div col-5">
														{el.title + ":"}
													</div>
													<div className="day bold-text col-6">
														{el.answerDetail?.value ? el.answerDetail?.value : "-"}
													</div>
												</div>
											))}
										</div>
										{
											checkPariDispari !== 0 && ( 
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.operatorKeyStatusConsegnate}
													</div>
													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<span>
																	{props?.objInfoDataOperatorKey?.OperatorName
																		? props?.objInfoDataOperatorKey?.OperatorName
																		: "-"}
																</span>
															</div>
														</div>
													</div>
												</div>
											)
										}
									</Col>
									<Col
										key="checkout-time"
										className="datetime-box col-5 d-flex flex-column align-items-start px-1"
									>
										<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
											<div className="bold-text operator-div col-5">
												{labels.dateKeyStatusConsegnate}
											</div>

											<div className="day bold-text col-md-6">
												<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
													<div className="bold-text col-12">
														<div className="day bold-text">
															{props?.objInfoDataOperatorKey?.time
																? dateParser(
																	props?.objInfoDataOperatorKey?.time || ""
																).getDay() +
																	" " +
																	dateParser(props?.objInfoDataOperatorKey?.time || "")
																		.getHour()
																		.substring(0, 5)
																: "-"}
														</div>
													</div>
												</div>
											</div>
										</div>
										{
											(resultRight.length > 0) && (
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													{resultRight?.map((el, i) => (
														<div key= {i} className="mt-1 d-flex flex-column flex-md-row w-100 flex-wrap item-box-margin">
															<div className="bold-text operator-div col-5">
																{el.title + ":"}
															</div>
															<div className="day bold-text col-6">
																{el.answerDetail?.value ? el.answerDetail?.value : "-"}
															</div>
														</div>
													))}
												</div>
											)
										}
										{
											checkPariDispari === 0 && ( 
												<div className="info d-flex flex-column flex-md-row w-100 flex-wrap">
													<div className="bold-text operator-div col-5">
														{labels.operatorKeyStatusConsegnate}
													</div>
													<div className="day bold-text col-md-6">
														<div className="d-flex flex-column flex-md-row w-100 flex-wrap ">
															<div className="bold-text col-12">
																<span>
																	{props?.objInfoDataOperatorKey?.OperatorName
																		? props?.objInfoDataOperatorKey?.OperatorName
																		: "-"}
																</span>
															</div>
														</div>
													</div>
												</div>
											)
										}
									</Col>
								</Row>
							</Row>
						</Container>
					)
				}
				
			</div>
		</div>
	);
};

export default KeyDetails;
