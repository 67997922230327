import "./index.scss";
import React, { useEffect, useState } from "react";

import { Button, Container } from "react-bootstrap";
import ChecklistForm from "components/modules/Checklist/ChecklistForm";
import ChecklistReport from "components/modules/Checklist/ChecklistReport";
import ModulePageTitle from "components/modules/ModulePageTitle";
import ErrorBoundary from "features/ErrorBoundary";
import Suspense from "features/Suspense/Suspense";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import { useParams } from "react-router-dom";
import { useGetChecklistQuestionsMutation, useGetOneChecklistQuery } from "features/storeManagement/services/checklistService";
import { labels } from "features/locale/it-it";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

const ChecklistPage = () => {
	useChangeDocumentTitle(labels.pageTitle_DettaglioChecklist);
	const [errorChecklist, setErrorChecklist] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined)
	const { shiftId, checklistId, draftId } = useParams();

	const [
		getChecklistQuestions,
		{
			data: checklistData,
			isLoading: checklistIsLoading,
			error: checklistError,
		},
	]  = useGetChecklistQuestionsMutation({
		fixedCacheKey: fixedCacheKeys.checklistQuestionsData,
	});

	useEffect(() => {
		if (shiftId && checklistId) {
			getChecklistQuestions({ shiftId, checklistId, answerId: draftId });
		}
	}, [shiftId, checklistId, draftId]);

	return (
		<ErrorBoundary errorMessage={checklistError || errorChecklist}>
			<Container className="checklist-page-container p-0 px-4 mb-0 mt-4 ">
				<Suspense isLoading={checklistIsLoading} fullPage>
					<>
						{checklistData && (
							<ModulePageTitle
								title={checklistData.description}
								date={checklistData.lastUpdateDate}
								isPrint={checklistData && checklistData.completedAt ? true : false}
								author={checklistData.createdBy}
							/>
						)}
						{checklistData && checklistData.completedAt ? (
							<ChecklistReport
								sections={checklistData && checklistData.sections}
							/>
						) : (
							<ChecklistForm
								sections={checklistData && checklistData.sections}
								isExecutable={ true }
								setError = {setErrorChecklist}
							/>
						)}
					</>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default ChecklistPage;
