import './style.scss'

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { CustomerNameProps } from 'features/types/modules/AuditModulesPageProps'
import { labels } from 'features/locale/it-it'


const CustomerName: React.FC<CustomerNameProps> = ({customerInfo, isOpen, setIsOpen}) => {
	return (
		<>
			<Row 
				className='accordion-box flex-nowrap px-3 px-md-3 px-lg-3 px-xl-5 mx-0'
				onClick={() => setIsOpen(!isOpen)}
			>
				<Col className='d-flex align-items-center col-2 col-sm-1 col-md-2 accordion-type mx-0 text-start p-0'></Col>
				<Col className='d-flex align-items-center col-8 col-sm-10 col-md-8 accordion-details ps-sm-4 ps-md-2'>
					<Row className="client-name w-100 flex-no-wrap justify-content-center align-items-center">
						<Col className="col-auto label bold-text text-end px-1">{labels.customer}</Col>
						<Col className="col-auto blue-text bold-text text-start px-1">{customerInfo?.customerName?.toLowerCase()}</Col>
					</Row> 
					<Row className="address"></Row>
				</Col>
				<Col className='d-flex align-items-center justify-content-center justify-content-md-end col-2 col-sm-1 col-md-2 arrow p-0 text-start text-md-end'>
					<ArrowIcon className={isOpen ? 'arrow-angle-up' : 'arrow-angle-down'}  />
				</Col>
			</Row>
		</>
	)
}

export default CustomerName