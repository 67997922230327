import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { ReactElement } from "react";
import './confirmModal.scss'

import { labels } from 'features/locale/it-it'
import { propsModalForm } from './ConfirmModalProps';
import { parcelKeys } from 'features/storeManagement/services/models/ParcelResponse';
import { Regex } from 'components/global/Utils';

const ConfirmModal = ({ showConfirmModal, setShowConfirmModal, isLoading, isFormValid, headerMessage, bodyMessage, onConfirm, idForm, headerOn = true, classCenterButton = false, data, checkObj, confirmButtonText, annullaVariant, buttonClass, setFormData, isValidEmail, setIsValidEmail, isFromKey }:
	propsModalForm) => {
	let dataCheckflag: string | undefined = undefined

	if (data && data[`${labels.parcelCompany} `] && data[`${labels.parcelCompany} `][0].trim() !== "" && checkObj?.societa === true) {
		dataCheckflag = data[`${labels.parcelCompany} `][0]
	}else if(data && data[`${labels.parcelLastname} `] && data[`${labels.parcelLastname} `][0].trim() !== "" && checkObj?.societa === false && checkObj.cognome === true){
		dataCheckflag = data[`${labels.parcelLastname} `][0]
	}

	if(isFromKey && data && data[`${labels.parcelLastname} `]){
		dataCheckflag = data[`${labels.parcelLastname} `][0]
	}
	
	useEffect(()=>{
		if(setIsValidEmail){
			if(data && data[`${labels.parcelEmail} `] && data[`${labels.parcelEmail} `][0] !== ""){
				const emailRegex = new RegExp(Regex.Email);
					
				setIsValidEmail(emailRegex.test(data[`${labels.parcelEmail} `][0]))
			}else {
				setIsValidEmail(true)
			}
		}
	},[data])

	const handleDisableButton = () => {
		
		return (
			isLoading || 
			(isValidEmail === false) ||
			(isFormValid === false) ||
			(!checkObj ? false : dataCheckflag === undefined || dataCheckflag === "")
		)		
	}

	return (
		<Modal
			className='confirm-popup-container'
			show={showConfirmModal}
			onHide={() => setShowConfirmModal(false)}
			backdrop={isLoading ? "static" : undefined}
			centered
		>
			{headerOn &&
				<Modal.Header className='moda-header h4'>
					{headerMessage}
				</Modal.Header>}
			<Modal.Body className='confirm-popup-body'>
				{bodyMessage}
			</Modal.Body>
			<Modal.Footer
				className={classCenterButton === true ? 'confirm-popup-buttons-container confirm-popup-buttons-container--center' : 'confirm-popup-buttons-container'}
			>
				<Button
					type="button"
					variant={annullaVariant ? annullaVariant : "green"}
					className={buttonClass ? buttonClass : `confirm-popup-no-button`}
					onClick={() => {
						setShowConfirmModal(false)
						if(setFormData){
							setFormData({ })
						}
					}}					
					disabled={isLoading}
				>{labels.cancel}</Button>
				<Button
					type={onConfirm ? "button" : "submit"}
					id="submit"
					variant='green'
					form={idForm ? idForm : ""}
					className={buttonClass ? buttonClass : `confirm-popup-yes-button`}
					disabled={handleDisableButton()}
					onClick={onConfirm ? onConfirm : () => { }}
				>{isLoading ? <Spinner /> : confirmButtonText ? confirmButtonText : labels.confirm}</Button>

			</Modal.Footer>
		</Modal>
	)
}

export default ConfirmModal