import { FC } from "react";
import { Container } from "react-bootstrap";
import {} from "theme/data/dashboard/default";
import { useGetModulesMutation } from "features/storeManagement/services/modulesService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { labels } from "features/locale/it-it";
import "./style.scss";
import { useChangeDocumentTitle } from "features/hooks/useChangeDocumentTitle";
import BoxDetails from "components/content/BoxDetails/BoxDetails";
import ModulesListReport from "components/modules/ModuleListReport/ModuleListReport";
import { useGetReportListMutation } from "features/storeManagement/services/reportService";
import Suspense from "features/Suspense";
import { useGetModuliCustomerMutation } from "features/storeManagement/services/customerService";
import ModulesCustomer from "components/modules/ModulesCustomer/ModulesCustomer";
import ErrorBoundary from "features/ErrorBoundary";

const CustomerPage: FC = function CustomerPage() {
	const company = sessionStorage.getItem('companies') || localStorage.getItem('companies')

	const [, { data: modulesData }] = useGetModulesMutation({
		fixedCacheKey: fixedCacheKeys.getModules,
	});
	
	useChangeDocumentTitle(labels.pageTile_ClientePage);

	const [
		getModuliCustomer,
		{
			data: moduliCustomer,
			isLoading: moduliCustomerIsLoading,
			isError: moduliCustomerIsError,
			error: moduliCustomerError,
		},
	] = useGetModuliCustomerMutation({
		fixedCacheKey: fixedCacheKeys.moduliCustomer,
	});

	const [
		,
		{ data: reportData, isError: reportIsError, error: reportError, isLoading: reportIsLoading },
	] = useGetReportListMutation({ fixedCacheKey: fixedCacheKeys.reportsData });


	return (
		<ErrorBoundary errorMessage={moduliCustomerError || reportError}>
			<Container
				fluid
				className="box-details-container d-flex customer-container justify-content-around align-items-start p-0 px-4 mb-0 mt-4"
			>
				<Suspense isLoading={reportIsLoading || moduliCustomerIsLoading} fullPage>
					<Container>
						{
							!!company && 
								<BoxDetails flagClientePage={true} companyName={JSON.parse(company)[0]} />
						}
						
						{!!moduliCustomer &&
							<ModulesCustomer checklists={moduliCustomer?.checklists} documents={moduliCustomer?.documents}/>
						}

						{!!reportData && (
							<ModulesListReport report={reportData} />
						)}
					</Container>
				</Suspense>
			</Container>
		</ErrorBoundary>
	);
};

export default CustomerPage;
