import { labels } from "features/locale/it-it";
import {
	ChecklistFormStateType,
	FormDataAnswersToPost,
	FormDataAnswersToPut,
	Section,
} from "features/storeManagement/services/models/ChecklistResponse";
import {
	FormDataAnswersToPostIncident,
	FormDataAnswersToPutIncident,
	FormDataPostModel,
	FormDataPutModel,
} from "features/storeManagement/services/models/IncidentRequest";
import {
	AnswerPostOneParcel,
	WareHousings,
} from "features/storeManagement/services/models/ParcelsResponse";
export const updateState = {
	updateTextState: (
		text: string,
		setFormData: Function,
		sectionAndQuestionIdAndID: string
	) => {
		setFormData((oldState: ChecklistFormStateType) => {
			return { ...oldState, [sectionAndQuestionIdAndID]: [text] };
		});
	},

	updateRadioState: (
		value: string,
		setFormData: Function,
		sectionAndQuestionIdAndID: string
	) => {
		setFormData((oldState: ChecklistFormStateType) => {
			if (value.length > 0) {
				return { ...oldState, [sectionAndQuestionIdAndID]: [value] };
			}
		});
	},

	updateMultipleCheckboxState: (
		checkboxes: string[],
		setFormData: Function,
		sectionAndQuestionIdAndID: string
	) => {
		setFormData((oldState: ChecklistFormStateType) => {
			return { ...oldState, [sectionAndQuestionIdAndID]: checkboxes };
		});
	},
};

export const formDataIncidentToApiResponseTransformer = (
	formData: ChecklistFormStateType,
	shiftId: string,
	status: number
) => {
	const toPost = () => {
		let answersToPost: FormDataPostModel[] = [];
		for (const answerKey in formData) {
			if (formData[answerKey].length > 0 && formData[answerKey][0] !== "") {
				answersToPost.push(
					(() => {
						const theAnswerIncident: FormDataPostModel = formData[answerKey][0] && formData[answerKey][0].includes("base64") ? {
							questionId: Number(answerKey.split(" ")[1]),
							medias: formData[answerKey].map(media => {
								return {
									mediaValue: media
								}
							}),
							value:[]
						} : {
							questionId: Number(answerKey.split(" ")[1]),
							value: formData[answerKey],
						};
						return theAnswerIncident;
					})()
				);
			}
		}
		return {
			shiftId: shiftId,
			status: status,
			answers: answersToPost,
		} as FormDataAnswersToPostIncident;
	};

	const toPut = () => {
		let answersToPut: FormDataPutModel[] = [];
		for (const answerKey in formData) {
			answersToPut.push(
				(() => {
					let base64Temp:any = [];
					let valueTemp:any = [];

					formData[answerKey].map(el => {
						if(el.includes("base64")) {
							base64Temp.push(el)
						}else{
							valueTemp.push(el)
						}
					})

					let theAnswerIncident: FormDataPutModel = 
						{
							questionId: Number(answerKey.split(" ")[1]),
							id: Number(answerKey.split(" ")[2]) || null,
							medias: base64Temp.map((media:any) => {
								return {
									mediaValue: media
								}
							}),
							value: valueTemp,
						}
					return theAnswerIncident;
				})()
			);
		}
		return {
			shiftId: shiftId || "",
			status: status,
			answers: answersToPut,
		} as FormDataAnswersToPutIncident;
	};
	return { toPost, toPut };
};

export const formDataToApiResponseTransformer = (
	formData: ChecklistFormStateType,
	shiftId: string,
	serviceAddress?: string
) => {
	const toPost = () => {
		let answersToPost: FormDataAnswersToPost[] = [];

		for (const answerKey in formData) {
			if (formData[answerKey].length > 0 && formData[answerKey][0] !== "") {
				answersToPost.push(
					(() => { {}
						const theAnswer: FormDataAnswersToPost = formData[answerKey][0] && formData[answerKey][0].includes("base64") ? {
							sectionId: answerKey.split(" ")[0],
							questionId: answerKey.split(" ")[1],
							medias: formData[answerKey].map(media => {
								return {
									mediaValue: media
								}
							}),
							value:[]
						} : {
							sectionId: answerKey.split(" ")[0],
							questionId: answerKey.split(" ")[1],
							value: formData[answerKey],
						};
						return theAnswer;
					})()
				);
			}
		}

		return {
			shiftId: shiftId,
			answers: answersToPost,
			serviceAddress: serviceAddress
		};
	};

	const toPut = () => {
		let answersToPut: FormDataAnswersToPut[] = [];

		for (const answerKey in formData) {
			answersToPut.push(
				(() => {
					let base64Temp:any = [];
					let valueTemp:any = [];

					formData[answerKey].map(el => {
						if(el.includes("base64")) {
							base64Temp.push(el)
						}else{
							valueTemp.push(el)
						}
					})

					let theAnswerIncident: FormDataAnswersToPut = 
						{
							sectionId: answerKey.split(" ")[0],
							questionId: answerKey.split(" ")[1],
							id: Number(answerKey.split(" ")[2]) || undefined,
							medias: base64Temp.map((media:any) => {
								return {
									mediaValue: media
								}
							}),
							value: valueTemp,
						}
					return theAnswerIncident;
				})()
			);
		}

		return {
			shiftId: shiftId,
			answers: answersToPut,
		};
	};
	return { toPost, toPut };
};

export const apiResponseToFormDataTransformer = (sections?: Section[]) => {
	const formData: ChecklistFormStateType = {};
	sections &&
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				let theKey = `${section.id} ${question.id}`;
				if (question.answerDetail) {
					theKey = `${section.id} ${question.id} ${question.answerDetail.id}`;
					formData[theKey] = question.answerDetail.value;
				}
			});
		});
	return formData;
};

export const formDataParcelAnswersToApiRequestTransformer = (
	formData: ChecklistFormStateType,
	warehouses: WareHousings[] | undefined
) => {
	const toPost = () => {
		let answersToPost: AnswerPostOneParcel[] = [];

		for (const answerKey in formData) {
			if (formData[answerKey].length > 0 &&
				formData[answerKey][0] !== "" &&
				answerKey !== `${labels.parcelSelectKey} ` &&
				answerKey !== `${labels.parcelDeliveryPersonKey} ` &&
				answerKey !== `${labels.parcelRecipientPersonKey} `	) {

				answersToPost.push(
					(() => { {}
						const theAnswer: AnswerPostOneParcel = formData[answerKey][0] && formData[answerKey][0].includes("base64") ? {
							questionId: Number(answerKey),
							medias: formData[answerKey].map(media => {
								return {
									mediaValue: media
								}
							}),
							value:[]
						} : {
							questionId: Number(answerKey),
							value: formData[answerKey],
						};
						return theAnswer;
					})()
				)
			}
		}
		
		const warehouseDetails: WareHousings | undefined = warehouses?.filter(
			(warehouse) => {
				let arrLabel = [warehouse.building, warehouse.buildingStory, warehouse.room, warehouse.shelf, warehouse.shelfTier];
								
				const parcel = warehouse;

				const parts = [
					parcel?.building ? `Edificio: ${parcel.building}` : "",
					parcel?.buildingStory ? `Piano: ${parcel.buildingStory}` : "",
					parcel?.room ? `Stanza: ${parcel.room}` : "",
					parcel?.shelf ? `Scaffale: ${parcel.shelf}` : "",
					parcel?.shelfTier ? `Ripiano: ${parcel.shelfTier}` : "",
				].filter(Boolean);

				const optionToReturn = parts.join(" - ").replace(/ - $/, "");
				
				if (optionToReturn === formData[`${labels.parcelSelectKey} `][0]) {
					return warehouse;
				}
			}
		)[0];

		return {
			answers: answersToPost,
			warehouseId: warehouseDetails?.id || 0,
			warehouseShelfTier: Number(warehouseDetails?.shelf) || 0,
			parcelDeliveryPersonId: Number(
				formData[`${labels.parcelDeliveryPersonKey} `][0]
			),
			parcelRecipientPersonId: Number(
				formData[`${labels.parcelRecipientPersonKey} `][0]
			),
		};
	};
	return { toPost };
};
